import { toast } from "react-toastify";
import { useState, useEffect } from "react";

import {
  RegisterClinicForm,
  URL_CODE_PARAM,
} from "@components/forms/RegisterClinicForm";
import { useRegisterClinicMutation } from "@hooks/queries";
import { useAppTranslation } from "@hooks";
import { AlloweatLayout } from "@layouts/AlloweatLayout";
import useGoogleTagManagerScript from "@hooks/helpers/useGoogleTagManagerScript";

import { ConfirmSignUpView } from "./components/ConfirmSignUpView";
import {
  REGISTER_CLINIC_30_FORM_ID,
  REGISTER_CLINIC_FORM_ID,
  useTrackUserForm,
} from "./useTrackUserForm";
import { useSearchParams } from "react-router-dom";

export const RegisterClinic = () => {
  const { t } = useAppTranslation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get(URL_CODE_PARAM);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [resendEmail, setResendEmail] = useState("");
  const { register } = useTrackUserForm(
    import.meta.env.VITE_APP_ENABLE_USERCOM === "true",
  );

  const { registerClinic, isLoading } = useRegisterClinicMutation({
    onSuccess: (data, variables) => {
      setOpenConfirm(true);
      register({
        firstName: variables?.firstName ?? "",
        lastName: variables?.lastName ?? "",
        email: variables?.email ?? "",
        formId: code ? REGISTER_CLINIC_30_FORM_ID : REGISTER_CLINIC_FORM_ID,
        userId: data?.data.owner.id ?? 0,
        code,
        phoneNumber: variables?.phoneNumber,
      });
    },
    onError: e => {
      if (!(e.response.status >= 400 && e.response.status < 500))
        toast.error(t("auth.login.api_error"));
      console.log("err", e);
    },
  });

  const { handleHeadScript } = useGoogleTagManagerScript();

  useEffect(() => {
    const removeHeadScript = handleHeadScript();

    return () => removeHeadScript();
  }, [handleHeadScript]);

  return (
    <AlloweatLayout>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-THMSVKW"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      {openConfirm && !isLoading ? (
        <ConfirmSignUpView resendEmail={resendEmail} />
      ) : (
        <RegisterClinicForm
          onClick={setOpenConfirm}
          isLoading={isLoading}
          registerClinic={registerClinic}
          setResendEmail={setResendEmail}
        />
      )}
    </AlloweatLayout>
  );
};
