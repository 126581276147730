import { SummaryCardWrapper } from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCard.styled";
import SummaryCardTitle from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCardTitle";
import { useAppTranslation } from "@hooks";
import { PatientMealsMonitoringResource } from "@client/resources/PatientMealsMonitoringResource";
import { useTagsUtils } from "@hooks/useTagsUtils";
import { useMemo, useState } from "react";
import { Chip, Stack, Tooltip } from "@mui/material";
import { FetchTagCategoriesTag } from "@client";
import { SymptomsIcon } from "@icons/index";
import SymptomsDrawer from "@views/dietician/PatientMonitoring2/components/Symptoms/SymptomsDrawer";

type SymptomsSummaryCardProps = {
  tags: PatientMealsMonitoringResource["meals"][0]["tags"];
};
const SymptomsSummaryCard = ({ tags }: SymptomsSummaryCardProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const [open, setOpen] = useState(false);
  const { getTagById } = useTagsUtils();

  const tagsDictionary = useMemo(
    () =>
      tags
        .map(tag => getTagById(tag.id))
        .filter(t => !!t) as FetchTagCategoriesTag[],
    [getTagById, tags],
  );

  return (
    <>
      <SummaryCardWrapper
        onClick={() => setOpen(true)}
        sx={{ cursor: "pointer" }}
      >
        <Stack display="flex" flexDirection="column" spacing="8px">
          <SummaryCardTitle
            title={t("monitoring2.summary_cards.symptoms_title")}
            icon={<SymptomsIcon color="#7448D0" fontSize={24} />}
          />

          <Stack display="flex" flexDirection="row" gap="8px">
            {tagsDictionary.slice(0, 2).map(tag => (
              <Chip
                key={tag.id}
                color="primary"
                label={isPolishChosen ? tag?.namePl : tag?.nameEn}
              />
            ))}
            {tagsDictionary.length > 2 && (
              <Tooltip
                title={
                  <Stack
                    display="flex"
                    flexDirection="row"
                    gap="8px"
                    flexWrap="wrap"
                    maxWidth="300px"
                  >
                    {tagsDictionary.slice(2, -1).map(tag => (
                      <Chip
                        key={tag.id}
                        color="primary"
                        label={isPolishChosen ? tag?.namePl : tag?.nameEn}
                      />
                    ))}
                  </Stack>
                }
                arrow
                componentsProps={{
                  tooltip: { sx: { backgroundColor: "#fff", padding: "8px" } },
                }}
              >
                <Chip
                  variant="outlined"
                  color="primary"
                  sx={{ background: "#fff", borderColor: "#F5F1FE" }}
                  label={`+${tagsDictionary.length - 2}`}
                />
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </SummaryCardWrapper>
      <SymptomsDrawer onClose={() => setOpen(false)} open={open} />
    </>
  );
};

export default SymptomsSummaryCard;
