import { Stack } from "@mui/material";
import { ProgramRowType } from "@views/dietician/PatientPrograms/Grid/RowType";
import {
  PatientProgramsGridStyled,
  SecondText,
} from "@views/dietician/PatientPrograms/PatientProgramsGrid.styled";
import { CalendarMonitoring } from "@assets/icons/Monitoring";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";

const NameColumn = ({ program }: ProgramRowType) => {
  const { t, currentLanguage } = useAppTranslation();

  const name = useMemo(
    () =>
      program.translations.find(t => t.lang === currentLanguage)?.name ??
      program.name,
    [program, currentLanguage],
  );

  return (
    <Stack>
      <PatientProgramsGridStyled>{name}</PatientProgramsGridStyled>
      <Stack direction="row" gap="16px" alignItems="center">
        <CalendarMonitoring height="12" fill="#727272" />
        <SecondText>
          {t("patient.programs_grid.row.type_days.days.c", {
            count: program.programDaysCount,
            variable: program.programDaysCount,
          })}
        </SecondText>
      </Stack>
    </Stack>
  );
};

export default NameColumn;
