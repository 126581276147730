//TODO: Add all endpoints here

export enum ApiEndpoints {
  Tasks = "/dietitian/tasks",
  SuggestedTasks = "/dietitian/suggested-tasks",
  SuggestedTasksCount = "/dietitian/suggested-tasks-count",
  SuggestedTasksList = "/dietitian/suggested-tasks-list",
  AccountNotes = "/dietitian/account/notes",
  TasksCount = "/dietitian/number-of-tasks",
  CollectionsSearchPreview = "/dietitian/collections/search-preview",
  SearchScheduleEvents = "/dietitian/schedules/search",
  ProgramSectionCategories = "/dietitian/programs/sections/categories",
  SearchSectionsNew = "/dietitian/program-section-templates/search-new",
  UpdateSectionFavorite = "/dietitian/program-section-templates/favorite",
  UploadEducationalMaterial = "/dietitian/media/educational-material/upload",
}
