import { Stack, styled } from "@mui/material";

type NutrientsLabelLayoutProps = {
  label: string;
  description?: string;
};

const NutrientsLabelLayout = ({
  label,
  description,
}: NutrientsLabelLayoutProps) => {
  return (
    <Stack direction="column" gap="">
      <Title>{label}</Title>
      {Description && <Description>{description}</Description>}
    </Stack>
  );
};

const Title = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  color: #333333;
`;

const Description = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 12px;
  line-height: 20px;
  color: #727272;
`;

export default NutrientsLabelLayout;
