import { useMemo, useState } from "react";

import { Radio, Typography, useTheme } from "@mui/material";

import { Loupe } from "@assets/icons";
import { Pagination } from "@components/PaginationNew";
import { useAppParams, useAppTranslation } from "@hooks";
import { LangDto } from "@client";
import { useFetchClientQuery } from "@hooks/queries";
import {
  useFetchSurveysQuery,
  usePostPatientSendSurveyMutation,
} from "@hooks/queries/surveys";
import { getPaginatedSlice, mapElementsCountLabel } from "@utils/pagination";

import { FormControlLabelStyled } from "../AddQuestionnaireModal/AddQuestionnaireModal.styled";
import { ChipsSelect } from "../AddQuestionnaireModal/ChipsSelect";
import { CustomRadioLabel } from "../AddQuestionnaireModal/CustomRadioLabel";
import { DEFAULT_PER_PAGE } from "../QuestionnairesList/QuestionnairesList";
import { ModalWrapper } from "../components/ModalWrapper/ModalWrapper";
import {
  ModalLabel,
  PaginationTypography,
  PaginationWrapper,
  RadioGroupStyled,
  TextFieldStyled,
} from "./SendQuestionnaireModal.styled";
import { PATIENTS } from "@routes";
import { useNavigate } from "react-router-dom";

interface SendQuestionnaireModalProps {
  onSubmit?: () => void;
  onClose: () => void;
  open: boolean;
  newClientId?: string;
  onSuccess?: () => void;
}

export const SendQuestionnaireModal = ({
  onSubmit,
  onClose,
  open,
  newClientId = "",
  onSuccess,
}: SendQuestionnaireModalProps) => {
  const { t } = useAppTranslation();
  const { colors } = useTheme();
  const { patientId } = useAppParams();
  const navigate = useNavigate();

  useFetchClientQuery(patientId || newClientId, {
    enabled: !!patientId || !!newClientId,
    onSuccess: data => {
      const clientLang = data?.data.profile.lang;
      setLangId(
        langOptions.find(l => l.label.toLowerCase() === clientLang)?.id ?? 1,
      );
    },
  });

  const { mutate } = usePostPatientSendSurveyMutation();
  const { surveys } = useFetchSurveysQuery();
  const isOnePage = !!surveys && surveys.length <= DEFAULT_PER_PAGE;
  const [langId, setLangId] = useState(1);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [selectedSurveyId, setSelectedSurveyId] = useState<number | null>(null);

  const langOptions = useMemo(
    () => [
      {
        id: 1,
        label: "PL",
      },
      {
        id: 2,
        label: "EN",
      },
    ],
    [],
  );

  const filteredOptions = useMemo(
    () =>
      (surveys ?? [])
        .filter(s => (langId !== 0 ? s.langId === langDict[langId] : true))
        .filter(s =>
          query
            ? s.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
            : true,
        ),
    [surveys, langId, query, page],
  );

  const paginatedSurveys = getPaginatedSlice(
    filteredOptions ?? [],
    page,
    DEFAULT_PER_PAGE,
  );
  const pageCount = Math.ceil(filteredOptions.length / DEFAULT_PER_PAGE);

  const handleCloseAndReset = () => {
    setSelectedSurveyId(null);
    onClose();
  };

  const handleSubmit = () => {
    if (selectedSurveyId === null) return;
    patientId
      ? mutate(
          { patientId, surveyId: selectedSurveyId },
          {
            onSuccess: () => {
              onSuccess?.();
              handleCloseAndReset();
            },
          },
        )
      : mutate(
          { patientId: newClientId, surveyId: selectedSurveyId },
          {
            onSuccess: () => {
              handleCloseAndReset;
              navigate(`${PATIENTS}/${newClientId}/info-and-services`);
            },
          },
        );
    onSubmit && onSubmit();
  };

  return (
    <ModalWrapper
      onClose={handleCloseAndReset}
      onSubmit={handleSubmit}
      open={open}
      submitText={t("common.send")}
      title={t("questionnaires.send_modal.send")}
      disableSubmit={selectedSurveyId === null}
    >
      <div className="grid gap-4">
        <Typography>{t("questionnaires.send_modal.title")}</Typography>

        <div className="grid gap-1">
          <ModalLabel>{t("questionnaires.language")}</ModalLabel>
          <ChipsSelect
            onChange={setLangId}
            selectedId={Number(langId)}
            options={langOptions}
          />
        </div>

        <TextFieldStyled
          size="small"
          placeholder={t("common.search")}
          value={query}
          onChange={e => {
            setQuery(e.target.value);
            setPage(0);
          }}
          InputProps={{
            startAdornment: (
              <Loupe className="mr-2" fill={colors.neutral.dark[700]} />
            ),
          }}
        />

        <RadioGroupStyled
          value={selectedSurveyId}
          onChange={(_, v) => setSelectedSurveyId(Number(v))}
        >
          {paginatedSurveys.map(({ id, name }) => (
            <FormControlLabelStyled
              key={id}
              value={id}
              control={<Radio />}
              label={<CustomRadioLabel label={name} />}
            />
          ))}
        </RadioGroupStyled>
      </div>

      <PaginationWrapper>
        <PaginationTypography>
          {mapElementsCountLabel(
            filteredOptions.length,
            page,
            DEFAULT_PER_PAGE,
          )}{" "}
          {t("common.of")} {filteredOptions.length}
        </PaginationTypography>
        <Pagination
          isOnePage={isOnePage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          definedPageCount={pageCount}
        />
      </PaginationWrapper>
    </ModalWrapper>
  );
};

export const langDict: Record<number, LangDto> = {
  1: LangDto.PL,
  2: LangDto.EN,
};
