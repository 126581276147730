import {
  BoxTitleContainer,
  BoxWrapper,
} from "@views/dietician/PatientMonitoring2/components/BoxWrapper";
import { Stack } from "@mui/material";
import { useAppTranslation } from "@hooks";
import MonitoringDivider from "@views/dietician/PatientMonitoring2/components/MonitoringDivider";
import MeasurementsCompareSection from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsCompareSection";
import MeasurementsBodySectionFactory from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBodySectionFactory";
import MeasurementsPhotosSection from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsPhotosSection";
import { WeightMonitoring } from "@assets/icons/Monitoring";
import MeasurementDrawer from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Drawer/MeasurementDrawer";

const MeasurementsBox = () => {
  const { t } = useAppTranslation();
  return (
    <BoxWrapper>
      <Stack spacing="24px" direction="column">
        <Stack direction="row" justifyContent="space-between">
          <BoxTitleContainer
            icon={<WeightMonitoring fill="#727272" />}
            title={t("monitoring2.measurements_box.title")}
          />
          <MeasurementDrawer />
        </Stack>
        <MeasurementsBodySectionFactory />
        <MonitoringDivider />
        <MeasurementsCompareSection />
        <MeasurementsPhotosSection />
      </Stack>
    </BoxWrapper>
  );
};

export default MeasurementsBox;
