import { Chip, Stack } from "@mui/material";

import { OuterLabel } from "@components/OuterLabel";
import { useFetchGlobalPatientSettingsQuery } from "@hooks/queries/clinic";

import { Card, CardHeader } from "../components";
import { mapChipsFromObject } from "../utils";
import { EditBasesModal } from "../modals/EditBasesModal";
import { useAppTranslation, useModalState } from "@hooks";
import { FetchGlobalPatientSettingsResponse } from "@client/clinic/fetchGlobalPatientSettings";

export const Databases = () => {
  const { data } = useFetchGlobalPatientSettingsQuery();

  if (!data) return null;
  return <DatabasesContent data={data} />;
};

export interface ContentProps {
  data: FetchGlobalPatientSettingsResponse;
}

const DatabasesContent = ({ data }: ContentProps) => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const {
    bases: { recipeBasesAvailable, exchangersProducts, autoFilters },
  } = data.data;

  return (
    <Card>
      <CardHeader
        onEdit={onOpen}
        title={t("cooperation_settings.databases.recipe_product_databases")}
      />

      <OuterLabel
        label={t("cooperation_settings.databases.available_databases")}
      >
        <Stack direction="row" gap="8px">
          {mapChipsFromObject(recipeBasesAvailable, t)}
        </Stack>
      </OuterLabel>
      <OuterLabel
        label={t("cooperation_settings.databases.exchangers")}
        tooltip={t("cooperation_settings.databases.exchangers_description")}
      >
        {Object.values(exchangersProducts).some(value => value) ? (
          mapChipsFromObject(exchangersProducts, t)
        ) : (
          <Chip
            color="primary"
            label={t("cooperation_settings.databases.traditional")}
          />
        )}
      </OuterLabel>

      <EditBasesModal
        open={open}
        onClose={onClose}
        bases={{ recipeBasesAvailable, exchangersProducts, autoFilters }}
      />
    </Card>
  );
};
