import { LastLoginSummaryCard } from "@views/dietician/PatientMonitoring2/components/SummaryCard/LastLoginSummaryCard";
import KcalSummaryCardFactory from "@views/dietician/PatientMonitoring2/components/SummaryCard/Kcal/KcalSummaryCardFactory";
import MealsSummaryCardFactory from "@views/dietician/PatientMonitoring2/components/SummaryCard/Meals/MealsSummaryCardFactory";
import IrrigationSummaryCardFactory from "@views/dietician/PatientMonitoring2/components/SummaryCard/Irrigation/IrrigationSummaryCardFactory";
import { styled } from "@mui/material";
import SymptomsSummaryCardFactory from "@views/dietician/PatientMonitoring2/components/Symptoms/SummaryCard/SymptomsSummaryCardFactory";

const SummaryCardsBox = () => {
  return (
    <SummaryCardsContainer className="hide-scrollbar">
      <LastLoginSummaryCard />
      <KcalSummaryCardFactory />
      <MealsSummaryCardFactory />
      <IrrigationSummaryCardFactory />
      <SymptomsSummaryCardFactory />
    </SummaryCardsContainer>
  );
};

const SummaryCardsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 12px;
  overflow-x: auto;
`;

export default SummaryCardsBox;
