import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

import { Edit } from "@assets/icons";
import {
  useAppTranslation,
  useProgramSectionsDetailedDictionary,
} from "@hooks";
import {
  CreateSectionModal,
  EditSectionModal,
} from "@views/dietician/ProgramForm/components";
import { Minus } from "@assets/icons/Program";
import { IconButton } from "@components/IconButton";

import { ButtonsWrapper, StyledCard } from "./ProgramSectionsCard.styled";
import { DownloadSectionPdf } from "./DownloadSectionPdf";
import { useFetchProgramPdfBasicQuery } from "@hooks/queries";
import { useProgram } from "@hooks/resources";
import { ThemeProviderWrapperNew } from "themeNew";
import {
  ModalType,
  useEducationalModalContext,
} from "@views/dietician/EducationalMaterials/_hooks/useEducationalMaterialsContext";

interface ProgramSectionsCardProps {
  sections: number[];
  removeSection: (sectionId: number) => void;
  canEdit: boolean;
  programId: number;
  patientId?: number;
}

export const ProgramSectionsCard = ({
  programId,
  sections,
  removeSection,
  canEdit,
}: ProgramSectionsCardProps) => {
  const { pdfData } = useFetchProgramPdfBasicQuery(programId, {
    refetchOnWindowFocus: false,
  });
  const { program } = useProgram();
  const { t, isPolishChosen } = useAppTranslation();

  const navigate = useNavigate();

  const { closeModal, getModalState, openModal } = useEducationalModalContext();

  const { sectionsDictionary, isSuccess: isSectionsSuccess } =
    useProgramSectionsDetailedDictionary(programId);

  const handleEditSections = useCallback(() => navigate(`edit`), []);

  return (
    <>
      <StyledCard
        header={t("program.summary.program_sections_card.title")}
        action={
          <IconButton onClick={handleEditSections} disabled={!canEdit}>
            <Edit size="w-5 h-5" />
          </IconButton>
        }
      >
        {sections &&
          pdfData &&
          program &&
          isSectionsSuccess &&
          sections.map(sectionId => (
            <div
              key={sectionId}
              className="flex justify-between items-center pt-4"
            >
              <p>
                {
                  sectionsDictionary[sectionId]?.[
                    isPolishChosen ? "title" : "titleEn"
                  ]
                }
              </p>
              <ButtonsWrapper>
                <>
                  {!sectionsDictionary[sectionId]?.isSystemic && (
                    <IconButton
                      onClick={() =>
                        openModal(
                          ModalType.CREATE_DOCUMENT,
                          sectionId.toString(),
                        )
                      }
                      disabled={!canEdit}
                    >
                      <Edit size="w-5 h-5" />
                    </IconButton>
                  )}
                  <DownloadSectionPdf
                    sectionTitle={sectionsDictionary[sectionId]?.title ?? ""}
                    pdf={pdfData}
                    langId={program.langId}
                    kcal={program.showCals}
                    macros={program.showMacros}
                    micros={program.showNutrients}
                    programId={programId}
                    sectionId={sectionId}
                  />
                </>
                <IconButton
                  onClick={() => removeSection(sectionId)}
                  disabled={!canEdit}
                >
                  <Minus size="w-5 h-5" />
                </IconButton>
              </ButtonsWrapper>
            </div>
          ))}
      </StyledCard>
      <ThemeProviderWrapperNew>
        <CreateSectionModal
          onClose={() => closeModal(ModalType.CREATE_DOCUMENT)}
          open={getModalState(ModalType.CREATE_DOCUMENT)}
          programId={programId}
        />
      </ThemeProviderWrapperNew>
    </>
  );
};
