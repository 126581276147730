import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { Checkbox, Dialog, FormControlLabel, useTheme } from "@mui/material";
import dayjs from "dayjs";

import { CheckedIcon, Plus } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import {
  useCreateScheduleEventMutation,
  useFetchClientQuery,
  useFetchClientsQuery,
  useFetchClinicProducts,
  useFetchCurrencies,
  useFetchScheduleEvent,
  useFetchSchedulesDictionariesQuery,
  useUpdateScheduleEvent,
} from "@hooks/queries";
import { MEETING_TYPE } from "@utils/scheduleEvent";
import {
  CreateClientResponse,
  CreateScheduleEventRequest,
  CreateScheduleEventResponse,
} from "@client";
import { AddClientModal, StyledTooltip } from "@components/AddClientModal";
import { SearchClientsAutocomplete } from "@components/SearchClientsAutocomplete";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";
import { FormLabeledCheckboxMui } from "@components/FormCheckboxMui";

import { ApiError } from "@components/ApiError";
import { FormTextField } from "@components/FormTextField";
import { FormAutocomplete } from "@components/FormAutocomplete";
import { PATIENTS } from "@routes";

import {
  AddEventModalInput,
  EVENT_ONLINE_LOCATION,
  mapAddVisitForm,
  mapAddVisitRequest,
  useAddVisitForm,
} from "./useAddVisitForm";
import {
  ClientAddWrapper,
  DateTimeWrapper,
  DecisionBtnsSection,
  Delimeter,
  FormControlLabelStyled,
  MainSection,
  PriceSection,
  StyledAddBtn,
  StyledCancelBtn,
  StyledSubmitBtn,
  StyledTextArea,
  VisitModalWrapper,
} from "./AddVisitModal.styled";
import { EventTypeCards } from "./components/EventTypeCards";
import { ApiResponse } from "@typeDefinitions";

export interface TransitionDuration {
  appear?: number | undefined;
  enter?: number | undefined;
  exit?: number | undefined;
}

interface AddVisitModalProps {
  onClose?: () => void;
  onOpen: boolean;
  edit?: boolean;
  id?: number;
  onlyDates?: boolean;
  defaultClient?: number;
  transitionDuration?: number | TransitionDuration;
  navigateToVisit?: boolean;
  onSuccess?: () => void;
}

export const AddVisitModal = ({
  onClose,
  onOpen,
  edit = false,
  onlyDates = false,
  id,
  defaultClient,
  transitionDuration,
  navigateToVisit,
  onSuccess,
}: AddVisitModalProps) => {
  const [addClientModal, setAddClientModal] = useState(false);
  const [clientId, setClientId] = useState<number | undefined>(undefined);
  const [transition, setTransition] = useState(transitionDuration);

  const navigate = useNavigate();
  const { t, isPolishChosen } = useAppTranslation();
  const { palette, transitions } = useTheme();
  const { account } = useFetchDietitianAccountQuery();

  const { clients, isError, isSuccess } = useFetchClientsQuery(undefined, {
    enabled: !!account?.clinic?.id,
    keepPreviousData: true,
  });
  const { client } = useFetchClientQuery(clientId ?? defaultClient ?? 0, {
    enabled: !!clientId || !!defaultClient,
  });
  const { schedulesDictionaries } = useFetchSchedulesDictionariesQuery();
  const { currencies } = useFetchCurrencies();
  const { clinicProducts } = useFetchClinicProducts();
  const { mutate: createScheduleEvent } = useCreateScheduleEventMutation({
    onSuccess: (d, payload) => {
      if (
        navigateToVisit &&
        payload?.meetingTypeId === MEETING_TYPE.CONSULTATION
      )
        navigate(
          `${PATIENTS}/${d?.data.patient?.id}/appointments#${d?.data.id}`,
        );
      onSuccess?.();
    },
  });
  const { mutate: updateScheduleEvent } = useUpdateScheduleEvent(id ?? 0);
  const { scheduleEvent } = useFetchScheduleEvent(id ?? 0, { enabled: edit });

  const eventData = useMemo(
    () => mapAddVisitForm(scheduleEvent),
    [scheduleEvent],
  );
  const newClientLabel = useMemo(
    () =>
      client
        ? {
            id: client.id.toString() ?? "",
            label: client.firstName + " " + client.lastName,
            email: client.email,
          }
        : {
            id: "",
            label: "",
          },
    [client, defaultClient],
  );

  const form = useAddVisitForm(eventData);
  const tab = form.watch("eventType");
  const isAllDayValue = form.watch("isAllDay");
  const startDate = form.watch("startDate");
  const productId = form.watch("clinicProductId");
  const currency = form.watch("currency");

  const clientsMapped = useMemo(
    () => [
      ...(clients?.map(client => ({
        id: client.id.toString(),
        label: client.firstName + " " + client.lastName,
        email: client.email,
      })) ?? []),
      newClientLabel,
    ],
    [clients, newClientLabel],
  );

  const dietitiansMapped = schedulesDictionaries?.dietitians.map(dietitian => ({
    id: dietitian.id.toString(),
    label: `${dietitian.firstName} ${dietitian.lastName}`,
  }));
  const facilitiesMapped = schedulesDictionaries?.facilities.map(clinic => ({
    id: clinic.id.toString(),
    label: clinic.city,
  }));
  const facilitiesRemote = facilitiesMapped
    ? [
        {
          id: `${EVENT_ONLINE_LOCATION}`,
          label: t("common.remote"),
        },
        ...facilitiesMapped,
      ]
    : undefined;

  const productsMapped = clinicProducts?.map(product => ({
    id: product.id.toString(),
    label: isPolishChosen
      ? product.translations.pl.name
      : product.translations.en.name,
  }));
  const currenciesMapped = currencies?.map(currency => ({
    id: currency.currency,
    label: currency.currency,
  }));

  const checkedClient =
    clientsMapped?.find(val => val.id === form.watch("patientId")?.toString())
      ?.label ?? "";

  const checkedClientEmail =
    clientsMapped?.find(({ id }) => id === form.watch("patientId")?.toString())
      ?.email ?? "";

  const openAddClientModal = useCallback(() => setAddClientModal(true), []);
  const closeAddClientModal = useCallback(() => setAddClientModal(false), []);
  const handleCreateClientSuccess = useCallback(
    (data?: CreateClientResponse) => setClientId(data?.id),
    [],
  );

  const submitForm = useCallback((data: AddEventModalInput) => {
    resetTransition();
    edit
      ? updateScheduleEvent(mapAddVisitRequest(data))
      : createScheduleEvent(mapAddVisitRequest(data));
    form.reset();
    onClose && onClose();
  }, []);

  const onCloseWithoutSubmit = () => {
    form.reset();
    onClose && onClose();
    resetTransition();
  };

  const handleIsAllDay = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    form.setValue("isAllDay", checked);
  };

  const removeTransition = () =>
    setTransition({
      enter: 0,
      exit: transitions.duration.leavingScreen,
    });
  const resetTransition = () =>
    setTransition({
      enter: transitions.duration.enteringScreen,
      exit: transitions.duration.leavingScreen,
    });

  useEffect(() => {
    if (
      id === undefined &&
      tab === MEETING_TYPE.CONSULTATION &&
      form.watch("patientId")
    ) {
      form.setValue("eventName", checkedClient);
    }
  }, [form.watch("patientId"), tab, clientsMapped]);

  useEffect(() => {
    if (startDate && !edit) {
      form.setValue("finishDate", dayjs(startDate).add(1, "hour").toDate());
    }
  }, [startDate, edit]);

  useEffect(() => {
    if (eventData || edit) return;

    if (schedulesDictionaries?.facilities[0]) {
      form.setValue("clinicFacilityId", schedulesDictionaries.facilities[0].id);
    }

    if (clinicProducts?.[0]?.prices[0]) {
      const currency = clinicProducts[0].prices[0]?.currency;
      const price = clinicProducts[0].prices[0]?.amount;

      form.setValue("clinicProductId", clinicProducts[0].id.toString());
      form.setValue("price", price);
      form.setValue("currency", currency);
    }

    if (currencies?.length && !form.watch("currency")) {
      1;
      const defaultCurrency = currencies.find(currency => currency.isDefault);

      form.setValue(
        "currency",
        defaultCurrency?.currency ?? currencies[0].currency,
      );
    }
  }, [onOpen, schedulesDictionaries, clinicProducts, currencies]);

  const { isDirty } = form.formState;

  useEffect(() => {
    const selectedProduct = clinicProducts?.find(
      product => product.id.toString() === productId,
    );

    const currentPrice = scheduleEvent?.price;

    if ((!edit && selectedProduct) || (edit && currentPrice && isDirty)) {
      const selectedCurrency = selectedProduct?.prices.find(
        curr => curr.currency === currency,
      );

      const selectedCurrentCurrency = selectedProduct?.prices.find(
        curr => curr.currency === currentPrice?.currency,
      );

      form.setValue(
        "price",
        selectedCurrency?.amount ?? currentPrice?.value ?? 0,
      );
      form.setValue(
        "currency",
        selectedCurrency?.currency ??
          selectedCurrentCurrency?.currency ??
          currency,
      );
    }
  }, [productId, currency, scheduleEvent]);

  useEffect(() => {
    if (clientId) form.setValue("patientId", clientId);
    if (defaultClient) form.setValue("patientId", defaultClient);
  }, [clientId, defaultClient]);

  useEffect(() => {
    if (addClientModal) removeTransition();
  }, [addClientModal]);

  if (isError || (isSuccess && !clients))
    return <ApiError message={t("patient.programs.api_error")} />;

  if (addClientModal)
    return (
      <AddClientModal
        onClose={closeAddClientModal}
        open={addClientModal}
        onSuccess={handleCreateClientSuccess}
        transitionDuration={0}
      />
    );

  return (
    <FormProvider {...form}>
      <Dialog
        open={onOpen}
        onClose={onCloseWithoutSubmit}
        transitionDuration={transition}
      >
        <VisitModalWrapper>
          <div className="w-96">
            <EventTypeCards disabled={onlyDates || edit} />
          </div>

          <div>
            <MainSection>
              {tab === MEETING_TYPE.CONSULTATION && (
                <ClientAddWrapper>
                  <SearchClientsAutocomplete
                    control={form.control}
                    name="patientId"
                    label={t("visits.add.client")}
                    newClient={newClientLabel}
                  />
                  <StyledAddBtn
                    size="small"
                    variant="contained"
                    onClick={openAddClientModal}
                    disabled={onlyDates || !!defaultClient}
                  >
                    <Plus size="w-3 h-3" className="stroke-current" />
                  </StyledAddBtn>
                </ClientAddWrapper>
              )}

              <FormTextField
                control={form.control}
                name="eventName"
                size="small"
                label={t("visits.add.event_name")}
                variant="outlined"
                disabled={onlyDates}
              />
              <MuiTimeDatePicker
                dateVersion
                name="startDate"
                control={form.control}
                label={t("visits.add.term")}
              />

              <DateTimeWrapper>
                <FormControlLabelStyled
                  label={t("visits.add.all_day")}
                  control={
                    <Checkbox
                      onChange={handleIsAllDay}
                      checked={isAllDayValue}
                    />
                  }
                />
                <MuiTimeDatePicker
                  timeVersion
                  name="startDate"
                  step={5}
                  control={form.control}
                  disabled={isAllDayValue}
                  label={t("visits.add.start")}
                />
                <MuiTimeDatePicker
                  timeVersion
                  name="finishDate"
                  step={5}
                  control={form.control}
                  disabled={isAllDayValue}
                  label={t("visits.add.end")}
                />
              </DateTimeWrapper>

              <Delimeter />

              {tab !== MEETING_TYPE.CONSULTATION && (
                <ClientAddWrapper>
                  <SearchClientsAutocomplete
                    control={form.control}
                    name="patientId"
                    label={t("visits.add.client")}
                    disabled={onlyDates || !!defaultClient}
                    newClient={newClientLabel}
                  />
                  <StyledAddBtn
                    size="small"
                    variant="contained"
                    onClick={openAddClientModal}
                    disabled={onlyDates || !!defaultClient}
                  >
                    <Plus size="w-3 h-3" className="stroke-current" />
                  </StyledAddBtn>
                </ClientAddWrapper>
              )}

              <FormAutocomplete
                control={form.control}
                name="dietitianId"
                id="dietitianId"
                size="small"
                popupIcon={<CheckedIcon fill={palette.primary.main} />}
                className="w-full"
                options={dietitiansMapped ?? []}
                label={t("visits.add.dietitian")}
                disabled={onlyDates}
              />
              {tab === MEETING_TYPE.CONSULTATION && (
                <>
                  <FormAutocomplete
                    control={form.control}
                    name="clinicFacilityId"
                    id="clinicFacilityId"
                    size="small"
                    popupIcon={<CheckedIcon fill={palette.primary.main} />}
                    className="w-full"
                    options={facilitiesRemote ?? []}
                    label={t("visits.add.location")}
                    disabled={onlyDates}
                  />
                  <FormAutocomplete
                    control={form.control}
                    name="clinicProductId"
                    id="clinicProductId"
                    size="small"
                    popupIcon={<CheckedIcon fill={palette.primary.main} />}
                    className="w-full"
                    options={productsMapped ?? []}
                    label={t("visits.add.product")}
                    disabled={onlyDates}
                  />

                  <PriceSection>
                    <FormTextField
                      control={form.control}
                      name="price"
                      type="number"
                      size="small"
                      label={t("visits.add.price")}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      disabled={onlyDates}
                    />
                    <FormAutocomplete
                      control={form.control}
                      name="currency"
                      id="currency"
                      size="small"
                      popupIcon={<CheckedIcon fill={palette.primary.main} />}
                      className="w-full"
                      options={currenciesMapped ?? []}
                      label={t("visits.add.currency")}
                      disabled={onlyDates}
                    />
                  </PriceSection>
                </>
              )}

              <StyledTextArea
                control={form.control}
                name="additionalInfo"
                label={t("visits.add.additional_info")}
                multiline
                rows={5}
                size="small"
                disabled={onlyDates}
              />

              {tab === MEETING_TYPE.CONSULTATION && !edit && (
                <>
                  <Delimeter />
                  <StyledTooltip
                    placement="top"
                    title={t("visits.add.disabled_notification_msg")}
                    disableInteractive={!!checkedClientEmail}
                    disableHoverListener={!!checkedClientEmail}
                    disableFocusListener={!!checkedClientEmail}
                    disableTouchListener={!!checkedClientEmail}
                  >
                    <div>
                      <FormLabeledCheckboxMui
                        disabled={!checkedClientEmail}
                        label={t("visits.add.notification")}
                        name="notification"
                      />
                    </div>
                  </StyledTooltip>
                </>
              )}
            </MainSection>
          </div>

          <DecisionBtnsSection>
            <StyledCancelBtn variant="contained" onClick={onCloseWithoutSubmit}>
              {t("common.cancel")}
            </StyledCancelBtn>

            <StyledSubmitBtn
              variant="contained"
              onClick={form.handleSubmit(submitForm)}
              autoFocus
            >
              {t("common.save")}
            </StyledSubmitBtn>
          </DecisionBtnsSection>
        </VisitModalWrapper>
      </Dialog>
    </FormProvider>
  );
};
