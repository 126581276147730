import { styled } from "@mui/material";

export const MealName = styled("span")`
  font: var(--font-figtree-semi-bold);
  font-size: 14px;
  line-height: 20px;
  color: #4d516e;
`;

export const MealItem = styled("span")`
  font: var(--font-figtree-regular);
  font-size: 14px;
  line-height: 20px;
  color: #4d516e;
`;

export const MealLabel = styled("span")`
  font: var(--font-figtree-regular);
  font-size: 12px;
  line-height: 20px;
  color: #4d516e;
`;
