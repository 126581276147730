import { memo, ReactNode } from "react";
import { MuiInfoClickTooltip } from "@components/MuiInfoClickTooltip";

interface LabeledFormInputProps {
  label: string;
  children?: ReactNode;
  tooltip?: ReactNode;
  className?: string;
}

export const Labeled = memo(
  ({ label, children, className, tooltip }: LabeledFormInputProps) => {
    return (
      <div className={`grid ${className}`}>
        <div className="flex items-center">
          <p className="font-normal pb-1 text-grayDark">{label}</p>
          {tooltip && (
            <MuiInfoClickTooltip
              placement="right-start"
              content={tooltip}
              iconSize="small"
            />
          )}
        </div>
        {children}
      </div>
    );
  },
);
