import { Avatar } from "@components/AvatarNew";
import { useFetchClinicDietitiansSimpleListQuery } from "@hooks/queries/settings";
import { Box, Typography } from "@mui/material";

export interface EducationalMaterialAuthorProps {
  authorId: number;
}

export const EducationalMaterialAuthor = ({
  authorId,
}: EducationalMaterialAuthorProps) => {
  const { dietitians, isLoading } = useFetchClinicDietitiansSimpleListQuery();

  const dietitian = dietitians?.find(
    dietitian => dietitian.id === Number(authorId),
  );

  const fullName = dietitian?.name;

  if (isLoading) {
    return null;
  }

  return authorId ? (
    <Box display="flex" alignItems="center" gap={1}>
      <Avatar image={dietitian?.image?.url} fullName={fullName ?? ""} />

      <Box>
        <Typography variant="subtitle2" fontWeight="bold">
          {fullName ?? "-"}
        </Typography>
      </Box>
    </Box>
  ) : (
    <p className="pl-4">-</p>
  );
};
