import { MeasurementDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { CloseIcon } from "@assets/icons_new";
import { useAppTranslation } from "@hooks";
import { OuterLabel } from "@components/OuterLabel";
import AllBodyField from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/AllBodyField";
import BodyField from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/BodyField";
import RequiredPhotosField from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/RequiredPhotosField";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { useMemo } from "react";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import {
  mapEditMeasurementsForm,
  mapEditMeasurementsRequest,
  useEditMeasurementsForm,
} from "./useEditMeasurementsForm";
import { FormProvider } from "react-hook-form";
import { useUpdateGlobalPatientSettingsMutation } from "@hooks/queries/clinic";
import { LoadingButton } from "@mui/lab";

interface EditMeasurementsModalProps {
  open: boolean;
  onClose: () => void;
  measurement: MeasurementDto;
}
export const EditMeasurementsModal = ({
  open,
  onClose,
  measurement,
}: EditMeasurementsModalProps) => {
  const { t } = useAppTranslation();
  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();
  const { mutate, isLoading } = useUpdateGlobalPatientSettingsMutation();

  const defaultValues = useMemo(
    () => mapEditMeasurementsForm(measurement),
    [measurement],
  );
  const form = useEditMeasurementsForm(defaultValues);
  const filteredBodyMeasurements = useMemo(() => {
    if (!bodyMeasurements) {
      return [];
    }

    return bodyMeasurements.data.filter(
      body =>
        ![
          BodyMeasurementConst.water,
          BodyMeasurementConst.bodyFatMass,
          BodyMeasurementConst.skeletalMuscleMass,
          BodyMeasurementConst.boneTissueMass,
        ].includes(body.id),
    );
  }, [bodyMeasurements]);

  const handleCloseAndReset = () => {
    onClose();
    form.reset();
  };
  const handleSubmit = () =>
    form.handleSubmit(
      values =>
        mutate(
          { measurement: mapEditMeasurementsRequest(values) },
          { onSuccess: onClose },
        ),
      e => console.log(e),
    )();
  return (
    <Dialog open={open}>
      <DialogTitleStyled>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {t(
            "patient.health_and_goal.body_measurement_tab.section_settings_modal.title",
          )}
          <IconButtonStyled onClick={handleCloseAndReset} size="small">
            <CloseIcon fontSize="24px" />
          </IconButtonStyled>
        </Stack>
      </DialogTitleStyled>

      <DialogContent sx={{ width: "510px" }}>
        <FormProvider {...form}>
          <Stack gap="24px">
            <Stack gap="4px">
              <OuterLabel
                label={t(
                  "patient.health_and_goal.body_measurement_tab.section_settings_modal.monitored_measurements",
                )}
              >
                <Stack direction="column" gap="8px">
                  <AllBodyField bodyMeasurements={filteredBodyMeasurements} />
                  {filteredBodyMeasurements.map(body => (
                    <BodyField key={body.id} body={body} />
                  ))}
                </Stack>
              </OuterLabel>
            </Stack>
            <DividerStyled />
            <Stack gap="4px">
              <OuterLabel
                label={t(
                  "patient.health_and_goal.body_measurement_tab.section_settings_modal.required_photos",
                )}
              >
                <RequiredPhotosField />
              </OuterLabel>
            </Stack>
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAndReset} variant="outlined">
          {t("common.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: unset;
  height: unset;
`;
const DialogTitleStyled = styled(DialogTitle)`
  font-size: 16px;
  padding: 16px;
`;

const DividerStyled = styled(Divider)`
  border-color: #d0d0d0;
`;
