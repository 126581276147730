import { difference, range } from "lodash";
import { useLocalStorage } from "react-use";
import { whatsNewListStub } from "@components/WhatsNewList";
import { useModalState } from "@hooks";
import { useEffect } from "react";

export const whatsNewReadEventsKey = "whatsNewReadEventsKey";

export const useWhatsNew = () => {
  const [openWhatsNew, onOpenWhatsNew, onCloseWhatsNew] = useModalState();

  const [readNews, setReadNews] = useLocalStorage<number[]>(
    whatsNewReadEventsKey,
    range(0, 27),
  );

  const handleReadAll = () => setReadNews(whatsNewListStub.map(({ id }) => id));
  const unreadNews = difference(
    whatsNewListStub.map(({ id }) => id),
    readNews ?? [],
  );

  useEffect(() => {
    if (unreadNews.length) onOpenWhatsNew();
  }, [unreadNews]);

  return {
    readNews,
    setReadNews,
    handleReadAll,
    unreadNews,
    openWhatsNew,
    onOpenWhatsNew,
    onCloseWhatsNew,
  };
};
