import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { usePostNewSubscriptionPurchaseMutation } from "@hooks/queries/clinic";
import {
  Alert,
  capitalize,
  Checkbox,
  Chip,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";

import { ViewType } from "../Pricing";
import { DividerStyled, PriceGross, PriceNet, Title } from "./common";
import { SCALE_ANNUALLY_PRICE, SCALE_MONTHLY_PRICE } from "./ScaleCard";
import { STARTER_ANNUALLY_PRICE, STARTER_MONTHLY_PRICE } from "./StarterCard";
import { MONTHS, VAT_PERCENTAGE } from "./consts";
import { mapBuyPackageRequest, useBuyPackageForm } from "./useBuyPackageForm";
import { FormProvider, useController } from "react-hook-form";
import { FormTextField } from "@components/FormTextField";
import { round } from "lodash";
import { useState } from "react";
import { PurchaseSuccessModal } from "./PurchaseSuccessModal";

interface BuyPackageModalProps {
  open: boolean;
  onClose: () => void;
  selectedPackage: "starter" | "scale";
  view: ViewType;
  showVat: boolean;
}
export const BuyPackageModal = ({
  open,
  onClose,
  selectedPackage,
  view,
  showVat,
}: BuyPackageModalProps) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { mutate } = usePostNewSubscriptionPurchaseMutation();
  const { control, reset, handleSubmit, watch, ...rest } = useBuyPackageForm();
  const {
    field: { value: buyer, onChange: setBuyer },
  } = useController({ control, name: "buyer" });
  const {
    field: { value: isStudent, onChange: setIsStudent },
  } = useController({ control, name: "isStudent" });

  const annualPrice =
    selectedPackage === "starter"
      ? STARTER_ANNUALLY_PRICE
      : SCALE_ANNUALLY_PRICE;
  const monthPrice =
    view === ViewType.annually
      ? annualPrice / MONTHS
      : selectedPackage === "starter"
      ? STARTER_MONTHLY_PRICE
      : SCALE_MONTHLY_PRICE;

  const calculateWithVat = (value: number) =>
    round(value + value * VAT_PERCENTAGE, 2);

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleSubmitWrapper = () => {
    handleSubmit(
      values =>
        mutate(mapBuyPackageRequest(values, view, selectedPackage), {
          onSuccess: () => setShowSuccess(true),
        }),
      err => console.log(err),
    )();
  };

  if (showSuccess)
    return <PurchaseSuccessModal open={open} onClose={handleOnClose} />;

  return (
    <FormProvider
      control={control}
      reset={reset}
      handleSubmit={handleSubmit}
      watch={watch}
      {...rest}
    >
      <ModalWrapper
        open={open}
        onClose={handleOnClose}
        onSubmit={handleSubmitWrapper}
        title={<ModalTitle>Kup pakiet</ModalTitle>}
        confirmText="Kup pakiet"
      >
        <Wrapper>
          <SelectedPackageWrapper>
            <div className="flex justify-between">
              <Title>{selectedPackage && capitalize(selectedPackage)}</Title>
              <ChipStyled
                color="primary"
                variant="outlined"
                label={
                  view === ViewType.monthly
                    ? "Rozliczenie miesięczne"
                    : "Rozliczenie roczne"
                }
              />
            </div>
            <div className="flex flex-col gap-[8px]">
              <PriceNet value={round(monthPrice)} showNet />
              <PriceGross
                value={calculateWithVat(
                  view === ViewType.annually ? annualPrice : monthPrice,
                )}
                grossPrice
                view={view}
              />
            </div>
          </SelectedPackageWrapper>

          <DividerStyled />

          <Description>
            Aby kupić pakiet, wypełnij formularz zakupu. Fakturę otrzymasz na
            wskazany adres email w ciągu 48 godzin.
          </Description>

          <OuterLabel label="Kupuję jako">
            <RadioGroupStyled
              defaultValue={buyer}
              onChange={(e, value) => setBuyer(value as "person" | "company")}
            >
              <FormControlLabelStyled
                value="person"
                control={<Radio />}
                label="Osoba prywatna"
              />
              <FormControlLabelStyled
                value="company"
                control={<Radio />}
                label="Firma"
              />
            </RadioGroupStyled>
          </OuterLabel>
          {buyer === "person" && (
            <>
              <OuterLabel label="Imię">
                <FormTextField name="firstName" fullWidth size="small" />
              </OuterLabel>
              <OuterLabel label="Nazwisko">
                <FormTextField name="lastName" fullWidth size="small" />
              </OuterLabel>
            </>
          )}
          {buyer === "company" && (
            <>
              <OuterLabel label="Firma">
                <FormTextField name="companyName" fullWidth size="small" />
              </OuterLabel>
              <OuterLabel label="NIP">
                <FormTextField name="nip" fullWidth size="small" />
              </OuterLabel>
            </>
          )}

          <AddressText>Adres</AddressText>

          <OuterLabel label="Ulica">
            <FormTextField name="street" fullWidth size="small" />
          </OuterLabel>

          <div className="flex gap-[24px] flex-1">
            <OuterLabelFlex1 label="Numer budynku">
              <FormTextField name="streetNumber" fullWidth size="small" />
            </OuterLabelFlex1>
            <OuterLabelFlex1 label="Numer lokalu">
              <FormTextField name="houseNumber" fullWidth size="small" />
            </OuterLabelFlex1>
          </div>

          <div className="flex gap-[24px] flex-1">
            <OuterLabelFlex1 label="Kod pocztowy">
              <FormTextField name="postcode" fullWidth size="small" />
            </OuterLabelFlex1>
            <OuterLabelFlex1 label="Miejscowość">
              <FormTextField name="city" fullWidth size="small" />
            </OuterLabelFlex1>
          </div>

          {view === ViewType.annually && (
            <div className="flex flex-col gap-[4px]">
              <FormControlLabelStyled
                checked={isStudent}
                onChange={(e, checked) => setIsStudent(checked)}
                control={<Checkbox />}
                label="Jestem studentem"
              />
              {isStudent && (
                <Alert severity="info" sx={{ paddingLeft: "26px" }}>
                  Wyślij zdjęcie legitymacji na czacie i zgarnij 50% zniżki na
                  pakiet roczny!.
                </Alert>
              )}
            </div>
          )}
        </Wrapper>
      </ModalWrapper>
    </FormProvider>
  );
};

const SelectedPackageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #faf7ff;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid #fff;
  box-shadow: 0px 0px 10px 0px #0000000d;
  backdrop-filter: blur(24px);
`;

const Description = styled("span")`
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

const RadioGroupStyled = styled(RadioGroup)`
  &.MuiFormGroup-root {
    display: flex;
    gap: 24px;
    flex-direction: row;
  }
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: unset;
    margin-right: unset;
  }
`;

const AddressText = styled("span")`
  font-family: Figtree;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

const OuterLabelFlex1 = styled(OuterLabel)`
  flex: 1;
`;

const ModalTitle = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  padding: 18px 24px;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 552px;
  padding: 0 8px;
`;

const ChipStyled = styled(Chip)`
  background: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.4px;
`;
