import { memo, useEffect, useMemo } from "react";
import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { IconButton } from "@components/IconButton";
import { DASHBOARD, RESET } from "@routes";
import { BookWithQuestionMark, Checklist, Note } from "@assets/icons";
import {
  useAppEnvironment,
  useAppTranslation,
  useLogRocket,
  useUserRoles,
} from "@hooks";
import { useTourTriggerContext } from "@context";
import { useFeatureFlags } from "@utils/featureFlags";
import { ChatList } from "@views/dietician/Chat";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { TrialCountdownBanner } from "./TrialCountdownBanner";
import { OfflineBackdrop } from "./OfflineBackdrop";
import { NutrientsViewSettings } from "@components/NutrientsViewSettings";
import { useTasksContext } from "@views/dietician/Tasks/Tasks.context";
import { useNotesSidePanelContext } from "@components/NotesSidePanel/NotesSidePanel.context";
import { HeaderStyled, StyledLImg, TourButton } from "./Header.styled";
import { ClientsSearch } from "./ClientsSearch";
import { Avatar } from "@components/AvatarNew";
import { NotificationMenu } from "@components/NotificationMenu";
import { Link, To, useLocation } from "react-router-dom";
import { UserSettingsDropDown } from "./UserSettingsDropDown";
import { StudentBanner } from "./StudentBanner";

export const Header = memo(() => {
  const { account } = useFetchDietitianAccountQuery();
  const { isSchool } = useUserRoles();
  const { pathname } = useLocation();
  const { t, i18n, secondLanguage } = useAppTranslation();
  const { handleOpenCreateModal } = useTasksContext();
  const { handleOpenNotesPanel } = useNotesSidePanelContext();
  const { breakpoints, colors } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const { isProd } = useAppEnvironment();
  const userFullName = useMemo(() => {
    if (!account) return "";
    return account.firstName + " " + account.lastName;
  }, [account]);
  const imgUrl = account?.avatar;

  useLogRocket(account);
  if (import.meta.env.VITE_APP_ENABLE_USERCOM === "true") {
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (account && window?.UE?.pageHit && !pathname.startsWith(RESET)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.UE.pageHit({
          apiKey: "kjcaol",
          name: `${account.firstName} ${account.lastName}`,
          user_id: account.id,
          email: account.email,
        });
        console.log("User initialization success");
      }
    }, [account, pathname]);
  }

  const { notify, enabled } = useTourTriggerContext();
  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    notify();
  };
  const { chat } = useFeatureFlags();

  return (
    <>
      <HeaderStyled>
        <Link to={DASHBOARD}>
          <StyledLImg
            src="/static/Logo@2x.png"
            srcSet="/static/Logo@2x.png"
            alt="Alloweat logo"
          />
        </Link>
        {!isProd && (
          <IconButton
            size="small"
            onClick={() => i18n.changeLanguage(secondLanguage)}
          >
            <p>{secondLanguage.toUpperCase()}</p>
          </IconButton>
        )}
        <ClientsSearch />
        <div className="flex items-center">
          <OfflineBackdrop />
          {!downMedium && <TrialCountdownBanner />}
          {enabled && (
            <TourButton id="show-tour" size="small" onClick={handleClickStart}>
              <BookWithQuestionMark />
            </TourButton>
          )}
          <div className="flex items-center divide-x divide-neutral-medium-400 mx-6">
            {isSchool && (
              <div className="flex items-center pr-[17px]">
                <StudentBanner />
              </div>
            )}

            <Box display="flex" alignItems="center" paddingLeft={2}>
              <Tooltip title={t("tasks.addTask")} arrow className="pr-2.5">
                <Box>
                  <IconButton
                    color="neutral-dark"
                    onClick={handleOpenCreateModal}
                    className="px-0"
                  >
                    <Checklist className="text-neutral-dark-800 text-2xl" />
                  </IconButton>
                </Box>
              </Tooltip>
              <Tooltip title={t("tasks.addNote")}>
                <Box>
                  <IconButton
                    color="neutral-dark"
                    onClick={handleOpenNotesPanel}
                    className="p-0 mr-4"
                  >
                    <Note className="text-2xl" />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>

            <div className="flex items-center pl-[17px]">
              <NotificationMenu />
              {chat && <ChatList />}
            </div>
          </div>
          <Avatar
            size="small"
            image={imgUrl}
            fullName={userFullName}
            status={undefined}
          />
          {account && <UserSettingsDropDown fullName={userFullName} />}
        </div>
      </HeaderStyled>
    </>
  );
});
