import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export type CloneRecipeToBaseResponse = ApiResponse<CloneRecipeToBaseReponse>;

export const cloneRecipeToBase = async (
  recipeId: string,
): Promise<CloneRecipeToBaseResponse> => {
  const respose = await fetchData(
    `/dietitian/recipes/${recipeId}/store-in-base`,
    APIMethods.POST,
  );

  return await cloneRecipeToBaseResponse.validate(respose);
};

export interface CloneRecipeToBaseReponse {
  id: number;
}

const cloneRecipeToBaseResponse = yup.object().shape({
  data: yup.object().shape({
    id: yup.number().required(),
  }),
});
