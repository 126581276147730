import { OuterLabel } from "@components/OuterLabel";
import { Card, CardHeader } from "../components";
import { useFetchGlobalPatientSettingsQuery } from "@hooks/queries/clinic";
import { mapChipsFromObject } from "../utils";
import { useAppTranslation, useModalState } from "@hooks";
import { EditDiscoverModal } from "../modals/EditDiscoverModal/EditDiscoverModal";
import { FetchGlobalPatientSettingsResponse } from "@client/clinic/fetchGlobalPatientSettings";
import { ContentProps } from "./Databases";

export const Discover = () => {
  const { data } = useFetchGlobalPatientSettingsQuery();
  if (!data) return null;
  return <DiscoverContent data={data} />;
};

export const DiscoverContent = ({ data }: ContentProps) => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();

  const {
    mobileApp: { discoverFeatures, ...rest },
  } = data.data;

  return (
    <Card>
      <CardHeader
        onEdit={onOpen}
        title={t("cooperation_settings.discover.discover")}
        tooltipText={t("cooperation_settings.discover.module_description")}
      />

      <OuterLabel label={t("cooperation_settings.discover.active_sections")}>
        {mapChipsFromObject(discoverFeatures, t)}
      </OuterLabel>

      <EditDiscoverModal
        open={open}
        onClose={onClose}
        mobileApp={{ discoverFeatures, ...rest }}
      />
    </Card>
  );
};
