import { useState } from "react";
import { Download } from "@assets/icons";
import { FetchProgramPdfBasicResponse } from "@client";
import { IconButton } from "@components/IconButton";
import { InfiniteCircularProgress } from "@components/InfiniteCircularProgress";
import usePdfSectionExport from "@Pdf/hooks/usePdfSectionExport";
import { PDF_COLORS, PDF_DISPLAY_CODE } from "@Pdf/defaults/pdfDefaults";
import { toast } from "react-toastify";
import { useAppParams, useAppTranslation } from "@hooks";
import useFetchPatientShowNutrients from "@hooks/queries/client/nutrient/useFetchPatientShowNutrients";

interface PropsType {
  sectionId: number;
  programId: number;
  pdf: FetchProgramPdfBasicResponse;
  langId?: string;
  kcal?: boolean;
  macros?: boolean;
  micros?: Array<number>;
  sectionTitle: string;
}

export const DownloadSectionPdf = ({
  pdf,
  programId,
  sectionId,
  langId,
  kcal = true,
  micros = [],
  macros = true,
  sectionTitle,
}: PropsType) => {
  const [loading, setLoading] = useState(false);
  const { patientId } = useAppParams();
  const { data } = useFetchPatientShowNutrients({
    patientId: Number(patientId),
  });

  const { t } = useAppTranslation();
  const { onExport } = usePdfSectionExport();

  const handleDownloadButton = async () => {
    try {
      setLoading(true);
      await onExport(
        programId,
        sectionId,
        pdf.patient,
        pdf.clinic,
        pdf.dietitian,
        {
          color: PDF_COLORS.PRIMARY,
          coverPageType: "photo",
          title: sectionTitle,
          options: [
            PDF_DISPLAY_CODE.WEEKDAY,
            PDF_DISPLAY_CODE.DATE,
            PDF_DISPLAY_CODE.MEAL_HOUR,
            PDF_DISPLAY_CODE.DAY_TYPE,
            PDF_DISPLAY_CODE.LOGO,
          ],
          clinicOptions: [
            PDF_DISPLAY_CODE.CLINIC_NAME,
            PDF_DISPLAY_CODE.CLINIC_ADDRESS,
            PDF_DISPLAY_CODE.CLINIC_EMAIL,
            PDF_DISPLAY_CODE.CLINIC_PHONE,
          ],
          startDate: pdf.program.startDate,
          endDate: pdf.program.finishDate,
          kcal: !!data?.data.kcal,
          macros,
          micros,
        },
        true,
        langId,
      );

      setLoading(false);
    } catch {
      toast.error(t("pdf.upload_pdf_error"));

      setLoading(false);
    }
  };

  return (
    <IconButton onClick={handleDownloadButton} disabled={loading}>
      {loading ? (
        <InfiniteCircularProgress start={loading} />
      ) : (
        <Download size="w-5 h-5" />
      )}
    </IconButton>
  );
};
