import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useEducationalMaterialsFilterConfig } from "../_hooks/useEducationalMaterialsFilterConfig";
import { FunnelButton } from "@components/Filters";
import { FiltersDrawer } from "@components/Filters/FiltersDrawer";
import { useEducationalMaterialsFiltersForm } from "../_hooks/useEducationalMaterialsForm";
interface EducationalMaterialsFiltersProps {
  total?: number;
  compact?: boolean;
}

export const EducationalMaterialsFilters = ({
  compact,
}: EducationalMaterialsFiltersProps) => {
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const { form, onSubmit, onClear } =
    useEducationalMaterialsFiltersForm(setTotalCount);

  const filters = useEducationalMaterialsFilterConfig();

  return (
    <FormProvider {...form}>
      <FunnelButton
        allTags={totalCount}
        open={open}
        setOpen={setOpen}
        compact={compact}
      />
      <FiltersDrawer
        name="author"
        tabs={[]}
        filtersLists={[filters]}
        onClose={() => setOpen(false)}
        onClear={onClear}
        open={open}
        onSubmit={onSubmit}
        resetValues={{
          author: [],
        }}
      />
    </FormProvider>
  );
};
