import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { ApiEndpoints } from "@typeDefinitions";
import { apiUrl } from "@utils/configEnv";
import axios from "axios";

export const uploadEducationalMaterial = async (payload: FormData) => {
  return await axios.post(
    `${apiUrl()}${ApiEndpoints.UploadEducationalMaterial}`,
    payload,
  );
};
