import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";

import {
  PatientProgramFormInput,
  useAppTranslation,
  usePatientProgramForm,
} from "@hooks";
import { usePatientProgramNewParams } from "@views/dietician/PatientProgram";
import { FetchProgramResponse } from "@client/program";
import {
  useCreatePatientProgramMutation,
  useFetchProgramQuery,
  useUpdatePatientProgramMutation,
} from "@hooks/queries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { SingleCardPageLayout } from "@components/PageLayout";
import { ProgramInfoNavigation } from "@components/forms/ProgramInfoNavigation";
import {
  LanguageSelect,
  PatientProgramDurationSelect,
  ProgramNameSelect,
  SectionsSelect,
} from "@views/dietician/ProgramForm/components";
import { EducationalModalProvider } from "@views/dietician/EducationalMaterials/_hooks/useEducationalMaterialsContext";
import { PatientProgramEditWrapper } from "./PatientAddProgram.styled";
import { toast } from "react-toastify";

export const PatientAddProgram = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const [newProgramId, setNewProgramId] = useState<number | undefined>();

  const mapPatientProgramForm = (
    program?: FetchProgramResponse,
  ): PatientProgramFormInput | undefined => {
    if (!program) return;
    return {
      namePl: program.name,
      nameEn: program.nameEn ?? program.name,
      workModel: program.hasDiets ? "diets" : "macros",
      showMicros: program.showNutrients,
      showCals: program.showCals,
      showMacros: program.showMacros,
      showMeasurements: false,
      hasDiets: program.hasDiets,
      hasTasks: program.hasTasks,
      hasDiary: program.hasDiary,
      hasChat: program.hasChat,
      hasEducation: program.hasEducation,
      hasMacros: program.hasMacros,
      hasMonitoring: program.hasMonitoring,
      hasNewsfeed: program.hasNewsfeed,
      hasSubstitutes: false,
      hasShoppingList: program.hasShoppingList,
      sectionIds: program.sections,
      programLanguage: program.langId,
      days: program.days,
      startDate: program.durationDays ? dayjs().toDate() : undefined,
      finishDate: program.durationDays
        ? dayjs().add(program.durationDays, "d").toDate()
        : null,
      isIndefinite: !program.durationDays,
    };
  };

  const { patientId, programId } = usePatientProgramNewParams();
  const {
    program,
    isLoading: isLoadingInit,
    isError: isErrorInit,
  } = useFetchProgramQuery(programId, { enabled: !isNaN(programId) });
  const methods = usePatientProgramForm(
    useMemo(() => mapPatientProgramForm(program), [program]),
  );

  const { mutate, isLoading, isError } = useCreatePatientProgramMutation(
    patientId,
    {
      onSuccess: data => data && setNewProgramId(data?.id),
    },
  );

  const { mutate: updatePatientProgram, isLoading: isUpdating } =
    useUpdatePatientProgramMutation(patientId, newProgramId ?? 0, {
      onSuccess: () =>
        navigate(
          `/patients/${patientId}/nutritional-programs/${newProgramId}/days`,
        ),
      onError: () =>
        toast.error(t("addNutritionalProgram.step1.finish_date_error")),
    });

  useEffect(() => mutate(), []);

  const submit = useCallback(
    methods.handleSubmit(program =>
      updatePatientProgram({
        name: program.namePl,
        nameEn: program.nameEn,
        showNutrients: program.showMicros,
        showCals: program.showCals,
        showMacros: program.showMacros,
        showMeasurements: program.showMeasurements,
        hasDiets: program.workModel === "diets",
        hasMacros: program.workModel === "macros",
        hasTasks: program.hasTasks,
        hasDiary: program.hasDiary,
        hasChat: program.hasChat,
        hasEducation: program.hasEducation,
        hasMonitoring: program.hasMonitoring,
        hasNewsfeed: program.hasNewsfeed,
        hasSubstitutes: program.hasSubstitutes,
        hasShoppingList: program.hasShoppingList,
        sections: program.sectionIds,
        langId: program.programLanguage,
        days: program.days,
        startDate: program.startDate
          ? dayjs(program.startDate).format("YYYY-MM-DD")
          : undefined,
        finishDate: program.finishDate
          ? dayjs(program.finishDate).format("YYYY-MM-DD")
          : null,
      }),
    ),
    [mutate],
  );

  if (isLoading || (isLoadingInit && !isNaN(programId)))
    return <Spinner className="h-52" />;

  if (isError || isErrorInit || !newProgramId) return <ApiError />;

  return (
    <>
      <SingleCardPageLayout title={t("programs.add_new_program")}>
        {isLoading && <Spinner className="h-52" />}

        {isError && <ApiError />}

        <FormProvider {...methods}>
          <PatientProgramEditWrapper>
            <ProgramNameSelect />
            <LanguageSelect name="programLanguage" />
            <PatientProgramDurationSelect />
            <EducationalModalProvider>
              <SectionsSelect programId={newProgramId} />
            </EducationalModalProvider>
          </PatientProgramEditWrapper>
        </FormProvider>
      </SingleCardPageLayout>

      <ProgramInfoNavigation
        onBack={() => navigate(-1)}
        onSubmit={submit}
        isSubmitting={isUpdating}
      />
    </>
  );
};
