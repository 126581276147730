import { useFetchSystemNotificationsQuery } from "@hooks/queries";
import { ThemeProviderWrapperNew } from "themeNew";
import {
  BannersWrapper,
  StyledButton,
  SystemSentAlert,
  TrialPeriodAlert,
} from "./SystemNotificationBanner.styled";
import { useEffect, useState } from "react";
import { DollarSign, ExclamationMarkInTriangle } from "@assets/icons";
import { Trans } from "react-i18next";
import { useAppTranslation, useUserRoles } from "@hooks";
import { getSubscriptionInfo } from "@utils/subscription";
import { useNavigate } from "react-router-dom";
import { PRICING } from "@routes";

interface SystemNotificationBannerProps {
  endsIn?: number | null;
}

export const SystemNotificationBanner = ({
  endsIn,
}: SystemNotificationBannerProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { isClinicAdmin } = useUserRoles();
  const { systemNotifications } = useFetchSystemNotificationsQuery();
  const { isTrial } = getSubscriptionInfo();
  const [openedSystemNotifications, setOpenedSystemNotifications] =
    useState(systemNotifications);
  const ThreeDays = 3;

  const handleContent = (days?: number | null) => {
    if (days && days < 4) return t("banners.trial_alert", { count: days });
    if (days === 0) return t("banners.trial_alert_ends_today");
  };

  const navigate = useNavigate();
  const navigateToPricing = () => navigate(PRICING);

  useEffect(() => {
    if (systemNotifications?.length) {
      setOpenedSystemNotifications(systemNotifications);
    }
  }, [systemNotifications]);

  const transComponents = {
    b: <b />,
  };
  return (
    <>
      <ThemeProviderWrapperNew>
        {((endsIn && endsIn <= ThreeDays) || endsIn === 0) && isTrial && (
          <TrialPeriodAlert
            variant="filled"
            icon={<ExclamationMarkInTriangle size="w-[14px] h-[14px]" />}
            action={
              isClinicAdmin && (
                <StyledButton
                  color="inherit"
                  size="small"
                  startIcon={<DollarSign />}
                  onClick={navigateToPricing}
                >
                  {t("banners.purchase_now")}
                </StyledButton>
              )
            }
          >
            <Trans
              i18nKey={handleContent(endsIn)}
              components={transComponents}
            />
          </TrialPeriodAlert>
        )}
        {!!systemNotifications?.length && (
          <div className="flex-1">
            {openedSystemNotifications?.map(({ id, text, textEn }) => (
              <BannersWrapper key={id}>
                <SystemSentAlert
                  variant="filled"
                  icon={<ExclamationMarkInTriangle size="w-[14px] h-[14px]" />}
                  onClose={() =>
                    setOpenedSystemNotifications(state =>
                      state?.filter(el => el.id !== id),
                    )
                  }
                >
                  <Trans
                    i18nKey={isPolishChosen ? text : textEn}
                    components={transComponents}
                  />
                </SystemSentAlert>
              </BannersWrapper>
            ))}
          </div>
        )}
      </ThemeProviderWrapperNew>
    </>
  );
};
