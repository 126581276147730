import { Pencil } from "@assets/icons";
import {
  useAppParams,
  useAppTranslation,
  useClientAdditionalFeatures,
  useModalState,
} from "@hooks";
import {
  ClientProfileCard,
  EditButton,
  SectionWrapper,
  TitleSection,
} from "@views/dietician/ClientProfile";
import { Ref } from "react";
import { ClientProfileCardItem } from "../ClientProfileCardItem";
import { Chip } from "@mui/material";
import {
  AdditionalOptions,
  EditHealthStatusModal,
} from "@components/EditHealthStatusModal";
import { noData } from "../../ClientHealth";

interface HealthSectionProps {
  healthSectionRef?: Ref<HTMLDivElement>;
  additionalInfo: number;
}

export const HealthSection = ({
  healthSectionRef,
  additionalInfo,
}: HealthSectionProps) => {
  const { t } = useAppTranslation();
  const { getAdditionalFeatureName } = useClientAdditionalFeatures();
  const { patientId } = useAppParams();
  const [modalOpened, onModalOpen, onModalClose] = useModalState();
  const chipContent =
    additionalInfo === Number(AdditionalOptions.NONE) || !additionalInfo
      ? noData
      : getAdditionalFeatureName(additionalInfo);

  return (
    <>
      <ClientProfileCard>
        <TitleSection>
          <p>{t("client_profile.health.health_status")}</p>
          <EditButton
            onClick={onModalOpen}
            startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
            disableRipple
          >
            {t("common.edit")}
          </EditButton>
        </TitleSection>

        <SectionWrapper>
          <ClientProfileCardItem
            title={t("client_profile.health.additional_information")}
            extraEl={
              <Chip
                className="w-fit min-w-[50px]"
                label={chipContent}
                color="primary"
              ></Chip>
            }
          />
        </SectionWrapper>
      </ClientProfileCard>

      <EditHealthStatusModal
        open={modalOpened}
        onClose={onModalClose}
        id={patientId}
      />
    </>
  );
};
