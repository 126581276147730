import { useEffect, useRef, useState } from "react";

import { Backdrop, Button } from "@mui/material";

import {
  useAppTranslation,
  useFiniteProgress,
  useInfiniteProgress,
} from "@hooks";

import {
  CardStyled,
  LinearProgressStyled,
  Text,
} from "./GeneratingBackdrop.styled";

interface GeneratingBackdropProps {
  open: boolean;
  onCancel: () => void;
  onClose: () => void;
}

export const GeneratingBackdrop = ({
  open,
  onCancel,
  onClose,
}: GeneratingBackdropProps) => {
  const { t } = useAppTranslation();
  const initTime = useRef<number>(Date.now());
  const [durationRemaining, setDurationRemaining] = useState<number>(
    FINITE_PROGRESS_DURATION,
  );
  const [progress, setProgress] = useState<number>(0);
  const [infiniteProgress] = useInfiniteProgress(open, 1);
  const [finiteProgress] = useFiniteProgress(
    !open,
    durationRemaining,
    progress,
  );
  const [closingAnimation, setClosingAnimation] = useState(false);

  const handleClose = () => {
    setClosingAnimation(true);
    setDurationRemaining(
      Math.max(FINITE_PROGRESS_DURATION - (Date.now() - initTime.current), 0),
    );
  };

  useEffect(() => {
    open ? setProgress(infiniteProgress) : handleClose();
  }, [open, infiniteProgress]);

  useEffect(() => {
    if (!open && finiteProgress >= 100) {
      setTimeout(() => {
        setClosingAnimation(false);
        onClose();
      }, 500);
    }
  }, [open, finiteProgress]);

  const progressValue = Math.max(infiniteProgress, finiteProgress, progress);

  return (
    <Backdrop open={open || closingAnimation} sx={{ zIndex: 1 }}>
      <CardStyled>
        <img
          src="https://alloweat.s3.eu-central-1.amazonaws.com/public/web/mealGenerating.gif"
          width={"184px"}
          height={"184px"}
        />
        <LinearProgressStyled
          sx={{ width: 184 }}
          color="primary"
          variant="determinate"
          value={progressValue}
        />
        <Text>{t("diet.generating_recipes")}</Text>
        <Button onClick={onCancel}>{t("common.cancel")}</Button>
      </CardStyled>
    </Backdrop>
  );
};

const FINITE_PROGRESS_DURATION = 6500;
