import { object, number, string, boolean, array, InferType } from "yup";

import {
  ApiEndpoints,
  APIMethods,
  MetadataSchema,
  PaginationLinksSchema,
} from "@typeDefinitions";

import { fetchData } from "@utils/api";
import { actionsSchema } from "./common";
import qs from "qs";

export interface SearchSectionsNewParams {
  page?: number;
  perPage?: number;
  query?: string;
  owner?: string;
  categoryId?: string[];
  authorId?: string;
  reviewed?: boolean;
  favorite?: number;
  latest?: number;
}

export const searchSectionsNew = async (
  params?: SearchSectionsNewParams,
): Promise<SearchSectionsResponseNew> => {
  const stringifiedParams = qs.stringify(params, { arrayFormat: "brackets" });

  const data = await fetchData<SearchSectionsResponseNew>(
    `${ApiEndpoints.SearchSectionsNew}?${stringifiedParams}`,
    APIMethods.GET,
  );

  return await searchSectionsNewSchema.validate(data);
};

const Translations = object({
  lang: string().required(),
  name: string().nullable().defined(),
  description: string().nullable().defined(),
});

const Image = object({
  url: string().defined(),
  variants: array().of(string()),
});

const Clinic = object({
  id: number().required(),
});

const Category = object({
  id: number().required(),
});

const sectionNewSchema = object({
  actions: actionsSchema.required(),
  author: object({
    id: string(),
    name: string().nullable(),
    avatar: string().nullable(),
  }).nullable(),
  category: Category,
  clinic: Clinic,
  id: number().required(),
  description: string().nullable().defined(),
  favorite: boolean().required(),
  image: Image.nullable().defined(),
  name: string().nullable().defined(),
  translations: array().of(Translations).required(),
});

const searchSectionsNewSchema = object({
  data: array().of(sectionNewSchema).required(),
  meta: MetadataSchema.required(),
  links: PaginationLinksSchema.required(),
});

export type SearchSectionsResponseNew = InferType<
  typeof searchSectionsNewSchema
>;

export type SectionsNew = SearchSectionsResponseNew["data"];
