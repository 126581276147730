import { useForm } from "react-hook-form";

export const useRecipeAndProductsForm = () => {
  const form = useForm<RecipeAndProductsInput>();

  return form;
};

interface RecipeAndProductsInput {
  alloweat: boolean;
  dietitian: boolean;
  allergen: boolean;
  diet: boolean;
  glutenFree: boolean;
  lactoseFree: boolean;
  vegetarian: boolean;
  vegan: boolean;
}
