import { ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { useDeletePatientProgramMutation } from "@hooks/queries";
import { TrashNew } from "@assets/icons";
import { CustomMenuItem } from "@views/dietician/PatientPrograms/PatientProgramsGrid.styled";
import { useAppTranslation } from "@hooks";
import DeleteModal from "@components/modals/DeleteModal/DeleteModal";
import { useState } from "react";

type DeleteActionProps = {
  onSuccess: () => void;
  patientId: number;
  programId: number;
  disabled?: boolean;
};

const DeleteAction = ({
  onSuccess,
  patientId,
  programId,
  disabled,
}: DeleteActionProps) => {
  const { t } = useAppTranslation();
  const [open, setOpen] = useState(false);
  const { mutate, isLoading: isDeleting } =
    useDeletePatientProgramMutation(patientId);

  const handleRemove = () => {
    setOpen(true);

    if (isDeleting) {
      return;
    }

    mutate(programId, {
      onSuccess: () => {
        onSuccess();
        setOpen(false);
      },
    });
  };

  return (
    <Tooltip
      arrow
      title={t("common.access_module_tooltip")}
      disableHoverListener={!disabled}
    >
      <div>
        <CustomMenuItem
          disabled={isDeleting || disabled}
          onClick={() => setOpen(true)}
        >
          <ListItemIcon>
            <TrashNew fill="#727272" style={{ width: "24px" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.programs_grid.row.actions.delete")}
          </ListItemText>
        </CustomMenuItem>
        <DeleteModal
          open={open}
          onClose={() => setOpen(false)}
          isLoading={isDeleting}
          confirmOnClick={handleRemove}
          content={t(
            "patient.programs_grid.row.actions.delete_confirm_question",
          )}
          title={t("common.remove")}
        />
      </div>
    </Tooltip>
  );
};

export default DeleteAction;
