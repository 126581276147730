import { useAppTranslation } from "@hooks";
import { Control, useController } from "react-hook-form";
import {
  Divider,
  FormControlLabel,
  Stack,
  styled,
  Switch,
} from "@mui/material";
import { FormControlLabelSwitchStyled } from "@components/EditClientPaymentsModal/EditClientPaymentsModal.styled";
import { FormCheckboxMui } from "@components/FormCheckboxMui";
import { EditClientPaymentsFormInput } from "@components/EditClientPaymentsModal/useEditClientPaymentsForm";
import { OuterLabel } from "@components/OuterLabel";

interface ClientContactsViewProps {
  control: Control<EditClientPaymentsFormInput>;
}

export const ClientMobileAppView = ({ control }: ClientContactsViewProps) => {
  const { t } = useAppTranslation();

  const {
    field: { value, onChange },
  } = useController({ control, name: "isMobileAppActive" });

  return (
    <div className="grid gap-4">
      <FormControlLabelStyled
        value={value}
        checked={value}
        control={<Switch color="primary" onChange={onChange} />}
        label={
          value
            ? t("client_profile.payments.deactivate_app")
            : t("client_profile.payments.activate_app")
        }
      />
      {value && (
        <Stack gap="24px">
          <DividerStyled />

          <OuterLabel
            label={t("cooperation_settings.modal.mobile.active_app_features")}
          >
            <Stack gap="8px">
              <FormCheckboxMui
                rebranding
                control={control}
                label={t("client_profile.edit.payments.chat")}
                name="chat"
              />
              <FormCheckboxMui
                rebranding
                control={control}
                label={t("cooperation_settings.modal.mobile.meal_review")}
                name="rateMeal"
              />
              <FormCheckboxMui
                rebranding
                control={control}
                label={t("cooperation_settings.modal.mobile.measurements")}
                name="measurements"
              />
              <FormCheckboxMui
                rebranding
                control={control}
                label={t("cooperation_settings.modal.mobile.hydration")}
                name="hydration"
              />
              <FormCheckboxMui
                rebranding
                control={control}
                label={t("cooperation_settings.modal.mobile.discover")}
                name="explore"
              />
            </Stack>
          </OuterLabel>
        </Stack>
      )}
    </div>
  );
};

export const FormControlLabelStyled = styled(FormControlLabel)`
  display: flex;
  gap: 8px;
  margin: unset;
`;

export const DividerStyled = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutral.light[200]};
`;
