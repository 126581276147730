import { useMemo, useState } from "react";

import { isArray } from "lodash";

import {
  CreatorDietMealRecipeDto,
  CreatorDietMealRecipeRequest,
} from "@client/diets/creator";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { ServingContextProvider } from "@components/PreviewDrawer/Recipe/ServingsContext";
import { MediumThumbComponent } from "@components/SliderThumbs";
import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchCreatorDietMealRecipeQuery } from "@hooks/queries/diets/creator";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";
import { CircularProgress, Slider, styled } from "@mui/material";
import { scaleNutrients } from "@utils";
import { useUpdateDietMealRecipeMutation } from "@views/dietician/DietCreator/hooks";

import { ProductIf, ProductsTable } from "./ProductsTable";

interface RecipeServingsModalProps {
  id: number;
  open: boolean;
  onClose: () => void;
  selectedServings: number;
  totalServings: number;
  name: string;
  mealId: string;
}

export const RecipeServingsModal = ({
  id,
  open,
  onClose,
  selectedServings,
  totalServings,
  name,
  mealId,
}: RecipeServingsModalProps) => {
  const [selected, setSelected] = useState<number>(selectedServings);
  const { isPolishChosen, t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { data, isLoading, isSuccess } = useFetchCreatorDietMealRecipeQuery(
    dietId,
    mealId,
    id.toString(),
    {
      enabled: open,
    },
  );
  const { version, invalidateVersion } = useCreatorVersion();
  const { mutate: updateRecipe, isLoading: isSaving } =
    useUpdateDietMealRecipeMutation({
      onSuccess: onClose,
      onError: e => invalidateVersion(e),
    });

  const productsMapped = useMemo<ProductIf[]>(
    () =>
      data?.recipe?.foodRecipe.map(f => ({
        id: f.food.id,
        grams: ((f.grams ?? 0) * selected) / totalServings,
        measureId: f.foodMeasureId,
        name:
          (isPolishChosen ? f.food.descriptionPl : f.food.description) ?? "",
        nutrients: f.food.nutrients,
        measures: f.food.measures,
        foodId: f.food.id,
      })) ?? [],
    [data, isPolishChosen, selected, totalServings],
  );
  const nutrientsScaled = scaleNutrients(
    data?.recipe?.nutrients ?? [],
    selected,
    totalServings,
  );

  const handleSubmit = () => {
    if (!data) return;

    updateRecipe({
      dietId,
      mealId,
      recipeId: id.toString(),
      payload: mapRecipeServingsRequest(data, selected, version),
    });
  };

  const isRecipeMultiplier = totalServings === 1;
  const maxTotalServings = isRecipeMultiplier ? 5 : totalServings;
  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={
        isRecipeMultiplier
          ? t("diet.servings_modal.multiply_title")
          : t("diet.servings_modal.servings_change_title")
      }
      loading={isSaving}
    >
      {isLoading && (
        <div className="grid place-items-center w-full">
          <CircularProgress />
        </div>
      )}

      {isSuccess && (
        <ServingContextProvider totalServings={totalServings}>
          <OuterLabel label={name}>
            <div className="flex flex-col gap-[16px]">
              <SliderWrapper>
                <SliderLabel>
                  {isRecipeMultiplier
                    ? t("diet.servings_modal.multiply_label")
                    : t("diet.servings_modal.servings_change_label")}
                  :
                </SliderLabel>

                <SliderText>
                  {isRecipeMultiplier
                    ? `${selected}x`
                    : `${selected}/${totalServings}`}
                </SliderText>
                <Slider
                  slots={{ thumb: MediumThumbComponent }}
                  min={1}
                  max={maxTotalServings}
                  value={selected}
                  onChange={(e, value) => !isArray(value) && setSelected(value)}
                />
              </SliderWrapper>

              <ProductsTable
                nutrients={nutrientsScaled}
                products={productsMapped}
                selectedServings={selected}
              />
            </div>
          </OuterLabel>
        </ServingContextProvider>
      )}
    </ModalWrapper>
  );
};

const SliderWrapper = styled("div")`
  display: flex;
  gap: 16px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  align-items: center;
  width: 400px;
`;

const SliderLabel = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #4d516e;
  white-space: nowrap;
`;

const SliderText = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

const mapRecipeServingsRequest = (
  { recipe }: CreatorDietMealRecipeDto,
  selectedServings: number,
  version: string,
): CreatorDietMealRecipeRequest => {
  const {
    id,
    name,
    nameEn,
    description,
    descriptionEn,
    servings,
    reviewed,
    tags,
    media,
    foodRecipe,
    comment,
    commentEn,
  } = recipe;

  const maxSelectedServings =
    servings === 1 ? selectedServings : Math.min(servings, selectedServings);

  return {
    servings: maxSelectedServings,
    recipe: {
      id: id.toString(),
      name,
      nameEn,
      description,
      descriptionEn,
      servings,
      reviewed,
      tags: tags.map(({ id }) => id),
      media: media,
      foodRecipe: foodRecipe.map(({ id, food, grams, foodMeasureId }) => ({
        id: id,
        foodId: food.id,
        grams: grams ?? 0,
        foodMeasureId,
      })),
      version: Number(version),
      comment,
      commentEn,
    },
  };
};
