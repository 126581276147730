import { useForm } from "react-hook-form";

export const useExploreForm = () => {
  const form = useForm<ExploreFormInput>();

  return form;
};

interface ExploreFormInput {
  inspire: boolean;
  preference: boolean;
  quiz: boolean;
}
