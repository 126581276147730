import { Button, styled } from "@mui/material";

interface FooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  disabled?: boolean;
}
export const Footer = ({ onCancel, onSubmit, disabled }: FooterProps) => {
  return (
    <Wrapper>
      <Button variant="outlined" onClick={onCancel}>
        Anuluj
      </Button>
      <Button variant="contained" onClick={onSubmit} disabled={disabled}>
        Zapisz
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  width: 510px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background: ${({ theme }) => theme.palette.primary.light};
  gap: 8px;
`;
