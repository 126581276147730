import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppParams, useAppTranslation } from "@hooks";
import {
  useDeleteDietMealProductMutation,
  useDeleteDietMealRecipeMutation,
} from "@views/dietician/DietCreator/hooks";

interface IdsIf {
  recipe: string;
  product: string;
}
interface DeleteMealElementsModalProps {
  open: boolean;
  onClose: () => void;
  mealId: string;
  ids: IdsIf;
  variant: "recipe" | "product";
}
export const DeleteMealElementModal = ({
  open,
  onClose,
  mealId,
  ids,
  variant,
}: DeleteMealElementsModalProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { mutate: deleteProduct, isLoading: isDeletingProduct } =
    useDeleteDietMealProductMutation();
  const { mutate: deleteRecipe, isLoading: isDeletingRecipe } =
    useDeleteDietMealRecipeMutation();

  const handleDeleteRecipe = () =>
    deleteRecipe(
      { dietId, mealId, recipeId: ids.recipe },
      { onSuccess: onClose },
    );
  const handleDeleteProduct = () =>
    deleteProduct(
      { dietId, mealId, productId: ids.product },
      { onSuccess: onClose },
    );

  if (variant === "product")
    return (
      <ModalWrapper
        open={open}
        onClose={onClose}
        onSubmit={handleDeleteProduct}
        color="error"
        title={t("diet.delete_product_modal.title")}
        confirmText={t("diet.delete_product_modal.title")}
        loading={isDeletingProduct}
      >
        {t("diet.delete_product_modal.question")}
      </ModalWrapper>
    );

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleDeleteRecipe}
      color="error"
      title={t("diet.delete_recipe_modal.title")}
      confirmText={t("diet.delete_recipe_modal.title")}
      loading={isDeletingRecipe}
    >
      {t("diet.delete_recipe_modal.question")}
    </ModalWrapper>
  );
};
