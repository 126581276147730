import _ from "lodash";

export const clientDictionariesMapper = (
  array: ArrayToDictElement[],
): ClientDictionary => {
  return _(array)
    .groupBy("id")
    .mapValues(el => ({
      ...el[0],
      names: _(el[0].names)
        .groupBy("lang")
        .mapValues(el => el[0].value)
        .value(),
    }))
    .value();
};

interface ArrayToDictElement {
  id: number;
  names: {
    lang: "pl" | "en";
    value: string;
  }[];
  sex?: "m" | "w";
}

interface ClientDictionary {
  [p: string]: {
    names: {
      [p: string]: string;
    };
    sex?: "m" | "w";
    id: number;
  };
}

export const POLISH_PHONE_PREFIX = "+48";

export const TAKEN_EMAIL_ERROR = "The email has already been taken.";
export const INVALID_NUMBER_ERROR =
  "The phone number field must be a valid number.";

export enum PATIENT_PROFILE_PROGRAM_STATUS {
  ACTIVE = 1,
  PENDING_ACTIVE = 2,
  PENDING = 3,
  ENDING = 4,
  FINISHED = 5,
  NO_PROGRAM = 6,
}

export enum CLIENT_PROFILE_EVENT_STATUS {
  CREATED = 1,
  ACTIVATED = 2,
  DEACTIVATED = 3,
  SURVEY_SENT = 4,
  SURVEY_RESPONDED = 5,
  VISIT_CREATED = 6,
  SUBSCRIPTION_ACTIVATED = 7,
  PROGRAM_CREATED = 8,
  MOBILE_APP_ACTIVATED = 9,
  MOBILE_APP_DEACTIVATED = 10,
}

export enum NOTIFICATION_EVENT_STATUS {
  PROGRAM_ENDS = 1,
  PATIENT_COOPERATION_ENDS = 2,
  SURVEY_REPORT = 3,
  PATIENT_BIRTHDAY = 4,
}
