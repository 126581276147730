import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import Macros from "@views/dietician/PatientMonitoring2/components/MealsBox/Macros";
import { useMemo } from "react";
import { ItemRecipe } from "@client/resources/DietitianMealsMonitoringResource";

type MealMacrosProps = {
  item: ItemRecipe;
};

const DietitianMealMacros = ({ item }: MealMacrosProps) => {
  const getNutrientValue = (id: number) =>
    Math.round(
      (item.item.servings / item.item.recipe.servings) *
        (item.item.recipe.nutrients.find(n => n.id === id)?.value ?? 0),
    );

  const kcal = useMemo(() => getNutrientValue(ENERGY_ID), [item]);
  const protein = useMemo(() => getNutrientValue(PROTEIN_ID), [item]);
  const fat = useMemo(() => getNutrientValue(FATS_ID), [item]);
  const carb = useMemo(() => getNutrientValue(CARBS_ID), [item]);

  return <Macros kcal={kcal} protein={protein} fat={fat} carb={carb} />;
};

export default DietitianMealMacros;
