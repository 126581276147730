import { OuterLabel } from "@components/OuterLabel";
import { Card, CardHeader } from "../components";
import { useFetchGlobalPatientSettingsQuery } from "@hooks/queries/clinic";
import { useAppTranslation, useModalState } from "@hooks";
import { EditHydrationModal } from "../modals/EditHydrationModal";
import { ContentProps } from "./Databases";
import { useIrrigationProducts } from "@hooks/dictionary/useIrrigationProducts";
import { useMemo } from "react";
import { Language } from "@typeDefinitions";
import { Chip } from "@mui/material";

export const Hydration = () => {
  const { data } = useFetchGlobalPatientSettingsQuery();

  if (!data) return null;
  return <HydrationContent data={data} />;
};

export const HydrationContent = ({ data }: ContentProps) => {
  const { t, currentLanguage } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { getTranslationById } = useIrrigationProducts();

  const irrigationProductName = useMemo(() => {
    if (!data) return "";

    return (
      getTranslationById(
        data.data.hydration.mainProductId,
        currentLanguage as unknown as Language,
      )?.name ?? ""
    );
  }, [data, currentLanguage, getTranslationById]);

  const {
    hydration: { goal, mainProductId },
  } = data.data;

  return (
    <Card>
      <CardHeader
        onEdit={onOpen}
        title={t("cooperation_settings.hydration.hydration")}
      />

      <OuterLabel label={t("cooperation_settings.hydration.daily_goal")}>
        {goal} ml
      </OuterLabel>
      <OuterLabel label={t("cooperation_settings.hydration.primary_beverage")}>
        <Chip label={irrigationProductName} color="primary" />
      </OuterLabel>

      <EditHydrationModal
        open={open}
        onClose={onClose}
        hydration={{ goal, mainProductId }}
      />
    </Card>
  );
};
