import { useMemo } from "react";
import { useAppTranslation } from "@hooks";
import { BodyMeasurementResource } from "@client/dictionaries/fetchBodyMeasurements";
import {
  Layout,
  Value,
  Label,
} from "@components/Client/BodyMeasurementsTabs/BodyMeasurementItem.styled";
import { getTranslation } from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";
import TrendArrowIcon from "@assets/icons_new/downwardArrow.svg?react";
import { useTheme } from "@mui/material";

type BodyMeasurementItemItemProps = {
  measurement: BodyMeasurementResource;
  value: string | null;
  showUnit?: boolean;
  upwardTrend?: boolean;
};

const BodyMeasurementItem = ({
  measurement,
  value,
  showUnit = true,
  upwardTrend,
}: BodyMeasurementItemItemProps) => {
  const { currentLanguage } = useAppTranslation();
  const { palette } = useTheme();
  const label = useMemo(() => {
    const translation = getTranslation(measurement, currentLanguage);

    if (!translation) {
      return measurement.name;
    }

    return translation.name;
  }, [measurement, currentLanguage]);

  const getValue = () => {
    if (!value) {
      return "-";
    }

    if (!showUnit) {
      return value;
    }

    return `${value} ${measurement.unit}`;
  };

  return (
    <Layout>
      <Label>{label}</Label>
      <div className="flex gap-[4px] items-center">
        <Value>{getValue()}</Value>
        {upwardTrend !== undefined && (
          <TrendArrowIcon
            fontSize={16}
            transform={upwardTrend ? `scale(1, -1)` : ""}
            color={palette.success.dark}
          />
        )}
      </div>
    </Layout>
  );
};

export default BodyMeasurementItem;
