import Divider from "@components/Alloweat/Divider";
import { DividerProps, styled } from "@mui/material";

type SymptomsDayDividerType = {
  title: string;
  textAlign: DividerProps["textAlign"];
};
const SymptomsDayDivider = ({ title, textAlign }: SymptomsDayDividerType) => {
  return <CustomDivider textAlign={textAlign}>{title}</CustomDivider>;
};

const CustomDivider = styled(Divider)`
  color: #727272;
  font: var(--font-figtree-regular);
  font-size: 10px;
  line-height: 18px;
`;

export default SymptomsDayDivider;
