import { useMutation, useQueryClient } from "@tanstack/react-query";

import { postFoodImage } from "@client/food";

import { MutationOptions } from "../types";
import { fetchProductQueryKey } from "./useFetchProductQuery";

export const usePostFoodImageMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UsePostFoodImageMutationPayload>(
    ({ foodId, formData, signal }) => postFoodImage(foodId, formData, signal),
    {
      ...options,
      onSuccess: (data, { foodId }) => {
        queryClient.invalidateQueries([fetchProductQueryKey, foodId]);
      },
    },
  );
};

interface UsePostFoodImageMutationPayload {
  foodId: string;
  formData: FormData;
  signal?: AbortSignal;
}
