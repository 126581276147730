import { Stack, styled } from "@mui/material";

import {
  Databases,
  Discover,
  Hydration,
  Measurements,
  Mobile,
  Pdf,
  Profile,
} from "./cards";
import { useAppTranslation } from "@hooks";

export const CooperationSettings = () => {
  const { t } = useAppTranslation();

  return (
    <Stack gap="24px">
      <Title>{t("cooperation_settings.cooperation_client")}</Title>

      <Stack direction="row" gap="16px">
        <Stack gap="16px" flex={1}>
          <Profile />
          <Databases />
        </Stack>

        <Stack gap="16px" flex={1}>
          <Mobile />
          <Measurements />
          <Hydration />
          <Discover />
        </Stack>

        <Stack gap="16px" flex={1}>
          <Pdf />
        </Stack>
      </Stack>
    </Stack>
  );
};

const Title = styled("h1")`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
