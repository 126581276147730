import { OuterLabel } from "@components/OuterLabel";
import { Card, CardHeader } from "../components";
import { useFetchGlobalPatientSettingsQuery } from "@hooks/queries/clinic";
import { mapChipsFromObject } from "../utils";
import { useAppTranslation, useModalState } from "@hooks";
import { EditMobileModal } from "../modals/EditMobileModal/EditMobileModal";
import { ContentProps } from "./Databases";

export const Mobile = () => {
  const { data } = useFetchGlobalPatientSettingsQuery();
  if (!data) return null;
  return <MobileContent data={data} />;
};

const MobileContent = ({ data }: ContentProps) => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const {
    mobileApp: { features, ...rest },
  } = data.data;

  return (
    <Card>
      <CardHeader
        onEdit={onOpen}
        title={t("cooperation_settings.mobile.mobile_application")}
      />

      <OuterLabel label={t("cooperation_settings.mobile.active_app_features")}>
        {mapChipsFromObject(features, t)}
      </OuterLabel>

      <EditMobileModal
        open={open}
        onClose={onClose}
        mobileApp={{ features, ...rest }}
      />
    </Card>
  );
};
