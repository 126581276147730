import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { fetchSection } from "@client";
import { useAppTranslation } from "@hooks";

export const fetchSectionQueryKey = "sectionDetails";

export const useFetchSectionQuery = (
  sectionId: number,
  options?: UseQueryOptions,
) => {
  const { t } = useAppTranslation();
  const { data: section, ...rest } = useQuery({
    queryKey: [fetchSectionQueryKey, sectionId],
    queryFn: () => fetchSection(sectionId),
    onError: () => {
      toast.error(t("common.error"));
    },
    enabled: options?.enabled,
  });

  return { section, ...rest };
};
