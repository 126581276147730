import { useCallback, useEffect, useMemo } from "react";

import { Dialog } from "@mui/material";

import {
  useAppTranslation,
  useClientParams,
  useUpdateClientMobileAppAccess,
  useUpdateClientPayments,
} from "@hooks";
import {
  fetchClientQueryKey,
  fetchClientsQueryKey,
  fetchPatientProgramQueryKey,
  useFetchClientQuery,
  useUpdateClientChatMutation,
  useUpdateClientFoodCreatorMutation,
} from "@hooks/queries";

import { Close } from "@assets/icons";

import {
  CancelButton,
  CloseButton,
  EditClientModalWrapper,
  StyledDialogActions,
  Title,
  TitleWrapper,
} from "./EditClientPaymentsModal.styled";
import { LoadingButton } from "@mui/lab";
import { ClientMobileAppView } from "./components/ClientMobileAppView";
import {
  EditClientPaymentsFormInput,
  mapEditClientPaymentsForm,
  useEditClientPaymentsForm,
} from "./useEditClientPaymentsForm";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useQueryClient } from "@tanstack/react-query";
import { fetchClientEventsQueryKey } from "@hooks/queries/useFetchClientEventsQuery";
dayjs.extend(utc);

interface EditClientModalProps {
  open: boolean;
  onClose: () => void;
}

export const EditClientPaymentsModal = ({
  open,
  onClose,
}: EditClientModalProps) => {
  const { t } = useAppTranslation();

  const id = useClientParams();
  const { client } = useFetchClientQuery(id);
  const queryClient = useQueryClient();
  if (!client) return <></>;

  const clientsMapped = useMemo(
    () => mapEditClientPaymentsForm(client),
    [client],
  );

  const { control, handleSubmit, reset } =
    useEditClientPaymentsForm(clientsMapped);

  const email = client.email;
  const {
    mutateAsync: mutatePayments,
    isSuccess: isSuccessPayments,
    isLoading: isLoadingPayments,
  } = useUpdateClientPayments({
    onSuccess: () => {
      null;
    },
  });

  const {
    mutateAsync: mutateChat,
    isSuccess: isSuccessChat,
    isLoading: isLoadingChat,
  } = useUpdateClientChatMutation(id, {
    onSuccess: () => {
      null;
    },
  });

  const {
    mutateAsync: mutateFoodCreator,
    isSuccess: isSuccessFoodCreator,
    isLoading: isLoadingFoodCreator,
  } = useUpdateClientFoodCreatorMutation(id, {
    onSuccess: () => {
      null;
    },
  });
  const {
    mutateAsync: mutateMobileAppAccess,
    isSuccess: isSuccessMobileAppAccess,
    isLoading: isLoadingMobileAppAccess,
  } = useUpdateClientMobileAppAccess({
    onSuccess: () => {
      null;
    },
  });

  const submit = useCallback(
    async (data: EditClientPaymentsFormInput) => {
      await mutatePayments(
        {
          id: id.toString(),
          payload: {
            activeUntil: null,
            isActive: true,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([fetchClientQueryKey, id]);
          },
        },
      );
      if (!email || !client.active) return;
      await mutateMobileAppAccess({
        id: id.toString(),
        payload: { hasAccess: data.isMobileAppActive },
      });
      await mutateChat({
        isActive: data.isMobileAppActive ? data.chat : false,
      });
      await mutateFoodCreator(
        { isActive: data.isMobileAppActive },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([fetchClientQueryKey, id]);
            queryClient.invalidateQueries([fetchPatientProgramQueryKey, id]);
            queryClient.invalidateQueries([
              fetchClientEventsQueryKey,
              id.toString(),
            ]);
            queryClient.invalidateQueries([fetchClientsQueryKey]);
          },
        },
      );
      //waiting for API
    },
    [
      mutateChat,
      mutateFoodCreator,
      mutateMobileAppAccess,
      mutatePayments,
      email,
      client.active,
    ],
  );

  const onCloseWithReset = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const isAllSuccess =
    isSuccessPayments &&
    isSuccessChat &&
    isSuccessFoodCreator &&
    isSuccessMobileAppAccess;

  const isSomeLoading =
    isLoadingPayments ||
    isLoadingChat ||
    isLoadingFoodCreator ||
    isLoadingMobileAppAccess;

  useEffect(() => {
    if (isAllSuccess) onCloseWithReset();
  }, [isAllSuccess]);

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <EditClientModalWrapper>
        <TitleWrapper>
          <Title>{t("client_profile.edit.payments.mobile_app")}</Title>
          <CloseButton size="small" onClick={onCloseWithReset}>
            <Close size="w-3 h-3" />
          </CloseButton>
        </TitleWrapper>
        <ClientMobileAppView control={control} />
      </EditClientModalWrapper>
      <StyledDialogActions>
        <CancelButton variant="outlined" onClick={onCloseWithReset}>
          {t("common.cancel")}
        </CancelButton>
        <LoadingButton
          variant="contained"
          loading={isSomeLoading}
          onClick={handleSubmit(submit, e => console.log("e", e))}
        >
          {t("common.save")}
        </LoadingButton>
      </StyledDialogActions>
    </Dialog>
  );
};
