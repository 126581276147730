import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import SymptomsSummaryCardOfDay from "@views/dietician/PatientMonitoring2/components/Symptoms/SummaryCard/SymptomsSummaryCardOfDay";
import SymptomsSummaryCardOfDays from "@views/dietician/PatientMonitoring2/components/Symptoms/SummaryCard/SymptomsSummaryCardOfDays";

const SymptomsSummaryCardFactory = () => {
  const { type } = useMonitoring();

  if (type.type === "day") {
    return <SymptomsSummaryCardOfDay />;
  }

  return <SymptomsSummaryCardOfDays />;
};

export default SymptomsSummaryCardFactory;
