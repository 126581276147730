import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchClinicDietitiansSimpleList = async (): Promise<
  ApiResponse<ClinicDietitianSimpleListDto[]>
> => {
  const response = await fetchData(
    "/dietitian/clinic/dietitians-simple-list",
    APIMethods.GET,
  );

  return await fetchClinicDietitiansSimpleListSchema.validate(response);
};

export interface ClinicDietitianSimpleListDto {
  id: number;
  name: string;
  image: ClinicDietitianImageDto | null;
}

export interface ClinicDietitianImageDto {
  id: number | null;
  url: string | null;
}

const clinicDietitianImageSchema = yup.object().shape({
  id: yup.number().nullable().defined(),
  url: yup.string().nullable().defined(),
});

const clinicDietitianSimpleSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  image: clinicDietitianImageSchema.nullable().defined(),
});

const fetchClinicDietitiansSimpleListSchema = yup.object().shape({
  data: yup.array(clinicDietitianSimpleSchema).required(),
});
