import { useState } from "react";
import { Menu, MenuItem, ListItemIcon, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { Pencil, Plus, TrashNew } from "@assets/icons";
import { IconButton } from "@components/IconButton";
import { useAppTranslation } from "@hooks";
import { CopyFrame } from "@assets/icons";
import { AddDocument } from "@assets/icons/AddDocument";
import { EducationalMaterialsMenuButton } from "../EducationalMaterialsView.styled";
import { AddFile } from "@assets/icons/AddFile";
import { AddVideo } from "@assets/icons/AddVideo";
import {
  ModalType,
  useEducationalModalContext,
} from "../_hooks/useEducationalMaterialsContext";

type SetAnchorEl = Dispatch<SetStateAction<HTMLElement | null>>;
type ExternalItemFunction = (setAnchorEl: SetAnchorEl) => ReactNode;

interface EducationalMaterialsEmptyStatesDropdownProps {
  externalButton?: ExternalItemFunction;
  externalContent?: ExternalItemFunction;
  horizontalOrigin?: "left" | "right";
}

export const EducationalMaterialsEmptyStatesDropdown = ({
  externalButton,
  externalContent,
  horizontalOrigin = "left",
}: EducationalMaterialsEmptyStatesDropdownProps) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { openModal } = useEducationalModalContext();
  const open = Boolean(anchorEl);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {externalButton ? (
        externalButton(setAnchorEl)
      ) : (
        <IconButton
          color="secondary-purple"
          className="border-none font-medium text-[14px] flex items-center gap-x-2"
          onClick={handleOpenDropdown}
        >
          <Plus />
          <span>{t("educational_materials.createDocument")}</span>
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseDropDown}
        transformOrigin={{ horizontal: horizontalOrigin, vertical: "top" }}
        anchorOrigin={{ horizontal: horizontalOrigin, vertical: "bottom" }}
        slotProps={{
          paper: {
            style: {
              width: "300px",
              marginTop: "10px",
            },
          },
        }}
      >
        {externalContent && externalContent(setAnchorEl)}
        <MenuItem onClick={() => openModal(ModalType.CREATE_DOCUMENT)}>
          <ListItemIcon>
            <EducationalMaterialsMenuButton>
              <AddDocument className="!text-[24px]" fill="none" />
              <span>{t("educational_materials.createDocument")}</span>
            </EducationalMaterialsMenuButton>
          </ListItemIcon>
        </MenuItem>
        <Tooltip
          title={t("educational_materials.featureInProgress")}
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -20],
                  },
                },
              ],
            },
          }}
        >
          <div>
            <MenuItem
              disabled={true}
              onClick={() => openModal(ModalType.CREATE_FILE)}
            >
              <ListItemIcon>
                <EducationalMaterialsMenuButton>
                  <AddFile className="!text-[24px] text-gray-450" fill="none" />
                  <span>{t("educational_materials.uploadFile")}</span>
                </EducationalMaterialsMenuButton>
              </ListItemIcon>
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip
          title={t("educational_materials.featureInProgress")}
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -20],
                  },
                },
              ],
            },
          }}
        >
          <div>
            <MenuItem
              disabled={true}
              onClick={() => openModal(ModalType.CREATE_VIDEO)}
            >
              <ListItemIcon>
                <EducationalMaterialsMenuButton>
                  <AddVideo className="!text-[24px] text-gray-450" />
                  <span>{t("educational_materials.addVideo")}</span>
                </EducationalMaterialsMenuButton>
              </ListItemIcon>
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
    </div>
  );
};
