import { useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { countBy, isArray, range, uniq } from "lodash";

import { Close } from "@assets/icons";
import { OptionIf } from "@components/Filters";
import { TabsChipsFilter } from "@components/Filters/TabsChipsFilter";
import { whatsNewListStub } from "@components/WhatsNewList";
import { useAppTranslation } from "@hooks";

import { EventItem } from "./components";
import {
  CloseButton,
  DrawerHeader,
  KnowledgeButton,
  StyledDrawer,
  StyledList,
} from "./WhatsNewList.styled";
import { ClickAwayListener, IconButton, Stack, styled } from "@mui/material";
import { ChevronIcon } from "@icons/index";
import { useHorizontalScroll } from "./hooks/useHorizontalScroll";

interface WhatsNewListProps {
  onClose: () => void;
  open: boolean;
  readNews: number[] | undefined;
}

type TabType = "all" | "mobile" | "web" | "announcement";
const KNOWLEDGE_LINK =
  "https://www.youtube.com/playlist?list=PLLkOkRlsDcT5jbMV7XocVpy1mJZQuOy18";

export const WhatsNewList = ({
  onClose,
  open,
  readNews,
}: WhatsNewListProps) => {
  const { t } = useAppTranslation();
  const [selected, setSelected] = useState<TabType>("all");
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const { scrollLeft, scrollRight } = useHorizontalScroll(tabsRef);
  const { mobile, web, announcement } = useMemo(
    () => countBy(whatsNewListStub, "type"),
    [countBy, whatsNewListStub],
  );

  const tabs: OptionIf<TabType>[] = [
    { id: "all", label: `${t("whatsNew.all")} (${whatsNewListStub.length})` },
    { id: "mobile", label: `${t("whatsNew.mobile")} (${mobile || 0})` },
    { id: "web", label: `${t("whatsNew.web")} (${web || 0})` },
    {
      id: "announcement",
      label: `${t("whatsNew.announcement")} (${announcement || 0})`,
    },
  ];

  const filteredList = useMemo(
    () =>
      whatsNewListStub.filter(
        ({ type }) => type === selected || selected === "all",
      ),
    [selected, readNews?.length],
  );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <StyledDrawer variant="persistent" anchor="right" open={open}>
        <DrawerHeader>
          {t("whatsNew.title")}
          <div className="flex items-center">
            <Link to={KNOWLEDGE_LINK} target="_blank" rel="noopener noreferrer">
              <KnowledgeButton>{t("whatsNew.video_tutorial")}</KnowledgeButton>
            </Link>
            <CloseButton onClick={onClose} size="small">
              <Close size="w-[10px] h-[10px]" />
            </CloseButton>
          </div>
        </DrawerHeader>

        <Stack gap="24px" padding="16px">
          <Stack direction="row" alignItems="center" gap="8px">
            <IconButtonStyled onClick={scrollLeft} size="small">
              <ChevronIcon fontSize={24} />
            </IconButtonStyled>
            <Stack
              direction="row"
              gap="8px"
              sx={{ overflowX: "scroll" }}
              className="hide-scrollbar"
              ref={tabsRef}
            >
              <TabsChipsFilter
                options={tabs}
                setSelected={id => !isArray(id) && setSelected(id)}
                selected={selected}
                isWrapped={false}
              />
            </Stack>
            <IconButtonStyled onClick={scrollRight} size="small">
              <ChevronIcon
                style={{ transform: "rotate(180deg)" }}
                color="#727272"
              />
            </IconButtonStyled>
          </Stack>

          <StyledList onClick={e => e.stopPropagation()}>
            {open &&
              filteredList.map(({ id, date, type, title, content, url }) => (
                <EventItem
                  key={id}
                  id={id}
                  date={date}
                  type={type as "mobile" | "web" | "announcement"}
                  title={title}
                  content={content}
                  url={url}
                  unread={!readNews?.includes(id)}
                />
              ))}
          </StyledList>
        </Stack>
      </StyledDrawer>
    </ClickAwayListener>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: unset;
  height: unset;
`;
