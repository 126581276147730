import { useTheme } from "@mui/material";
import { isArray } from "lodash";

import { PieChart } from "@assets/icons";
import { MediumThumbComponent } from "@components/SliderThumbs";
import { useAppTranslation } from "@hooks";

import {
  Label,
  LabelWrapper,
  SliderStyled,
  Value,
  Wrapper,
} from "./ServingsSlider.styled";

export interface ServingsSliderProps {
  onSliderChange: (value: number) => void;
  onSliderCommit: (value: number) => void;
  totalServings: number;
  selectedServings: number;
}

export const ServingsSlider = ({
  onSliderChange,
  onSliderCommit,
  totalServings,
  selectedServings,
}: ServingsSliderProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const { t } = useAppTranslation();

  const handleSliderChange = (value: number) => {
    onSliderChange(value);
  };

  const handleCommitedChange = (value: number) => {
    onSliderCommit(value);
  };

  const SINGLE_SERVING = 1;
  const FIVE_SERVINGS = 5;
  const isSingleServing = totalServings === SINGLE_SERVING;

  return (
    <Wrapper>
      <LabelWrapper>
        <PieChart fill={neutral.dark[700]} size="w-[14px] h-[14px]" />
        <Label>
          {isSingleServing
            ? t("recipe_edit.multiply_recipe")
            : t("recipe_edit.select_servings")}
          :
        </Label>
      </LabelWrapper>

      <Value>
        {selectedServings} {!isSingleServing && `/ ${totalServings}`}
      </Value>

      <SliderStyled
        slots={{ thumb: MediumThumbComponent }}
        value={selectedServings}
        max={isSingleServing ? FIVE_SERVINGS : totalServings}
        min={1}
        onChange={(e, v) => !isArray(v) && handleSliderChange(v)}
        onChangeCommitted={(e, v) => !isArray(v) && handleCommitedChange(v)}
      />
    </Wrapper>
  );
};
