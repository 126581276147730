import { useController, useFormContext } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { FormProps } from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsForm";
import NutrientsLabelLayout from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsLabelLayout";
import { useEffect } from "react";

const MealsMacroTargetField = () => {
  const { t } = useAppTranslation();
  const { control, watch } = useFormContext<FormProps>();
  const macroChecked = watch("macro");
  const {
    field: { onChange, value },
  } = useController({ control, name: "targetMeals" });

  useEffect(() => {
    if (value && !macroChecked) onChange(false);
  }, [macroChecked]);

  return (
    <FormControlLabel
      disabled={!macroChecked}
      sx={{
        margin: "unset",
        alignItems: "self-start",
        gap: "8px",
        marginLeft: "28px",
      }}
      control={
        <Switch
          color="primary"
          checked={value}
          onChange={onChange}
          sx={{ marginTop: "4px" }}
        />
      }
      label={
        <NutrientsLabelLayout
          label={t(
            "cooperation_settings.modal.profile.macronutrients_per_meal",
          )}
          description={t(
            "cooperation_settings.modal.profile.macronutrients_per_meal_description",
          )}
        />
      }
    />
  );
};

export default MealsMacroTargetField;
