import { Stack, styled } from "@mui/material";

import { OuterLabel } from "@components/OuterLabel";
import { useFetchGlobalPatientSettingsQuery } from "@hooks/queries/clinic";

import { Card, CardHeader, Divider } from "../components";
import { mapChipsFromObject } from "../utils";
import { EditPdfModal } from "../modals/EditPdfModal";
import { useAppTranslation, useModalState } from "@hooks";
import { Template } from "@client/resources/GlobalClinicPatientSettingsResource";
import { ContentProps } from "./Databases";
import { omit } from "lodash";
import usePdfImagesQuery from "@Pdf/hooks/query/usePdfImagesQuery";
import { useMemo } from "react";

export const Pdf = () => {
  const { data } = useFetchGlobalPatientSettingsQuery();
  if (!data) return null;
  return <PdfContent data={data} />;
};

const PdfContent = ({ data }: ContentProps) => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { data: images } = usePdfImagesQuery();

  const {
    pdf: {
      color,
      clinicData,
      mainPhotoId,
      menuData,
      shoppingListPeriod,
      template,
    },
  } = data.data;

  const selectedImg = useMemo(
    () => images?.find(({ id }) => id === Number(mainPhotoId))?.url,
    [images, mainPhotoId],
  );
  const imgObj = {
    [Template.PHOTO]: "/static/pdf/photo-cover.jpg",
    [Template.HAND_DRAWN]: "/static/pdf/hand-drawn-cover.jpg",
    [Template.PRINT]: "/static/pdf/print-cover.jpg",
  };
  const labelObj = {
    [Template.PHOTO]: t("pdf.template.photo"),
    [Template.HAND_DRAWN]: t("pdf.template.handDrawn"),
    [Template.PRINT]: t("pdf.template.print"),
  };

  return (
    <Card>
      <CardHeader
        onEdit={onOpen}
        title={t("cooperation_settings.pdf.pdf_settings")}
        tooltipText={t(
          "cooperation_settings.pdf.appearance_settings_description",
        )}
      />

      <SectionTitle>{t("cooperation_settings.pdf.appearance")}</SectionTitle>
      <OuterLabel label={t("cooperation_settings.pdf.primary_color")}>
        <Stack direction="row" gap="8px" alignItems="center">
          <ColorDot style={{ background: color }} />
          <Label>{color}</Label>
        </Stack>
      </OuterLabel>
      <OuterLabel label={t("cooperation_settings.pdf.template")}>
        <ImgContainer>
          <Img src={selectedImg ?? imgObj[template]} />
          <Label>{labelObj[template]}</Label>
        </ImgContainer>
      </OuterLabel>

      <Divider />

      <SectionTitle>
        {t("cooperation_settings.pdf.document_details")}
      </SectionTitle>
      <OuterLabel label={t("cooperation_settings.pdf.visible_clinic_info")}>
        {mapChipsFromObject(clinicData, t)}
      </OuterLabel>
      <OuterLabel
        label={t("cooperation_settings.pdf.additional_schedule_info")}
      >
        {mapChipsFromObject(
          { ...omit(menuData, "logo"), alloweatLogo: menuData.logo },
          t,
        )}
      </OuterLabel>
      <OuterLabel label={t("cooperation_settings.pdf.shopping_list_frequency")}>
        {shoppingListPeriod} {t("common.days", { count: shoppingListPeriod })}
      </OuterLabel>

      <EditPdfModal
        open={open}
        onClose={onClose}
        pdf={{
          color,
          clinicData,
          mainPhotoId,
          menuData,
          shoppingListPeriod,
          template,
        }}
      />
    </Card>
  );
};

const SectionTitle = styled("span")`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  text-transform: uppercase;
`;

const ColorDot = styled("div")`
  height: 16px;
  width: 16px;
  border-radius: 100%;
`;

const Label = styled("span")`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

const ImgContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 248px;
  width: 216px;
  border-radius: 16px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
`;

const Img = styled("img")`
  width: 200px;
  height: 200px;
  border-radius: 4px;
  border: 0.5px solid #f5f1fe;
  object-fit: cover;
`;
