import { MENU_OPENED_WIDTH } from "@layouts/Dashboard/Dashboard.styled";
import {
  Badge,
  Collapse,
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";

export const DrawerStyled = styled(Drawer)`
  & .MuiPaper-root {
    z-index: 599;
  }

  &
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    height: calc(100vh - 56px);
    border: none;
  }
  & .MuiDrawer-paper {
    padding: 0.75rem 0.5rem;
    gap: 1.25rem;
    top: 4rem;
    border-radius: unset;

    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.values.smallNew + MENU_OPENED_WIDTH}px) {
      box-shadow: 0px 0px 2px 0px #00000014;
    }
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
        theme.breakpoints.values.medium + MENU_OPENED_WIDTH}px) {
      box-shadow: 0px 0px 2px 0px #00000014;
    }
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.values.desktop}px) and (max-width: ${({ theme }) =>
        theme.breakpoints.values.desktop + MENU_OPENED_WIDTH}px) {
      box-shadow: 0px 0px 2px 0px #00000014;
    }
  }
`;

export const NavigationHeader = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: left;
  text-transform: uppercase;
  padding: 0.1875rem 0.5rem;
  height: 1.5rem;
`;

interface ListItemStyledProps {
  hidden?: boolean;
}

interface SelectedItemProps {
  selected?: boolean;
}

export const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "selected",
})<SelectedItemProps>`
  gap: 1rem;
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: background, color;

  ${({ selected, theme }) =>
    selected &&
    `
    background: ${theme.palette.primary.medium};
    color: ${theme.palette.primary.main};
    &:hover {
      color: ${theme.colors.neutral.dark[900]};
    }
  `}
`;

export const CollapseStyled = styled(Collapse)`
  overflow-y: auto;
  overflow-x: hidden;
  &.MuiCollapse-root.MuiCollapse-horizontal {
    height: 100%;
  }
  & .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 18px;
  }
`;

export const ListItemStyled = styled(ListItem, {
  shouldForwardProp: prop => prop !== "hidden",
})<ListItemStyledProps>`
  width: ${({ hidden }) => (hidden ? "2.5rem" : "100%")};
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const ListItemTextStyled = styled(ListItemText, {
  shouldForwardProp: prop => prop !== "selected",
})<SelectedItemProps>`
  width: 10.063rem;

  & .MuiTypography-root {
    font-weight: ${({ selected }) => (selected ? "600" : "normal")};
  }
`;

export const DividerStyled = styled(Divider)`
  border-bottom: unset;
  border-top-width: thin;
  height: 1.5rem;
  box-sizing: border-box;
`;

export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 12px;
    min-width: 12px;
    border-radius: 50%;
    padding: 0;
    top: 3px;
    font-size: 10px;
    right: 3px;

    &.MuiBadge-dot {
      border: 2px solid ${({ theme }) => theme.colors.neutral.light[100]};
    }
  }
`;
