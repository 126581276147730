import { IconButton, styled } from "@mui/material";
import CloseIcon from "@assets/icons_new/closeClean.svg?react";

interface HeaderProps {
  title: string;
  onClose: () => void;
}
export const Header = ({ title, onClose }: HeaderProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <IconButtonStyled size="small" onClick={onClose}>
        <CloseIcon fontSize={10} />
      </IconButtonStyled>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  width: 510px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;
const Title = styled("span")`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
const IconButtonStyled = styled(IconButton)`
  padding: unset;
  height: 20px;
  width: 20px;
`;
