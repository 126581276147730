import CalendarDays from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays";
import { CalendarDaysOfProps } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayOfType";
import { useFetchMonitoringCalendarDays } from "@hooks/queries/client/monitoring";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import dayjs, { Dayjs } from "dayjs";
import {
  CalendarDayTitle,
  CalendarDayWrapper,
} from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays.styled";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { CalendarDayMonitoringResource } from "@client/resources/CalendarDayMonitoringResource";
import CalendarDayItem from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayItem";
import { capitalizeFirstLetter } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysUtils";

const CalendarDaysOfCustom = (props: CalendarDaysOfProps) => {
  const {
    type: { setValue: setType },
    selectedDays: { value: selectDays, setValue: setSelectDays },
  } = props;

  const { patientId } = useMonitoring();

  const { data: calendarDaysData } = useFetchMonitoringCalendarDays({
    patientId,
    dateFrom: selectDays.from.format("YYYY-MM-DD"),
    dateTo: selectDays.to.format("YYYY-MM-DD"),
  });

  const handleOnClickDay = (day: Dayjs) => {
    setType("day");
    setSelectDays({ from: day, to: day });
  };

  const handleOnClickMonth = (item: Dayjs) => {
    setType("month");
    setSelectDays({
      from: item.clone().startOf("month"),
      to: item.clone().endOf("month"),
    });
  };

  const renderDaysOfMonth = (dayOfMonth: Dayjs) => {
    const days = [];

    const startDayOfMonth = selectDays.from.isAfter(
      dayOfMonth.clone().startOf("month"),
    )
      ? selectDays.from.clone()
      : dayOfMonth.clone().startOf("month");

    const endDayOfMonth = selectDays.to.isBefore(
      dayOfMonth.clone().endOf("month"),
    )
      ? selectDays.to.clone()
      : dayOfMonth.clone().endOf("month");

    for (
      let day = dayjs(startDayOfMonth);
      day.isBefore(endDayOfMonth);
      day = day.add(1, "day")
    ) {
      const apiDay: CalendarDayMonitoringResource | null =
        calendarDaysData?.data.find(d => d.date === day.format("YYYY-MM-DD")) ||
        null;

      days.push(
        <CalendarDayWrapper
          key={day.format("DD.MM.YYYY")}
          onClick={() => handleOnClickDay(day.clone())}
          selected={true}
        >
          <CalendarDayItem
            text={day.format("DD")}
            progress={apiDay?.progress ?? 0}
            selected={true}
            notification={Boolean(apiDay?.notification)}
            today={day.isToday()}
          />
        </CalendarDayWrapper>,
      );
    }
    return days;
  };

  const renderMonths = () => {
    const months = [];

    const getDaysCountInMonth = (currentMonth: Dayjs) => {
      let startOf = currentMonth.clone().startOf("month");
      let endOf = currentMonth.clone().endOf("month");

      if (selectDays.from.isAfter(startOf)) {
        startOf = selectDays.from.clone();
      }

      if (selectDays.to.isBefore(endOf)) {
        endOf = selectDays.to.clone();
      }

      return Math.abs(startOf.diff(endOf, "day", false));
    };

    for (
      let month = selectDays.from.clone();
      month.isSameOrBefore(selectDays.to, "month");
      month = month.add(1, "month")
    ) {
      const firstDayOfMonth = month.clone().startOf("month");
      const daysCount = getDaysCountInMonth(month);

      months.push(
        <Stack
          key={firstDayOfMonth.format("MM-YYYY")}
          direction="column"
          flex={daysCount}
        >
          <CalendarDayTitle
            onClick={() => {
              handleOnClickMonth(firstDayOfMonth.clone());
            }}
          >
            {capitalizeFirstLetter(firstDayOfMonth.format("MMMM"))}
          </CalendarDayTitle>
          <Stack direction="row">{renderDaysOfMonth(firstDayOfMonth)}</Stack>
        </Stack>,
      );
    }

    return months;
  };

  const daysComponents = useMemo(
    () => renderMonths(),
    [selectDays, calendarDaysData?.data],
  );

  return <CalendarDays>{daysComponents}</CalendarDays>;
};

export default CalendarDaysOfCustom;
