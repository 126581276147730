import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@assets/icons_new/closeClean.svg?react";
import { useLocalStorage } from "react-use";
import { useState } from "react";
import { ConferenceCard } from "./ConferenceCard";
import { WebinarCard } from "./WebinarCard";
import dayjs from "dayjs";

const EXPIRATION_DATE = "2025-02-22T14:00:00.000000Z";

const keepShowingWebinarKey = "keepShowingWebinarKey";

interface WebinarModalProps {
  open: boolean;
  onClose: () => void;
}

export const WebinarModal = ({ open, onClose }: WebinarModalProps) => {
  const [show, setShow] = useLocalStorage(keepShowingWebinarKey, true);
  const [hidePermamently, setHidePermamenlty] = useState(false);
  const isExpired = dayjs().isAfter(EXPIRATION_DATE);
  const handleClose = () => {
    hidePermamently && setShow(false);
    onClose();
  };

  if (!show || isExpired) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <Header>
        <IconButtonStyled onClick={onClose} size="small">
          <CloseIcon fontSize={12} />
        </IconButtonStyled>
      </Header>

      <Container>
        <Title>Dzieje się!</Title>
        <Subtitle>
          Działamy, inspirujemy i edukujemy. Dowiedz się, gdzie można nas
          spotkać i czerp wiedzę od ekspertów z branży.
        </Subtitle>

        <WebinarCard />

        <ConferenceCard />
      </Container>

      <Footer>
        <FormControlLabelStyled
          control={<Checkbox />}
          label="Nie pokazuj więcej"
          checked={hidePermamently}
          onChange={(e, value) => setHidePermamenlty(value)}
        />
        <ButtonStyled onClick={handleClose} variant="outlined">
          Zamknij
        </ButtonStyled>
      </Footer>
    </Dialog>
  );
};

const Header = styled("div")`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-radius: 16px;
  width: 660px;
`;

const IconButtonStyled = styled(IconButton)`
  padding: 4px;
`;

const Footer = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #fbfafc;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: unset;
`;

const ButtonStyled = styled(Button)`
  height: 40px;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 0 24px;
  width: 660px;
`;

const Title = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #141414;
`;

const Subtitle = styled("span")`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #141414;
`;
