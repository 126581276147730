import { useAppTranslation } from "@hooks";
import { useFetchSectionCategoriesQuery } from "@hooks/queries";

export const useGetSectionCategoryName = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { sectionCategories } = useFetchSectionCategoriesQuery();

  const getSectionCategoryName = (categoryId: number) => {
    const category = sectionCategories?.find(
      category => category.id === categoryId,
    );
    return isPolishChosen ? category?.description : category?.descriptionEn;
  };

  return { getSectionCategoryName };
};
