import { styled } from "@mui/material";

import CalendarIcon from "@assets/icons_new/calendar.svg?react";
import DesktopIcon from "@assets/icons_new/desktop.svg?react";
import PhoneIcon from "@assets/icons_new/phone.svg?react";

interface TypeIconProps {
  type: "mobile" | "web" | "announcement";
  unread?: boolean;
}
export const TypeIcon = ({ type, unread }: TypeIconProps) => {
  if (type === "announcement")
    return (
      <Warning>
        <CalendarIcon fontSize={24} />
        {unread && <Dot />}
      </Warning>
    );

  if (type === "mobile")
    return (
      <Info>
        <PhoneIcon fontSize={24} />
        {unread && <Dot />}
      </Info>
    );

  return (
    <Primary>
      <DesktopIcon fontSize={24} />
      {unread && <Dot />}
    </Primary>
  );
};

const Circle = styled("div")`
  display: grid;
  place-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 100%;
  position: relative;
`;

const Primary = styled(Circle)`
  background: ${({ theme }) => theme.palette.primary.medium};
  color: ${({ theme }) => theme.palette.primary.main};
`;
const Info = styled(Circle)`
  background: ${({ theme }) => theme.palette.info.light};
  color: ${({ theme }) => theme.palette.info.main};
`;
const Warning = styled(Circle)`
  background: ${({ theme }) => theme.palette.warning.light};
  color: ${({ theme }) => theme.palette.warning.main};
`;

const Dot = styled("div")`
  height: 12px;
  width: 12px;
  position: absolute;
  z-index: 9999999999;
  top: 0;
  right: 0;
  border-radius: 100%;
  border: 1.5px solid ${({ theme }) => theme.colors.neutral.light[100]};
  background: #5252e5;
`;
