import { FormRadioTileGroup } from "@components/FormRadioTileGroup";
import { RadioTile } from "@components/RadioTile";
import { useAppTranslation } from "@hooks";
import type { Control } from "react-hook-form";
import type { FC } from "react";
import type { FieldValues } from "react-hook-form/dist/types";

interface EducationalMaterialsLanguageSwitchProps {
  control: Control<FieldValues>;
}

export const EducationalMaterialsLanguageSwitch: FC<
  EducationalMaterialsLanguageSwitchProps
> = ({ control }) => {
  const { t } = useAppTranslation();
  return (
    <div className="flex items-center gap-3">
      <p className="uppercase text-gray-450 font-semibold">
        {t("educational_materials.languageSection")}
      </p>

      <FormRadioTileGroup
        variant="primary"
        name="language"
        className="bg-transparent border border-primary-opacity"
        control={control}
      >
        <RadioTile label="PL" value="PL" variant="primary" />
        <RadioTile label="EN" value="EN" variant="primary" />
      </FormRadioTileGroup>
    </div>
  );
};
