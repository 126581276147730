import { FeaturesDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { EditClientPaymentsFormInput } from "@components/EditClientPaymentsModal/useEditClientPaymentsForm";
import { useForm } from "react-hook-form";

export const useEditMobileForm = (defaultValues?: EditMobileFormInput) => {
  const form = useForm<EditMobileFormInput>({ defaultValues });

  return form;
};

export interface EditMobileFormInput
  extends Partial<EditClientPaymentsFormInput> {
  isMobileAppActive: boolean;
  chat: boolean;
  rateMeal: boolean;
  measurements: boolean;
  hydration: boolean;
  explore: boolean;
}

export const mapEditMobileForm = ({
  chat,
  discover,
  hydration,
  mealRating,
  measurement,
}: FeaturesDto): EditMobileFormInput => {
  return {
    chat,
    explore: discover,
    hydration,
    isMobileAppActive: true,
    measurements: measurement,
    rateMeal: mealRating,
  };
};
export const mapEditMobileRequest = ({
  chat,
  explore,
  hydration,
  measurements,
  rateMeal,
}: EditMobileFormInput): FeaturesDto => {
  return {
    chat,
    discover: explore,
    hydration,
    mealRating: rateMeal,
    measurement: measurements,
  };
};
