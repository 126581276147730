import { useEffect, useRef } from "react";

import { ImageOutlined } from "@mui/icons-material";

import { MediaExtendedDto } from "@client";
import { useAppParams, useAppTranslation, useImageUpload } from "@hooks";
import {
  ImgWrapper,
  StyledConfirmationIconButton,
  StyledIconButton,
  StyledImgInput,
} from "@components/RecipeEditDetails/RecipeEditDetails.styled";
import { ConfirmationIconButton } from "@components/ConfirmationIconButton";
import { Trash, UploadImgFrame } from "@assets/icons";

import { StyledImagePlaceholder, StyledImg } from "./SelectedRecipeEdit.styled";
import { RecipeWrapperIf } from "./SelectedRecipeEdit";
import { useUpdateCreatorMealRecipeMutation } from "@hooks/queries/meals";
import { apiUrl } from "@utils/configEnv";

interface MealRecipeImageProps {
  preview: boolean;
  media: MediaExtendedDto | null;
  recipeWrapper: RecipeWrapperIf;
}

export const MealRecipeImage = ({
  preview,
  media,
  recipeWrapper,
}: MealRecipeImageProps) => {
  const { t } = useAppTranslation();
  const { mealId } = useAppParams();
  const { mutate } = useUpdateCreatorMealRecipeMutation(
    mealId,
    recipeWrapper.id.toString(),
  );

  const {
    isUploading,
    imgUrl,
    handleUploadAvatar,
    uploadImgResponse,
    resetImageUploadResponse,
  } = useImageUpload({
    url: `${apiUrl()}/dietitian/media/recipe/upload`,
    formDataName: "image",
  });

  const handleDeleteImg = () => {
    resetImageUploadResponse();
    mutate({
      recipe: {
        ...recipeWrapper.recipe,
        nameEn: recipeWrapper.recipe.nameEn ?? "",
        description: recipeWrapper.recipe.description ?? "",
        descriptionEn: recipeWrapper.recipe.descriptionEn ?? "",
        tags: recipeWrapper.recipe.tags.map(t => t.id),
        media: null,
        foodRecipe: recipeWrapper.recipe.foodRecipe.map(f => ({
          foodId: f.food.id,
          foodMeasureId: f.foodMeasureId,
          grams: f.grams ?? 0,
          id: f.id,
        })),
      },
      servings: recipeWrapper.servings,
    });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFileButtonClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  useEffect(() => {
    if (uploadImgResponse)
      mutate({
        recipe: {
          ...recipeWrapper.recipe,
          nameEn: recipeWrapper.recipe.nameEn ?? "",
          description: recipeWrapper.recipe.description ?? "",
          descriptionEn: recipeWrapper.recipe.descriptionEn ?? "",
          tags: recipeWrapper.recipe.tags.map(t => t.id),
          media: { id: uploadImgResponse.id },
          foodRecipe: recipeWrapper.recipe.foodRecipe.map(f => ({
            foodId: f.food.id,
            foodMeasureId: f.foodMeasureId,
            grams: f.grams ?? 0,
            id: f.id,
          })),
        },
        servings: recipeWrapper.servings,
      });
  }, [uploadImgResponse]);

  return (
    <>
      {media || uploadImgResponse?.url ? (
        <ImgWrapper>
          <StyledImg
            src={imgUrl}
            srcSet={uploadImgResponse?.s3Url || media?.s3Url || ""}
            alt={t("recipe.recipe_img")}
          />
          {!preview && (
            <StyledConfirmationIconButton onClick={e => e.stopPropagation()}>
              <ConfirmationIconButton
                icon={<Trash size="w-5 h-5" />}
                onConfirm={handleDeleteImg}
                confirmationText={t("confirmation.question_delete")}
                submitText={t("confirmation.delete")}
              />
            </StyledConfirmationIconButton>
          )}
        </ImgWrapper>
      ) : (
        <>
          {preview && (
            <StyledImagePlaceholder>
              <ImageOutlined color="disabled" />
            </StyledImagePlaceholder>
          )}
          {!preview && (
            <StyledIconButton onClick={handleUploadFileButtonClick}>
              <StyledImgInput
                name="image"
                type="file"
                accept="image/*"
                ref={fileInputRef}
                disabled={isUploading}
                onChange={handleUploadAvatar}
              />
              <UploadImgFrame className="w-full h-full" />
            </StyledIconButton>
          )}
        </>
      )}
    </>
  );
};
