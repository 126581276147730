import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import { useMemo } from "react";
import { useAppTranslation } from "@hooks";
import { FormPops } from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/Dialog/IrrigationForm";
import { DialogSectionItemLabel } from "@views/dietician/ClientHealth/ClientHealthNew.styled";

export const getGoalsVariants = () => {
  const goals = [];
  for (let i = 1500; i < 4000; i = i + 250) {
    goals.push(i.toString());
  }

  return goals;
};

export const GOALS_VARIANTS = [
  "1500",
  "1750",
  "2000",
  "2250",
  "2500",
  "2750",
  "3000",
  "3250",
  "3500",
  "3750",
  "4000",
];

export const GOAL_OTHER_VARIANT = "other";

const GoalField = () => {
  const { t } = useAppTranslation();
  const { watch, setValue } = useFormContext<FormPops>();

  const selectedGoal = watch("selectedGoal");

  return (
    <Container>
      <DialogSectionItemLayout>
        <DialogSectionItemLabel>
          {t(
            "patient.health_and_goal.dietary_profile_tab.section_hydration_modal.daily_goal",
          )}
        </DialogSectionItemLabel>
        <Controller
          render={({ field }) => {
            return (
              <Select
                value={field.value}
                onChange={event => {
                  field.onChange(event.target.value);
                  let value = event.target.value;
                  if (event.target.value === GOAL_OTHER_VARIANT) {
                    value = "";
                  }
                  setValue("goal", value);
                }}
              >
                {GOALS_VARIANTS.map(variant => (
                  <MenuItem key={variant} value={variant}>
                    {variant} ml
                  </MenuItem>
                ))}
                <MenuItem value={GOAL_OTHER_VARIANT}>
                  {t(
                    "patient.health_and_goal.dietary_profile_tab.section_hydration_modal.other",
                  )}
                </MenuItem>
              </Select>
            );
          }}
          name="selectedGoal"
        />
      </DialogSectionItemLayout>
      {selectedGoal === "other" && (
        <DialogSectionItemLayout>
          <DialogSectionItemLabel>
            {t(
              "patient.health_and_goal.dietary_profile_tab.section_hydration_modal.set_custom_daily_goal",
            )}
          </DialogSectionItemLabel>
          <Controller
            render={({ field }) => {
              return (
                <TextField
                  inputProps={{
                    style: { height: "unset", padding: "8px 12px" },
                  }}
                  placeholder={t(
                    "patient.health_and_goal.dietary_profile_tab.section_hydration_modal.daily_goal",
                  )}
                  value={field.value}
                  onChange={event => {
                    if (
                      !/^\d+$/.test(event.target.value) &&
                      event.target.value != ""
                    ) {
                      return;
                    }

                    if (event.target.value.length > 4) {
                      return;
                    }

                    field.onChange(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">ml</InputAdornment>
                    ),
                  }}
                />
              );
            }}
            name="goal"
          />
        </DialogSectionItemLayout>
      )}
    </Container>
  );
};

export default GoalField;

const DialogSectionItemLayout = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 140px;
`;
const Container = styled("div")`
  display: flex;
  gap: 16px;
`;
