import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  styled,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import CalendarIcon from "@assets/icons_new/calendar.svg?react";

const EXPIRATION_DATE = "2025-02-22T14:00:00.000000Z";

export const ConferenceCard = () => {
  const isExpired = dayjs().isAfter(EXPIRATION_DATE);
  const { palette } = useTheme();
  const dayjsDate = dayjs(EXPIRATION_DATE);
  const dateString = `${dayjsDate.format("L")} (${dayjsDate.format(
    "dddd",
  )}) | godz. 14:45 - 15:20`;
  if (isExpired) return null;

  return (
    <Container>
      <TopSection>
        <Stack gap="8px">
          <Stack gap="4px">
            <Image src="/static/webinarModal/conferenceLogo.png" />
            <Title>Jadłospis w 2025 - hot or not</Title>
          </Stack>

          <Stack direction="row" gap="8px" alignItems="center">
            <CalendarIcon fontSize={24} color={palette.primary.main} />
            <DateText>{dateString}</DateText>
          </Stack>
        </Stack>

        <Hero src="/static/webinarModal/singlePhoto.png" />
      </TopSection>

      <DividerStyled />

      <Box>
        <ButtonStyled
          LinkComponent={Link}
          href="https://konferencja-psychodietetyki.pl/"
          variant="contained"
          target="_blank"
        >
          Dowiedz się więcej
        </ButtonStyled>
      </Box>
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 612px;
  height: 210px;
  background: #fbfafc;
  box-shadow: 0px 0px 10px 0px #0000000d;
  backdrop-filter: blur(24px);
  border-radius: 12px;
  padding: 16px;
`;

const TopSection = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const Image = styled("img")`
  width: 111px;
  height: 34.8px;
`;
const Hero = styled("img")`
  width: 100px;
  height: 100px;
`;

const Title = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: #2d1d51;
`;

const DateText = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #2d1d51;
`;

const DividerStyled = styled(Divider)`
  border-color: #f5f1fe;
`;

const ButtonStyled = styled(Button)`
  height: 40px;
` as typeof Button;
