import {
  AuthorType,
  EducationalMaterialsTabs,
  StatusTag,
  ViewTabs,
} from "../EducationalMaterialsView.types";
import { FourSquares } from "@assets/icons/DesignSystem";
import { List } from "@assets/icons";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { useFetchSectionCategoriesQuery } from "@hooks/queries";
import { ListTabs, useListTabs, useUserRoles } from "@hooks";
import { useMemo } from "react";
import { useTaskFilterOptions } from "@hooks/tasks/useTaskFilterOptions";

export const useEducationalMaterialsFilterOptions = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { sectionCategories } = useFetchSectionCategoriesQuery();

  const { dietitiansOptions } = useTaskFilterOptions();

  const tabs = Object.entries(EducationalMaterialsTabs).map(([, label]) => ({
    id: label,
    label: t(`educational_materials.${label}`),
    disabled:
      label === EducationalMaterialsTabs.VIDEO_FILES ||
      label === EducationalMaterialsTabs.PDF_FILES,
    tooltip:
      label === EducationalMaterialsTabs.VIDEO_FILES
        ? t("educational_materials.featureInProgress")
        : label === EducationalMaterialsTabs.PDF_FILES
        ? t("educational_materials.featureInProgress")
        : undefined,
  }));

  const { regularTabs } = useListTabs();

  const finalTags: { label: string; id: ListTabs }[] = useMemo(
    () =>
      regularTabs
        .filter(({ id }) => id !== ListTabs.OWN && id !== ListTabs.FAVORITE)
        .map(({ label, id }) => ({ label, id })),
    [regularTabs],
  );

  const tagsWithCount = useMemo(() => {
    return finalTags.map(({ id, label }) => ({
      id,
      label,
    }));
  }, [t, tabs]);

  const viewTabsOptions = Object.values(ViewTabs).map(tab => {
    const size = "w-[16px] h-[16px]";
    const tabIcon =
      tab === ViewTabs.LIST ? (
        <List size={size} />
      ) : (
        <FourSquares size={size} />
      );
    return {
      id: tab,
      label: tabIcon,
    };
  });

  const categoryOptions =
    sectionCategories?.map(category => ({
      id: String(category.id),
      label: isPolishChosen ? category.description : category.descriptionEn,
    })) ?? [];

  const authorOptions =
    Object.values(AuthorType).map(author => ({
      id: author,
      label: t(`educational_materials.${author}`),
    })) ?? [];

  const dietitianOptions = dietitiansOptions ?? [];

  const statusOptions = finalTags.filter(({ id }) => id !== ListTabs.OWN);

  return {
    viewTabsOptions,
    categoryOptions,
    sectionCategories,
    tabs,
    tagsWithCount,
    finalTags,
    dietitianOptions,
    authorOptions,
    statusOptions,
  };
};
