import { useEffect, useMemo, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import {
  FormTextField,
  FormTextFieldCharCounter,
} from "@components/FormTextField";
import { OuterLabel } from "@components/OuterLabel";
import { TabSwitch } from "@components/TabSwitch";
import { UploadImage } from "@components/UploadImage";
import { Lang, useAppTranslation } from "@hooks";

import { CardStyled } from "../common.styled";
import { RecipeEditInput } from "../useRecipeEditForm";
import { Label } from "./BasicInformation.styled";

export const BasicInformation = () => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState(Lang.PL);
  const tabs = useMemo(
    () => [
      { id: Lang.PL, label: Lang.PL.toUpperCase() },
      { id: Lang.EN, label: Lang.EN.toUpperCase() },
    ],
    [],
  );
  const {
    field: { value, onChange },
  } = useController<RecipeEditInput, "media">({ name: "media" });
  const { formState } = useFormContext<RecipeEditInput>();

  useEffect(() => {
    const errorFieldNames = Object.keys(formState.errors);
    if (errorFieldNames.includes(Lang.PL)) setTab(Lang.PL);
  }, [formState.errors]);

  return (
    <>
      <div className="flex gap-[12px] items-center">
        <Label>{t("diet.recipe_sidepanel.language")}</Label>
        <TabSwitch tabs={tabs} value={tab} onChange={setTab} />
      </div>

      <UploadImage
        title={
          <>
            <strong>{t("diet.recipe_sidepanel.add_photo")}</strong>{" "}
            {t("diet.recipe_sidepanel.or_drag")}
          </>
        }
        min={{ height: 600, width: 600 }}
        img={value?.url ?? undefined}
        onDelete={() => onChange(null)}
        onSuccess={media =>
          media && onChange({ id: media.id, url: media.s3Url })
        }
      />
      <CardStyled>
        <Label>{t("diet.recipe_sidepanel.info_tab")}</Label>

        <OuterLabel label={t("diet.recipe_sidepanel.name")}>
          <FormTextField
            key={`${tab}.name`}
            name={`${tab}.name`}
            size="small"
            fullWidth
            placeholder={t("diet.recipe_sidepanel.name_placeholder")}
          />
        </OuterLabel>
        <OuterLabel label={t("diet.recipe_sidepanel.preparation")}>
          <FormTextFieldCharCounter
            key={`${tab}.description`}
            name={`${tab}.description`}
            size="small"
            fullWidth
            multiline
            maxLength={1600}
            minRows={2}
          />
        </OuterLabel>
        <OuterLabel label={t("recipe.comment")}>
          <FormTextFieldCharCounter
            key={`${tab}.comment`}
            name={`${tab}.comment`}
            size="small"
            fullWidth
            multiline
            maxLength={1200}
            minRows={3}
            strict
          />
        </OuterLabel>
      </CardStyled>
    </>
  );
};
