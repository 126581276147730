import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  MeasurementAnswerResourceDto,
  SystemQuestionDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";
import { decimalInput2ThreeDigitsNotFixed } from "@utils/inputs";
import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider, FormTextFieldWrapper } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  MeasurementsAnswerFormInputs,
  mapMeasurementsAnswerForm,
  mapMeasurementsAnswerRequest,
  useMeasurementsAnswerForm,
} from "../forms";

interface SystemQuestionMeasurementsProps {
  question: SystemQuestionDto;
  answer?: MeasurementAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
}

export const SystemQuestionMeasurements = ({
  question: {
    title,
    config: { required },
    id,
  },
  answer,
  readOnly,
  ...rest
}: SystemQuestionMeasurementsProps) => {
  const { t } = useAppTranslation();
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapMeasurementsAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, control, ...form } =
    useMeasurementsAnswerForm(mappedForm, required);
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: MeasurementsAnswerFormInputs) => {
    if (url) mutate({ payload: mapMeasurementsAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);
  return (
    <FormProvider
      formState={formState}
      handleSubmit={handleSubmit}
      control={control}
      {...form}
    >
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={null}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <OuterLabel
            label={t("questionnaires.questions.system_measurements.arm")}
          >
            <FormTextFieldWrapper
              control={control}
              fullWidth
              name="arm"
              size="small"
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                readOnly,
                error: !readOnly && hasError,
                inputComponent: decimalInput2ThreeDigitsNotFixed,
              }}
            />
          </OuterLabel>
          <OuterLabel
            label={t("questionnaires.questions.system_measurements.biceps")}
          >
            <FormTextFieldWrapper
              control={control}
              fullWidth
              name="biceps"
              size="small"
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                readOnly,
                error: !readOnly && hasError,
                inputComponent: decimalInput2ThreeDigitsNotFixed,
              }}
            />
          </OuterLabel>
          <OuterLabel
            label={t("questionnaires.questions.system_measurements.waist")}
          >
            <FormTextFieldWrapper
              control={control}
              fullWidth
              name="waist"
              size="small"
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                readOnly,
                error: !readOnly && hasError,
                inputComponent: decimalInput2ThreeDigitsNotFixed,
              }}
            />
          </OuterLabel>
          <OuterLabel
            label={t("questionnaires.questions.system_measurements.hips")}
          >
            <FormTextFieldWrapper
              control={control}
              fullWidth
              name="hip"
              size="small"
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                readOnly,
                error: !readOnly && hasError,
                inputComponent: decimalInput2ThreeDigitsNotFixed,
              }}
            />
          </OuterLabel>
          <OuterLabel
            label={t("questionnaires.questions.system_measurements.thigh")}
          >
            <FormTextFieldWrapper
              control={control}
              fullWidth
              name="thigh"
              size="small"
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                readOnly,
                error: !readOnly && hasError,
                inputComponent: decimalInput2ThreeDigitsNotFixed,
              }}
            />
          </OuterLabel>
          <OuterLabel
            label={t("questionnaires.questions.system_measurements.calf")}
          >
            <FormTextFieldWrapper
              control={control}
              fullWidth
              name="calf"
              size="small"
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                readOnly,
                error: !readOnly && hasError,
                inputComponent: decimalInput2ThreeDigitsNotFixed,
              }}
            />
          </OuterLabel>
          <OuterLabel
            label={t("questionnaires.questions.system_measurements.abdominal")}
          >
            <FormTextFieldWrapper
              control={control}
              fullWidth
              name="abdominal"
              size="small"
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                readOnly,
                error: !readOnly && hasError,
                inputComponent: decimalInput2ThreeDigitsNotFixed,
              }}
            />
          </OuterLabel>
          <OuterLabel
            label={t("questionnaires.questions.system_measurements.chest")}
          >
            <FormTextFieldWrapper
              control={control}
              fullWidth
              name="chest"
              size="small"
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                readOnly,
                error: !readOnly && hasError,
                inputComponent: decimalInput2ThreeDigitsNotFixed,
              }}
            />
          </OuterLabel>
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
