import { ConfirmationModal } from "@components/ConfirmationModal";
import { useAppTranslation } from "@hooks/useAppTranslation";
import {
  ModalType,
  useEducationalModalContext,
} from "../_hooks/useEducationalMaterialsContext";
import { useDeleteSectionMutation } from "@hooks/queries";
import { CreateSectionModal } from "@views/dietician/ProgramForm/components";
import { EducationalMaterialsCreateCategoryModal } from "./EducationalMaterialsCreateCategoryModal";
import { EducationalMaterialsCreateVideoModal } from "./EducationalMaterialsCreateVideoModal";
import { EducationalMaterialsCreateFileModal } from "./EducationalMaterialsCreateFileModal";
import { EducationalMaterialsPreview } from "./EducationalMaterialsPreview";
import { useFetchSectionQuery } from "@hooks/queries";

export const EducationalMaterialsModals = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { closeModal, getModalState, materialId } =
    useEducationalModalContext();

  const deleteSectionMutation = useDeleteSectionMutation();

  const { section } = useFetchSectionQuery(Number(materialId), {
    enabled: !!materialId,
  });

  const handleDelete = () => {
    if (materialId) {
      deleteSectionMutation.mutate(Number(materialId));
    }
  };

  const deleteItemTitle = isPolishChosen
    ? section?.data.title
    : section?.data.titleEn;

  return (
    <>
      {deleteItemTitle && (
        <ConfirmationModal
          confirmText={t("common.delete")}
          description={t(
            "educational_materials.deleteEducationalMaterialConfirmation",
            {
              variable: deleteItemTitle,
            },
          )}
          onCancel={() => closeModal(ModalType.DELETE_MATERIAL)}
          open={getModalState(ModalType.DELETE_MATERIAL)}
          onConfirm={handleDelete}
          title={t("educational_materials.deleteEducationalMaterial")}
        />
      )}
      <EducationalMaterialsCreateCategoryModal
        onClose={() => closeModal(ModalType.CREATE_CATEGORY)}
        open={getModalState(ModalType.CREATE_CATEGORY)}
      />
      <CreateSectionModal
        onClose={() => closeModal(ModalType.CREATE_DOCUMENT)}
        open={getModalState(ModalType.CREATE_DOCUMENT)}
      />
      <EducationalMaterialsCreateVideoModal
        onClose={() => closeModal(ModalType.CREATE_VIDEO)}
        open={getModalState(ModalType.CREATE_VIDEO)}
      />
      <EducationalMaterialsCreateFileModal
        onClose={() => closeModal(ModalType.CREATE_FILE)}
        open={getModalState(ModalType.CREATE_FILE)}
      />
      <EducationalMaterialsPreview
        onClose={() => closeModal(ModalType.PREVIEW)}
        open={getModalState(ModalType.PREVIEW)}
      />
    </>
  );
};
