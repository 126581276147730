import { SyntheticEvent, useMemo, useRef, useState } from "react";

import { TabContext } from "@mui/lab";
import { useTheme } from "@mui/material";

import { Clock, Cutlery, PieChart } from "@assets/icons";
import { ArrowRight } from "@assets/icons/Arrows";
import { FoodRecipeDto } from "@client/meals";
import { RecipeTabPanels, RecipeTabs } from "@components/PreviewDrawer/Recipe";
import { ProductIf } from "@components/PreviewDrawer/Recipe/NutrientsTab/ProductsTable";
import { ServingContextProvider } from "@components/PreviewDrawer/Recipe/ServingsContext";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { useAppTranslation } from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";
import { Nutrient } from "@typeDefinitions";
import { TagCategoryType } from "@utils/tagsNew";

import { AccordionTitle } from "../AccordionTitle/AccordionTitle";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  Label,
  TabsWrapper,
  TagIconWrapper,
} from "./common.styled";
import { RecipeTabPanelsProxy } from "./RecipeTabPanelsProxy";

interface RecipeAccordionProps {
  id: number;
  name: string;
  nutrients: Nutrient[];
  tags: number[];
  servings: number;
  totalServings: number;
  img: string | null;
  collectionId: number;
}

export const RecipeAccordion = ({
  id,
  name,
  nutrients,
  tags,
  servings,
  totalServings,
  img,
  collectionId,
}: RecipeAccordionProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const {
    colors: { neutral },
  } = useTheme();
  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const [tab, setTab] = useState(RecipeTabs.NUTRITIONAL_VALUES);
  const anchorEl = useRef<null | HTMLDivElement>(null);

  const hasMultipleServings = totalServings > 1;

  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;

  const difficultyOfPreparationTag = tags?.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id === tag),
  );
  const preparationTimeTag = tags?.find(tag =>
    preparationTimeTagList?.some(t => t.id === tag),
  );

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        id: RecipeTabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.preparation"),
        id: RecipeTabs.PREPARATION,
      },
      {
        label: t("common.tags"),
        id: RecipeTabs.TAGS,
      },
    ],
    [t],
  );

  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    isExpanded: boolean,
  ) => {
    const current = anchorEl.current;
    if (isExpanded && current) {
      setTimeout(
        () => current.scrollIntoView({ behavior: "smooth", block: "center" }),
        350,
      );
    }
  };
  return (
    <AccordionStyled
      key={id}
      TransitionProps={{ unmountOnExit: true }}
      ref={anchorEl}
      onChange={handleChange}
    >
      <AccordionSummaryStyled
        expandIcon={<ArrowRight size="w-[16px] h-[16px]" />}
      >
        <AccordionTitle
          img={img}
          name={name}
          tags={
            <>
              {!!preparationTimeTag && (
                <TagIconWrapper>
                  <Clock fill={neutral.dark[700]} />
                  <Label>
                    {tagNameDictionary.get(Number(preparationTimeTag))}
                  </Label>
                </TagIconWrapper>
              )}
              {!!difficultyOfPreparationTag && (
                <TagIconWrapper>
                  <Cutlery fill={neutral.dark[700]} />
                  <Label>
                    {tagNameDictionary.get(Number(difficultyOfPreparationTag))}
                  </Label>
                </TagIconWrapper>
              )}
              {hasMultipleServings && (
                <TagIconWrapper>
                  <PieChart fill={neutral.dark[700]} />
                  <Label>{`${servings}/${totalServings} ${t(
                    "recipe.portions",
                  )}`}</Label>
                </TagIconWrapper>
              )}
            </>
          }
          nutrients={nutrients}
        />
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <TabsWrapper>
          <TabSwitch value={tab} onChange={v => setTab(v)} tabs={tabs} />
        </TabsWrapper>
        <TabContext value={tab}>
          <ServingContextProvider
            totalServings={totalServings}
            servings={servings}
          >
            <RecipeTabPanelsProxy collectionId={collectionId} id={id} />
          </ServingContextProvider>
        </TabContext>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
