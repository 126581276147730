import { CloseIcon } from "@assets/icons_new";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import {
  mapEditDiscoverFormMap,
  mapEditDiscoverRequestMap,
  useEditDiscoverForm,
} from "./useEditDiscoverForm";
import { useUpdateGlobalPatientSettingsMutation } from "@hooks/queries/clinic";
import { useAppTranslation } from "@hooks";
import { LabeledCheckbox } from "./LabeledCheckbox";
import { MobileAppDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useMemo } from "react";

interface EditDiscoverModalProps {
  open: boolean;
  onClose: () => void;
  mobileApp: MobileAppDto;
}
export const EditDiscoverModal = ({
  open,
  onClose,
  mobileApp,
}: EditDiscoverModalProps) => {
  const { mutate, isLoading } = useUpdateGlobalPatientSettingsMutation();
  const defaultValues = useMemo(
    () => mapEditDiscoverFormMap(mobileApp.discoverFeatures),
    [mobileApp.discoverFeatures],
  );
  const { t } = useAppTranslation();
  const { control, handleSubmit, reset } = useEditDiscoverForm(defaultValues);
  const handleCloseAndReset = () => {
    onClose();
    reset();
  };
  const onSubmit = () =>
    handleSubmit(
      values =>
        mutate(
          {
            mobileApp: {
              ...mobileApp,
              discoverFeatures: mapEditDiscoverRequestMap(values),
            },
          },
          { onSuccess: onClose },
        ),
      e => console.log(e),
    )();
  return (
    <Dialog open={open}>
      <DialogTitleStyled>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {t("cooperation_settings.modal.discover.discover")}
          <IconButtonStyled onClick={handleCloseAndReset} size="small">
            <CloseIcon fontSize="24px" />
          </IconButtonStyled>
        </Stack>
      </DialogTitleStyled>
      <DialogContent sx={{ width: "510px" }}>
        <Stack gap="16px">
          <Text>
            {t("cooperation_settings.modal.discover.select_sections")}
          </Text>

          <Stack gap="4px">
            <LabeledCheckbox
              control={control}
              name="inspire"
              label={t("cooperation_settings.modal.discover.get_inspired")}
            />
            <Text>
              {t(
                "cooperation_settings.modal.discover.get_inspired_description",
              )}
            </Text>
          </Stack>
          <Stack gap="4px">
            <LabeledCheckbox
              control={control}
              name="preference"
              label={t(
                "cooperation_settings.modal.discover.what_are_you_craving",
              )}
            />
            <Text>
              {t(
                "cooperation_settings.modal.discover.what_are_you_craving_description",
              )}
            </Text>
          </Stack>
          <Stack gap="4px">
            <LabeledCheckbox
              control={control}
              name="quiz"
              label={t("cooperation_settings.modal.discover.quiz")}
            />
            <Text>
              {t("cooperation_settings.modal.discover.quiz_description")}
            </Text>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAndReset} variant="outlined">
          {t("cooperation_settings.modal.discover.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onSubmit}
          loading={isLoading}
        >
          {t("cooperation_settings.modal.discover.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: unset;
  height: unset;
`;
const DialogTitleStyled = styled(DialogTitle)`
  font-size: 16px;
  padding: 16px;
`;

const Text = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #4d516e;
`;
