import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import type { FC } from "react";
import BookStamp from "@assets/img/BookStamp.png";
import {
  EducationalMaterialCardDiv,
  EducationalMaterialsCard,
  EducationalMaterialsTableCategoryBadge,
} from "../EducationalMaterialsView.styled";
import { EducationalMaterialsCardActions } from "./EducationalMaterialsCardActions";
import {
  ModalType,
  useEducationalModalContext,
} from "../_hooks/useEducationalMaterialsContext";

interface EducationalMaterialCardProps {
  id: number;
  image: string | null;
  title: string | null;
  description: string | null;
  icon: React.ReactNode;
  favorite: boolean;
  category?: string;
}

export const EducationalMaterialCard: FC<EducationalMaterialCardProps> = ({
  image,
  title,
  description,
  id,
  favorite,
  category,
}) => {
  const { openModal } = useEducationalModalContext();

  return (
    <EducationalMaterialsCard
      className="group cursor-pointer"
      onClick={() => {
        openModal(ModalType.PREVIEW, String(id));
      }}
    >
      <EducationalMaterialsCardActions id={id} favorite={favorite} />
      {image ? (
        <CardMedia
          component="img"
          sx={{
            height: "150px",
            objectFit: "cover",
            backgroundPosition: "center",
            borderRadius: "8px",
          }}
          image={image}
        />
      ) : (
        <EducationalMaterialCardDiv>
          <div className="bg-neutral-light-200/10 rounded-full w-40 h-40 flex items-center justify-center">
            <img src={BookStamp} alt="book stamp" />
          </div>
        </EducationalMaterialCardDiv>
      )}
      <CardContent className="flex flex-col gap-2 items-start">
        <EducationalMaterialsTableCategoryBadge>
          {category}
        </EducationalMaterialsTableCategoryBadge>
        <Typography className="!font-semibold !text-md">{title}</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className="line-clamp-2"
        >
          {description}
        </Typography>
      </CardContent>
    </EducationalMaterialsCard>
  );
};
