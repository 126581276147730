export interface TranslationVariables {
  lang?: string | null;
  langId?: string | null;
  locale?: string | null;

  title?: string | null;
  name?: string | null;
  value?: string | null;
  description?: string | null;
  comment?: string | null;
}

export const getTranslation = (
  translations: TranslationVariables[] | undefined,
  lang: string,
  keyName?: keyof TranslationVariables,
): string => {
  const translation = translations?.find(t => {
    if ("lang" in t) return t.lang === lang;
    if ("langId" in t) return t.langId === lang;
    if ("locale" in t) return t.locale === lang;
  });

  if (!translation) return "";

  if (keyName && keyName in translation) return translation[keyName] ?? "";

  if ("title" in translation) return translation.title ?? "";
  if ("name" in translation) return translation.name ?? "";
  if ("value" in translation) return translation.value ?? "";
  if ("description" in translation) return translation.description ?? "";
  if ("comment" in translation) return translation.comment ?? "";

  return "";
};
