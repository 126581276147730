import { useCallback } from "react";
import { Clock, Cutlery, PieChart } from "@assets/icons";
import { TagCategoryType } from "@utils/tagsNew";
import { SpecialTagIf } from "@components/TagWithIcon";
import { SvgIconProps } from "@components/SvgIcon";
import FileTextIcon from "@assets/icons_new/fileText.svg?react";
import { useTheme } from "@mui/material";
interface Tag {
  id: string;
  label: string;
  Icon: (props: SvgIconProps) => JSX.Element;
}

type TagCategoryDictionary = Map<number, { tags: { id: number }[] }>;
type TagDictionary = Map<number, string>;

export const useSpecialTags = (
  tagCategoryDictionary: TagCategoryDictionary,
  tagDictionary: TagDictionary,
  t: (key: string, options?: Record<string, any>) => string,
) => {
  type DefinedLabelSpecialTagIf = SpecialTagIf & { label: string };

  const { colors } = useTheme();
  return useCallback(
    (tags: number[], totalServings: number, servings: number): Tag[] => {
      const findTag = (categoryType: TagCategoryType) => {
        const tagList = tagCategoryDictionary.get(categoryType)?.tags;
        return tags?.find(tag => tagList?.some(t => t.id === tag));
      };

      const preparationTimeTag = findTag(TagCategoryType.PREPARATION_TIME);
      const difficultyOfPreparationTag = findTag(
        TagCategoryType.DIFFICULTY_OF_PREPARATION,
      );

      const preparationTimeName =
        preparationTimeTag && tagDictionary.get(preparationTimeTag);
      const difficultyOfPreparationName =
        difficultyOfPreparationTag &&
        tagDictionary.get(difficultyOfPreparationTag);

      const selectedServingsLabel =
        totalServings > 1 &&
        totalServings >= servings &&
        `${servings}/${totalServings} ${t("recipe.portions")}`;

      const servingsLabel =
        totalServings > 1 &&
        totalServings === servings &&
        !selectedServingsLabel &&
        `${t("recipe.recipe_complex", { count: totalServings })}`;

      const multipliedRecipe =
        totalServings === 1 && servings > totalServings && `x${servings}`;

      return [
        { id: "1", label: preparationTimeName, Icon: Clock },
        { id: "2", label: difficultyOfPreparationName, Icon: Cutlery },
        { id: "3", label: servingsLabel, Icon: PieChart },
        { id: "4", label: selectedServingsLabel, Icon: PieChart },
        {
          id: "5",
          label: multipliedRecipe,
          Icon: () => (
            <FileTextIcon fontSize={14} color={colors.neutral.dark[700]} />
          ),
        },
      ].filter((tag): tag is DefinedLabelSpecialTagIf => !!tag.label);
    },
    [tagCategoryDictionary, tagDictionary, t],
  );
};
