import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import ImportantNutrientsField from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/ImportantNutrientsField";
import ShowNutrientsField from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/ShowNutrientsField";
import {
  mapEditProfileForm,
  mapEditProfileRequest,
  useEditProfileForm,
} from "./useEditProfileForm";
import { FormProvider } from "react-hook-form";
import { NutrientsDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useMemo } from "react";
import { CloseIcon } from "@assets/icons_new";
import { useUpdateGlobalPatientSettingsMutation } from "@hooks/queries/clinic";
import { useAppTranslation } from "@hooks";
import { LoadingButton } from "@mui/lab";

interface EditProfileModalProps {
  open: boolean;
  onClose: () => void;
  nutrients: NutrientsDto;
}

export const EditProfileModal = ({
  open,
  onClose,
  nutrients,
}: EditProfileModalProps) => {
  const { t } = useAppTranslation();
  const defaultValues = useMemo(
    () => mapEditProfileForm(nutrients),
    [nutrients],
  );
  const form = useEditProfileForm(defaultValues);
  const { mutate, isLoading } = useUpdateGlobalPatientSettingsMutation();

  const handleCloseAndReset = () => {
    onClose();
    form.reset();
  };

  const handleSubmit = form.handleSubmit(
    values =>
      mutate(
        { nutrients: mapEditProfileRequest(values) },
        { onSuccess: onClose },
      ),
    e => console.log(e),
  );

  return (
    <Dialog open={open}>
      <DialogTitleStyled>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {t("cooperation_settings.modal.profile.dietary_profile")}
          <IconButtonStyled onClick={handleCloseAndReset} size="small">
            <CloseIcon fontSize="24px" />
          </IconButtonStyled>
        </Stack>
      </DialogTitleStyled>
      <DialogContent sx={{ width: "510px" }}>
        <FormProvider {...form}>
          <Stack direction="column" gap="16px">
            <ShowNutrientsField />
            <ImportantNutrientsField />
          </Stack>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseAndReset} variant="outlined">
          {t("cooperation_settings.modal.profile.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
        >
          {t("cooperation_settings.modal.profile.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: unset;
  height: unset;
`;
const DialogTitleStyled = styled(DialogTitle)`
  font-size: 16px;
  padding: 16px;
`;
