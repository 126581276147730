import { memo } from "react";
import { useFormContext, useFormState } from "react-hook-form";

import { Alert, InputAdornment } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { Labeled } from "@components/Labeled";
import { decimalInput0 } from "@utils/inputs";
import { FormTextField } from "@components/FormTextField";
import { ThemeProviderWrapperNew } from "themeNew";
import { DurationAlert } from "./DurationAlert";

interface DurationSelectProps {
  edit?: boolean;
  onSubmit?: () => void;
}

export const DurationSelect = memo(
  ({ edit = false, onSubmit }: DurationSelectProps) => {
    const { t } = useAppTranslation();
    const { isDirty } = useFormState({ name: "duration" });
    const { control } = useFormContext();

    return (
      <Labeled label={t("addNutritionalProgram.step1.specifyDurationTime")}>
        <div className="flex gap-6 items-baseline">
          <FormTextField
            control={control}
            name="duration"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t("common.days")}
                </InputAdornment>
              ),
              inputComponent: decimalInput0,
            }}
          />
        </div>
        <DurationAlert show={edit && isDirty} onSubmit={onSubmit} />
      </Labeled>
    );
  },
);
