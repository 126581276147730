import { StatusTag } from "@views/dietician/EducationalMaterials/EducationalMaterialsView.types";
import { QueryOptionsTyped } from "./types";
import { useMemo, useState, useEffect } from "react";
import { SectionsFiltersInput } from "@typeDefinitions/types";
import { useQuery } from "@tanstack/react-query";
import { ApiEndpoints } from "@typeDefinitions";
import { useQueryParams } from "./useQueryParams";
import { useDebounce } from "react-use";
import { ITEMS_ON_PAGE } from "@components/PaginationNew";
import { searchSectionsNew } from "@client/searchSectionsNew";

interface SearchSectionInputs extends SectionsFiltersInput {
  page: number;
  perPage: number;
}

const useComposeSectionQueryParams = () => {
  const { get } = useQueryParams("author", "status", "search", "categoryId");

  const { author, status, search, categoryId } = get<{
    author: string | undefined;
    status: string | undefined;
    search: string | undefined;
    categoryId: string[] | undefined;
  }>();

  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    1000,
    [search],
  );

  return {
    query: debouncedSearch,
    authorId: author,
    categoryId,
    ...(status === StatusTag.FAVORITE && { favorite: 1 }),
    ...(status === StatusTag.LATEST && { latest: 1 }),
  };
};

export function useSearchSectionsQueryNew(
  options?: QueryOptionsTyped<{
    enabled: boolean;
  }>,
) {
  const [committedInputs, setCommittedInputs] = useState<SearchSectionInputs>({
    page: 1,
    perPage: ITEMS_ON_PAGE.TEN,
  });

  const composedParams = useComposeSectionQueryParams();

  useEffect(() => {
    setCommittedInputs(prev => ({
      ...prev,
      page: 1,
    }));
  }, [
    composedParams.query,
    composedParams.authorId,
    composedParams.categoryId,
    composedParams.favorite,
    composedParams.latest,
  ]);

  useEffect(() => {
    setCommittedInputs(prev => {
      if (prev.page !== 1) {
        return {
          ...prev,
          page: 1,
        };
      }
      return prev;
    });
  }, [committedInputs.perPage]);

  const finalParams = useMemo(
    () => ({
      ...composedParams,
      ...committedInputs,
    }),
    [composedParams, committedInputs],
  );

  const { data, ...rest } = useQuery({
    queryKey: [ApiEndpoints.SearchSectionsNew, finalParams],
    queryFn: () => searchSectionsNew(finalParams),
    enabled: options?.enabled,
    staleTime: 1000 * 30,
  });

  return {
    sections: data?.data,
    links: data?.meta?.links,
    meta: data?.meta,
    submit: setCommittedInputs,
    ...rest,
  };
}
