import {
  fetchGlobalPatientSettings,
  FetchGlobalPatientSettingsResponse,
} from "@client/clinic/fetchGlobalPatientSettings";
import { QueryOptionsTyped } from "../types";
import { useQuery } from "@tanstack/react-query";
import { clinicKeys } from "./clinicKeys";

export const useFetchGlobalPatientSettingsQuery = (
  options?: QueryOptionsTyped<FetchGlobalPatientSettingsResponse>,
) => {
  return useQuery({
    queryKey: clinicKeys.globalPatientSettings(),
    queryFn: fetchGlobalPatientSettings,
    ...options,
  });
};
