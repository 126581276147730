import { MutableRefObject, useCallback } from "react";

const SCROLL_NUMBER_OF_PIXELS = 200;

export const useHorizontalScroll = (
  ref: MutableRefObject<HTMLDivElement | null>,
) => {
  const scrollLeft = useCallback(() => {
    if (!ref.current) return;
    ref.current.scrollBy({
      left: -SCROLL_NUMBER_OF_PIXELS,
      behavior: "smooth",
    });
  }, [ref]);

  const scrollRight = useCallback(() => {
    if (!ref.current) return;
    ref.current.scrollBy({ left: SCROLL_NUMBER_OF_PIXELS, behavior: "smooth" });
  }, [ref]);

  return { scrollLeft, scrollRight };
};
