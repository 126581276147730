import { LangDto } from "@client";
import { RecipeTabPanels } from "@components/PreviewDrawer/Recipe";
import { ProductIf } from "@components/PreviewDrawer/Recipe/NutrientsTab/ProductsTable";
import { Lang, useAppTranslation } from "@hooks";
import { useFetchCollectionSearchPreviewRecipeQuery } from "@hooks/queries/collections";
import { CircularProgress, Stack } from "@mui/material";
import { getTranslation } from "@utils/translations";
import { useMemo } from "react";

interface RecipeTabPanelsProxyProps {
  id: number;
  collectionId: number;
}
export const RecipeTabPanelsProxy = ({
  id,
  collectionId,
}: RecipeTabPanelsProxyProps) => {
  const { currentLanguage } = useAppTranslation();
  const { data: recipe, isLoading } =
    useFetchCollectionSearchPreviewRecipeQuery(
      collectionId.toString(),
      id.toString(),
    );

  const mappedProducts = useMemo<ProductIf[]>(
    () =>
      recipe?.recipe.products.map(
        ({ grams, id: productId, measure, product }) => ({
          id: productId,
          grams: grams ?? 0,
          measureId: measure.id,
          measures: product.measures,
          name: getTranslation(product.translations, currentLanguage),
          nutrients: product.nutrients,
          foodId: product.id,
        }),
      ) ?? [],
    [recipe, currentLanguage],
  );

  if (isLoading)
    return (
      <Stack width="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  if (!recipe) return null;

  const { nutrients, description, tags, comment, translations } = recipe.recipe;
  console.log(translations);

  return (
    <RecipeTabPanels
      description={description ?? ""}
      descriptionEn={getTranslation(translations, LangDto.EN, "description")}
      tags={tags.map(t => t.id)}
      comment={comment}
      commentEn={getTranslation(translations, LangDto.EN, "comment")}
      products={mappedProducts}
      nutrients={nutrients}
    />
  );
};
