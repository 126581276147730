import { useCallback, useRef } from "react";
import { getPdfSectionClientSide } from "@Pdf/utils/pdfUtils";
import { NutrientDetails, useAppTranslation } from "@hooks";
import { usePdfWorker } from "@Pdf/hooks/usePdfWorker";
import { getPdfSectionAPI } from "@Pdf/api/pdfAPI";
import {
  LanguagesSlugs,
  NutrientCategory,
  ProgramPdfPatient,
} from "@typeDefinitions";
import { ClinicDto, DietitianDto } from "@client";
import i18n from "@services/i18n";
import { useQueryClient } from "@tanstack/react-query";
import {
  fetchNutrientCategoriesQueryKey,
  useFetchNutrientCategoriesQuery,
} from "@hooks/queries";

const usePdfSectionExport = () => {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<NutrientCategory[]>([
    fetchNutrientCategoriesQueryKey,
  ]);
  const { nutrientCategories } = useFetchNutrientCategoriesQuery({
    enabled: !cacheData,
  });
  const categories = cacheData ?? nutrientCategories;
  const { t } = useAppTranslation();

  const abortController = useRef(new AbortController());

  const { generateSectionPdf, terminateWorker } = usePdfWorker();

  const onCancelExport = () => {
    abortController.current.abort();
    terminateWorker();
  };

  const onExport = useCallback(
    async (
      programId: number,
      sectionId: number,
      patient: ProgramPdfPatient,
      clinic: ClinicDto,
      dietitian: Omit<DietitianDto, "id">,
      {
        color,
        coverPageType,
        title,
        options,
        clinicOptions,
        kcal,
        macros,
        startDate,
        endDate,
        micros,
      }: {
        color: string;
        coverPageType: string;
        title: string;
        startDate: string | null;
        endDate: string | null;
        options: Array<string>;
        clinicOptions: Array<string>;
        micros: Array<number>;
        macros: boolean;
        kcal: boolean;
      },
      download = false,
      lngId = i18n.language,
    ) => {
      const response = await getPdfSectionAPI(programId, sectionId);

      const isPolishChosen = lngId === LanguagesSlugs.PL;
      const nutrientDict: Record<number, NutrientDetails> = {};

      categories
        ?.flatMap(category => category.nutrients)
        .forEach(nutrient => {
          nutrientDict[nutrient.id] = {
            name: isPolishChosen
              ? nutrient.descriptionPl
              : nutrient.descriptionEn,
            unit: nutrient.units,
            short: isPolishChosen
              ? nutrient.shortNamePl ?? nutrient.descriptionPl.charAt(0)
              : nutrient.shortNameEn ?? nutrient.descriptionEn.charAt(0),
            categoryId: nutrient.nutrientCategoryId,
          };
        });

      const tOptions = { lng: lngId };
      const translations = {
        servings: t("pdf.servings", tOptions),
        serving: t("pdf.serving", tOptions),
        proposition: t("pdf.proposition", tOptions),
        day: t("pdf.day", tOptions),
        wayToPrepare: t("pdf.way_to_prepare", tOptions),
        product: t("pdf.product", tOptions),
        mass: t("pdf.mass", tOptions),
        measure: t("pdf.measure", tOptions),
        shopping: t("pdf.shopping", tOptions),
        macronutrientBreakdown: t("pdf.macronutrient_breakdown", tOptions),
        page: t("pdf.page", tOptions),
        leadingDietitian: t("pdf.leading_dietitian", tOptions),
        localisation: t("pdf.localisation", tOptions),
        mealComment: t("pdf.meal_comment", tOptions),
        recipeNotes: t("pdf.recipe_notes", tOptions),
        ingredientsList: t("pdf.ingredients_list", tOptions),
        avgValues: t("pdf.avg_values", tOptions),
        days: t("common.days", tOptions),
        tableOfContent: t("pdf.table_of_content", tOptions),
        emptyProgram: t("pdf.emptyProgram", tOptions),
        // Weekdays! - used in food menu
        1: t("pdf.week_days.monday_long", tOptions),
        2: t("pdf.week_days.tuesday_long", tOptions),
        3: t("pdf.week_days.wednesday_long", tOptions),
        4: t("pdf.week_days.thursday_long", tOptions),
        5: t("pdf.week_days.friday_long", tOptions),
        6: t("pdf.week_days.saturday_long", tOptions),
        7: t("pdf.week_days.sunday_long", tOptions),
      };

      const weekdays = [
        t("pdf.week_days.monday_short", tOptions),
        t("pdf.week_days.tuesday_short", tOptions),
        t("pdf.week_days.wednesday_short", tOptions),
        t("pdf.week_days.thursday_short", tOptions),
        t("pdf.week_days.friday_short", tOptions),
        t("pdf.week_days.saturday_short", tOptions),
        t("pdf.week_days.sunday_short", tOptions),
      ];

      const { imageRefs, htmlContentCache } = await getPdfSectionClientSide(
        response,
      );

      const content = await generateSectionPdf({
        content: response,
        patient,
        clinic,
        dietitian,
        htmlContentCache,
        imageRefs,
        nutrientDict,
        primaryColor: color,
        coverPageType,
        weekdays,
        options,
        clinicOptions,
        translations,
        kcal,
        macros,
        micros,
      });

      if (download) {
        const file = new Blob([content], { type: "application/octet-stream" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${patient.firstName} ${
          patient.lastName
        }-${title.replaceAll(" ", "-")}-${startDate}-${endDate}.pdf`;
        link.click();
        link.remove();
      }
    },
    [t, categories],
  );

  return {
    onCancelExport,
    onExport,
  };
};

export default usePdfSectionExport;
