import { LangDto, ClientSexDto } from "@client";
import { StyledTextArea } from "../../EditClientInfoModal.styled";
import { SelectOption } from "@components/FormAutocomplete";
import { FormTextField } from "@components/FormTextField";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";
import { Control } from "react-hook-form";
import { MenuItem, Typography, useTheme } from "@mui/material";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";
import { FormSelect } from "@components/FormSelectNew";
import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";

interface ClientInfoViewProps {
  control: Control<any>;
  lang?: LangDto;
  gender?: ClientSexDto | null;
}

export const ClientInfoView = ({
  control,
  lang,
  gender,
}: ClientInfoViewProps) => {
  const { t } = useAppTranslation();
  const { colors } = useTheme();

  enum genderOptions {
    WOMAN = "w",
    MAN = "m",
    NOT_EXIST = "",
  }

  const genderList = useMemo(
    () => [
      { id: genderOptions.WOMAN, label: t("client_profile.edit.info.woman") },
      { id: genderOptions.MAN, label: t("client_profile.edit.info.man") },
      {
        id: genderOptions.NOT_EXIST,
        label: t("client_profile.edit.info.not_specified"),
      },
    ],
    [t],
  );
  const langList = useMemo<SelectOption[]>(
    () => [
      { id: LangDto.PL, label: t("common.pl") },
      { id: LangDto.EN, label: t("common.en") },
    ],
    [t],
  );

  return (
    <div className="grid gap-6">
      <ClientProfileCardItem
        title={t("common.first_name")}
        extraEl={
          <FormTextField
            control={control}
            name="firstName"
            size="small"
            variant="outlined"
          />
        }
      />
      <ClientProfileCardItem
        title={t("common.last_name")}
        extraEl={
          <FormTextField
            control={control}
            name="lastName"
            size="small"
            variant="outlined"
          />
        }
      />
      <ClientProfileCardItem
        title={t("client_profile.edit.info.date_of_birth")}
        extraEl={
          <MuiTimeDatePicker dateVersion name="birthDate" control={control} />
        }
      />
      <ClientProfileCardItem
        title={t("client_profile.info.gender")}
        extraEl={
          <FormSelect
            displayEmpty
            defaultValue={gender}
            control={control}
            name="sex"
            id="sex"
            size="small"
          >
            {genderList.map(param => (
              <MenuItem value={param.id} key={param.label}>
                {param.label}
              </MenuItem>
            ))}
          </FormSelect>
        }
      />
      <ClientProfileCardItem
        title={t("common.language")}
        extraEl={
          <FormSelect
            defaultValue={lang}
            control={control}
            name="lang"
            id="lang"
            size="small"
          >
            {langList.map(param => (
              <MenuItem value={param.id} key={param.label}>
                {param.label}
              </MenuItem>
            ))}
          </FormSelect>
        }
      />
      <div className="grid gap-3">
        <Typography variant="body2" color={colors.neutral.medium[800]}>
          {t("client_profile.edit.info.notes_and_comments")}
        </Typography>
        <StyledTextArea
          control={control}
          name="description"
          multiline
          rows={5}
          size="small"
        />
      </div>
    </div>
  );
};
