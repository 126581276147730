import { OuterLabel } from "@components/OuterLabel";
import { Chip, Stack } from "@mui/material";
import { Card, CardHeader } from "./components";
import { useAppTranslation, useModalState } from "@hooks";
import { FavoriteNutrientsModal } from "../modals";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import { useMemo } from "react";
import { NutrientResponse } from "@typeDefinitions/responses/dictionaries";

export const FavoriteNutrients = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { data } = useCategoriesNutrientsFetchQuery();
  const { nutrients: visibleNutrients } = useVisibleNutrients();

  const favoriteNutrients = useMemo(() => {
    if (!visibleNutrients || !data) {
      return [];
    }

    const nutrients = data.map(category => category.nutrients).flat();

    return visibleNutrients
      .map(id => nutrients.find(n => n.id === parseInt(id)))
      .filter(i => i !== undefined) as NutrientResponse[];
  }, [visibleNutrients, data]);

  return (
    <Card>
      <CardHeader
        title={t("settings.favorite.favorite_nutrition")}
        tooltipText={t("settings.favorite.your_nutrition")}
        onEdit={onOpen}
      />
      <OuterLabel label={t("settings.favorite.favorite_nutrition")}>
        <Stack direction="row" flexWrap="wrap" gap="8px">
          {favoriteNutrients.map(({ id, descriptionPl, descriptionEn }) => (
            <Chip
              key={id}
              color="primary"
              label={isPolishChosen ? descriptionPl : descriptionEn}
            />
          ))}
        </Stack>
      </OuterLabel>

      <FavoriteNutrientsModal open={open} onClose={onClose} />
    </Card>
  );
};
