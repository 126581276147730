import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const AddDocument = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M13.3346 5.3335H8.0013C7.64768 5.3335 7.30854 5.47397 7.05849 5.72402C6.80844 5.97407 6.66797 6.31321 6.66797 6.66683V17.3335C6.66797 17.6871 6.80844 18.0263 7.05849 18.2763C7.30854 18.5264 7.64768 18.6668 8.0013 18.6668H16.0013C16.3549 18.6668 16.6941 18.5264 16.9441 18.2763C17.1942 18.0263 17.3346 17.6871 17.3346 17.3335V9.3335M13.3346 5.3335L17.3346 9.3335M13.3346 5.3335L13.3346 9.3335H17.3346M12.0013 16.0002V12.0002M10.0013 14.0002H14.0013"
      stroke="#727272"
      fill={props.fill ?? "currentColor"}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
