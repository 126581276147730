import { Box, CircularProgress, Stack } from "@mui/material";
import {
  ActualValue,
  TargetValue,
  Title,
  Percent,
  PercentInCircle,
  SummaryCardWrapper,
} from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCard.styled";
import { Link } from "react-router-dom";
import SummaryCardTitle from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCardTitle";

export type SummaryCardProps = {
  actualValue: number;
  targetValue: number | null;
  title: string;
  icon: JSX.Element;
  unit?: string;
  color?: string;
  round?: (value: number) => string;
  boxId?: string;
};

const SummaryCard = ({
  icon,
  title,
  actualValue,
  targetValue,
  unit,
  color,
  round,
  boxId,
}: SummaryCardProps) => {
  const getTarget = () => {
    if (targetValue === 0) {
      return Math.max(actualValue, 1);
    }

    if (!targetValue) {
      return Math.max(actualValue, 1);
    }

    return targetValue;
  };

  const percent = Math.round((actualValue / getTarget()) * 100);

  const handleOnClick = () => {
    if (boxId) {
      document
        .getElementById(boxId)
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <SummaryCardWrapper onClick={handleOnClick}>
      <Stack direction="row" justifyContent="space-between" spacing="16px">
        <Stack direction="column" spacing="8px">
          <SummaryCardTitle title={title} icon={icon} />
          <Box>
            <ActualValue>
              {round ? round(actualValue) : actualValue}
            </ActualValue>
            {(targetValue ?? 0) > 0 && (
              <TargetValue>
                / {round ? round(getTarget()) : getTarget()} {unit}
              </TargetValue>
            )}
          </Box>
        </Stack>
        <Box position="relative" display="inline-flex" alignItems="center">
          <CircularProgress
            variant="determinate"
            size={58}
            thickness={4}
            value={100}
            sx={{
              color: "#F2F2FF",
            }}
          />
          <CircularProgress
            variant="determinate"
            sx={{
              color: color ? color : "#7448D0",
              position: "absolute",
              left: 0,
            }}
            size={58}
            value={Math.min(percent, 100)}
            thickness={4}
          />
          <PercentInCircle>
            <Percent>{percent}%</Percent>
          </PercentInCircle>
        </Box>
      </Stack>
    </SummaryCardWrapper>
  );
};

export default SummaryCard;
