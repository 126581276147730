import {
  CheckedMonitoring,
  UncheckedMonitoring,
} from "@assets/icons/Monitoring";
import { IconButton, Tooltip } from "@mui/material";
import { useChangeSharedProgramMutation } from "@hooks/queries/client/program/useChangeSharedProgramMutation";
import { useAppTranslation } from "@hooks";
import { MouseEventHandler } from "react";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";
import { useFetchClientQuery } from "@hooks/queries";

type SharedColumnProps = {
  program: {
    id: number;
    shared: boolean;
  };
  patientId: number;
};
const SharedColumn = ({ program, patientId }: SharedColumnProps) => {
  const { t } = useAppTranslation();
  const { mutate, isLoading } = useChangeSharedProgramMutation({});
  const { client } = useFetchClientQuery(patientId);
  const isClientActive = !!client?.active;

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> | undefined = e => {
    if (e) {
      e.stopPropagation();
    }
    handleMutate();
  };

  const handleMutate = () => {
    mutate({
      programId: program.id,
      patientId: patientId,
      payload: {
        shared: !program.shared,
      },
    });
  };

  return (
    <Tooltip
      arrow
      title={t("common.access_module_tooltip")}
      disableHoverListener={isClientActive}
    >
      <div>
        {program.shared ? (
          <ConfirmationWrapper
            disabled={!isClientActive}
            onConfirm={handleMutate}
            confirmationText={t(
              "patient.programs_grid.row.shared.confirmation_off_question",
            )}
            submitText={t(
              "patient.programs_grid.row.shared.confirmation_off_answer",
            )}
          >
            <IconButton href="" disabled={!isClientActive}>
              <CheckedMonitoring fill="#3AB795" size="" />
            </IconButton>
          </ConfirmationWrapper>
        ) : (
          <ConfirmationWrapper
            disabled={!isClientActive}
            onConfirm={handleMutate}
            confirmationText={t(
              "patient.programs_grid.row.shared.confirmation_on_question",
            )}
            submitText={t(
              "patient.programs_grid.row.shared.confirmation_on_answer",
            )}
          >
            <Tooltip
              title={t("patient.programs_grid.row.shared.tooltip")}
              arrow
              placement="top"
            >
              <IconButton href="" disabled={!isClientActive}>
                <UncheckedMonitoring fill="#FFFFFF" size="" />
              </IconButton>
            </Tooltip>
          </ConfirmationWrapper>
        )}
      </div>
    </Tooltip>
  );
};

export default SharedColumn;
