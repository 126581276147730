import { ReactNode } from "react";
import { OuterLabelWrapper, StyledLabel } from "./OuterLabel.styled";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import InfoIcon from "@assets/icons_new/iInCircle.svg?react";
interface OuterLabelProps {
  label?: string | false;
  children: ReactNode;
  className?: string;
  tooltip?: string;
}

export const OuterLabel = ({
  label,
  children,
  className,
  tooltip,
}: OuterLabelProps) => {
  const { colors } = useTheme();
  return (
    <OuterLabelWrapper className={className}>
      {label && (
        <div className="flex items-center gap-[4px]">
          <StyledLabel>{label}</StyledLabel>
          {tooltip && (
            <Tooltip title={tooltip} arrow>
              <div>
                <InfoIcon fontSize={14} color="#727272" />
              </div>
            </Tooltip>
          )}
        </div>
      )}
      {children}
    </OuterLabelWrapper>
  );
};
