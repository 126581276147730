import { useClientParams, useResizeObserver } from "@hooks";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { useFetchClientQuery } from "@hooks/queries";

import { InfoSection } from "./components/InfoSection";
import { InfoSection as InfoSectionLegacy } from "./components/InfoSectionLegacy";
import {
  ClientProfileWrapper,
  PaymentsOfficeWrapper,
} from "./ClientProfile.styled";
import { MobileAppSection } from "./components/MobileAppSection";
import { FetchClientResponse } from "@client";
import { OfficeSection } from "./components/OfficeSection";
import { HistorySection } from "./components/HistorySection";
import { useRef } from "react";
import { SHOW_OLD_PROFILE_FLAG } from "@layouts/Dashboard/ClientLayout";
import { PaymentsSection } from "./components/PaymentsSection";

export const noData = "-";

export interface MobileAppSectionProps {
  account: FetchClientResponse;
}

export const ClientProfile = () => {
  const id = useClientParams();
  const { client, isLoading, isError } = useFetchClientQuery(id);
  const infoSectionRef = useRef<HTMLDivElement>(null);
  const clientHeight = useResizeObserver(infoSectionRef);

  if (isLoading) return <Spinner />;

  if (isError || !client) return <ApiError />;

  return (
    <ClientProfileWrapper>
      {SHOW_OLD_PROFILE_FLAG ? (
        <InfoSectionLegacy
          infoSectionRef={infoSectionRef}
          account={client}
          id={id}
        />
      ) : (
        <InfoSection infoSectionRef={infoSectionRef} account={client} id={id} />
      )}
      <PaymentsOfficeWrapper>
        {SHOW_OLD_PROFILE_FLAG ? (
          <PaymentsSection account={client} />
        ) : (
          <MobileAppSection account={client} />
        )}
        <OfficeSection account={client} />
      </PaymentsOfficeWrapper>
      <HistorySection id={id} maxHeight={clientHeight} />
    </ClientProfileWrapper>
  );
};
