import { useAppTranslation } from "@hooks";
import { Nutrient } from "@typeDefinitions";

import { TagsTab } from "../common/TagsTab";
import { NutrientsTab, NutrientsTabProps } from "./NutrientsTab";
import { ProductIf } from "./NutrientsTab/ProductsTable";
import { PreparationTab } from "./PreparationTab";
import { RecipeTabs } from "./RecipePreview";
import { TabPanelStyled } from "./RecipeReview.styled";
import { NutrientsByProductProps } from "./NutrientsTab/NutrientsByProduct";

interface RecipeTabPanelsProps {
  nutrients: Nutrient[];
  description: string;
  descriptionEn: string;
  products: ProductIf[];
  tags: number[];
  NutrientsTab?: (props: NutrientsTabProps) => JSX.Element;
  padding?: string;
  NutrientsByProductComponent?: ({
    products,
  }: NutrientsByProductProps) => JSX.Element;
  comment?: string | null;
  commentEn?: string | null;
}
export const RecipeTabPanels = ({
  nutrients,
  description,
  descriptionEn,
  products,
  tags,
  NutrientsTab: NutrientsTabExternal,
  padding,
  NutrientsByProductComponent,
  comment,
  commentEn,
}: RecipeTabPanelsProps) => {
  const { isPolishChosen } = useAppTranslation();

  const NutrientsTabComponent = NutrientsTabExternal ?? NutrientsTab;

  return (
    <>
      <TabPanelStyled value={RecipeTabs.NUTRITIONAL_VALUES} padding={padding}>
        <NutrientsTabComponent
          nutrients={nutrients}
          products={products}
          NutrientsByProductComponent={NutrientsByProductComponent}
        />
      </TabPanelStyled>
      <TabPanelStyled value={RecipeTabs.PREPARATION} padding={padding}>
        <PreparationTab
          products={products}
          preparation={isPolishChosen ? description : descriptionEn}
          comment={isPolishChosen ? comment : commentEn}
        />
      </TabPanelStyled>
      <TabPanelStyled value={RecipeTabs.TAGS} padding={padding}>
        <TagsTab tags={tags} />
      </TabPanelStyled>
    </>
  );
};
