import RocketIcon from "@assets/icons_new/gradientRocket.svg?react";
import { Button, styled, useMediaQuery, useTheme } from "@mui/material";
import { getHexColorOpacity } from "@utils";
import { StudentModal } from "@views/dietician/Home/components/StudentModal/StudentModal";
import { useState } from "react";

export const StudentBanner = () => {
  const { breakpoints } = useTheme();
  const upMediumMax = useMediaQuery(`${breakpoints.up("mediumMax")}`);
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);

  if (!upMediumMax)
    return (
      <>
        <IconWrapper onClick={handleClick}>
          <RocketIcon fontSize={16} />
        </IconWrapper>
        <StudentModal open={open} onClose={() => setOpen(false)} />
      </>
    );
  return (
    <>
      <Container>
        <Badge src="/static/studentModal/50percentBadge.svg" />
        <ButtonStyled onClick={handleClick}>Kup teraz</ButtonStyled>
      </Container>
      <StudentModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};
const Badge = styled("img")`
  width: 77px;
  height: 26px;
`;

const IconWrapper = styled("div")`
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.primary.light2};
  border: 1px solid
    ${({ theme }) => getHexColorOpacity(theme.palette.primary.main, 30)};
  transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.palette.primary.medium};
  }
  &:active {
    background: ${({ theme }) =>
      getHexColorOpacity(theme.palette.primary.main, 15)};
  }
`;

const Container = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.palette.primary.medium};
  padding: 3px 8px;
  border-radius: 8px;
  white-space: nowrap;
`;

const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const ButtonStyled = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: unset;
`;
