import styled from "@emotion/styled";

export const FileUploadWrapper = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.neutral.light[200]};
  border-radius: 16px;
  padding: 24px 0;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.neutral.dark[700]};
  }
`;
