import { useFormContext, useWatch } from "react-hook-form";

import { Typography, useTheme, Button } from "@mui/material";
import { useFetchSectionCategoriesQuery } from "@hooks/queries/useFetchSectionCategoriesQuery";
import { useAppTranslation, useUserRoles } from "@hooks";
import { Labeled } from "@components/Labeled";
import { FormInput } from "@components/FormInput";
import { FormTinyMceEditor } from "@components/FormTinyMceEditor";
import { FormCheckbox } from "@components/FormCheckbox";
import { Reviewed } from "@assets/icons";

import { StyledFormAutocomplete } from "./SectionForm.styled";
import { CheckedIcon } from "@assets/icons";
import FileUpload from "@components/FileUpload/FileUpload";
import { EducationalMaterialsLanguageSwitch } from "@views/dietician/EducationalMaterials/_components/EducationalMaterialsLanguageSwitch";
import { EducationalMaterialsDocumentFormWrapper } from "@views/dietician/EducationalMaterials/EducationalMaterialsView.styled";
import { Language } from "@views/dietician/EducationalMaterials/EducationalMaterialsView.types";
import { useUploadEducationalMaterialMutation } from "@hooks/queries/useUploadEducationalMaterialMutation";
import { toast } from "react-toastify";
import { FileUploadError } from "@components/FileUpload/FileUpload";
import { FormTextarea } from "@components/FormTextarea";

interface ISectionFormProps {
  withCategory?: boolean;
  programId?: number;
}

export const SectionForm = ({ withCategory, programId }: ISectionFormProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { isAdminClinicMember } = useUserRoles();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { palette } = useTheme();

  const { sectionCategories } = useFetchSectionCategoriesQuery();

  const maxCharacterCount = 160;

  const descriptionPl = useWatch({
    control,
    name: "description_pl",
  });

  const descriptionEn = useWatch({
    control,
    name: "description_en",
  });

  const categoriesMapped = sectionCategories?.map(
    ({ description, descriptionEn, id }) => ({
      id: id.toString(),
      label: isPolishChosen ? description : descriptionEn,
    }),
  );

  const attachedImageUrl = useWatch({
    control,
    name: "imageUrl",
  });

  const { mutateAsync: uploadEducationalMaterialMutation } =
    useUploadEducationalMaterialMutation();

  const language = useWatch({
    control,
    name: "language",
  });

  const handleFileDrop = async (files: File[]) => {
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];

    const formData = new FormData();
    formData.append("image", file, file.name);

    try {
      const { data } = await uploadEducationalMaterialMutation(formData);

      setValue("imageId", data.data.id);
      setValue("imageUrl", data.data.url);

      toast.success(t("educational_materials.successAddMedia"));
    } catch (error) {
      toast.error(t("educational_materials.errorAddMedia"));
    }
  };

  const handleFileRejected = (reason: string) => {
    if (reason === FileUploadError.FILE_TOO_LARGE) {
      toast.error(t("educational_materials.fileTooLarge"));
    } else if (reason === FileUploadError.FILE_INVALID_TYPE) {
      toast.error(t("educational_materials.wrongMime"));
    } else {
      toast.error(t("educational_materials.errorAddMedia"));
    }
  };

  const handleRemoveImage = () => {
    setValue("imageId", null);
    setValue("imageUrl", null);
    toast.success(t("educational_materials.imageRemoved"));
  };

  return (
    <EducationalMaterialsDocumentFormWrapper>
      <div className="flex flex-col gap-4 bg-white py-5 px-4 rounded-lg w-full">
        <Typography fontSize="16px" fontWeight="600">
          {t("educational_materials.documentDetails")}
        </Typography>
        <EducationalMaterialsLanguageSwitch control={control} />
        {!programId && withCategory && (
          <Labeled label={t("sections.category")}>
            <StyledFormAutocomplete
              control={control}
              name="programSectionCategoryId"
              id="programSectionCategoryId"
              size="small"
              popupIcon={<CheckedIcon fill={palette.primary.dark} />}
              options={categoriesMapped ?? []}
            />
          </Labeled>
        )}
        <Labeled label={t("educational_materials.sectionName")}>
          <FormInput
            fullWidth
            name={language === Language.PL ? "title" : "titleEn"}
            key={language === Language.PL ? "title-input" : "titleEn-input"}
            control={control}
            error={
              (errors.title || errors.titleEn) &&
              t("educational_materials.nameIsRequired")
            }
            variant="outlined"
          />
        </Labeled>
        {!programId && (
          <div className="flex flex-col gap-1">
            <Labeled label={t("educational_materials.shortenDescription")}>
              <FormTextarea
                fullWidth
                name={
                  language === Language.PL ? "description_pl" : "description_en"
                }
                key={
                  language === Language.PL ? "desc-pl-input" : "desc-en-input"
                }
                control={control}
                placeholder={t("common.content")}
                maxLength={160}
                minRows={4}
                maxRows={10}
              />
            </Labeled>
            <p className="text-xs self-end text-gray-500">
              {language === Language.PL
                ? `${descriptionPl?.length ?? 0} / ${maxCharacterCount}`
                : `${descriptionEn?.length ?? 0} / ${maxCharacterCount}`}
            </p>
          </div>
        )}

        {!programId &&
          (attachedImageUrl ? (
            <Labeled label={t("educational_materials.materialCover")}>
              <div className="flex flex-col gap-3">
                <div className="max-w-[200px] flex items-center gap-x-2 rounded-xl overflow-hidden">
                  <img src={attachedImageUrl} alt="attached" />
                </div>
                <div className="flex gap-3 mt-2">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      const fileUploadInput = document.getElementById(
                        "change-image-upload",
                      );
                      if (fileUploadInput) {
                        fileUploadInput.click();
                      }
                    }}
                  >
                    {t("common.change")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={handleRemoveImage}
                  >
                    {t("common.remove")}
                  </Button>
                </div>

                <div className="hidden">
                  <FileUpload
                    id="change-image-upload"
                    onFileDrop={handleFileDrop}
                    onFileRejected={handleFileRejected}
                    acceptedFormats={["image/jpeg", "image/jpg", "image/png"]}
                    maxSize={1000000} // 1MB in bytes
                    className="hidden"
                  />
                </div>
              </div>
            </Labeled>
          ) : (
            <FileUpload
              onFileDrop={handleFileDrop}
              onFileRejected={handleFileRejected}
              acceptedFormats={["image/jpeg", "image/jpg", "image/png"]}
              maxSize={1000000} // 1MB in bytes
            />
          ))}
      </div>

      <div className="flex flex-col gap-3 bg-white py-5 px-4 rounded-lg">
        <Typography fontSize="16px" fontWeight="600">
          {t("educational_materials.text")}
        </Typography>
        <Labeled label={t("educational_materials.content")}>
          <div hidden={language === Language.EN}>
            <FormTinyMceEditor name="content" control={control} />
            {errors.content && (
              <div className="text-red text-sm mt-1">
                {t("educational_materials.contentRequired")}
              </div>
            )}
          </div>
          <div hidden={language === Language.PL}>
            <FormTinyMceEditor name="contentEn" control={control} />
            {errors.contentEn && (
              <div className="text-red text-sm mt-1">
                {t("educational_materials.contentRequired")}
              </div>
            )}
          </div>
        </Labeled>

        {isAdminClinicMember && (
          <div className="flex gap-4 mt-4">
            <FormCheckbox
              name="reviewed"
              control={control}
              label={
                <div className="flex items-center gap-2">
                  {t("products.reviewed")}
                  <Reviewed size="w-5 h-5" />
                </div>
              }
            />
          </div>
        )}
      </div>
    </EducationalMaterialsDocumentFormWrapper>
  );
};
