import { object, string, array, number, boolean, InferType } from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import {
  ActionsDto,
  actionsSchema,
  SectionCategoryDto,
  sectionSearchCategorySchema,
} from "./common";
import { validateAndFixNutrients } from "./utils/validateCatching";

export const fetchSection = async (
  sectionId: number,
): Promise<ApiResponse<FetchSectionResponse>> => {
  const data = await fetchData(
    `/dietitian/program-section-templates/${sectionId}`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchSectionSchema, data);
};

const Image = object({
  url: string().required(),
  id: number().required(),
});

const fetchSectionSchema = object({
  data: object().shape({
    id: number().required(),
    title: string().required(),
    titleEn: string().nullable(),
    content: string().required(),
    contentEn: string().nullable(),
    programSectionCategory: sectionSearchCategorySchema.required(),
    reviewed: boolean().required(),
    actions: actionsSchema.required(),
    descriptionPl: string().nullable().defined(),
    descriptionEn: string().nullable().defined(),
    image: Image.nullable().defined(),
  }),
});

type Image = InferType<typeof Image>;

export interface FetchSectionResponse {
  id: number;
  title: string;
  titleEn?: string;
  content: string;
  contentEn?: string;
  programSectionCategory: SectionCategoryDto;
  reviewed: boolean;
  actions: ActionsDto;
  descriptionPl?: string;
  descriptionEn?: string;
  image: Image | null;
}
