import { useCallback, useEffect, useMemo } from "react";

import { useAppTranslation } from "@hooks";
import {
  useFetchDietitianAccountQuery,
  useUpdateDietitianAccountMutation,
} from "@hooks/queries/settings";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { FormTextField } from "@components/FormTextField";
import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";

import {
  DietitianAccountFormInput,
  mapDietitianAccountForm,
  mapDietitianAccountRequest,
  useDietitianAccountForm,
} from "./useDietitianAccountForm";
import { Dialog, Typography, useTheme } from "@mui/material";
import { Close } from "@assets/icons";
import { LoadingButton } from "@mui/lab";
import {
  CancelButton,
  CloseButton,
  SettingsModalCard,
  SettingsModalWrapper,
  StyledDialogActions,
  Title,
  TitleWrapper,
} from "../../SettingsAccount.styled";

interface EditPersonalInfoModalProps {
  onClose: () => void;
  open: boolean;
}

export const EditPersonalInfoModal = ({
  open,
  onClose,
}: EditPersonalInfoModalProps) => {
  const { i18n, t } = useAppTranslation();
  const { colors } = useTheme();

  const { account, isLoading, isError } = useFetchDietitianAccountQuery();
  const { mutate: updateDietitianAccount } =
    useUpdateDietitianAccountMutation();

  const mappedDietitianAccount = useMemo(
    () => mapDietitianAccountForm(account),
    [mapDietitianAccountForm, account],
  );

  const { control, handleSubmit, reset } = useDietitianAccountForm(
    mappedDietitianAccount,
  );

  const handleUpdateUser = useCallback(
    (data: DietitianAccountFormInput) => {
      updateDietitianAccount(mapDietitianAccountRequest(data));
      reset();
      onClose && onClose();
    },
    [updateDietitianAccount, mapDietitianAccountRequest],
  );

  const onCloseWithReset = () => {
    reset();
    onClose && onClose();
  };

  useEffect(() => {
    i18n.changeLanguage(account?.language);
  }, [account?.language]);

  if (isLoading) return <Spinner className="h-screen" />;

  if (isError) return <ApiError />;

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <SettingsModalWrapper>
        <TitleWrapper>
          <Title>{t("settings.profile.modal.personal_info")}</Title>
          <CloseButton onClick={onCloseWithReset}>
            <Close />
          </CloseButton>
        </TitleWrapper>
        <SettingsModalCard>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("settings.profile.modal.first_name")}
            </Typography>
            <FormTextField
              name="firstName"
              control={control}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("settings.profile.modal.last_name")}
            </Typography>
            <FormTextField
              name="lastName"
              control={control}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("settings.profile.modal.email_address")}
            </Typography>
            <FormTextField
              name="email"
              control={control}
              size="small"
              disabled
            />
          </div>
          <div className="flex gap-5 ">
            <div className="grid gap-1 w-[120px]">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("settings.profile.modal.prefix")}
              </Typography>
              <FormCountryAutocomplete
                control={control}
                name="prefix"
                id="prefix"
                size="small"
              />
            </div>
            <div className="grid gap-1 flex-3">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("settings.profile.modal.phone_number")}
              </Typography>
              <FormTextField
                name="phone"
                control={control}
                variant="outlined"
                size="small"
                fullWidth
              />
            </div>
          </div>
        </SettingsModalCard>
      </SettingsModalWrapper>
      <StyledDialogActions>
        <CancelButton variant="outlined" onClick={onCloseWithReset}>
          {t("settings.profile.modal.cancel")}
        </CancelButton>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit(handleUpdateUser, e => console.log("e", e))}
        >
          {t("settings.profile.modal.save")}
        </LoadingButton>
      </StyledDialogActions>
    </Dialog>
  );
};
