import { useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { InputAdornment } from "@mui/material";
import { Loupe } from "@assets/icons";
import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { EducationalMaterialsFilters } from "../EducationalMaterials/_components/EducationalMaterialsFilters";
import { FormTextFieldClearableStyled } from "@components/RecipesFiltersNew/RecipesFilters.styled";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { useAppTranslation } from "@hooks";
import { EducationalMaterialsLayout } from "./_components/EducationalMaterialsLayout";
import { ViewTabs } from "./EducationalMaterialsView.types";
import { EducationalMaterialsViewItem } from "./_components/EducationalMaterialsViewItem";
import { useQuickFiltersForm } from "./_hooks/useEducationalMaterialsForm";
import { useEducationalMaterialsFilterOptions } from "./_hooks/useEducationalMaterialsOptions";
import { EducationalMaterialsAddButton } from "./_components/EducationalMaterialsAddButton";
import { EducationalModalProvider } from "./_hooks/useEducationalMaterialsContext";
import { EducationalMaterialsModals } from "./_components/EducationalMaterialsModals";
import { useSearchSectionsQueryNew } from "@hooks/queries/useSearchSectionsQueryNew";

export const EducationalMaterialsView = () => {
  const { t } = useAppTranslation();

  const [view, setView] = useState<ViewTabs>(ViewTabs.GRID);

  const { viewTabsOptions, categoryOptions, tagsWithCount, tabs } =
    useEducationalMaterialsFilterOptions();

  const form = useQuickFiltersForm();

  const { meta } = useSearchSectionsQueryNew();

  const handleChangeType = (type: string) => {
    // TODO: Implement change type
  };

  return (
    <EducationalModalProvider>
      <EducationalMaterialsLayout
        title={t("educational_materials.title")}
        headerContent={
          <div className="flex gap-x-4">
            {/* <EducationalMaterialsBadge>
              <CloudUpload fill="none" className="!text-[24px]" />
              <span>{`${t(
                "educational_materials.storageUsed",
              )} ${diskSpace} `}</span>
            </EducationalMaterialsBadge> */}
            {/* <EducationalMaterialsIconButton color="secondary-purple">
              <ShareArrow fill="currentColor" className="!text-[24px]" />
              <span>{t("educational_materials.share")}</span>
            </EducationalMaterialsIconButton> */}
            <EducationalMaterialsAddButton className="!h-[40px]" />
          </div>
        }
      >
        <div className="grid gap-[16px]">
          <FormProvider {...form}>
            <div className="flex justify-between items-center">
              <Controller
                name="tab"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <TabSwitch onChange={onChange} tabs={tabs} value={value} />
                )}
              />
              <div className="flex items-center gap-2">
                <FormTextFieldClearableStyled
                  name="search"
                  size="small"
                  placeholder={t("educational_materials.searchForMaterial")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Loupe />
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="flex">
                  <EducationalMaterialsFilters compact total={meta?.total} />
                </div>
                <TabSwitch
                  onChange={val => setView(val)}
                  tabs={viewTabsOptions}
                  value={view}
                />
              </div>
            </div>
            <div className="flex gap-2">
              <TabsChipsFiltersForm
                name="status"
                options={tagsWithCount}
                isWrapped={false}
              />
              {categoryOptions.length > 0 && (
                <TabsChipsFiltersForm
                  name="categoryId"
                  options={categoryOptions}
                  isWrapped={false}
                />
              )}
            </div>
          </FormProvider>
          <EducationalMaterialsViewItem view={view} />
        </div>

        <EducationalMaterialsModals />
      </EducationalMaterialsLayout>
    </EducationalModalProvider>
  );
};
