import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppTranslation } from "@hooks";
import { PatchDietitianPasswordRequest } from "@client/settings";

export const useDietitianPasswordForm = () => {
  const { t } = useAppTranslation();
  const dietitianPasswordSchema = yup.object().shape({
    currentPassword: yup.string().required(t("common.required_field")),
    newPassword: yup
      .string()
      .test("len", t("settings.password_edit.min_length"), val =>
        val?.length ? val.length >= 8 : false,
      )
      .required(t("common.required_field")),
    newPasswordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), ""],
        t("settings.password_edit.must_match_new_password"),
      )
      .required(t("common.required_field")),
  });

  return useForm<DietitianPasswordInput>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    resolver: yupResolver(dietitianPasswordSchema),
  });
};

export interface DietitianPasswordInput {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const mapDietitianPasswordRequest = (
  data: DietitianPasswordInput,
): PatchDietitianPasswordRequest => {
  return {
    oldPassword: data.currentPassword,
    password: data.newPassword,
    passwordConfirmation: data.newPasswordConfirmation,
  };
};
