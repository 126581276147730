export enum EducationalMaterialsTabs {
  TEXT_MATERIALS = "textMaterials",
  PDF_FILES = "pdfFiles",
  VIDEO_FILES = "videoFiles",
}

export enum ViewTabs {
  GRID = "2",
  LIST = "1",
}

export enum BaseSwitch {
  RECIPES = "recipes",
  COLLECTIONS = "meals",
}

export enum AuthorType {
  ALLOWEAT = "alloweat",
  OWN = "own",
}

export enum StatusType {
  all = "active",
  INACTIVE = "inactive",
}

export enum StatusTag {
  ALL = "all",
  LATEST = "latest",
  FAVORITE = "favorite",
  OWN = "own",
}

export interface Tag {
  id: string;
  label: string;
}

export enum DocumentType {
  TEXT = "text",
  PDF = "pdf",
  VIDEO = "video",
}

export enum Language {
  PL = "PL",
  EN = "EN",
}

export enum SectionCategory {
  GENERAL_INFORMATION = 1,
  HEALTH = 2,
  LIFESTYLE = 3,
  BEAUTY = 4,
  SPORT = 5,
  CULINARY = 6,
  OTHERS = 7,
}
