import {
  Layout,
  Value,
  Label,
} from "@components/Client/BodyMeasurementsTabs/BodyMeasurementItem.styled";
import { useMemo } from "react";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { calculateBmi } from "@views/dietician/PatientEnergyBalance";
import { PatientMeasurementResource } from "@client/resources/PatientMeasurementResource";
import { Chip } from "@mui/material";

type BMIProps = {
  patientBodyMeasurement: Pick<
    PatientMeasurementResource,
    "bodyMeasurements"
  > | null;
};

const BMI = ({ patientBodyMeasurement }: BMIProps) => {
  const bmi = useMemo(() => {
    if (!patientBodyMeasurement) {
      return "-";
    }

    const getMeasurementValue = (id: number) => {
      return (
        patientBodyMeasurement?.bodyMeasurements.find(
          measurement => measurement.body.id === id,
        )?.value ?? null
      );
    };

    const weight = getMeasurementValue(BodyMeasurementConst.weight);
    const growth = getMeasurementValue(BodyMeasurementConst.height);
    if (!weight || !growth) {
      return "-";
    }

    return calculateBmi(weight, growth).toFixed(2);
  }, [patientBodyMeasurement]);

  const getBmiCategory = (value: string) => {
    interface Category {
      label: string;
      color: "success" | "warning";
      min: number;
      max: number;
    }
    const categories: Category[] = [
      { min: 0, max: 18.49, label: "Niedowaga", color: "warning" },
      { min: 18.5, max: 24.99, label: "W normie", color: "success" },
      { min: 25, max: 29.99, label: "Nadwaga", color: "warning" },
      { min: 30, max: 34.99, label: "Otyłość I stopnia", color: "warning" },
      { min: 35, max: 39.99, label: "Otyłość II stopnia", color: "warning" },
      {
        min: 40,
        max: Infinity,
        label: "Otyłość III stopnia",
        color: "warning",
      },
    ];

    const valueParsed = Number(value);
    return (
      categories.find(
        ({ min, max }) => valueParsed >= min && valueParsed <= max,
      ) || null
    );
  };
  const bmiChip = useMemo(() => getBmiCategory(bmi), [bmi]);

  return (
    <Layout>
      <Label>BMI</Label>
      <div className="flex gap-[4px]">
        <Value>{bmi}</Value>
        {bmiChip && (
          <Chip variant="filled" color={bmiChip.color} label={bmiChip.label} />
        )}
      </div>
    </Layout>
  );
};

export default BMI;
