import { ReactNode } from "react";

import { createTheme, ThemeProvider } from "@mui/material";
import { plPL, enUS } from "@mui/x-data-grid";
import {
  plPL as pickersPlPL,
  enUS as pickersEnUS,
} from "@mui/x-date-pickers/locales";
import { plPL as corePlPL, enUS as coreEnUS } from "@mui/material/locale";

import { Spinner } from "@components/Spinner";
import {
  GroupHeaderStyled,
  GroupItemsStyled,
} from "@views/dietician/Diet/components/SwapProductsModal/components";
import { ArrowDown, CheckedIcon, Remove } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import {
  CheckboxFilledPurple,
  CheckboxEmpty,
  CheckboxIndeterminate,
} from "@assets/icons/Checkbox";

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    newFilled: true;
    newOutlined: true;
  }
}

interface ThemeProviderProps {
  children: ReactNode;
}
export const ThemeProviderWrapper = ({ children }: ThemeProviderProps) => {
  const { isPolishChosen } = useAppTranslation();

  const theme = createTheme(
    {
      colors: {
        tableBackground: "#f1f1f1",
        tableHeader: "#f8f5fd",
        cancelButton: "#E0E0E0",
        cancelButtonHover: "#b8b7b3",
        submitButtonHover: "#a46bfc",
        whiteBackground: "#ffffff",
        tableBorder: "#e0dfde",
        textDisabled: "#8E8E8E",
        eventBorder: "#b38bff",
        border: "#E2E1DEFF",
        focusedBorder: "#a46bfc",
        gray000: "#000000",
        gray200: "#FAF9F9",
        gray300: "#F1F1F1CC",
        gray500: "#666666",
        labelGray: "#00000099",
        alertTomato: "#F25C5A",
        acceptGreen: "#46BB63",
        black087: "rgba(0, 0, 0, 0.87)",
        lightPurple: "rgba(128, 31, 255, 0.03)",
        hoverBtnPurple: "rgba(128, 31, 255, 0.3)",
        greyBlue: "#677099",
        yellow: "#FFBB33",
        yellowLight: "#FFF6E2",
        chartPurple: "#CFAAFF",
        chartOrange: "#FEA745",
        chartGreen: "#87D89C",
        solidLightPurple: "#FAF6FF",
        borderGray: "#EEEEEE",
        subtlePurple: "#F5EDFF",
        chartLightPurple: "#B379FF",
        primaryLightNew: "#FBFAFC",
        orangeDark: "#E5A117",
        // --------------- new colors --------------- //
        neutral: {
          dark: {
            700: "",
            800: "",
            900: "",
          },
          medium: {
            400: "",
            600: "",
            800: "",
          },
          light: {
            100: "",
            200: "",
            300: "",
          },
        },
        survey: {
          light: "",
          yellow: {
            light: "",
            dark: "",
          },
          green: {
            light: "",
            dark: "",
          },
          pink: {
            light: "",
            dark: "",
          },
        },

        orange: {
          light: "",
          dark: "",
        },
      },
      breakpoints: {
        values: {
          mobile: 360,
          smallNew: 740,
          small: 848,
          tablet: 1128,
          smallMax: 1199.5,
          medium: 1200,
          mediumMax: 1439.5,
          laptop: 1440,
          laptopMax: 1619.5,
          desktop: 1620,
          desktopMax: 1919.5,
          largeDesktop: 1920,
        },
      },
      fontFamily: {
        regular: "Figtree, sans-serif",
        rockSalt: "Rock Salt, Figtree, sans-serif",
        sueEllenFrancisco: "Sue Ellen Francisco, Figtree, sans-serif",
      },
      palette: {
        primary: {
          main: "#801fff",
        },
        secondary: {
          main: "#FFAA00",
          dark: "#EC7100",
          light: "#FFBB33",
          contrastText: "#FFFFFF",
        },
        error: {
          main: "#F25C5A",
        },
        success: {
          main: "#46BB63",
        },
        info: {
          main: "#4C7CFB",
        },
        warning: {
          main: "#FF9800",
        },
      },
      boxShadows: {
        light: "0px 2px 10px 0px #0000000f",
        tooltip: "",
        card: "",
        cardDark: "",
        mobileNavigation: "",
      },
      gradients: {
        survey: "",
        surveySemiTransparent: "",
      },
      components: {
        MuiButton: {
          defaultProps: {
            disableElevation: true,
          },
          styleOverrides: {
            root: {
              textTransform: "none",
              fontWeight: 700,
              lineHeight: "1em",
            },
            sizeSmall: {
              height: "1.25rem",
            },
            sizeMedium: {
              height: "2.25rem",
            },
            sizeLarge: {
              height: "3rem",
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              fontSize: "0.875rem",
              padding: "0 0.5rem",
            },
          },
        },
        MuiButtonGroup: {
          defaultProps: {
            disableElevation: true,
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              justifyContent: "center",
              padding: "1rem",
            },
          },
        },
        MuiDialogContentText: {
          styleOverrides: {
            root: {
              fontSize: "0.875rem",
            },
          },
        },
        MuiSlider: {
          defaultProps: {
            valueLabelDisplay: "on",
            color: "primary",
          },
          styleOverrides: {
            root: {
              height: "6px",
            },
            thumb: {
              width: "16px",
              height: "16px",
            },
            rail: {
              opacity: 0.15,
            },
            valueLabel: {
              borderRadius: "0.375rem",
            },
          },
          variants: [
            {
              props: { color: "secondary" },
              style: ({ theme }) => ({
                "& .MuiSlider-valueLabel, & .MuiSlider-valueLabelOpen": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }),
            },
            {
              props: { color: "primary" },
              style: ({ theme }) => ({
                "& .MuiSlider-valueLabel, & .MuiSlider-valueLabelOpen": {
                  backgroundColor: theme.palette.primary.main,
                },
              }),
            },
          ],
        },
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: "0px 2px 10px 0px #0000000f",
            },
          },
        },
        MuiPaper: {
          defaultProps: {
            style: {
              fontSize: 14,
            },
          },
        },
        MuiTextField: {
          defaultProps: {
            InputLabelProps: {
              shrink: true,
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: ({ theme }) => ({
              boxShadow: theme.shadows[1],
            }),
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: ({ theme }) => ({
              "fontSize": ".875rem",
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.colors.border,
                },
                "&:hover:not(.Mui-disabled) fieldset": {
                  borderColor: theme.colors.focusedBorder,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary,
                  borderWidth: "1px",
                },
              },
              "&.MuiInput-root": {
                "&:not(.Mui-disabled)::before": {
                  borderColor: theme.colors.border,
                },
                "&:hover:not(.Mui-disabled)::before": {
                  borderColor: theme.colors.focusedBorder,
                  borderBottomWidth: "1px",
                },
                "&.Mui-focused &:not(.Mui-disabled)::before": {
                  borderColor: theme.palette.primary,
                  borderBottomWidth: "1px",
                },
                "&::after": {
                  borderBottomWidth: "1px",
                },
              },
            }),
            input: {
              height: "1rem",
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            input: {
              "padding": ".875rem .75rem .7rem .75rem",
              "&:focus": {
                backgroundColor: "unset",
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              backgroundColor: "white",
              paddingRight: ".25rem",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: ({ theme }) => ({
              borderColor: theme.colors.border,
              borderRadius: ".375rem",
            }),
            input: {
              padding: ".9rem .75rem .7rem .875rem",
            },
            inputMultiline: {
              padding: 0,
            },
            multiline: {
              padding: ".25rem .875rem",
            },
          },
        },
        MuiAccordion: {
          defaultProps: {
            elevation: 0,
            disableGutters: true,
          },
          styleOverrides: {
            root: {
              ":before": {
                opacity: 0,
              },
            },
          },
        },
        MuiAccordionSummary: {
          defaultProps: {
            expandIcon: <ArrowDown />,
          },
          styleOverrides: {
            root: {
              gap: "0.75rem",
            },
            expandIconWrapper: {
              color: "#801fff",
              fontSize: "22px",
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              paddingTop: "unset",
            },
          },
        },
        MuiSelect: {
          defaultProps: {
            variant: "standard",
            IconComponent: props => <CheckedIcon {...props} />,
          },
          styleOverrides: {
            standard: {
              height: ".875rem",
              minHeight: ".875rem",
              padding: ".875rem .75rem .7rem .75rem",
            },
            icon: ({ theme }) => ({
              right: "0.5rem",
              color: theme.palette.primary.main,
            }),
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            popupIcon: <CheckedIcon />,
            renderGroup: params => (
              <li key={params.key}>
                <GroupHeaderStyled>{params.group}</GroupHeaderStyled>
                <GroupItemsStyled>{params.children}</GroupItemsStyled>
              </li>
            ),
            loadingText: <Spinner />,
          },
          styleOverrides: {
            root: ({ theme }) => ({
              "&.MuiAutocomplete-root .MuiInput-root .MuiInput-input": {
                padding: ".875rem .75rem .7rem .75rem",
              },
              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                padding: ".9rem .75rem .7rem .875rem",
              },
              ".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input":
                {
                  padding: 0,
                },
              "& .MuiIconButton-root:not(.Mui-disabled)": {
                color: theme.palette.primary.main,
              },
              "& .MuiIconButton-root.Mui-disabled": {
                color: theme.colors.textDisabled,
              },
              "& svg": {
                width: "1rem",
                height: "1rem",
              },
            }),
            endAdornment: {
              right: ".5rem",
              top: "calc(50% - .75rem)",
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            sizeSmall: ({ theme }) => ({
              "& svg": {
                color: theme.palette.primary.main,
                width: ".875rem",
                height: ".875rem",
              },
              "width": "1.5rem",
              "height": "1.5rem",
            }),
          },
        },
        MuiMenu: {
          styleOverrides: {
            list: {
              padding: 0,
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              "padding": 0,
              "& .MuiTypography-root": {
                fontSize: ".875rem",
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              fontSize: ".875rem",
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        MuiChip: {
          defaultProps: {
            deleteIcon: <Remove />,
          },
          variants: [
            {
              props: { variant: "newFilled", color: "primary" },
              style: ({ theme }) => ({
                "color": theme.palette.primary.dark,
                "backgroundColor": "rgba(128, 31, 255, 0.12)",

                "& .MuiChip-deleteIcon": {
                  "color": theme.palette.primary.main,
                  "&:hover": {
                    color: "unset",
                  },
                },
                "&.MuiChip-clickableColorPrimary:hover": {
                  backgroundColor: "rgba(128, 31, 255, 0.3)",
                },
              }),
            },
            {
              props: { variant: "newOutlined", color: "primary" },
              style: ({ theme }) => ({
                "color": theme.palette.primary.dark,
                "border": `1px solid ${theme.palette.primary.main}`,
                "backgroundColor": "white",

                "&.MuiChip-clickableColorPrimary:hover": {
                  backgroundColor: "rgba(128, 31, 255, 0.3)",
                },
              }),
            },
            {
              props: { variant: "newOutlined", color: "default" },
              style: ({ theme }) => ({
                color: theme.palette.text.secondary,
                border: `1px solid ${theme.palette.text.secondary}`,
                backgroundColor: theme.palette.action.hover,
              }),
            },
            {
              props: { variant: "filled" },
              style: ({ theme }) => ({
                "borderRadius": "0.375rem",
                "fontWeight": 600,
                "fontSize": "0.75rem",
                "& .MuiChip-deleteIcon": {
                  fontSize: "0.5rem",
                  marginRight: "0.75rem",
                  color: "black",
                },
                "backgroundColor": "#e0dfde",
              }),
            },
            {
              props: { variant: "outlined" },
              style: ({ theme }) => ({
                "borderRadius": "0.375rem",
                "fontWeight": 600,
                "fontSize": ".75rem",
                "& .MuiChip-deleteIcon": {
                  fontSize: ".5rem",
                  marginRight: ".75rem",
                  color: theme.palette.primary.main,
                },
                "borderColor": theme.palette.primary.main,
                "color": theme.palette.primary.main,
              }),
            },
          ],
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: ({ theme }) => ({
              "& .MuiTypography-root": {
                fontSize: "0.875rem",
                color: "black",
              },
              "& .MuiIconButton-root:not(.Mui-disabled)": {
                color: theme.palette.primary.main,
              },
              "& svg": {
                width: "1rem",
                height: "1rem",
                marginRight: "-0.075rem",
              },
            }),
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              padding: ".9rem .75rem .7rem .875rem",
              minWidth: "4rem",
            },
          },
        },
        MuiAlert: {
          variants: [
            {
              props: { severity: "success" },
              style: ({ theme }) => ({
                fontWeight: 600,
                color: theme.colors.acceptGreen,
              }),
            },
            {
              props: { severity: "error" },
              style: ({ theme }) => ({
                fontWeight: 600,
                color: theme.colors.alertTomato,
              }),
            },
            {
              props: { severity: "info", variant: "outlined" },
              style: ({ theme }) => ({
                "borderColor": theme.palette.primary.dark,
                "color": theme.colors.gray000,
                "backgroundColor": theme.colors.whiteBackground,
                "minWidth": "20rem",

                ".MuiAlert-icon": {
                  color: theme.palette.primary.dark,
                },

                ".MuiAlertTitle-root": {
                  fontWeight: "bold",
                },
              }),
            },
            {
              props: { severity: "error", variant: "outlined" },
              style: ({ theme }) => ({
                "fontFamily": theme.fontFamily.regular,

                ".MuiAlert-icon": {
                  color: "inherit",
                },

                ".MuiAlertTitle-root": {
                  fontWeight: "bold",
                },
              }),
            },
          ],
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              fontFamily: "Figtree",
            },
          },
        },
        MuiCheckbox: {
          defaultProps: {
            icon: <CheckboxEmpty size="w-6 h-5" />,
            indeterminateIcon: <CheckboxIndeterminate size="w-6 h-5" />,
            checkedIcon: <CheckboxFilledPurple size="w-6 h-5" />,
          },
          styleOverrides: {
            root: ({ theme }) => ({
              color: theme.palette.primary.main,
            }),
          },
        },
      },
      typography: {
        h6: {
          fontSize: "1.125rem",
          fontFamily: "Figtree",
          fontWeight: 600,
          lineHeight: "1.6 !important",
        },
        fontFamily: ["Figtree", "system-ui", "sans-serif"].join(","),
        button: {
          fontFamily: "Figtree, system- ui, sans- serif",
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    isPolishChosen ? plPL : enUS,
    isPolishChosen ? pickersPlPL : pickersEnUS,
    isPolishChosen ? corePlPL : coreEnUS,
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
