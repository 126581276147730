import { ModalWrapper } from "@components/ModalWrapperNew";
import { Dialog, Radio, RadioGroup, styled } from "@mui/material";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useRecipeAndProductsForm } from "./useRecipeAndProductsForm";
import { OuterLabel } from "@components/OuterLabel";
import { FormCheckboxMui } from "@components/FormCheckboxMui";
import { FormControlLabelStyled, LabeledCheckbox } from "../LabeledCheckbox";
import { useState } from "react";

interface RecipeAndProductsModalProps {
  open: boolean;
  onClose: () => void;
}
export const RecipeAndProductsModal = ({
  open,
  onClose,
}: RecipeAndProductsModalProps) => {
  const { control, watch } = useRecipeAndProductsForm();
  const [radioValue, setRadioValue] = useState(Exchangers.REGULAR);
  const disableButton =
    radioValue === Exchangers.SPECIAL &&
    [
      watch("glutenFree"),
      watch("lactoseFree"),
      watch("vegetarian"),
      watch("vegan"),
    ].every(value => !value);
  return (
    <Dialog open={open} onClose={onClose}>
      <Header title="Baza przepisów i produktów" onClose={onClose} />

      <Container>
        <OuterLabel label="Dostępne bazy">
          <LabeledCheckbox
            control={control}
            name="alloweat"
            label="Przepisy alloweat"
          />
          <LabeledCheckbox
            control={control}
            name="dietitian"
            label="Przepisy dietetyka"
          />
        </OuterLabel>

        <OuterLabel label="Filtry baz">
          <LabeledCheckbox
            control={control}
            name="allergen"
            label="Alergerny"
          />
          <LabeledCheckbox control={control} name="diet" label="Rodzaj diety" />
        </OuterLabel>

        <OuterLabel label="Wymienniki produktów">
          <RadioGroup
            value={radioValue}
            onChange={(e, value) => setRadioValue(value as Exchangers)}
          >
            <FormControlLabelStyled
              control={<Radio />}
              label="Tradycyjne"
              value={Exchangers.REGULAR}
            />
            <FormControlLabelStyled
              control={<Radio />}
              label="Specjalistyczne"
              value={Exchangers.SPECIAL}
            />
          </RadioGroup>

          {radioValue === Exchangers.SPECIAL && (
            <ExchangersWrapper>
              <LabeledCheckbox
                control={control}
                name="glutenFree"
                label="Bez glutenu"
              />
              <LabeledCheckbox
                control={control}
                name="lactoseFree"
                label="Bez laktozy"
              />
              <LabeledCheckbox
                control={control}
                name="vegetarian"
                label="Wegetariańskie"
              />
              <LabeledCheckbox
                control={control}
                name="vegan"
                label="Wegańskie"
              />
            </ExchangersWrapper>
          )}
        </OuterLabel>
      </Container>

      <Footer
        onCancel={onClose}
        onSubmit={() => console.log("submit")}
        disabled={disableButton}
      />
    </Dialog>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

const ExchangersWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 24px;
`;
enum Exchangers {
  REGULAR = "1",
  SPECIAL = "2",
}
