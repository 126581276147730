import { useMemo } from "react";

import { useFetchDietitianAccountQuery } from "./queries/settings";
import { SubscriptionTypes } from "@utils";

export const useSubscription = () => {
  const { account } = useFetchDietitianAccountQuery();
  const subscriptionId = account?.clinic?.subscriptionNew?.subscription.id;

  return useMemo(
    () => ({
      isTrial: subscriptionId === SubscriptionTypes.TRIAL,
      isUnlimitedGrowth: subscriptionId === SubscriptionTypes.UNLIMITED_GROWTH,
      isGrowth: subscriptionId === SubscriptionTypes.GROWTH,
      isStarter: subscriptionId === SubscriptionTypes.STARTER,
      isGrowth10: subscriptionId === SubscriptionTypes.GROWTH_10,
      isGrowth30: subscriptionId === SubscriptionTypes.GROWTH_30,
      isGrowth60: subscriptionId === SubscriptionTypes.GROWTH_60,
      isEnterprise: subscriptionId === SubscriptionTypes.ENTERPRISE,
      isStudent: subscriptionId === SubscriptionTypes.STUDENT,
    }),
    [subscriptionId],
  );
};
