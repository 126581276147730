import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { QuestionTableDto, TableAnswerResourceDto } from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { Close, Fullscreen } from "@assets/icons";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation, useModalState } from "@hooks";

import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider, FormTextFieldWrapper } from "../BlurContext";
import { Question } from "../Question/Question";
import { QuestionModal } from "../Question/QuestionModal";
import {
  TableAnswerFormInputs,
  mapTableAnswerForm,
  mapTableAnswerRequest,
  useTableAnswerForm,
} from "../forms/useTableAnswerForm";
import {
  ContentWrapper,
  HeaderStyled,
  IconButtonStyled,
} from "./QuestionTable.styled";
import { TableAnswerInputs } from "./TableAnswerInputs";

interface QuestionTableProps {
  question: QuestionTableDto;
  answer?: TableAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
}
export const QuestionTable = ({
  question: { title, description, config, id },
  answer,
  readOnly,
  ...rest
}: QuestionTableProps) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const { t } = useAppTranslation();
  const { url } = useParseUrl();
  const { required, heading, columns, rows } = config;
  const mappedForm = useMemo(
    () => mapTableAnswerForm(config, answer?.answer),
    [answer?.answer, config],
  );
  const { formState, handleSubmit, ...form } = useTableAnswerForm(
    mappedForm,
    required,
  );
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: TableAnswerFormInputs) => {
    if (url) mutate({ payload: mapTableAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);

  const [modalOpened, onModalOpen, onModalClose] = useModalState();

  return (
    <FormProvider formState={formState} handleSubmit={handleSubmit} {...form}>
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={description}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
          actionComponents={
            <IconButtonStyled onClick={onModalOpen}>
              <Fullscreen />
            </IconButtonStyled>
          }
        >
          <ContentWrapper>
            {heading && <HeaderStyled>{heading}</HeaderStyled>}

            <TableAnswerInputs
              columns={columns}
              rows={rows}
              readOnly={readOnly}
              showFab
            />

            <OuterLabel
              label={t("questionnaires.questions.table.extra_comment")}
            >
              <FormTextFieldWrapper
                multiline
                fullWidth
                rows={3}
                control={form.control}
                name="comment"
                size="small"
                FormHelperTextProps={{ hidden: true }}
                InputProps={{ readOnly }}
              />
            </OuterLabel>
          </ContentWrapper>
        </Question>

        <QuestionModal
          {...rest}
          title={title}
          description={description}
          required={required}
          hasError={hasError}
          open={modalOpened}
          onClose={onModalClose}
          actionComponents={
            <IconButtonStyled onClick={onModalClose} size="small">
              <Close size="w-3 h-3" />
            </IconButtonStyled>
          }
        >
          <ContentWrapper>
            {heading && <HeaderStyled>{heading}</HeaderStyled>}

            <TableAnswerInputs
              columns={columns}
              rows={rows}
              readOnly={readOnly}
              showFab
            />

            <OuterLabel
              label={t("questionnaires.questions.table.extra_comment")}
            >
              <FormTextFieldWrapper
                multiline
                fullWidth
                rows={3}
                control={form.control}
                name="comment"
                size="small"
                FormHelperTextProps={{ hidden: true }}
                InputProps={{ readOnly }}
              />
            </OuterLabel>
          </ContentWrapper>
        </QuestionModal>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
