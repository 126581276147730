import { MouseEvent, useContext, useState } from "react";

import { Button, ToggleButtonGroup, Typography } from "@mui/material";

import {
  useAppParams,
  useAppTranslation,
  useClientBalanceForecast,
  useModalState,
} from "@hooks";

import { simulationCalculator } from "./simulationCalculations";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { MuiInfoClickTooltip } from "@components/MuiInfoClickTooltip";
import { SimulationSliders } from "@components/SimulationSliders";
import { SimulationChart } from "@components/SimulationChart";
import { SimulationTable } from "@components/SimulationTable";

import {
  SimulationSectionWrapper,
  StyledToggleButton,
  TopSectionWrapper,
} from "./SimulationSection.styled";
import { SimulationSlidersContext } from "@views/dietician/PatientEnergyBalance/context/SimulationSlidersContext";
import { StyledAlert } from "@components/CaloricNeedsForm";
import { Pen } from "@assets/icons";
import { ThemeProviderWrapperNew } from "themeNew";
import { EditBalanceClientProfileModal } from "@components/EditBalanceClientProfileModal";

export const SimulationSection = () => {
  const [alignment, setAlignment] = useState<"chart" | "table">("chart");
  const { patientId } = useAppParams();
  const { t } = useAppTranslation();
  const [modalOpened, onModalOpen, onModalClose] = useModalState();
  const { weeksToGoal } = useContext(SimulationSlidersContext);

  const handleAlignment = (
    event: MouseEvent<HTMLElement>,
    newAlignment: "chart" | "table" | null,
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const [pace, setPace] = useState<number>(DEFAULT_FLOAT);

  const { differenceInKg, client, isLoading, isError, direction } =
    useClientBalanceForecast();

  if (isLoading) return <Spinner />;
  if (isError || !client) return <ApiError />;

  const patientDataFilled =
    !!client.profile.weight && !!client.profile.targetWeight;

  const data = simulationCalculator({
    duration: Math.abs(weeksToGoal),
    clientWeight: client.profile.weight ?? 0,
    balancePace: differenceInKg,
    simulationPace: pace,
    simulationDuration: weeksToGoal,
    positive: direction,
  });

  return (
    <SimulationSectionWrapper>
      <div className="flex-1">
        <TopSectionWrapper>
          <div className="flex items-center">
            <Typography variant="h6">{t("balance.simulation")}</Typography>
            <MuiInfoClickTooltip
              content={t("balance.simulation_description")}
            />
          </div>

          {patientDataFilled && (
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
            >
              <StyledToggleButton value="chart">
                {t("balance.chart")}
              </StyledToggleButton>
              <StyledToggleButton value="table">
                {t("balance.table")}
              </StyledToggleButton>
            </ToggleButtonGroup>
          )}
        </TopSectionWrapper>

        {patientDataFilled && (
          <SimulationSliders pace={pace} setPace={setPace} />
        )}

        {!patientDataFilled && (
          <ThemeProviderWrapperNew>
            <StyledAlert
              variant="filled"
              action={
                <Button
                  color="inherit"
                  onClick={onModalOpen}
                  startIcon={<Pen size="w-4 h-4" />}
                >
                  {t("balance.config_client")}
                </Button>
              }
            >
              {t("balance.no_data_balance")}
            </StyledAlert>
          </ThemeProviderWrapperNew>
        )}
      </div>

      {patientDataFilled && (
        <div className="flex-1">
          {alignment === "chart" ? (
            <SimulationChart
              data={data}
              targetWeight={client.profile.targetWeight}
            />
          ) : (
            <SimulationTable data={data} />
          )}
        </div>
      )}
      <ThemeProviderWrapperNew>
        <EditBalanceClientProfileModal
          open={modalOpened}
          onClose={onModalClose}
          id={patientId}
        />
      </ThemeProviderWrapperNew>
    </SimulationSectionWrapper>
  );
};

export const DEFAULT_FLOAT = 0.01;
export const DEFAULT_INT = 1;
