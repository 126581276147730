import { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useAppTranslation, useSectionForm } from "@hooks";
import { useCreateSectionMutation } from "@hooks/queries";
import { useUpdateSectionMutation } from "@hooks/queries";
import { SectionForm } from "@components/SectionForm";
import { Dialog } from "@mui/material";
import { DialogContentStyled } from "@components/ModalWrapperNew/ModalWrapper.styled";
import { Close } from "@assets/icons";
import {
  EducationalMaterialsDialogHeader,
  EducationalMaterialsDialogTitle,
} from "@views/dietician/EducationalMaterials/EducationalMaterialsView.styled";
import { IconButton } from "@components/IconButton";
import type { SectionFormSchema } from "@hooks/useSectionForm";
import omit from "lodash/omit";
import { toast } from "react-toastify";
import { useEducationalModalContext } from "@views/dietician/EducationalMaterials/_hooks/useEducationalMaterialsContext";
import { CloudAdd } from "@assets/icons";

interface SectionModalProps {
  open: boolean;
  onClose: () => void;
  programId?: number;
}

export const CreateSectionModal = ({
  open,
  onClose,
  programId,
}: SectionModalProps) => {
  const { t } = useAppTranslation();
  const { materialId } = useEducationalModalContext();
  const [showSavedNotification, setShowSavedNotification] = useState(false);

  const form = useSectionForm({
    onSave: setShowSavedNotification,
    programId,
  });

  const { mutate: createSectionMutation } = useCreateSectionMutation();
  const { mutate: updateSectionMutation } = useUpdateSectionMutation();

  const onSubmit = useCallback(
    (formData: SectionFormSchema) => {
      if (materialId) {
        updateSectionMutation(
          {
            id: Number(materialId),
            input: omit(formData, ["language", "imageUrl"]),
          },
          {
            onSuccess: () => {
              setShowSavedNotification(true);
              setTimeout(() => setShowSavedNotification(false), 2000);

              toast.success(
                t("educational_materials.successUpdateSection", {
                  variable: formData.title,
                }),
              );
              onClose();
            },
          },
        );
      } else {
        createSectionMutation(omit(formData, ["language", "imageUrl"]), {
          onSuccess: () => {
            setShowSavedNotification(true);
            setTimeout(() => setShowSavedNotification(false), 2000);

            onClose();
            toast.success(
              t("educational_materials.successCreateSection", {
                variable: formData.title,
              }),
            );
          },
        });
      }
    },
    [createSectionMutation, onClose, t],
  );

  useEffect(() => {
    if (showSavedNotification) {
      setTimeout(() => setShowSavedNotification(false), 5000);
    }
  }, [showSavedNotification]);

  const handleCloseModal = () => {
    form.reset();
    onClose();
  };

  return (
    <FormProvider {...form}>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullScreen={true}
        sx={{
          "& .MuiPaper-rounded": {
            borderRadius: 0,
          },
        }}
        disableEnforceFocus={true}
      >
        <EducationalMaterialsDialogHeader>
          <EducationalMaterialsDialogTitle>
            {t("educational_materials.createDocument")}
          </EducationalMaterialsDialogTitle>

          <div className="flex items-center gap-4">
            {showSavedNotification && (
              <div className="flex items-center gap-1">
                <CloudAdd sx={{ color: "transparent", fontSize: "24px" }} />
                <p className="text-xs text-gray-450">
                  {t("educational_materials.saved")}
                </p>
              </div>
            )}
            <IconButton color="gray" onClick={handleCloseModal}>
              <Close size="w-[12px] h-[12px]" />
            </IconButton>
          </div>
        </EducationalMaterialsDialogHeader>

        <DialogContentStyled className={`custom-scrollbar !bg-[#FBFAFC]`}>
          <SectionForm withCategory programId={programId} />
        </DialogContentStyled>

        {/* <DialogActions className="!bg-white">
          <Button variant="outlined" onClick={handleCloseModal}>
            {t("common.cancel")}
          </Button>
          <LoadingButton
            disabled={!isDirty || isSubmitting}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.save")}
          </LoadingButton>
        </DialogActions> */}
      </Dialog>
    </FormProvider>
  );
};
