import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { SectionRequestDto } from "./common";
import { apiUrl } from "@utils/configEnv";
import axios from "axios";

export const createSection = async (section: SectionRequestDto) => {
  return await fetchData(
    "/dietitian/program-section-templates",
    APIMethods.POST,
    section,
  );
};

export const updateSection = async (id: number, section: SectionRequestDto) => {
  return await fetchData(
    `/dietitian/program-section-templates/${id}`,
    APIMethods.PUT,
    section,
  );
};

export const uploadSectionMedia = async (media: FormData) => {
  return await axios.post(
    `${apiUrl()}/dietitian/program-section-templates/media`,
    media,
  );
};
