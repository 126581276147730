import { Accordion, AccordionSummary, Chip, styled } from "@mui/material";

export const CustomChip = styled(Chip)`
  background: #f2f2ff;
` as typeof Chip;

export const CustomAccordion = styled(Accordion)`
  padding: 16px;
  border: solid 1px #f1f1f1;
  box-shadow: none;
  &.MuiPaper-root {
    border-radius: 8px;
  }

  .MuiAccordionDetails-root {
    padding: 0;
    margin-top: 12px;
  }
` as typeof Accordion;

export const Label = styled("span")`
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  color: #4d516e;
`;

export const CustomAccordionSummary = styled(AccordionSummary)`
  min-height: auto;
  &:before {
    display: none;
    content: unset;
  }
  .MuiAccordionSummary-content {
    margin: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
  &.Mui-expanded {
    min-height: auto;
    margin: 0;
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
` as typeof AccordionSummary;
