import { useForm } from "react-hook-form";
import {
  PdfExportPdfFormStateType,
  PdfImageResponseType,
} from "@Pdf/types/pdfTypes";
import { PDF_COLORS, PDF_DISPLAY_CODE } from "@Pdf/defaults/pdfDefaults";
import { yupResolver } from "@hookform/resolvers/yup";
import { array, boolean, date, number, object, string } from "yup";
import { useAppTranslation } from "@hooks";
import {
  PdfDto,
  Template,
} from "@client/resources/GlobalClinicPatientSettingsResource";
import { capitalize } from "@mui/material";
import { useEffect } from "react";
import { FetchPatientProgramResponse } from "@client/program";
import dayjs from "dayjs";

export const defaultValues: PdfExportPdfFormStateType = {
  color: PDF_COLORS.PRIMARY,
  coverPageId: "",
  coverPageType: "photo",
  title: "",
  frequency: 7,
  startDate: null,
  endDate: null,
  options: [
    PDF_DISPLAY_CODE.WEEKDAY,
    PDF_DISPLAY_CODE.DATE,
    PDF_DISPLAY_CODE.MEAL_HOUR,
    PDF_DISPLAY_CODE.DAY_TYPE,
    PDF_DISPLAY_CODE.LOGO,
  ],
  clinicOptions: [
    PDF_DISPLAY_CODE.CLINIC_LOGO,
    PDF_DISPLAY_CODE.CLINIC_NAME,
    PDF_DISPLAY_CODE.CLINIC_ADDRESS,
    PDF_DISPLAY_CODE.CLINIC_EMAIL,
    PDF_DISPLAY_CODE.CLINIC_PHONE,
  ],
  kcal: false,
  macros: false,
  micros: [],
};

const usePdfExportForm = (
  defaultValuesExternal?: PdfExportPdfFormStateType,
) => {
  const { t } = useAppTranslation();

  const resolver = object().shape({
    color: string().required(t("common.required_field")),
    coverPageType: string().required(t("common.required_field")),
    coverPageId: string().required(t("common.required_field")),
    title: string()
      .required(t("common.required_field"))
      .max(160, t("errors.too_long")),
    frequency: number().required(),
    startDate: date().required(t("common.required_field")),
    endDate: date().required(t("common.required_field")),
    options: array(),
    clinicOptions: array(),
    kcal: boolean(),
    macros: boolean(),
    micros: array(),
  });

  const form = useForm<PdfExportPdfFormStateType>({
    defaultValues: defaultValuesExternal,
    resolver: yupResolver(resolver),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (defaultValuesExternal) form.reset(defaultValuesExternal);
  }, [defaultValuesExternal]);

  return form;
};

export default usePdfExportForm;

export const mapPdfExportForm = (
  data: PdfDto | undefined,
  coverPages: PdfImageResponseType[],
  program: FetchPatientProgramResponse | undefined,
  kcal: boolean | undefined,
): PdfExportPdfFormStateType | undefined => {
  if (!data) return;
  const {
    template,
    mainPhotoId,
    shoppingListPeriod,
    color,
    clinicData,
    menuData,
  } = data;
  const coverPageDict = {
    [Template.PHOTO]: "photo",
    [Template.HAND_DRAWN]: "hand-drawn",
    [Template.PRINT]: "print",
  };
  const coverPageId = coverPages.find(item => item.id === mainPhotoId)?.url;
  const menuDataChangedKeys = {
    weekday: menuData.weekDay,
    date: menuData.date,
    dayType: menuData.typeDay,
    mealHour: menuData.mealHour,
    logo: menuData.logo,
  };
  let startDate = new Date();
  const now = new Date();

  if (program?.startDate) {
    startDate = new Date(program?.startDate);

    if (startDate.getTime() < now.getTime()) startDate = now;
  }
  if (program?.finishDate) {
    let endDate = new Date(program?.finishDate);

    if (endDate.getTime() < now.getTime()) {
      endDate = now;
      endDate.setDate(endDate.getDate() + 1);
    }
  }
  return {
    ...defaultValues,
    title: program?.name ?? "",
    startDate: startDate,
    endDate: program?.finishDate
      ? new Date(program?.finishDate)
      : dayjs(startDate).add(3, "months").toDate(),
    micros: program?.showNutrients ?? [],
    macros: program?.showMacros ?? false,
    kcal: kcal ?? false,
    color: color,
    coverPageType: coverPageDict[template],
    coverPageId: coverPageId || coverPages[0]?.url || "",
    frequency: shoppingListPeriod,
    options: Object.entries(menuDataChangedKeys)
      .filter(([_, value]) => value)
      .map(([key]) => key),
    clinicOptions: Object.entries(clinicData)
      .filter(([_, value]) => value)
      .map(([key]) => `clinic${capitalize(key)}`),
  };
};
