import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";
import {
  ClinicDietitianSimpleListDto,
  fetchClinicDietitiansSimpleList,
} from "@client/settings";

export const fetchClinicDietitiansSimpleListQueryKey =
  "fetchClinicDietitiansSimpleListQueryKey";

export const useFetchClinicDietitiansSimpleListQuery = (
  options?: QueryOptionsTyped<ApiResponse<ClinicDietitianSimpleListDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClinicDietitiansSimpleListQueryKey],
    fetchClinicDietitiansSimpleList,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      ...options,
    },
  );

  return {
    dietitians: data?.data,
    ...rest,
  };
};
