import { PatientMealsMonitoringResource } from "@client/resources/PatientMealsMonitoringResource";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import Macros from "@views/dietician/PatientMonitoring2/components/MealsBox/Macros";
import { useMemo } from "react";

type MealMacrosProps = {
  meal: Pick<PatientMealsMonitoringResource, "meals">["meals"][0];
};

const MealMacros = ({ meal }: MealMacrosProps) => {
  const getNutrientValue = (id: number) =>
    meal.items.reduce((acc, mealItem) => {
      if (mealItem.type === "food_diary_meal_item_recipe") {
        return (
          acc +
          Math.round(
            (mealItem.item.servings / mealItem.item.recipe.servings) *
              (mealItem.item.recipe.nutrients.find(n => n.id === id)?.value ??
                0),
          )
        );
      }

      if (mealItem.type === "food_diary_meal_item_product") {
        return (
          acc +
          Math.round(
            (mealItem.item.grams / 100) *
              (mealItem.item.product.nutrients.find(n => n.id === id)?.value ??
                0),
          )
        );
      }

      return 0;
    }, 0);

  const kcal = useMemo(() => getNutrientValue(ENERGY_ID), [meal]);
  const protein = useMemo(() => getNutrientValue(PROTEIN_ID), [meal]);
  const fat = useMemo(() => getNutrientValue(FATS_ID), [meal]);
  const carb = useMemo(() => getNutrientValue(CARBS_ID), [meal]);

  return <Macros kcal={kcal} protein={protein} fat={fat} carb={carb} />;
};

export default MealMacros;
