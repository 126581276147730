import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { string, object, mixed, InferType, array } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDeepCompareEffect } from "react-use";
import { useQueryParams } from "@hooks/queries/useQueryParams";
import { useEffect } from "react";

import {
  EducationalMaterialsTabs,
  StatusTag,
} from "../EducationalMaterialsView.types";

export const useQuickFiltersForm = () => {
  const { update } = useQueryParams();
  const { get } = useQueryParams("status", "categoryId", "search");

  const { status, categoryId, search } = get<{
    status: string | undefined;
    categoryId: string[] | undefined;
    search: string | undefined;
  }>();

  const resolver = object().shape({
    search: string().nullable(),
    status: string().nullable(),
    tab: mixed<EducationalMaterialsTabs>().oneOf(
      Object.values(EducationalMaterialsTabs),
    ),
    categoryId: array().of(string()),
  });

  type QuickFiltersFormSchema = InferType<typeof resolver>;

  const defaultValues = {
    search: search ?? "",
    status: status ?? StatusTag.ALL,
    tab: EducationalMaterialsTabs.TEXT_MATERIALS,
    categoryId: categoryId ?? [],
  };

  const form = useForm<QuickFiltersFormSchema>({
    defaultValues: defaultValues,
    resolver: yupResolver(resolver),
    mode: "onChange",
  });

  const formData = useWatch({ control: form.control });
  const currentFormStatus = useWatch({ control: form.control, name: "status" });

  useDeepCompareEffect(() => {
    update(formData);
  }, [formData]);

  useEffect(() => {
    const defaultCondition = status && status !== currentFormStatus;

    if (defaultCondition) {
      form.setValue("status", status);
    }
  }, [status]);

  return form;
};

const resolver = object().shape({
  author: array().of(string()).nullable(),
});

export type EducationalMaterialsFormSchema = InferType<typeof resolver>;

export const useEducationalMaterialsFiltersForm = (
  setTotalCount: (count: number) => void,
) => {
  const { update, remove, get } = useQueryParams("author");

  const { author } = get<{ author: string[] | undefined }>();

  const defaultValues = {
    author: author ? (Array.isArray(author) ? author : [author]) : [],
  };

  const form = useForm<EducationalMaterialsFormSchema>({
    defaultValues: defaultValues,

    resolver: yupResolver(resolver),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<EducationalMaterialsFormSchema> = data => {
    update({ author: (data.author ?? []).filter(Boolean) as string[] });

    setTotalCount(data.author?.length ?? 0);
  };

  const onClear = () => {
    form.reset();
    setTotalCount(0);
    remove("author");
  };

  return { form, onSubmit, onClear };
};
