import { useEffect, useMemo } from "react";
import { FieldArrayWithId, useFormContext, useWatch } from "react-hook-form";

import { Plus } from "@assets/icons";
import { MacrosText } from "@components/MacrosText";
import {
  NutrientsByProduct,
  ProductWithNutrients,
} from "@components/PreviewDrawer/Recipe/NutrientsTab/NutrientsByProduct";
import {
  useAppTranslation,
  useControlledFieldArray,
  useMealClientContext,
} from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { scaleNutrients } from "@utils";
import { getTranslation } from "@utils/translations";

import { ProductItemIf, useProductsContext } from "../ProductsContext";
import { RecipeEditInput } from "../useRecipeEditForm";
import { EditProductRow } from "./EditProductRow";
import { MacrosWrapper } from "./EditProductRow.styled";
import { ButtonStyled } from "./ProductContent.styled";
import {
  ServingsText,
  SummaryText,
  SummaryWrapper,
} from "./ProductsContent.styled";
import { ReactSortable } from "react-sortablejs";
import { detectMovedElement } from "@views/dietician/product-views/components/ProductMeasures/utils/move";
import { usePostPatientProductsContextMutation } from "@hooks/queries";

export const ProductsContent = () => {
  const { t } = useAppTranslation();
  const { watch } = useFormContext<RecipeEditInput>();
  const { fields, remove, append, move } = useControlledFieldArray<
    RecipeEditInput,
    "products"
  >({
    watch,
    name: "products",
  });
  const totalServings = useWatch<RecipeEditInput, "totalServings">({
    name: "totalServings",
  });
  const { currentLanguage } = useAppTranslation();
  const { products } = useProductsContext();
  const foodIds = useMemo(
    () =>
      fields
        .map(({ foodId }) => foodId)
        .filter((id): id is number => id !== null),
    [fields.length],
  );

  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();

  const mappedProducts = useMemo(
    () => mapProductsToProductNutrients(fields, currentLanguage, products),
    [fields, currentLanguage, products],
  );

  const calculateSummary = (): Nutrient[] => {
    const nutrientMap = new Map<number, number>();

    fields.forEach(field => {
      if (!field.foodId) return [];

      const product = products.get(field.foodId);

      if (!product) return [];

      const scaledNutrients = scaleNutrients(product.nutrients, field.grams);
      scaledNutrients.forEach(({ id, value }) => {
        if (nutrientMap.has(id)) {
          nutrientMap.set(id, nutrientMap.get(id)! + value);
        } else {
          nutrientMap.set(id, value);
        }
      });
    });

    return Array.from(nutrientMap, ([id, value]) => ({ id, value }));
  };

  const memoizedSummary = useMemo(() => calculateSummary(), [fields, products]);

  const handleAdd = () => {
    const lastProductId = fields[Math.max(fields.length - 1, 0)]?.foodId;
    if (fields.length && !lastProductId) return;
    append({ foodId: null, grams: 0, measureId: null });
  };

  useEffect(() => {
    if (foodIds.length) {
      fetchProducts({ payload: foodIds });
    }
  }, [foodIds.length]);

  return (
    <div className="grid gap-[24px]">
      <div className="flex flex-col gap-[16px]">
        <ReactSortable
          forceFallback={false}
          scrollSensitivity={100}
          animation={200}
          handle=".dragHandle"
          list={fields}
          setList={newOrder => {
            const result = detectMovedElement(
              fields.map(f => f.id.toString()),
              newOrder.map(n => n.id.toString()),
            );

            if (result) move(result.from, result.to);
          }}
          className="grid gap-[8px]"
        >
          {fields.map((field, idx) => (
            <EditProductRow
              key={field.id}
              label={idx === 0}
              formIdx={idx}
              onDelete={remove}
            />
          ))}
        </ReactSortable>
        <ButtonStyled onClick={handleAdd}>
          <Plus />
          {t("diet.recipe_sidepanel.add_product")}
        </ButtonStyled>
        <SummaryWrapper>
          <div>
            <SummaryText>{t("diet.recipe_sidepanel.summary")}</SummaryText>
            <ServingsText>
              &nbsp;({t("common.servings")}: {totalServings})
            </ServingsText>
          </div>
          <MacrosWrapper>
            <MacrosText
              nutrients={memoizedSummary}
              config={{
                brackets: false,
                dashes: false,
                zeros: true,
                precision: 1,
              }}
            />
          </MacrosWrapper>
        </SummaryWrapper>
      </div>
      <NutrientsByProduct products={mappedProducts} />
    </div>
  );
};

const mapProductsToProductNutrients = (
  fields: FieldArrayWithId<RecipeEditInput, "products", "id">[],
  language: string,
  dict: Map<number, ProductItemIf>,
): ProductWithNutrients[] => {
  const productNutrientsArray: ProductWithNutrients[] = [];

  fields.forEach(({ grams, foodId, measureId }) => {
    if (!foodId) return;
    const details = dict.get(foodId);

    const name = getTranslation(details?.translations, language);
    const measure = details?.measures.find(m => m.id === measureId);

    productNutrientsArray.push({
      name,
      nutrients: details?.nutrients ?? [],
      grams,
      id: foodId,
      measure,
    });
  });

  return productNutrientsArray;
};
