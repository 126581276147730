import { useCallback, useMemo, useState } from "react";
import { useFetchClientQuery, useFetchClientsQuery } from "@hooks/queries";
import { CreateClientResponse } from "@client";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { mapClientsToOptions } from "../_utils/scheduleVisitFormActions";
import { UseFormWatch } from "react-hook-form";
import { AddEventModalInput } from "./useScheduleVisitForm";
import { useScheduleVisitModalContext } from "../ScheduleVisitModalContext";

export interface ClientsMapped {
  id: string;
  label: string;
  email: string | null;
}

export const useClients = (formWatch: UseFormWatch<AddEventModalInput>) => {
  const { handleSetClientId, clientId } = useScheduleVisitModalContext();
  const [addClientModal, setAddClientModal] = useState(false);

  const { account } = useFetchDietitianAccountQuery();
  const { clients, isError, isSuccess } = useFetchClientsQuery(undefined, {
    enabled: !!account?.clinic?.id,
    keepPreviousData: true,
  });
  const { client } = useFetchClientQuery(
    clientId ?? formWatch("patientId") ?? 0,
    {
      enabled: !!clientId || !!formWatch("patientId"),
    },
  );

  const openAddClientModal = useCallback(() => setAddClientModal(true), []);
  const closeAddClientModal = useCallback(() => setAddClientModal(false), []);

  const handleCreateClientSuccess = useCallback(
    (data?: CreateClientResponse) => handleSetClientId(data?.id),
    [],
  );

  const clientsMapped = useMemo(
    (): ClientsMapped[] => [
      ...mapClientsToOptions(clients),
      {
        id: client?.id.toString() ?? "",
        label: client ? `${client.firstName} ${client.lastName}` : "",
        email: client?.email ?? "",
      },
    ],
    [clients, client],
  );

  const checkedClientEmail =
    clientsMapped?.find(({ id }) => id === formWatch("patientId")?.toString())
      ?.email ?? "";

  return {
    clientId,
    handleSetClientId,
    addClientModal,
    clients,
    client,
    isError,
    isSuccess,
    openAddClientModal,
    closeAddClientModal,
    handleCreateClientSuccess,
    clientsMapped,
    checkedClientEmail,
  };
};
