import { CollectionSearchPreviewDto } from "@client/collections";
import { compact } from "lodash";

export const getFirstImage = (meal: CollectionSearchPreviewDto | undefined) => {
  const recipesImgs = meal?.recipes.map(r => r.recipe.image?.url) ?? [];
  const productsImgs = meal?.products.map(p => p.product.image?.url) ?? [];

  const totalImages = compact([...recipesImgs, ...productsImgs]);

  return totalImages.length ? totalImages[0] : null;
};
