import { forwardRef, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import axios from "@services/axios";
import { apiUrl } from "@utils/configEnv";

export interface ITinyMceEditorProps {
  value?: string;
  defaultValue?: string;
  onChange?: (val: string) => void;
  placeholder?: string;
  className?: string;
  borderless?: boolean;
  onBlur?: () => void;
  inline?: boolean;
  toolbar?: string;
}

export const TinyMceEditor = forwardRef<Editor, ITinyMceEditorProps>(
  (props, ref) => {
    const {
      defaultValue,
      value,
      onChange,
      onBlur,
      inline,
      placeholder,
      toolbar,
    } = props;

    const editorRef = useRef<TinyMCEEditor | null>(null);

    const defaultToolbar = inline
      ? "bold italic underline strikethrough | link | forecolor backcolor removeformat | numlist bullist"
      : "bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image link";

    return (
      <>
        <Editor
          ref={ref}
          value={value}
          onInit={(evt, editor) => (editorRef.current = editor)}
          onBlur={onBlur}
          onEditorChange={val => !!onChange && onChange(val)}
          initialValue={defaultValue}
          apiKey="cwfmgyo8dknaq7wtyxzeyvkxnh0n1l8ksjv99boxws0hoch9"
          inline={inline}
          init={{
            placeholder,
            plugins: inline
              ? "lists autolink link"
              : "preview importcss searchreplace autolink code visualblocks visualchars fullscreen image link table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help charmap quickbars emoticons",
            editimage_cors_hosts: ["picsum.photos"],
            menubar: inline
              ? ""
              : "file edit view insert format tools table help",
            toolbar: toolbar || defaultToolbar,
            toolbar_sticky: true,
            image_advtab: true,
            font_family_formats:
              "Roboto=Roboto; Figtree=Figtree; Lato=Lato; Montserrat=Montserrat; Source Sans Pro=Source Sans Pro; Raleway=Raleway; Oswald=Oswald; Noto Sans=Noto Sans; Merriweather=Merriweather; PT Sans=PT Sans",
            link_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_class_list: [
              { title: "None", value: "" },
              { title: "Some class", value: "class-name" },
            ],
            importcss_append: true,
            template_cdate_format:
              "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
            template_mdate_format:
              "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
            width: 700,
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar: inline
              ? ""
              : "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            noneditable_class: "mceNonEditable",
            toolbar_mode: inline ? "scrolling" : "sliding",
            contextmenu: inline ? "" : "link image table",
            skin: "oxide",
            content_css: "default",
            content_style: inline
              ? undefined
              : '@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");\n' +
                '@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");' +
                "body { font-family: Figtree; font-size:12pt;}",
            images_upload_handler: imageUploadHandler,
            images_file_types: "jpeg,jpg,png,webp",
          }}
        />
      </>
    );
  },
);

interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

declare type ProgressFn = (percent: number) => void;

const supportedContentTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

async function imageUploadHandler(
  blobInfo: BlobInfo,
  progress: ProgressFn,
): Promise<string> {
  if (!supportedContentTypes.includes(blobInfo.blob().type)) {
    throw { message: "Image format not supported", remove: true };
  }

  if (blobInfo.blob().size > 2000000) {
    throw { message: "Image is too big. Image size exceeds 2MB", remove: true };
  }

  const formData = new FormData();
  formData.append("file", blobInfo.blob(), blobInfo.name());

  try {
    const rsp = await axios.post(
      `${apiUrl()}/dietitian/program-section-templates/media`,
      formData,
      {
        onUploadProgress: progressEvent =>
          progress(progressEvent.progress ?? 0),
      },
    );
    return rsp.data.data.url;
  } catch (e) {
    throw { message: e, remove: true };
  }
}
