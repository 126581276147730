import { toast } from "react-toastify";

import {
  camelCase,
  isArray,
  isPlainObject,
  mapKeys,
  mapValues,
  snakeCase,
} from "lodash";
import axios from "@services/axios";
import { AxiosResponse, ResponseType } from "axios";

import { APIMethods } from "@typeDefinitions";
import { getCSRFToken } from "@utils/csrf";
import { VersionToastInfo } from "@components/VersionToastInfo";
import { apiUrl } from "@utils/configEnv";

/**
 * Maps nested keys in an object or array of objects
 * @param map Map function from lodash
 * @returns Mapped object
 */
const deepMapKeys =
  (map: any) =>
  (
    object: Record<string, any>,
    fn: (_: any, key: string) => string,
  ): Record<string, unknown> => {
    return map(
      mapValues(object, v => {
        return isPlainObject(v)
          ? deepMapKeys(map)(v, fn)
          : isArray(v)
          ? v.map(x => (isPlainObject(x) ? deepMapKeys(map)(x, fn) : x))
          : v;
      }),
      fn,
    );
  };

/**
 * Maps snake_case API response to camelCase object
 * @returns Mapped to camelCase response object
 * @param data
 */
export const mapKeysToCamelCase = (
  data: Record<string, unknown> | Array<Record<string, unknown>>,
): Record<string, unknown> | Array<Record<string, unknown>> => {
  const mapFn = (_: any, key: string) => camelCase(key);
  return isArray(data)
    ? data.map(obj => deepMapKeys(mapKeys)(obj, mapFn))
    : deepMapKeys(mapKeys)(data, mapFn);
};

/**
 * Maps camelCase API request to snake_case object
 * @returns Mapped to snake_case request object
 * @param data
 */
export const mapKeysToSnakeCase = (
  data: Record<string, unknown> | Array<Record<string, unknown>>,
): Record<string, unknown> | Array<Record<string, unknown>> => {
  const mapFn = (_: any, key: string) => snakeCase(key);
  return isArray(data)
    ? data.map(obj => deepMapKeys(mapKeys)(obj, mapFn))
    : deepMapKeys(mapKeys)(data, mapFn);
};

export async function fetchDataRaw(
  url: string,
  method: APIMethods = APIMethods.GET,
  data: Record<string, any> = {},
  queryParams: Record<string, any> = {},
  headers: Record<string, string> = { "Content-Type": "application/json" },
  signal?: AbortSignal,
  envVar: string = import.meta.env.VITE_APP_API_URL,
  responseType?: ResponseType,
  withCredentials?: boolean,
  skipCSRF = false,
): Promise<AxiosResponse<any>> {
  try {
    return await axios({
      method,
      url: `${envVar !== "" ? apiUrl() : ""}${url}`,
      data: data instanceof FormData ? data : mapKeysToSnakeCase(data),
      params:
        queryParams instanceof URLSearchParams
          ? queryParams
          : mapKeysToSnakeCase(queryParams),
      headers: { ...headers, "Alloweat-Date": new Date().toUTCString() },
      signal,
      responseType,
      withCredentials,
    });
  } catch (e: any) {
    if (e?.response?.status === 401) window.location.replace("/auth/login");
    else if (e?.response?.status === 419 && !skipCSRF) {
      await getCSRFToken();
      return await fetchDataRaw(
        url,
        method,
        data,
        queryParams,
        headers,
        signal,
        envVar,
        responseType,
        withCredentials,
        true,
      );
    }
    throw e;
  }
}

export async function fetchData<T = any>(
  url: string,
  method: APIMethods = APIMethods.GET,
  data: Record<string, any> = {},
  queryParams: Record<string, any> = {},
  headers: Record<string, string> = { "Content-Type": "application/json" },
  _?: undefined,
  signal?: AbortSignal,
  envVar: string = import.meta.env.VITE_APP_API_URL,
  responseType?: ResponseType,
  withCredentials?: boolean,
  mapToCamelCase = true,
): Promise<T> {
  const response = await fetchDataRaw(
    url,
    method,
    data,
    queryParams,
    headers,
    signal,
    envVar,
    responseType,
    withCredentials,
  );

  if (
    response.headers["front-version"] &&
    APP_VERSION !== response.headers["front-version"] &&
    !["dev", "local"].includes(import.meta.env.VITE_APP_ENVIRONMENT)
  ) {
    toast(<VersionToastInfo />, {
      autoClose: false,
      position: "top-center",
      toastId: "update",
    });
  }

  return mapToCamelCase ? mapKeysToCamelCase(response.data) : response.data;
}

export async function fetchData2(
  url: string,
  method: APIMethods = APIMethods.GET,
  data: Record<string, any> = {},
  queryParams: Record<string, any> = {},
  headers: Record<string, string> = { "Content-Type": "application/json" },
  signal?: AbortSignal,
  envVar: string = import.meta.env.VITE_APP_API_URL,
  responseType?: ResponseType,
  withCredentials?: boolean,
): Promise<AxiosResponse<any, any>> {
  const response = await fetchDataRaw(
    url,
    method,
    data,
    queryParams,
    headers,
    signal,
    envVar,
    responseType,
    withCredentials,
  );

  if (
    response.headers["front-version"] &&
    APP_VERSION !== response.headers["front-version"] &&
    !["dev", "local"].includes(import.meta.env.VITE_APP_ENVIRONMENT)
  ) {
    toast(<VersionToastInfo />, {
      autoClose: false,
      position: "top-center",
      toastId: "update",
    });
  }

  return response;
}

/**
 * Fetches mock data
 * @param result Result data that we want to mock
 * @param ms Response time in miliseconds
 * @returns
 */
export const fetchDataMock: (result: any, ms?: number) => Promise<any> = async (
  result,
  ms,
) => new Promise(res => setTimeout(() => res(result), ms));
