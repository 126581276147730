import { useAppParams } from "@hooks";
import { RecipeEditContent } from "../RecipeEditContent";
import { StyledDrawer } from "./RecipeEditDrawer.styled";

interface RecipeEditDrawerProps {
  open: boolean;
  onClose: () => void;
  mealId: string;
  recipeIds: RecipeId | null;
  controlledDietId?: string;
  onSuccess?: (id: number) => void;
}

export interface RecipeId {
  dietRecipeId: string;
  recipeId: string;
  selectedServings?: number;
}

export const RecipeEditDrawer = ({
  open,
  onClose,
  mealId,
  recipeIds,
  controlledDietId,
  onSuccess,
}: RecipeEditDrawerProps) => {
  const { dietId } = useAppParams();
  const ensuredDietId = controlledDietId ?? dietId;

  return (
    <StyledDrawer
      open={open}
      variant="persistent"
      anchor="right"
      onClose={onClose}
    >
      {open && (
        <RecipeEditContent
          dietId={ensuredDietId}
          mealId={mealId}
          recipeIds={recipeIds}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </StyledDrawer>
  );
};
