import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import dayjs from "dayjs";

import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppParams, useAppTranslation } from "@hooks";
import {
  fetchCreatorDietMealNutrientsQueryKey,
  fetchCreatorDietMealsItemsQuery,
  fetchCreatorDietMealsQueryKey,
  fetchCreatorDietNutrientsQueryKey,
  fetchCreatorDietQueryKey,
  useFetchCreatorDietMealsItemsQuery,
} from "@hooks/queries/diets/creator";
import {
  useDeleteDietMealProductMutation,
  useDeleteDietMealRecipeMutation,
} from "@views/dietician/DietCreator/hooks";

interface DeleteMealModalItemsByOneProps {
  open: boolean;
  onClose: () => void;
  mealId: number | string;
  name: string;
  hour?: string;
}

export const DeleteMealModalItemsByOne = ({
  open,
  onClose,
  mealId,
  name,
  hour,
}: DeleteMealModalItemsByOneProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const { data } = useFetchCreatorDietMealsItemsQuery(dietId, `${mealId}`, {
    enabled: open,
  });
  const { mutateAsync: deleteProductAsync } = useDeleteDietMealProductMutation({
    onSuccess: () => null,
  });
  const { mutateAsync: deleteRecipeAsync } = useDeleteDietMealRecipeMutation({
    onSuccess: () => null,
  });

  const queryClient = useQueryClient();
  const handleOnSuccess = () => {
    queryClient.invalidateQueries([fetchCreatorDietMealsItemsQuery, dietId]);
    queryClient.invalidateQueries([fetchCreatorDietMealsQueryKey, dietId]);
    queryClient.invalidateQueries([fetchCreatorDietQueryKey, dietId]);
    queryClient.invalidateQueries([fetchCreatorDietNutrientsQueryKey, dietId]);
    queryClient.invalidateQueries([
      fetchCreatorDietMealNutrientsQueryKey,
      dietId,
    ]);
  };

  const handleDelete = async () => {
    if (!data) return;
    const { products, recipes } = data;

    try {
      setIsDeleting(true);
      for (const { id } of products) {
        await deleteProductAsync({
          dietId,
          mealId: mealId.toString(),
          productId: id.toString(),
        });
      }

      for (const { id } of recipes) {
        await deleteRecipeAsync({
          dietId,
          mealId: mealId.toString(),
          recipeId: id.toString(),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
      handleOnSuccess();
      onClose();
    }
  };

  const isValidHour =
    hour && dayjs(hour, "HH:mm").format("HH:mm") !== "Invalid Date";

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
      color="error"
      title={t("diet.delete_all_modal.title")}
      confirmText={t("diet.delete_all_modal.confirm")}
      loading={isDeleting}
    >
      <div className="max-w-[478px]">
        <p>
          {t("diet.delete_all_modal.question")}:{" "}
          <strong>
            {name}
            {isValidHour ? `, ${dayjs(hour, "HH:mm").format("HH:mm")}` : ""}
          </strong>
          ?
        </p>
      </div>
    </ModalWrapper>
  );
};
