import { useLocalStorage } from "react-use";

import { Link, useMediaQuery, useTheme } from "@mui/material";

import CloseIcon from "@assets/icons_new/closeClean.svg?react";
import PlayIcon from "@assets/icons_new/playTriangle.svg?react";
import OpenInWindowIcon from "@assets/icons_new/squareWithArrow.svg?react";
import { useAppTranslation } from "@hooks";

import {
  ButtonStyled,
  IconButtonAbsolute,
  ImgAbsolute,
  ImgFlipped,
  PacmanBackground,
  PurpleCard,
  Text,
  TitleYellow,
} from "./CloseableBanner.styled";

export const CloseableBannerLocalStorageKey = "showCloseableBanner";

export const CloseableBanner = () => {
  const { t } = useAppTranslation();
  const [open, setOpen] = useLocalStorage(CloseableBannerLocalStorageKey);
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);

  if (!open) return null;

  return (
    <PurpleCard>
      <IconButtonAbsolute size="small" onClick={() => setOpen(false)}>
        <CloseIcon fontSize={16} />
      </IconButtonAbsolute>
      <div className="relative z-[1]">
        <TitleYellow>{t("empty_states.launch_banner.discover")}</TitleYellow>
        {!downMedium && <ImgAbsolute src="/static/launchBanner/splash.svg" />}
      </div>
      <Text>{t("empty_states.launch_banner.desc")}</Text>
      <div className="flex gap-[16px]">
        <ButtonStyled
          variant="contained"
          LinkComponent={Link}
          href={VIDEO_LINK}
          target="_blank"
        >
          <PlayIcon />
          {t("empty_states.launch_banner.watch")}
        </ButtonStyled>
        <ButtonStyled
          variant="outlined"
          LinkComponent={Link}
          href={ARTICLE_LINK}
          target="_blank"
        >
          {t("empty_states.launch_banner.read")}
          <OpenInWindowIcon fontSize={24} />
        </ButtonStyled>
      </div>

      <PacmanBackground>
        <img src="/static/launchBanner/pacmanPattern.png" />
        {!downMedium && (
          <ImgFlipped src="/static/launchBanner/pacmanPattern.png" />
        )}
      </PacmanBackground>
    </PurpleCard>
  );
};

export const VIDEO_LINK = "https://youtu.be/srtaOFCRZ5E";
export const ARTICLE_LINK =
  "https://alloweat.com/zrobilismy-to-alloweat-2-0-juz-jest";
