import { ChangeEvent, MouseEventHandler, useRef, useState } from "react";

import { COLORS } from "@utils/constants";
import { Spinner } from "@components/Spinner";
import { MountainsWithSun } from "@assets/icons";
import { AvatarMui } from "./Avatar.styled";
import CameraIcon from "@assets/icons_new/camera.svg?react";
import PenIcon from "@assets/icons_new/edit.svg?react";
import TrashIconIcon from "@assets/icons_new/trash.svg?react";
import SettingsIcon from "@assets/icons_new/settings.svg?react";
import { CircularProgress, IconButton, styled } from "@mui/material";
import { DropDownMenu, MenuItemIf } from "@components/DropDownMenu";
import { useAppTranslation } from "@hooks";

export type AvatarSize = "extra-small" | "small" | "medium" | "large" | "huge";

export interface AvatarProps {
  fullName: string;
  image?: string | null;
  size?: AvatarSize;
  color?: string;
  isEditable?: boolean;
  isUploading?: boolean;
  onFileUpload?: (event: ChangeEvent<HTMLInputElement>) => Promise<void> | void;
  status?: boolean;
  className?: string;
  onDelete?: () => void;
}

export const Avatar = (props: AvatarProps) => {
  const {
    image,
    size = "medium",
    color = COLORS[0],
    fullName = "Anna Kowalska",
    isEditable = false,
    isUploading = false,
    onFileUpload,
    status,
    className,
    onDelete,
  } = props;

  const { t } = useAppTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleUploadFileButtonClick: MouseEventHandler<
    HTMLButtonElement
  > = e => {
    if (image) return setAnchorEl(e.currentTarget);
    if (fileInputRef.current) return fileInputRef.current.click();
  };

  function stringAvatar(name: string) {
    return {
      children: `${name
        .split(" ")
        .filter(w => w)
        .map(w => w[0].toUpperCase())
        .slice(0, 2)
        .join("")}`,
    };
  }
  const Icon = image ? SettingsIcon : CameraIcon;

  const deleteOption: MenuItemIf = {
    text: t("common.delete"),
    onClick: () => onDelete?.(),
    Icon: () => <TrashIconIcon fontSize={24} color="#727272" />,
  };

  const options: MenuItemIf[] = [
    {
      text: t("common.edit"),
      onClick: () => fileInputRef.current && fileInputRef.current.click(),
      Icon: () => <PenIcon fontSize={24} color="#727272" />,
    },
    ...(onDelete ? [deleteOption] : []),
  ];

  return (
    <div className="relative">
      <AvatarMui
        size={size}
        status={status}
        className={className}
        {...stringAvatar(fullName)}
        src={image ?? undefined}
      />
      {isEditable && (
        <StyledIconButton
          disabled={isUploading}
          onClick={handleUploadFileButtonClick}
          size="small"
        >
          {isUploading ? (
            <CircularProgress size={24} />
          ) : (
            <Icon fontSize={24} />
          )}
        </StyledIconButton>
      )}
      <input
        type="file"
        accept="image/png,image/jpg,image/jpeg,image/webp"
        ref={fileInputRef}
        disabled={isUploading}
        onChange={onFileUpload}
        style={{ display: "none" }}
      />
      <DropDownMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        options={options}
      />
    </div>
  );
};

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  padding: unset;
  height: 40px;
  width: 40px;
  right: -8px;
  bottom: 0;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.main};

  &.Mui-disabled {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
`;
