import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useAppEnvironment } from "@hooks";

const useNewMobileAppFeatureEnabled = () => {
  // const { isProd } = useAppEnvironment();
  // const { account } = useFetchDietitianAccountQuery();

  return true;

  // if (!isProd) {
  //   return true;
  // }
  //
  // if (!account) {
  //   return false;
  // }
  //
  // return account.clinic?.id === 2674;
};

export default useNewMobileAppFeatureEnabled;
