import { FormProvider } from "react-hook-form";
import { useDebounce } from "react-use";
import { Button } from "@mui/material";

import { useAppParams, useAppTranslation, useModalState } from "@hooks";

import {
  CaloricNeedsFormWrapper,
  StyledAlert,
} from "./CaloricNeedsForm.styled";
import { SpmFormInput } from "./components/SpmFormInput";
import { PalFormInput } from "./components/PalFormInput";
import { CpmFormInput } from "./components/CpmFormInput";
import { usePatientForBalance } from "./components/usePatientForBalance";
import {
  CaloricNeedsFormInputs,
  useCaloricNeedsForm,
} from "./useCaloricNeedsForm";
import { ThemeProviderWrapperNew } from "themeNew";
import { Pen } from "@assets/icons";
import { EditBalanceClientProfileModal } from "@components/EditBalanceClientProfileModal";

interface CaloricNeedsFormProps {
  defaultValues?: CaloricNeedsFormInputs;
  onChange: (values: CaloricNeedsFormInputs) => void;
}

export const CaloricNeedsForm = ({
  onChange,
  defaultValues,
}: CaloricNeedsFormProps) => {
  const { t } = useAppTranslation();
  const { patientDataFilled } = usePatientForBalance();
  const form = useCaloricNeedsForm(defaultValues);
  const { watch } = form;
  const [modalOpened, onModalOpen, onModalClose] = useModalState();
  const { patientId } = useAppParams();

  useDebounce(() => onChange(watch()), 1000, [JSON.stringify(watch())]);

  return (
    <>
      <FormProvider {...form}>
        <CaloricNeedsFormWrapper>
          <ThemeProviderWrapperNew>
            {!patientDataFilled && (
              <StyledAlert
                variant="filled"
                action={
                  <Button
                    color="inherit"
                    onClick={onModalOpen}
                    startIcon={<Pen size="w-4 h-4" />}
                  >
                    {t("balance.config_client")}
                  </Button>
                }
              >
                {t("balance.no_data")}
              </StyledAlert>
            )}
          </ThemeProviderWrapperNew>
          <SpmFormInput />
          <PalFormInput />
          <CpmFormInput />
        </CaloricNeedsFormWrapper>
      </FormProvider>
      <ThemeProviderWrapperNew>
        <EditBalanceClientProfileModal
          open={modalOpened}
          onClose={onModalClose}
          id={patientId}
        />
      </ThemeProviderWrapperNew>
    </>
  );
};
