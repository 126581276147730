import {
  Divider,
  TabHeadLayout,
  TabLayout,
  TabTitle,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation } from "@hooks";
import ProfileSection from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/ProfileSection";
import NutrientsSection from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/NutrientsSection";
import IrrigationSection from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/IrrigationSection";
import { Box, Stack, Tooltip } from "@mui/material";
import { InfoIcon } from "@icons/index";
import { SHOW_OLD_PROFILE_FLAG } from "@layouts/Dashboard/ClientLayout";

const ProfileTab = () => {
  const { t } = useAppTranslation();
  return (
    <TabLayout>
      <TabHeadLayout>
        <Stack direction="row" gap="0px" alignItems="center">
          <TabTitle>
            {t("patient.health_and_goal.dietary_profile_tab.tab_title")}
          </TabTitle>
          <Tooltip
            title={t(
              "patient.health_and_goal.dietary_profile_tab.tab_title_info",
            )}
            arrow
            placement="top"
          >
            <Box>
              <InfoIcon color="#727272" />
            </Box>
          </Tooltip>
        </Stack>
      </TabHeadLayout>
      <ProfileSection />
      <Divider />
      <NutrientsSection />
      {SHOW_OLD_PROFILE_FLAG && (
        <>
          <Divider />
          <IrrigationSection />
        </>
      )}
    </TabLayout>
  );
};

export default ProfileTab;
