import { Button, DialogContent } from "@mui/material";
import { DateRangePicker } from "@components/DateRangePicker";
import { addDays } from "date-fns";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import { CalendarMonitoring } from "@assets/icons/Monitoring";
import { CustomButton } from "@views/dietician/PatientMonitoring2/components/CalendarType/CalendarType.styled";
import {
  Type,
  useMonitoring,
} from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useAppTranslation } from "@hooks";
import { useMemo, useState } from "react";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import dayjs from "dayjs";

const CalendarTypeCustomButtom = () => {
  const { type, selectedDays } = useMonitoring();
  const { t } = useAppTranslation();
  const [opened, setOpened] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleCustomOnClick = (variant: Type) => {
    setOpened(true);
  };

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const maxDays = useMemo(() => {
    return addDays(new Date(startDate ?? new Date()), 31);
  }, [startDate]);

  const customButtons = useMemo(() => {
    return [7, 14, 21, 28].map(item => ({
      id: item,
      label: `${item} ${t("monitoring2.calendar.date_choice_button")}`,
      selected: false,
      onClick: () => {
        setStartDate(addDays(new Date(), item * -1));
        setEndDate(new Date());
      },
    }));
  }, [t]);

  const handelButtonSaveOnClick = () => {
    setOpened(false);
    type.setType("custom");
    selectedDays.setSelectDays({
      from: dayjs(startDate).startOf("day"),
      to: dayjs(endDate).endOf("day"),
    });
  };

  return (
    <>
      <CustomButton
        variant={type.type === "custom" ? "contained" : undefined}
        onClick={() => handleCustomOnClick("custom")}
      >
        <CalendarMonitoring />
      </CustomButton>
      <Dialog
        open={opened}
        onClose={() => setOpened(false)}
        onAnimationEnd={() => {
          setStartDate(new Date());
          setEndDate(null);
        }}
      >
        <DialogContent>
          <DateRangePicker
            onRangeChange={handleRangeChange}
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDays}
            inline={true}
            showPreviousMonths
            customButtons={customButtons}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpened(false)}
          >
            {t("common.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={!startDate || !endDate}
            variant="contained"
            onClick={handelButtonSaveOnClick}
          >
            {t("common.apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CalendarTypeCustomButtom;
