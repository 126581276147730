import PencilEmptyState from "@assets/img/PencilEmptyState.png";
import { Typography } from "@mui/material";
import type { FC } from "react";
import { useAppTranslation } from "@hooks";
import { EducationalMaterialsEmptyStatesDropdown } from "./EducationalMaterialsEmptyStatesDropdown";
import { EducationalMaterialsEmptyStateWrapper } from "../EducationalMaterialsView.styled";

interface EducationalMaterialsEmptyStateProps {
  category?: string;
  isGeneral?: boolean;
}

export const EducationalMaterialEmptyState: FC<
  EducationalMaterialsEmptyStateProps
> = ({ category, isGeneral }) => {
  const { t } = useAppTranslation();

  return (
    <EducationalMaterialsEmptyStateWrapper>
      <img src={PencilEmptyState} alt="pencil empty state" />
      <Typography fontSize="16px" fontWeight={600}>
        {isGeneral
          ? t("educational_materials.noMaterialsGeneralDescription")
          : t("educational_materials.noMaterials")}
      </Typography>
      <Typography
        fontSize="14px"
        fontWeight={400}
        color={({ colors }) => colors.neutral.dark[800]}
        className="line-clamp-2"
      >
        {isGeneral
          ? t("educational_materials.addFile")
          : t("educational_materials.noSectionMaterialsDescription", {
              variable: category,
            })}
      </Typography>
      <EducationalMaterialsEmptyStatesDropdown />
    </EducationalMaterialsEmptyStateWrapper>
  );
};
