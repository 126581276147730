import {
  CloudAdd,
  DuplicateFiles,
  Eye,
  ThreeVerticalPoints,
} from "@assets/icons";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { CustomMenuItem } from "@views/dietician/PatientPrograms/PatientProgramsGrid.styled";
import { Link } from "react-router-dom";
import Divider from "@components/Alloweat/Divider";
import { SaveToBaseModal } from "@views/dietician/Program/components/SaveToBaseModal";
import { ProgramCopyModal } from "@views/dietician/PatientProgramsLegacy/components/ProgramCopyModal";
import { useAppTranslation } from "@hooks";
import DeleteAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/DeleteAction";

type MenuActionProps = {
  patientId: number;
  program: {
    id: number;
    name: string;
  };
  isClientActive: boolean;
};

const MenuAction = ({
  patientId,
  program,
  isClientActive,
}: MenuActionProps) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openSaveToBaseModal, setOpenSaveToBaseModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <ThreeVerticalPoints />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Tooltip
          arrow
          title={t("common.access_module_tooltip")}
          disableHoverListener={isClientActive}
        >
          <div>
            <CustomMenuItem
              component={Link}
              to={`/patients/${patientId}/nutritional-programs/${program.id}`}
              disabled={!isClientActive}
            >
              <ListItemIcon>
                <Eye fill="#727272" style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText>
                {t("patient.program_preview.menu_actions.open")}
              </ListItemText>
            </CustomMenuItem>
          </div>
        </Tooltip>

        <CustomMenuItem
          onClick={() => {
            setOpenSaveToBaseModal(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <CloudAdd sx={{ fontSize: "24px", color: "#fff" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.program_preview.menu_actions.add_to_db")}
          </ListItemText>
        </CustomMenuItem>
        <Divider />
        <CustomMenuItem
          onClick={() => {
            setOpenCopyModal(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <DuplicateFiles fill="#727272" style={{ width: "24px" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.program_preview.menu_actions.copy")}
          </ListItemText>
        </CustomMenuItem>
        <DeleteAction
          onSuccess={handleClose}
          patientId={patientId}
          programId={program.id}
          disabled={!isClientActive}
        />
      </Menu>
      {openSaveToBaseModal && (
        <SaveToBaseModal
          open={openSaveToBaseModal}
          onClose={() => setOpenSaveToBaseModal(false)}
          defaultName={program.name}
          programId={program.id}
        />
      )}
      {openCopyModal && (
        <ProgramCopyModal
          open={openCopyModal}
          onClose={() => setOpenCopyModal(false)}
          selectedProgramId={program.id}
          patientId={patientId}
        />
      )}
    </>
  );
};

export default MenuAction;
