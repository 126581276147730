import { ItemName } from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import { Chip, Stack } from "@mui/material";
import { LikeMonitoring } from "@assets/icons/Monitoring";
import LikeCircle from "@views/dietician/PatientMonitoring2/components/LikeCircle";

type MealItemNameProps = {
  name: string;
  rating: number | null;
  onClick?: () => void;
};
const MealItemName = ({ name, rating, onClick }: MealItemNameProps) => {
  return (
    <Stack
      direction="row"
      spacing="8px"
      alignItems="center"
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <ItemName>{name}</ItemName>
      {rating !== null && <LikeCircle liked={!!rating} circle={{ size: 28 }} />}
    </Stack>
  );
};

export default MealItemName;
