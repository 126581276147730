import { useAppTranslation } from "@hooks";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { Box, Checkbox, FormControlLabel, Tab } from "@mui/material";
import { TabList } from "@components/Alloweat/Tabs/TabList";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import { TabContext } from "@mui/lab";
import { useMemo, useState } from "react";
import { Controller, FieldPath, useFormContext } from "react-hook-form";
import { FormProps } from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsForm";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import {
  CategoryNutrientsResponse,
  NutrientResponse,
} from "@typeDefinitions/responses/dictionaries";

const ImportantNutrientsField = () => {
  const { nutrients: visibleNutrients } = useVisibleNutrients();

  const form = useFormContext<FormProps>();
  const important = form.watch("important");

  const { data } = useCategoriesNutrientsFetchQuery({ staleTime: Infinity });

  const categories = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter(category => ![1, 2].includes(category.id));
  }, [data]);

  const favoriteNutrients = useMemo(() => {
    if (!visibleNutrients || !data) {
      return [];
    }

    const nutrients = data.map(category => category.nutrients).flat();

    return visibleNutrients
      .map(id => nutrients.find(n => n.id === parseInt(id)))
      .filter(i => i !== undefined) as NutrientResponse[];
  }, [visibleNutrients, data]);

  if (!important || categories.length === 0) {
    return null;
  }

  return (
    <ImportantNutrientsTabs
      favoriteNutrients={favoriteNutrients}
      categories={categories}
      visibleNutrients={visibleNutrients}
    />
  );
};

type ImportantNutrientsTabsProps = {
  favoriteNutrients: NutrientResponse[];
  categories: CategoryNutrientsResponse[];
  visibleNutrients: string[];
};
const ImportantNutrientsTabs = ({
  favoriteNutrients,
  categories,
  visibleNutrients,
}: ImportantNutrientsTabsProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const [selectedCategory, setSelectedCategory] = useState<string>(() => {
    if (visibleNutrients.length) {
      return "0";
    }

    return categories[0].id.toString();
  });

  return (
    <TabContext value={selectedCategory}>
      <TabList
        onChange={(_, newValue) => setSelectedCategory(newValue)}
        scrollButtons={"auto"}
        variant="scrollable"
      >
        {favoriteNutrients.length > 0 && (
          <Tab label={t("common.favourite")} value={"0"} />
        )}

        {categories.map(category => {
          return (
            <Tab
              key={category.id.toString()}
              label={isPolishChosen ? category.namePl : category.nameEn}
              value={category.id.toString()}
            />
          );
        })}
      </TabList>
      {favoriteNutrients.length > 0 && (
        <TabPanel
          value={"0"}
          sx={{ height: "250px", overflowY: "auto" }}
          className="scrollbar-thin"
        >
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            columnGap="24px"
            rowGap="16px"
          >
            {favoriteNutrients.map((nutrient, index) => (
              <CheckboxController
                key={nutrient.id}
                name={`importantNutrientsId.${nutrient.id}`}
                label={
                  isPolishChosen
                    ? nutrient.descriptionPl
                    : nutrient.descriptionEn
                }
              />
            ))}
          </Box>
        </TabPanel>
      )}
      {categories.map(category => {
        return (
          <TabPanel
            key={category.id}
            value={category.id.toString()}
            sx={{ height: "250px", overflowY: "auto" }}
            className="scrollbar-thin"
          >
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              columnGap="24px"
              rowGap="16px"
            >
              {category.nutrients.map((nutrient, index) => (
                <CheckboxController
                  key={nutrient.id}
                  name={`importantNutrientsId.${nutrient.id}`}
                  label={
                    isPolishChosen
                      ? nutrient.descriptionPl
                      : nutrient.descriptionEn
                  }
                />
              ))}
            </Box>
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

type CheckboxControllerProps = {
  name: FieldPath<FormProps>;
  label: string;
};
const CheckboxController = ({ name, label }: CheckboxControllerProps) => {
  const { control, getValues } = useFormContext<FormProps>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          sx={{ margin: "unset" }}
          control={
            <Checkbox
              checked={!!value}
              onChange={(event, checked) => {
                const length = Object.values(
                  getValues("importantNutrientsId"),
                ).filter(item => item).length;
                if (length > 9 && checked) {
                  return;
                }
                onChange(event, checked);
              }}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default ImportantNutrientsField;
