import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  styled,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import CalendarIcon from "@assets/icons_new/calendar.svg?react";

const EXPIRATION_DATE = "2025-02-12T18:30:00.000000Z";

export const WebinarCard = () => {
  const isExpired = dayjs().isAfter(EXPIRATION_DATE);
  const { palette } = useTheme();
  const dayjsDate = dayjs(EXPIRATION_DATE);
  const dateString = `${dayjsDate.format("L")} (${dayjsDate.format(
    "dddd",
  )}) | godz. 19:00`;

  if (isExpired) return null;

  return (
    <Container>
      <TopSection>
        <Stack gap="8px">
          <Stack gap="4px" alignItems="start">
            <Subtitle>Webinar</Subtitle>
            <Title>
              Jak dobrać idealne rozwiązanie dietetyczne dla klienta –{" "}
              <TitleGradient>w mniej niż 5 minut?</TitleGradient>
            </Title>
          </Stack>

          <Stack direction="row" gap="8px" alignItems="center">
            <CalendarIcon fontSize={24} color={palette.primary.main} />
            <DateText>{dateString}</DateText>
          </Stack>
        </Stack>

        <Hero src="/static/webinarModal/doublePhoto.png" />
      </TopSection>

      <DividerStyled />

      <Box>
        <ButtonStyled
          LinkComponent={Link}
          href="https://alloweat.com/webinar-12-02-2025-jak-dobrac-idealne-rozwiazanie-dietetyczne-dla-klienta/"
          variant="contained"
          target="_blank"
        >
          Zapisz się
        </ButtonStyled>
      </Box>
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 612px;
  height: 210px;
  background: #fbfafc;
  box-shadow: 0px 0px 10px 0px #0000000d;
  backdrop-filter: blur(24px);
  border-radius: 12px;
  padding: 16px;
`;

const TopSection = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const Subtitle = styled("span")`
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.secondary.main};
`;
const Hero = styled("img")`
  width: 170px;
  height: 100px;
`;

const Title = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: #2d1d51;
  max-width: 360px;
`;

const TitleGradient = styled(Title)`
  background-image: linear-gradient(91.08deg, #485ad0 1.74%, #c977f0 107.23%);
  color: transparent;
  background-clip: text;
`;
const DateText = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #2d1d51;
`;

const DividerStyled = styled(Divider)`
  border-color: #f5f1fe;
`;

const ButtonStyled = styled(Button)`
  height: 40px;
` as typeof Button;
