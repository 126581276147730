import { Stack } from "@mui/material";
import SymptomsDayDivider from "@views/dietician/PatientMonitoring2/components/Symptoms/Meals/SymptomsDayDivider";
import { PatientMealsMonitoringResource } from "@client/resources/PatientMealsMonitoringResource";
import SymptomsMeal from "@views/dietician/PatientMonitoring2/components/Symptoms/Meals/SymptomsMeal";
import { useMemo } from "react";
import dayjs from "dayjs";

type SymptomsDayType = {
  day: PatientMealsMonitoringResource;
};

const SymptomsDay = ({ day }: SymptomsDayType) => {
  const label = useMemo(() => {
    const date = dayjs(day.date);
    if (date.isToday()) {
      return "Dziś";
    }
    if (date.isYesterday()) {
      return "Wczoraj";
    }
    return date.format("DD.MM.YYYY");
  }, [day]);

  return (
    <>
      <SymptomsDayDivider title={label} textAlign={"center"} />
      <Stack useFlexGap direction="column" gap="16px">
        {day.meals.map(meal => (
          <SymptomsMeal key={meal.id} meal={meal} />
        ))}
      </Stack>
    </>
  );
};

export default SymptomsDay;
