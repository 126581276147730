import { Button, Divider, IconButton, styled } from "@mui/material";

export const NameWrapper = styled("span")`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
`;
export const HeaderStyled = styled("div")`
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: 600;
  box-shadow: 0px 0px 2px 0px #00000014;
  top: 56px;
  height: 4rem;
  padding: 0.75rem 1.5rem;
`;
export const DropMenuContentWrapper = styled("div")`
  display: grid;
  padding: 0.25rem 0;
  width: 12.75rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  border-radius: 0.5rem;
`;
export const StyledLImg = styled("img")`
  height: 1.875rem;
  width: 9.009rem;
`;
export const HeaderButton = styled(Button)`
  justify-content: start;
  padding: 0 1rem;
  color: inherit;
  width: 100%;
  & span.MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0.75rem;
  }
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  justify-self: center;
  margin: 0.75rem 0;
  border-color: ${({ theme }) => theme.colors.neutral.light[200]};
`;

export const StyledHeaderButton = styled(IconButton)`
  padding: 0.45rem;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const TourButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  padding: unset;
  height: 24px;
  width: 24px;
`;
