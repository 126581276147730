import { Dispatch, SetStateAction } from "react";

import { round } from "lodash";

import { ProductTabPanels } from "@components/PreviewDrawer/Product/ProductTabPanels";

import { useFetchCollectionSearchPreviewProductQuery } from "@hooks/queries/collections";
import { CircularProgress, Stack } from "@mui/material";
import { scaleNutrients } from "@utils";
interface Measure {
  id: number;
  grams: number;
}
interface ProductTabPanelsProxyProps {
  id: number;
  collectionId: number;
  grams: number;
  setMeasureValue: Dispatch<SetStateAction<number>>;
  measureId: number;
}

export const ProductTabPanelsProxy = ({
  id,
  collectionId,
  grams,
  setMeasureValue,
  measureId,
}: ProductTabPanelsProxyProps) => {
  const { data: product, isLoading } =
    useFetchCollectionSearchPreviewProductQuery(
      collectionId.toString(),
      id.toString(),
      {
        onSuccess: data => {
          if (data?.data?.product) {
            const product = data?.data?.product;
            setMeasureValue(
              round(
                grams /
                  (product.measures.find(m => m.id === measureId)?.grams || 1),
                2,
              ),
            );
          }
        },
      },
    );

  if (isLoading)
    return (
      <Stack width="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  if (!product) return null;

  return (
    <ProductTabPanels
      nutrients={scaleNutrients(product?.product.nutrients ?? [], grams)}
      tags={product?.product.tags.map(t => t.id) ?? []}
      measures={product?.product.measures ?? []}
    />
  );
};
