import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";
import {
  fetchMonitoringSymptoms,
  FetchMonitoringSymptomsParams,
  FetchMonitoringSymptomsResponse,
} from "@client/patient/monitoring/fetchMonitoringSymptoms";

export const useFetchMonitoringSymptoms = (
  params: FetchMonitoringSymptomsParams,
  options?: QueryOptionsTyped<FetchMonitoringSymptomsResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.symptoms(params),
    queryFn: () => fetchMonitoringSymptoms(params),
    ...options,
  });
};
