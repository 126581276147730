import { Divider as MuiDivider, styled } from "@mui/material";

export const Card = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const Divider = styled(MuiDivider)`
  border-color: ${({ theme }) => theme.colors.neutral.light[200]};
`;

export const Text = styled("span")`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
