import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  styled,
} from "@mui/material";

export const CommentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CommentLabel = styled("span")`
  font: var(--font-figtree-regular);
  font-size: 12px;
  line-height: 20px;
  line-height: 20px;
  color: #4d516e;
`;

export const Comment = styled("span")`
  background: #fbfafc;
  border-radius: 8px;
  flex: 1;
  padding: 10px;
  font-family: Figtree, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #4d516e;
`;

export const MacroChip = styled(Chip)`
  padding: 2px;
  width: 16px;
  height: 16px;
  .MuiChip-label {
    padding: 0px;
    line-height: 0px;
  }
` as typeof Chip;

export const KcalValue = styled("span")`
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const MacroValue = styled("span")`
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const MacroName = styled("span")`
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  font-size: 8px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const MacroWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const ItemImage = styled("img")`
  max-height: 220px;
  object-fit: cover;
  border-radius: 12px;
`;

export const MealName = styled("span")`
  line-height: 28px;
  font-size: 14px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 600;
  color: #2f303a;
`;

export const MealTime = styled("span")`
  line-height: 28px;
  font-size: 14px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 500;
  color: #2f303a;
`;

export const ItemName = styled("span")`
  line-height: 20px;
  font-size: 14px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;

export const MainAccordion = styled(Accordion)`
  box-shadow: none;
  min-height: unset;
  &.MuiAccordion-root::before {
    content: none;
  }
  .MuiButtonBase-root {
    min-height: unset;
  }
  background: transparent;
` as typeof Accordion;

export const MainAccordionSummary = styled(AccordionSummary)`
  padding: 0px;
  .MuiAccordionSummary-content {
    margin: 0px;
  }
` as typeof AccordionSummary;

export const ItemAccordion = styled(Accordion)`
  box-shadow: none;
  &.MuiAccordion-root::before {
    content: none;
  }
  padding: 0px;
` as typeof Accordion;

export const ItemAccordionSummary = styled(AccordionSummary)`
  min-height: auto;
  .MuiAccordionSummary-content {
    margin: 0px;
  }
  padding: 0px;
` as typeof AccordionSummary;

export const ItemAccordionDetails = styled(AccordionDetails)`
  padding: 0px;
` as typeof AccordionDetails;
