import { Button, ButtonProps, Stack, styled } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { Dispatch, useEffect, useMemo, useRef } from "react";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { getTranslation } from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";
import { IconButton } from "@components/Alloweat/IconButton";
import { ChevronIcon } from "@icons/index";

type MeasurementsBodyTabsProps = {
  type: number | null;
  setType: Dispatch<number | null>;
  measurements: BodyMeasurementMonitoringResource | null;
};

const SCROLL_NUMBER_OF_PIXELS = 200;

const MeasurementsBodyTabs = ({
  type,
  setType,
  measurements,
}: MeasurementsBodyTabsProps) => {
  const { currentLanguage } = useAppTranslation();
  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();
  const listRef = useRef<HTMLDivElement | null>(null);

  const bodyMeasurementsFiltered = useMemo(() => {
    if (!bodyMeasurements) {
      return [];
    }

    return bodyMeasurements.data.filter(
      bodyMeasurement => bodyMeasurement.id !== BodyMeasurementConst.height,
    );
  }, [bodyMeasurements]);

  useEffect(() => {
    if (!bodyMeasurementsFiltered.length) {
      return;
    }

    setType(bodyMeasurementsFiltered[0]?.id ?? null);
  }, [bodyMeasurementsFiltered]);

  const getValue = (bodyId: number) => {
    if (!measurements) {
      return null;
    }

    const bodyMeasurement = measurements.measurements?.bodyMeasurements.find(
      bodyMeasurement => bodyMeasurement.body.id === bodyId,
    );
    return bodyMeasurement?.value ?? null;
  };

  const handleScroll = (right: boolean) => {
    if (!listRef.current) {
      return;
    }

    listRef.current.scrollBy({
      left: SCROLL_NUMBER_OF_PIXELS * (right ? 1 : -1),
      behavior: "smooth",
    });
  };

  console.log(listRef.current?.scrollWidth, listRef.current?.scrollLeft);

  return (
    <Stack useFlexGap gap="8px" direction="row" alignItems="center">
      <IconButton
        onClick={() => {
          handleScroll(false);
        }}
      >
        <ChevronIcon color="#727272" />
      </IconButton>
      <Stack
        useFlexGap
        gap="8px"
        direction="row"
        overflow="auto"
        className="hide-scrollbar"
        ref={listRef}
      >
        {bodyMeasurementsFiltered.map(bodyMeasurement => {
          const value = getValue(bodyMeasurement.id);
          const name =
            getTranslation(bodyMeasurement, currentLanguage)?.name +
            (value ? ` - ${value} ${bodyMeasurement.unit}` : "");

          if (type === bodyMeasurement.id) {
            return <ActiveButton key={bodyMeasurement.id}>{name}</ActiveButton>;
          }

          return (
            <CustomButton
              key={bodyMeasurement.id}
              size="small"
              onClick={() => setType(bodyMeasurement.id)}
            >
              {name}
            </CustomButton>
          );
        })}
      </Stack>
      <IconButton
        onClick={() => {
          handleScroll(true);
        }}
      >
        <ChevronIcon style={{ transform: "rotate(180deg)" }} color="#727272" />
      </IconButton>
    </Stack>
  );
};

const CustomButton = styled(Button)<ButtonProps>({
  "boxShadow": "none",
  "textTransform": "none",
  "fontSize": 12,
  "padding": "4px 8px",
  "border": "1px solid",
  "lineHeight": "20px",
  "backgroundColor": "#fff",
  "borderColor": "#F1F1F1",
  "borderRadius": "8px",
  "color": "#727272",
  "minWidth": "auto",
  "height": "auto",
  "fontFamily": ["Figtree", "serif"].join(","),
  "&:hover": {
    backgroundColor: "#F5F1FE",
    borderColor: "#F1F1F1",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#F5F1FE",
    borderColor: "#7448D0",
  },
  "&:focus": {
    boxShadow: "none",
  },
  "whiteSpace": "nowrap",
});

const ActiveButton = styled(CustomButton)<ButtonProps>({
  "backgroundColor": "#F5F1FE",
  "borderColor": "#7448D0",
  "color": "#7448D0",
  "&:hover": {
    backgroundColor: "#F5F1FE",
    borderColor: "#7448D0",
  },
  "&:active": {
    backgroundColor: "#F5F1FE",
    borderColor: "#7448D0",
  },
});

export default MeasurementsBodyTabs;
