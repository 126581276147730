import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import {
  updateClientMeasurement,
  UpdateClientMeasurementRequest,
} from "@client";
import { fetchClientLastMeasurementQueryKey } from "./useFetchClientLastMeasurementQuery";
import { fetchClientMeasurementQueryKey } from "./useFetchClientMeasurementQuery";
import { fetchClientMeasurementListQueryKey } from "./useFetchClientMeasurementList";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

export const useUpdateClientMeasurementMutation = (
  clientId?: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateClientMeasurementMutationPayload>(
    ({ id, payload }) => updateClientMeasurement(id, payload),
    {
      ...options,
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([fetchClientLastMeasurementQueryKey]);
        queryClient.invalidateQueries([
          fetchClientMeasurementQueryKey,
          variables.id,
        ]);
        queryClient.invalidateQueries([fetchClientMeasurementListQueryKey]);
        if (clientId) {
          queryClient.invalidateQueries(
            patientBodyMeasurementKeys.last(clientId),
          );
        }
        options?.onSuccess && options.onSuccess(data, variables);
      },
    },
  );
};

interface UpdateClientMeasurementMutationPayload {
  id: string;
  payload: UpdateClientMeasurementRequest;
}
