import { CloseIcon } from "@assets/icons_new";
import { useAppTranslation } from "@hooks";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import GoalField from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/Dialog/GoalField";
import MainProductField from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/Dialog/MainProductField";
import {
  mapEditHydrationForm,
  mapEditHydrationRequest,
  useEditHydrationForm,
} from "./useEditHydrationForm";
import { useMemo } from "react";
import { HydrationDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { FormProvider } from "react-hook-form";
import { useUpdateGlobalPatientSettingsMutation } from "@hooks/queries/clinic";

interface EditHydrationModalProps {
  open: boolean;
  onClose: () => void;
  hydration: HydrationDto;
}
export const EditHydrationModal = ({
  open,
  onClose,
  hydration,
}: EditHydrationModalProps) => {
  const { t } = useAppTranslation();
  const defaultValues = useMemo(
    () => mapEditHydrationForm(hydration),
    [hydration],
  );
  const form = useEditHydrationForm(defaultValues);
  const { mutate, isLoading } = useUpdateGlobalPatientSettingsMutation();

  const handleCloseAndReset = () => {
    onClose();
    form.reset();
  };
  const handleSubmit = form.handleSubmit(
    values =>
      mutate(
        { hydration: mapEditHydrationRequest(values) },
        { onSuccess: onClose },
      ),
    e => console.log(e),
  );

  return (
    <Dialog open={open}>
      <DialogTitleStyled>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {t(
            "patient.health_and_goal.dietary_profile_tab.section_hydration_modal.title",
          )}
          <IconButtonStyled onClick={handleCloseAndReset} size="small">
            <CloseIcon fontSize="24px" />
          </IconButtonStyled>
        </Stack>
      </DialogTitleStyled>
      <DialogContent sx={{ width: "510px" }}>
        <FormProvider {...form}>
          <Stack direction="column" gap="16px">
            <GoalField />
            <MainProductField />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAndReset} variant="outlined">
          {t("common.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: unset;
  height: unset;
`;
const DialogTitleStyled = styled(DialogTitle)`
  font-size: 16px;
  padding: 16px;
`;
