import type { FC } from "react";
import { EducationalMaterialsTable } from "./EducationalMaterialsTable";
import { ViewTabs } from "../EducationalMaterialsView.types";
import { EducationalMaterialGrid } from "./EducationalMaterialsGrid";
import { EducationalMaterialEmptyState } from "./EducationalMaterialsEmptyStates";
import { Box, CircularProgress } from "@mui/material";
import {
  getEnumValuesAsNumbers,
  ITEMS_ON_PAGE,
} from "@components/PaginationNew";
import { useSearchSectionsQueryNew } from "@hooks/queries/useSearchSectionsQueryNew";

interface EducationalMaterialsViewItemProps {
  view: string;
}

export const EducationalMaterialsViewItem: FC<
  EducationalMaterialsViewItemProps
> = ({ view }) => {
  const { sections, meta, isLoading, submit } = useSearchSectionsQueryNew();

  const isGridView = view === ViewTabs.GRID;

  const handleChangePage = (page: number) => {
    submit(prev => ({ ...prev, page: page + 1 }));
  };

  const handleChangePerPage = (perPage: number) => {
    submit(prev => ({ ...prev, perPage }));
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop={15}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (!isLoading && !sections?.length) {
    return <EducationalMaterialEmptyState isGeneral />;
  }

  const perPage = meta?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE;

  const currentPage = meta?.currentPage ? meta.currentPage - 1 : 0;

  const itemsOnPage = getEnumValuesAsNumbers(ITEMS_ON_PAGE);

  return (
    <div>
      {isGridView ? (
        <EducationalMaterialGrid
          materials={sections ?? []}
          isOnePage={false}
          perPage={perPage}
          onPageChange={handleChangePage}
          page={currentPage}
          setPerPage={handleChangePerPage}
          rowCount={meta?.total ?? 1}
          itemsOnPage={itemsOnPage}
        />
      ) : (
        <EducationalMaterialsTable
          materials={sections ?? []}
          page={currentPage}
          setPage={handleChangePage}
          perPage={perPage}
          setPerPage={handleChangePerPage}
          meta={meta}
          itemsOnPage={itemsOnPage}
        />
      )}
    </div>
  );
};
