import { useQuery } from "@tanstack/react-query";

import { fetchSectionCategories } from "@client";

export function useFetchSectionCategoriesQuery() {
  const { data: sectionCategories, ...rest } = useQuery(
    ["sectionCategories"],
    fetchSectionCategories,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  );
  return { sectionCategories, ...rest };
}
