import { styled } from "@mui/material";
import { ReactNode } from "react";

interface SectionProps {
  title: string;
  children: ReactNode;
}
export const Section = ({ title, children }: SectionProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>

      <Container>{children}</Container>
    </Wrapper>
  );
};

const Title = styled("span")`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  text-transform: uppercase;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
