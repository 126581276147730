import { Stack, styled } from "@mui/material";
import { useMemo } from "react";
import dayjs from "dayjs";
import StatusColumn from "@views/dietician/PatientPrograms/Grid/StatusColumn";
import { PatientProgramPreviewSearchResource } from "@client/resources/PatientProgramPreviewSearchResource";

type ProgramPeriodProps = Pick<
  PatientProgramPreviewSearchResource,
  "startDate" | "endDate"
>;

const API_DATE_FORMAT = "YYYY-MM-DD";
const DATE_FORMAT = "DD.MM.YYYY";

const ProgramPeriod = ({ startDate, endDate }: ProgramPeriodProps) => {
  const period = useMemo(() => {
    const s = dayjs(startDate, API_DATE_FORMAT).format(DATE_FORMAT);
    const e = endDate
      ? dayjs(endDate, API_DATE_FORMAT).format(DATE_FORMAT)
      : null;

    return `${s} - ${e ?? "..."}`;
  }, [startDate, endDate]);

  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <StatusColumn program={{ startDate, endDate }} />
      <Period>{period}</Period>
    </Stack>
  );
};

const Period = styled("span")`
  color: #333333;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  font-family: Figtree, serif;
`;

export default ProgramPeriod;
