import { useCallback, useState } from "react";

import {
  Dialog,
  IconButton,
  InputAdornment,
  styled,
  Typography,
  useTheme,
} from "@mui/material";

import { useAppTranslation } from "@hooks";
import { Close } from "@assets/icons";
import { INVALID_OLD_PASSWORD_ERROR } from "@utils/settings";
import { usePatchDietitianPasswordMutation } from "@hooks/queries/settings";

import {
  DietitianPasswordInput,
  mapDietitianPasswordRequest,
  useDietitianPasswordForm,
} from "./useDietitianPasswordForm";
import {
  CancelButton,
  CloseButton,
  SettingsModalCard,
  SettingsModalWrapper,
  StyledDialogActions,
  Title,
  TitleWrapper,
} from "../../SettingsAccount.styled";
import { FormTextField } from "@components/FormTextField";
import { LoadingButton } from "@mui/lab";
import EyeIcon from "@assets/icons_new/eye.svg?react";
interface ChangePasswordModalProps {
  onClose: () => void;
  open: boolean;
}

export const ChangePasswordModal = ({
  onClose,
  open,
}: ChangePasswordModalProps) => {
  const { colors } = useTheme();

  const { t } = useAppTranslation();
  const { control, handleSubmit, setError, reset } = useDietitianPasswordForm();
  const { mutate: patchPassword, isLoading } =
    usePatchDietitianPasswordMutation({
      onSuccess: () => onCloseWithReset(),
      onError: e => {
        if (
          e.response?.data.errors.old_password?.includes(
            INVALID_OLD_PASSWORD_ERROR,
          )
        ) {
          setError("currentPassword", {
            message: t("settings.password_edit.invalid"),
          });
        }
      },
    });

  const handleChangePassword = useCallback(
    (data: DietitianPasswordInput) => {
      patchPassword(mapDietitianPasswordRequest(data));
      [patchPassword, mapDietitianPasswordRequest];
    },
    [patchPassword, mapDietitianPasswordRequest],
  );

  const onCloseWithReset = () => {
    reset();
    onClose();
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
    useState(false);

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <SettingsModalWrapper>
        <TitleWrapper>
          <Title>{t("settings.password_edit.change_password")}</Title>
          <CloseButton onClick={onCloseWithReset}>
            <Close />
          </CloseButton>
        </TitleWrapper>
        <SettingsModalCard>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("settings.password_edit.current_password")}
            </Typography>
            <FormTextField
              type={showOldPassword ? "text" : "password"}
              name="currentPassword"
              control={control}
              size="small"
              placeholder={t(
                "settings.password_edit.current_password_placeholder",
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EyeButton
                      size="small"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      <EyeIcon fontSize={24} />
                    </EyeButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("settings.password_edit.new_password")}
            </Typography>
            <FormTextField
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              control={control}
              size="small"
              placeholder={t("settings.password_edit.type_new_password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EyeButton
                      size="small"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      <EyeIcon fontSize={24} />
                    </EyeButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("settings.password_edit.new_password_confirmation")}
            </Typography>
            <FormTextField
              type={showNewPasswordConfirmation ? "text" : "password"}
              name="newPasswordConfirmation"
              control={control}
              size="small"
              placeholder={t(
                "settings.password_edit.new_password_confirmation",
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EyeButton
                      size="small"
                      onClick={() =>
                        setShowNewPasswordConfirmation(
                          !showNewPasswordConfirmation,
                        )
                      }
                    >
                      <EyeIcon fontSize={24} />
                    </EyeButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </SettingsModalCard>
      </SettingsModalWrapper>
      <StyledDialogActions>
        <CancelButton variant="outlined" onClick={onCloseWithReset}>
          {t("common.cancel")}
        </CancelButton>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit(handleChangePassword, e => console.log("e", e))}
        >
          {t("common.save")}
        </LoadingButton>
      </StyledDialogActions>
    </Dialog>
  );
};

const EyeButton = styled(IconButton)`
  padding: 4px;
  height: unset;
  width: unset;
`;
