import { useContext, useMemo, useState } from "react";

import { TabContext } from "@mui/lab";

import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { Nutrient } from "@typeDefinitions";
import { GLYCEMIC_INDEX_ID } from "@utils";

import { ServingContext } from "../ServingsContext";

import {
  NutrientsByProduct,
  NutrientsByProductProps,
} from "./NutrientsByProduct";

import { NutrientsSection } from "@components/PreviewDrawer/common";
import { MacrosChart } from "@components/PreviewDrawer/common/MacrosChart";
import { useAppTranslation } from "@hooks";
import {
  CardStyled,
  DividerStyled,
  TabPanelStyled,
} from "./NutrientsTab.styled";
import { ProductIf, ProductsTable } from "./ProductsTable";
import { useLocation } from "react-router-dom";

export interface NutrientsTabProps {
  nutrients: Nutrient[];
  products: ProductIf[];
  NutrientsByProductComponent?: ({
    products,
  }: NutrientsByProductProps) => JSX.Element;
}

export const NutrientsTab = ({
  nutrients,
  products,
  NutrientsByProductComponent,
}: NutrientsTabProps) => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState(Tabs.PRODUCTS);
  const [servingsTab, setServingsTab] = useState(ServingsTab.SELECTED);
  const { pathname } = useLocation();

  const {
    selectedServings,
    totalServings,
    setSelectedServings,
    commitedSelectedServings,
  } = useContext(ServingContext);
  const servingRatio = selectedServings / totalServings;

  const proportionalNutrients = useMemo(
    () =>
      nutrients.map(n => {
        if (n.id === GLYCEMIC_INDEX_ID) return n;
        return {
          ...n,
          value: n.value * servingRatio,
        };
      }),
    [nutrients, servingRatio],
  );

  const proportionalProducts = useMemo(
    () =>
      products.map(p => ({
        ...p,
        grams: p.grams * servingRatio,
      })),
    [products, servingRatio],
  );

  const singleServingRecipe =
    commitedSelectedServings === 1 && totalServings === 1;

  const isRecipeBasePath = pathname === "/recipes" || pathname === "/meals";
  const handleServingsChange = (value: ServingsTab) => {
    setServingsTab(value);
    if (value === ServingsTab.TOTAL) {
      setSelectedServings(totalServings);
    } else {
      setSelectedServings(commitedSelectedServings ?? totalServings);
    }
  };

  const servingsTabs =
    totalServings === 1
      ? [
          {
            id: ServingsTab.SELECTED,
            label: `${t(
              "recipe.multiplied_recipe",
            )}: x${commitedSelectedServings}`,
          },
          {
            id: ServingsTab.TOTAL,
            label: `${t("recipe.base_recipe")}`,
          },
        ]
      : [
          {
            id: ServingsTab.SELECTED,
            label: `${t(
              isRecipeBasePath ? "recipe.servings" : "recipe.clients_servings",
            )}: ${commitedSelectedServings}/${totalServings}`,
          },
          {
            id: ServingsTab.TOTAL,
            label: isRecipeBasePath
              ? t("recipe.whole_recipe")
              : `${t("recipe.base_recipe")}: ${totalServings}/${totalServings}`,
          },
        ];

  return (
    <TabContext value={tab}>
      <div className="grid gap-[24px]">
        <div className="flex justify-between">
          <TabSwitch
            value={tab}
            onChange={setTab}
            tabs={[
              { id: Tabs.PRODUCTS, label: t("common.products") },
              { id: Tabs.GENERAL, label: t("recipe.general") },
            ]}
          />
          {!singleServingRecipe && (
            <TabSwitch
              value={servingsTab}
              onChange={handleServingsChange}
              tabs={servingsTabs}
            />
          )}
        </div>

        <TabPanelStyled value={Tabs.PRODUCTS}>
          <CardStyled>
            <ProductsTable
              nutrients={proportionalNutrients}
              products={proportionalProducts}
            />

            <DividerStyled />

            {NutrientsByProductComponent ? (
              <NutrientsByProductComponent products={proportionalProducts} />
            ) : (
              <NutrientsByProduct products={proportionalProducts} />
            )}
          </CardStyled>
        </TabPanelStyled>

        <TabPanelStyled value={Tabs.GENERAL}>
          <CardStyled>
            <MacrosChart nutrients={proportionalNutrients} />

            <DividerStyled />

            <NutrientsSection nutrients={proportionalNutrients} />
          </CardStyled>
        </TabPanelStyled>
      </div>
    </TabContext>
  );
};

enum Tabs {
  PRODUCTS = "0",
  GENERAL = "1",
}
enum ServingsTab {
  SELECTED = "0",
  TOTAL = "1",
}
