import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const AddVideo = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M18.5 12C18.5 10.2761 17.8152 8.62279 16.5962 7.40381C15.3772 6.18482 13.7239 5.5 12 5.5C10.2761 5.5 8.62279 6.18482 7.40381 7.40381C6.18482 8.62279 5.5 10.2761 5.5 12C5.5 13.7239 6.18482 15.3772 7.40381 16.5962C8.62279 17.8152 10.2761 18.5 12 18.5C13.7239 18.5 15.3772 17.8152 16.5962 16.5962C17.8152 15.3772 18.5 13.7239 18.5 12ZM4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12ZM9.88438 8.59688C10.1219 8.46563 10.4094 8.46875 10.6438 8.6125L15.1438 11.3625C15.3656 11.5 15.5031 11.7406 15.5031 12.0031C15.5031 12.2656 15.3656 12.5063 15.1438 12.6438L10.6438 15.3938C10.4125 15.5344 10.1219 15.5406 9.88438 15.4094C9.64687 15.2781 9.5 15.0281 9.5 14.7563V9.25C9.5 8.97813 9.64687 8.72813 9.88438 8.59688Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
