import { useMemo, useState } from "react";

import { TabContext } from "@mui/lab";
import { Divider } from "@mui/material";

import { FormServingsSlider } from "@components/FormServingsSlider";
import { NutrientsSection } from "@components/PreviewDrawer/common";
import { MacrosChart } from "@components/PreviewDrawer/common/MacrosChart";
import { TabSwitch } from "@components/TabSwitch";
import { useAppTranslation } from "@hooks";

import { CardStyled } from "../common.styled";
import { TabPanelStyled } from "./ProductsAndNutritionalValues.styled";
import { ProductsContent } from "./ProductsContent";
import { useWatch } from "react-hook-form";
import { ProductInput, RecipeEditInput } from "../useRecipeEditForm";
import { Nutrient } from "@typeDefinitions";
import { scaleNutrients } from "@utils";
import { ProductItemIf, useProductsContext } from "../ProductsContext";

export const ProductsAndNutritionalValues = () => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState(Tab.PRODUCT);
  const tabs = useMemo(
    () => [
      { id: Tab.PRODUCT, label: t("diet.recipe_sidepanel.products") },
      { id: Tab.GENERAL, label: t("diet.recipe_sidepanel.general") },
    ],
    [t],
  );
  const productsList = useWatch<RecipeEditInput, "products">({
    name: "products",
  });
  const selectedServings = useWatch<RecipeEditInput, "servings">({
    name: "servings",
  });
  const { products } = useProductsContext();
  const summaryNutrients = useMemo(
    () => mapNutrientsSummary(productsList, products),
    [productsList],
  );

  return (
    <>
      <div className="flex gap-[8px] items-center">
        <TabSwitch tabs={tabs} value={tab} onChange={setTab} />

        <div className="w-[435px]">
          <FormServingsSlider
            name="totalServings"
            selectedServings={selectedServings}
          />
        </div>
      </div>
      <TabContext value={tab}>
        <TabPanelStyled value={Tab.PRODUCT}>
          <ProductsContent />
        </TabPanelStyled>

        <TabPanelStyled value={Tab.GENERAL}>
          <CardStyled>
            <MacrosChart nutrients={summaryNutrients} />
            <Divider
              sx={({ colors }) => ({ borderColor: colors.neutral.light[200] })}
            />
            <NutrientsSection nutrients={summaryNutrients} />
          </CardStyled>
        </TabPanelStyled>
      </TabContext>
    </>
  );
};

enum Tab {
  PRODUCT = "product",
  GENERAL = "general",
}

const mapNutrientsSummary = (
  products: ProductInput[] | undefined,
  dict: Map<number, ProductItemIf>,
): Nutrient[] => {
  const nutrientMap = new Map<number, number>();

  if (!products) return [];

  products.forEach(product => {
    if (!product.foodId) return;

    const details = dict.get(product.foodId);
    const scaledNutrients = scaleNutrients(
      details?.nutrients ?? [],
      product.grams,
    );
    scaledNutrients.forEach(({ id, value }) => {
      if (nutrientMap.has(id)) {
        nutrientMap.set(id, nutrientMap.get(id)! + value);
      } else {
        nutrientMap.set(id, value);
      }
    });
  });

  return Array.from(nutrientMap, ([id, value]) => ({ id, value }));
};
