import { SearchSectionsResponse } from "@client/searchSections";
import { EducationalMaterialCard } from "./EducationalMaterialsCard";
import { Star } from "@assets/icons/DesignSystem/Star";
import { PaginationWrapper } from "@components/DietsGridLayout/DietsGridLayout.styled";
import { CountLabel, Pagination } from "@components/PaginationNew";
import type { SectionsNew } from "@client/searchSectionsNew";
import { useGetSectionCategoryName } from "../_hooks/useGetSectionCategoryName";

interface EducationalMaterialGridProps {
  materials: SectionsNew;
  isOnePage: boolean;
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  onPageChange: (value: number) => void;
  rowCount: number;
  itemsOnPage: number[];
}

export const EducationalMaterialGrid = ({
  materials,
  isOnePage,
  page,
  perPage,
  setPerPage,
  onPageChange,
  rowCount,
  itemsOnPage,
}: EducationalMaterialGridProps) => {
  const { getSectionCategoryName } = useGetSectionCategoryName();

  return (
    <>
      <ul className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 pb-5">
        {materials.map(
          ({ id, name, image, favorite, description, category }) => (
            <li key={id} className="h-full">
              <EducationalMaterialCard
                title={name}
                icon={<Star />}
                image={image?.url ?? ""}
                id={id}
                favorite={favorite}
                description={description}
                category={getSectionCategoryName(category.id)}
              />
            </li>
          ),
        )}
      </ul>
      <PaginationWrapper>
        <CountLabel count={rowCount ?? 1} page={page} perPage={perPage} />
        <div className="flex items-center">
          <Pagination
            isOnePage={isOnePage}
            page={page}
            onPageChange={(_, newPage) => onPageChange && onPageChange(newPage)}
            count={rowCount}
            perPage={perPage}
            setPerPage={v => setPerPage(v as number)}
            rowsPerPageOptions={itemsOnPage}
          />
        </div>
      </PaginationWrapper>
    </>
  );
};
