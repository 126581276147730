import {
  Box,
  Chip,
  CircularProgress,
  Drawer,
  Stack,
  styled,
  Switch,
} from "@mui/material";
import {
  Header,
  Label,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsListDrawer.styled";
import { Close, Filters } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { useFetchMonitoringMealsClientInfinite } from "@hooks/queries/client/monitoring";
import { useCallback, useMemo, useRef, useState } from "react";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { CustomChip } from "@components/Filters/TabsChipsFilter/TabsChipsFilter.styled";
import MealsListFilterDrawer from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsListFilterDrawer";
import SymptomsDay from "@views/dietician/PatientMonitoring2/components/Symptoms/Meals/SymptomsDay";
import { CloseIcon, FilterIcon } from "@icons/index";
import { IconButton } from "@components/Alloweat/IconButton";

type SymptomsDrawerProps = {
  open: boolean;
  onClose: () => void;
};

const SymptomsDrawer = ({ open, onClose }: SymptomsDrawerProps) => {
  const { t } = useAppTranslation();
  const { patientId, selectedDays } = useMonitoring();

  const [ratings, setRatings] = useState<number[]>([]);
  const [tagsId, setTagsId] = useState<number[]>([]);
  const [symptoms, setSymptoms] = useState<number>(1);

  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);

  const params = useMemo(() => {
    return {
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
      ...(ratings.length > 0 && { rating: ratings }),
      ...(tagsId.length > 0 && { tags: tagsId }),
      ...(symptoms === 1 && { symptoms: 1 }),
    };
  }, [ratings, tagsId, symptoms, selectedDays.selectDays]);

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useFetchMonitoringMealsClientInfinite({ patientId, ...params });

  const handleObserver = useRef<IntersectionObserver>();
  const lastElement = useCallback(
    (element: HTMLLIElement) => {
      if (isLoading) return;
      if (handleObserver.current) handleObserver.current.disconnect();
      handleObserver.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });
      if (element) handleObserver.current.observe(element);
    },
    [isLoading, hasNextPage],
  );

  const isChecked = (rating: number) =>
    ratings.find(i => i === rating) != undefined;

  const handleChipOnClick = (rating: number) => {
    setRatings(prev => {
      if (prev.find(i => i === rating) != undefined) {
        return prev.filter(i => i !== rating);
      }

      return [...prev, rating];
    });
  };

  const days = useMemo(
    () => data?.pages.map(item => item.data).flat() ?? [],
    [data],
  );

  return (
    <>
      <CustomDrawer
        open={open}
        onClose={onClose}
        anchor="right"
        PaperProps={{
          sx: { width: "640px", padding: "16px", scrollbarWidth: "thin" },
        }}
      >
        <Stack useFlexGap direction="column" gap="16px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header>
              {/*{t("monitoring2.symptoms_box.symptoms_drawer.header")}*/}
              Posiłki z objawami i emocjami
            </Header>
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="24px" fill="#727272" />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          useFlexGap
          direction="row"
          justifyContent="space-between"
          marginTop="16px"
          alignItems="end"
        >
          <Stack useFlexGap direction="column" gap="8px">
            <Label>{t("monitoring2.meals_box.meals_list_drawer.label")}</Label>
            <Stack useFlexGap direction="row" spacing="6px">
              <CustomChip
                variant={isChecked(1) ? "filled" : "outlined"}
                onClick={() => handleChipOnClick(1)}
                label={t("monitoring2.meals_box.meals_list_drawer.tasted")}
                color={isChecked(1) ? "primary" : "default"}
              />
              <CustomChip
                variant={isChecked(0) ? "filled" : "outlined"}
                onClick={() => handleChipOnClick(0)}
                label={t("monitoring2.meals_box.meals_list_drawer.not_tasted")}
                color={isChecked(0) ? "primary" : "default"}
              />
              <Stack
                direction="row"
                spacing="4px"
                alignItems="center"
                onClick={() => setSymptoms(prev => (prev === 1 ? 0 : 1))}
                sx={{}}
              >
                <Switch checked={symptoms === 1} />
                <span>Posiłki z objawami i emocjami</span>
              </Stack>
            </Stack>
          </Stack>
          <IconButton
            color="primary"
            onClick={() => setOpenFiltersDrawer(true)}
            sx={{
              padding: "7px",
              border: "solid 1px #F5F1FE",
              borderRadius: "8px",
            }}
          >
            <FilterIcon fontSize="24px" color="#7448D0" />
            {tagsId.length > 0 && (
              <Chip
                sx={{ marginLeft: "4px" }}
                label={`+${tagsId.length}`}
                color="primary"
              />
            )}
          </IconButton>
        </Stack>
        <Stack direction="column" flex={1}>
          <Stack useFlexGap direction="column" gap="16px" marginTop="16px">
            {days.map(day => (
              <Box
                display="flex"
                flexDirection="column"
                gap="16px"
                key={day.id}
                ref={lastElement}
              >
                <SymptomsDay day={day} />
              </Box>
            ))}
            {(isLoading || isFetching) && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
          </Stack>
        </Stack>
      </CustomDrawer>
      <Drawer
        open={openFiltersDrawer}
        onClose={() => setOpenFiltersDrawer(false)}
        anchor="right"
        PaperProps={{
          sx: { width: "440px" },
        }}
        hideBackdrop={true}
      >
        <MealsListFilterDrawer
          onClose={() => setOpenFiltersDrawer(false)}
          tagsId={tagsId}
          setTagsId={setTagsId}
          count={data?.pages[0]?.meta.total ?? 0}
        />
      </Drawer>
    </>
  );
};

const CustomDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background: transparent;
  }
` as typeof Drawer;

export default SymptomsDrawer;
