import { Stack } from "@mui/material";

import { ApiError } from "@components/ApiError";
import { Avatar } from "@components/AvatarNew";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation, useImageUpload, useModalState } from "@hooks";
import {
  fetchDietitianAccountQueryKey,
  useFetchDietitianAccountQuery,
} from "@hooks/queries/settings";
import { apiUrl } from "@utils/configEnv";
import { EditPersonalInfoModal } from "@views/dietician/SettingsAccount/components/EditPersonalInfoModal";

import { Card, CardHeader, Divider, Section, Text } from "./components";
import { useQueryClient } from "@tanstack/react-query";

export const Profile = () => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const queryClient = useQueryClient();
  const { account, isError } = useFetchDietitianAccountQuery();
  const { isUploading, handleUploadAvatar } = useImageUpload({
    url: `${apiUrl()}/dietitian/account/avatar`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [fetchDietitianAccountQueryKey],
      });
    },
  });

  if (!account || isError) return <ApiError />;

  const {
    firstName,
    lastName,
    language,
    phone: { prefix, number },
    email,
  } = account;
  const imgUrl = account?.avatar;

  return (
    <Card>
      <CardHeader title={t("settings.profile.details")} onEdit={onOpen} />

      <Stack alignItems="center">
        <Avatar
          isEditable
          size="huge"
          isUploading={isUploading}
          onFileUpload={handleUploadAvatar}
          image={imgUrl ?? undefined}
          fullName={`${account?.firstName ?? ""} ${account?.lastName ?? ""}`}
        />
      </Stack>

      <Section title={t("settings.profile.personal_information")}>
        <OuterLabel label={t("settings.profile.name")}>
          <Text>{firstName ?? "-"}</Text>
        </OuterLabel>
        <OuterLabel label={t("settings.profile.last_name")}>
          <Text>{lastName ?? "-"}</Text>
        </OuterLabel>
      </Section>

      <Divider />

      <Section title={t("settings.profile.contact_information")}>
        <OuterLabel label={t("settings.profile.phone_number")}>
          <Text>{[prefix, number].filter(Boolean).join("") ?? "-"}</Text>
        </OuterLabel>
        <OuterLabel label={t("settings.profile.email_address")}>
          <Text>{email ?? "-"}</Text>
        </OuterLabel>
      </Section>

      <EditPersonalInfoModal open={open} onClose={onClose} />
    </Card>
  );
};
