import { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchDietitianAccountQuery } from "./queries/settings";

import { toast } from "react-toastify";
import axios from "axios";
import { MediaExtendedDto } from "@client";
import { mapKeysToCamelCase } from "@utils/api";

interface UseImageUploadProps {
  url?: string | null;
  onSuccess?: () => void;
  formDataName?: string;
}

export function useImageUpload({
  url,
  onSuccess = () => null,
  formDataName = "file",
}: UseImageUploadProps) {
  const [uploadImgResponse, setUploadImgResponse] =
    useState<MediaExtendedDto | null>();
  const [isUploading, setUploading] = useState(false);
  const [avatarTimestamp, setAvatarTimestamp] = useState<number>();
  const { t } = useTranslation();
  const { account, isLoading, isError } = useFetchDietitianAccountQuery();

  const handleUploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
    const ONE_MB = 1024000;
    const file = event.target.files?.item(0);
    const isImgPassed = file && file.size <= ONE_MB;
    if (file && url) {
      const formData = new FormData();
      formData.append(formDataName, file, file.name);
      try {
        if (isImgPassed) {
          setUploading(true);
          const response = await axios.post(url, formData);
          setUploadImgResponse(mapKeysToCamelCase(response.data.data) as any);
          setAvatarTimestamp(Date.now);
          onSuccess();
        } else {
          throw new Error("Image size is too big");
        }
      } catch (err) {
        console.log("err", err);
        toast.error(t("client_profile.photo_max_size"));
      } finally {
        setUploading(false);
      }
    }
  };

  const imgUrl = useMemo(() => {
    if (isLoading || isError)
      return avatarTimestamp ? `${url}?${avatarTimestamp}` : `${url}`;
    return avatarTimestamp
      ? `${url}?${avatarTimestamp}&${account?.id}`
      : `${url}?${account?.id}`;
  }, [isLoading, isError, avatarTimestamp, account, url]);

  const resetImageUploadResponse = () => setUploadImgResponse(null);

  return {
    isUploading,
    imgUrl,
    handleUploadAvatar,
    uploadImgResponse,
    resetImageUploadResponse,
  };
}
