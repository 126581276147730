import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  cloneProgramToClient,
  CloneProgramToClientRequest,
  CloneProgramToClientResponse,
} from "@client";
import { ApiResponse } from "@typeDefinitions";
import { MutationOptions } from "./types";

export const useCloneProgramToClient = (
  id: number,
  options?: MutationOptions<ApiResponse<CloneProgramToClientResponse>>,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponse<CloneProgramToClientResponse>,
    unknown,
    CloneProgramToClientRequest
  >(payload => cloneProgramToClient(id, payload), {
    ...options,
    onSuccess: data => {
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
