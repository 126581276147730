import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  FetchMonitoringCalendarDaysParams,
  FetchMonitoringMealsClientParams,
  FetchMonitoringMealsStatsParams,
  FetchMonitoringMeasurementsLastParams,
  FetchMonitoringNutrientsParams,
} from "@client/patient/monitoring";
import { FetchMonitoringIrrigationParams } from "@client/patient/monitoring/fetchMonitoringIrrigation";
import { FetchMonitoringMealsDietitianParams } from "@client/patient/monitoring/fetchMonitoringMealsDietitian";
import { FetchMonitoringMeasurementsCustomParams } from "@client/patient/monitoring/fetchMonitoringMeasurementCustom";
import { FetchMonitoringMeasurementsFirstParams } from "@client/patient/monitoring/fetchMonitoringMeasurementFirst";
import { FetchMonitoringMeasurementsParams } from "@client/patient/monitoring/fetchMonitoringMeasurements";
import { FetchMonitoringMeasurementsPhotosParams } from "@client/patient/monitoring/fetchMonitoringMeasurementsPhotos";
import { FetchMonitoringCalendarDaysByMonthsParams } from "@client/patient/monitoring/fetchMonitoringCalendarDaysByMonths";
import { FetchMonitoringSymptomsParams } from "@client/patient/monitoring/fetchMonitoringSymptoms";

export const patientMonitoringKeys = {
  base: (patientId: number) => patientKeys.monitoring(patientId),
  calendarDays: (params: FetchMonitoringCalendarDaysParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "calendar_days",
    params,
  ],
  calendarDaysByMonths: (params: FetchMonitoringCalendarDaysByMonthsParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "calendar_days_by_months",
    params,
  ],
  irrigation: (params: FetchMonitoringIrrigationParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "irrigation",
    params,
  ],
  symptoms: (params: FetchMonitoringSymptomsParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "symptoms",
    params,
  ],
  mealsClient: (params: FetchMonitoringMealsClientParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "meals_client",
    params,
  ],
  infiniteMealsClient: (params: FetchMonitoringMealsClientParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "infinite_meals_client",
    params,
  ],
  mealsDietitian: (params: FetchMonitoringMealsDietitianParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "meals_dietitian",
    params,
  ],
  mealsStats: (params: FetchMonitoringMealsStatsParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "meals_stats",
    params,
  ],
  measurement: (patientId: number) => [
    ...patientMonitoringKeys.base(patientId),
    "measurement",
  ],
  measurementCustom: (params: FetchMonitoringMeasurementsCustomParams) => [
    ...patientMonitoringKeys.measurement(params.patientId),
    "custom",
    params,
  ],
  measurementFirst: (params: FetchMonitoringMeasurementsFirstParams) => [
    ...patientMonitoringKeys.measurement(params.patientId),
    "first",
    params,
  ],
  measurementLast: (params: FetchMonitoringMeasurementsLastParams) => [
    ...patientMonitoringKeys.measurement(params.patientId),
    "last",
    params,
  ],
  measurements: (params: FetchMonitoringMeasurementsParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "measurements",
    params,
  ],
  measurementsPhotos: (params: FetchMonitoringMeasurementsPhotosParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "measurements_photos",
    params,
  ],
  nutrients: (params: FetchMonitoringNutrientsParams) => [
    ...patientMonitoringKeys.base(params.patientId),
    "nutrients",
    params,
  ],
};
