import { useFetchSectionQuery } from "@hooks/queries";
import { useEducationalModalContext } from "./useEducationalMaterialsContext";
import { useCreateSectionMutation } from "@hooks/queries";
import { FetchSectionResponse, SectionRequestDto } from "@client";
import { toast } from "react-toastify";
import { useAppTranslation } from "@hooks";
import { fetchSection } from "@client";
import { useQueryClient } from "@tanstack/react-query";
import { ApiEndpoints } from "@typeDefinitions";

const mapSectionToCreateSectionDto = (
  section: FetchSectionResponse,
): SectionRequestDto => {
  return {
    titleEn: `${section.titleEn ?? section.title} (Copy)`,
    title: `${section.title} (Kopia)`,
    contentEn: section.contentEn ?? "",
    content: section.content ?? "",
    programSectionCategoryId: section.programSectionCategory.id,
    reviewed: section.reviewed,
    description_pl: section.descriptionPl ?? "",
    description_en: section.descriptionEn ?? "",
    imageId: section.image?.id ?? null,
  };
};

export const useEducationalMaterialsCopy = (materialId: string) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  const { mutate: createSectionMutation } = useCreateSectionMutation({
    onSuccess: () => {
      toast.success(t("sections.success_copy"));
      //TODO: Improve this part
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: [ApiEndpoints.SearchSectionsNew],
        });
      }, 500);
    },
  });

  const handleCopy = async () => {
    if (!materialId) return;

    try {
      const sectionResponse = await fetchSection(Number(materialId));

      if (sectionResponse?.data) {
        createSectionMutation(
          mapSectionToCreateSectionDto(sectionResponse.data),
        );
      }
    } catch (error) {
      toast.error(t("common.error"));
    }
  };

  return { handleCopy };
};
