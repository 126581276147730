import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";

import { Edit } from "@assets/icons";
import { useAppTranslation, useModalState } from "@hooks";
import { ProgramEmptyDietContext } from "@context";
import { useProgram } from "@hooks/resources";
import { findInvalidDay } from "@views/dietician/PatientProgramDays/components/utils";
import { EmptyDietWarningDialog } from "@components/EmptyDietWarningDialog";
import { Card } from "@components/Card";
import { IconButton } from "@components/IconButton";
import {
  getLatestDate,
  isDateMoreThanOneYearLater,
} from "@views/dietician/ProgramForm/components/dateUtils";

interface Program {
  durationDays?: number | null;
  startDate?: string;
  finishDate?: string | null;
}

interface ProgramScheduleCardProps {
  programId: number;
  program: Program;
  patientId?: number;
  canEdit?: boolean;
}

export const ProgramScheduleCard = (props: ProgramScheduleCardProps) => {
  const {
    programId,
    program: { durationDays, startDate, finishDate },
    patientId,
    canEdit,
  } = props;
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const { setWarningBorder } = useContext(ProgramEmptyDietContext);
  const { program } = useProgram();
  const noDietDayId = program?.days.find(day => !day.diet)?.id;
  const [modalOpened, onModalOpen, onModalClose] = useModalState();
  const [openEmptyIngredientsModal, setOpenEmptyIngredientsModal] =
    useState(false);

  const invalidDayId = findInvalidDay(program);

  const handleAccept = () => {
    onModalClose();
    setOpenEmptyIngredientsModal(false);
    if (noDietDayId) {
      setWarningBorder();
      if (patientId) {
        navigate(
          `/patients/${patientId}/nutritional-programs/${programId}/days/${noDietDayId}`,
        );
      } else {
        navigate(`/programs/${programId}/days/${noDietDayId}`);
      }
    } else if (program?.days.length === 0) {
      if (patientId) {
        navigate(`/patients/${patientId}/nutritional-programs/${programId}`);
      } else {
        navigate(`/programs/${programId}`);
      }
    } else if (invalidDayId) {
      if (patientId) {
        navigate(
          `/patients/${patientId}/nutritional-programs/${programId}/days/${invalidDayId}`,
        );
      } else {
        navigate(`/programs/${programId}/days/${invalidDayId}`);
      }
    }
  };

  const onEdit = useCallback(() => {
    if (noDietDayId || program?.days.length === 0) {
      onModalOpen();
    } else if (invalidDayId) {
      setOpenEmptyIngredientsModal(true);
    } else {
      return navigate(
        patientId
          ? `/patients/${patientId}/nutritional-programs/${programId}/schedule`
          : `/programs/${programId}/schedule`,
      );
    }
  }, [navigate, noDietDayId, invalidDayId]);

  useEffect(() => {
    if (isDateMoreThanOneYearLater(finishDate, getLatestDate(startDate)))
      navigate("edit");
  }, [finishDate]);

  return (
    <>
      <EmptyDietWarningDialog
        open={modalOpened}
        onAccept={handleAccept}
        onCancel={onModalClose}
        title={t("addNutritionalProgram.step2.not_all_days_config")}
        content={t("addNutritionalProgram.step2.add_diet_to_days")}
      />

      <EmptyDietWarningDialog
        open={openEmptyIngredientsModal}
        onAccept={handleAccept}
        onCancel={() => setOpenEmptyIngredientsModal(false)}
        title={t("addNutritionalProgram.step2.not_all_diets_config")}
        content={t("addNutritionalProgram.step2.add_ingredients_to_diets")}
      />

      <Card
        className="mb-6"
        header={t("program.summary.program_schedule_card.title")}
        action={
          <IconButton onClick={onEdit} disabled={!canEdit}>
            <Edit size="w-5 h-5" className="stroke-current" />
          </IconButton>
        }
      >
        {!patientId && (
          <p className="font-semibold">
            {durationDays ?? "-"}{" "}
            {t("programs.days", { count: durationDays ?? 0 })}
          </p>
        )}
        {!!patientId && (
          <p className={"font-semibold"}>{`${startDate} - ${finishDate}`}</p>
        )}
      </Card>
    </>
  );
};
