import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { usePagination } from "@hooks";
import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { SectionsFiltersInput } from "@typeDefinitions/types";
import {
  parseNumber,
  parseOwnershipInput,
  parseSearchInput,
} from "@utils/filters";
import { searchSections } from "@client";

function parseSectionsFilters(filtersInput?: SectionsFiltersInput) {
  const params = new URLSearchParams();

  parseSearchInput(filtersInput, params);
  parseOwnershipInput(filtersInput, params);
  parseNumber(filtersInput?.category, "category_id", params);

  return params;
}

export const searchSectionsQueryKey = "searchSections";

export function useSearchSectionsQuery(
  categoryId?: number,
  options?: QueryOptions,
) {
  const [committedInputs, setCommittedInputs] =
    useState<SectionsFiltersInput>();
  const params = useMemo(
    () => parseSectionsFilters({ ...committedInputs }),
    [committedInputs, categoryId],
  );
  const { handlePaginationClick, paginatedParams } = usePagination(params);
  const { data, ...rest } = useQuery(
    [searchSectionsQueryKey, paginatedParams.toString()],
    () => searchSections(paginatedParams),
    buildDefaultQueryOptions(options),
  );

  return {
    sections: data?.data,
    links: data?.meta?.links,
    metadata: data?.meta,
    submit: setCommittedInputs,
    ...rest,
    handlePaginationClick,
  };
}
