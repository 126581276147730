import { ThemeProviderWrapperNew } from "themeNew";
import type { ReactNode } from "react";
import { RecipesViewWrapper as EducationalMaterialsViewWrapper } from "@views/dietician/Recipes/RecipesAndCollections.styles";
import { SingleCardPageLayout } from "@components/PageLayout";

type RN = ReactNode;

interface EducationalMaterialsLayoutProps {
  title: string;
  headerContent: RN;
  children: RN;
}

export const EducationalMaterialsLayout = ({
  headerContent,
  title,
  children,
}: EducationalMaterialsLayoutProps) => {
  return (
    <ThemeProviderWrapperNew>
      <EducationalMaterialsViewWrapper>
        <SingleCardPageLayout
          rebranding
          title={title}
          childrenClassName="flex flex-col gap-4"
          extra={headerContent}
        >
          {children}
        </SingleCardPageLayout>
      </EducationalMaterialsViewWrapper>
    </ThemeProviderWrapperNew>
  );
};
