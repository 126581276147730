import styled from "@emotion/styled";
import { MuiDataGrid } from "@components/MuiDataGrid";
import { IconButton } from "@components/IconButton";
import { Badge, Card } from "@mui/material";
import { DialogTitleStyled } from "@components/ModalWrapperNew/ModalWrapper.styled";
import { FormTextField } from "@components/FormTextField";

export const EducationalMaterialsGrid = styled(MuiDataGrid)`
  & .MuiDataGrid-row {
    background-color: #fff;
    box-shadow: unset;
    border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]} !important;
    display: flex;
    justify-content: center;

    &:hover {
      background: ${({ theme }) => theme.colors.neutral.light[200]};
      cursor: pointer;
    }
  }
`;

export const EducationalMaterialCardDiv = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.light2};
  border-radius: 12px;
`;

export const EducationalMaterialsIconButton = styled(IconButton)`
  display: flex;
  gap: 0 8px;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
`;

export const EducationalMaterialsBadge = styled(Badge)`
  display: flex;
  align-items: center;
  gap: 0 8px;
  background-color: ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;
  font-size: 14px;
  padding: 8px 12px;
  font-weight: 500;
  color: #5915b2;
`;

export const EducationalMaterialsEmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 8px;
  background-color: #fff;
  border-radius: 16px;
  border: 2px dashed ${({ theme }) => theme.colors.neutral.light[200]};
`;

export const EducationalMaterialsMenuButton = styled(IconButton)`
  display: flex;
  gap: 0 8px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: transparent;
  }
`;

export const EducationalMaterialsCardAction = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.neutral.light[300]};
  backdrop-filter: blur(8px);
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  font-size: 22px;
  border-radius: 8px;
  &:hover {
    background-color: #fff;
  }
`;

export const EducationalMaterialsDocumentBadge = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  margin-right: auto;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.survey.pink.light};
  color: ${({ theme }) => theme.colors.survey.pink.dark};
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
`;

export const EducationalMaterialsDialogTitle = styled(DialogTitleStyled)`
  font-size: 20px;
  font-weight: 600;
  padding: 0;
`;

export const EducationalMaterialsDialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12.5px 24px;
  box-shadow: 0px 2px 4px 0px #00000014;
`;

export const EducationalMaterialsFormTextField = styled(FormTextField)`
  & .MuiInputBase-input {
    padding: 10px 12px;
  }
`;

export const EducationalMaterialsDocumentFormWrapper = styled("div")`
  flex gap-x-10 my-10 mx-auto max-w-7xl;
  display: flex;
  gap: 24px;
  margin: 40px auto;
  max-width: 1280px;
`;

export const EducationalMaterialsTableCategoryBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  color: ${({ theme }) => theme.palette.info.dark};
  background-color: ${({ theme }) => theme.palette.info.light};
  padding: 4px 8px;
  border-radius: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
`;

export const EducationalMaterialsCard = styled(Card)`
  border-radius: 12px;
  padding: 4px;
  position: relative;
  height: 100%;
  box-shadow: none;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
