import { OuterLabel } from "@components/OuterLabel";
import { Card, CardHeader } from "../components";
import { useFetchGlobalPatientSettingsQuery } from "@hooks/queries/clinic";
import { Chip, Stack } from "@mui/material";
import { EditMeasurementsModal } from "../modals/EditMeasurementsModal";
import { useAppTranslation, useModalState } from "@hooks";
import { ContentProps } from "./Databases";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { getBodyMeasurementsById } from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";

export const Measurements = () => {
  const { data } = useFetchGlobalPatientSettingsQuery();

  if (!data) return null;

  return <MeasurementsContent data={data} />;
};

export const MeasurementsContent = ({ data }: ContentProps) => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { data: measurementsDict } = useFetchBodyMeasurementsQuery();

  const {
    measurement: { requiredImages, types },
  } = data.data;

  return (
    <Card>
      <CardHeader
        onEdit={onOpen}
        title={t("cooperation_settings.measurements.measurements")}
      />

      <OuterLabel
        label={t(
          "cooperation_settings.measurements.client_monitored_measurements",
        )}
      >
        <Stack direction="row" gap="8px" flexWrap="wrap">
          {types.length
            ? types.map(id => {
                const name = measurementsDict?.data
                  ? getBodyMeasurementsById(measurementsDict?.data, id)?.name
                  : id;
                return <Chip color="primary" key={id} label={name} />;
              })
            : "-"}
        </Stack>
      </OuterLabel>
      <OuterLabel
        label={t("cooperation_settings.measurements.required_photos")}
      >
        {requiredImages ? t("common.yes") : t("common.no")}
      </OuterLabel>

      <EditMeasurementsModal
        open={open}
        onClose={onClose}
        measurement={{ requiredImages, types }}
      />
    </Card>
  );
};
