import { useAppTranslation } from "@hooks";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import { DialogContent, Stack } from "@mui/material";
import { IconButton } from "@components/Alloweat/IconButton";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import { DialogSectionLayout } from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import CreateBodyMeasurementsForm from "@components/Client/FormBodyMeasurementsModal/CreateBodyMeasurementsForm";
import DateMeasurementField from "@components/Client/FormBodyMeasurementsModal/DateMeasurementField";
import BodyMeasurementsFieldsTabs from "@components/Client/FormBodyMeasurementsModal/BodyMeasurementsFieldsTabs";
import { CloseIcon } from "@icons/index";
import { FormCancelButton, FormSubmitButton } from "@components/FormButtons";

type CreateBodyMeasurementsModalProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const CreateBodyMeasurementsModal = ({
  open,
  onClose,
  onSuccess,
}: CreateBodyMeasurementsModalProps) => {
  const { t } = useAppTranslation();
  const handleSuccess = () => {
    onSuccess?.();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>
            {t(
              "patient.health_and_goal.body_measurement_tab.body_measurement_modal.create_title",
            )}
          </span>

          <IconButton onClick={onClose}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <CreateBodyMeasurementsForm onSuccess={handleSuccess}>
        <DialogContent>
          <DialogSectionLayout>
            <DateMeasurementField />
            <BodyMeasurementsFieldsTabs />
          </DialogSectionLayout>
        </DialogContent>
        <DialogActions>
          <FormCancelButton onClick={onClose} />
          <FormSubmitButton />
        </DialogActions>
      </CreateBodyMeasurementsForm>
    </Dialog>
  );
};

export default CreateBodyMeasurementsModal;
