import { DialogContentStyled } from "@components/ConfirmationModal/ConfirmationModal.styled";
import { Dialog } from "@mui/material";
import type { FC } from "react";
import { EducationalMaterialsDialogHeader } from "../EducationalMaterialsView.styled";
import { IconButton } from "@components/IconButton";
import { Close, CopyFrame, Pencil, TrashNew } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { SectionDetails } from "@views/dietician/Section/components/SectionDetails";
import {
  ModalType,
  useEducationalModalContext,
} from "../_hooks/useEducationalMaterialsContext";
import { useFetchSectionQuery } from "@hooks/queries";
import { useEducationalMaterialsCopy } from "../_hooks/useEducationalMaterialsCopy";

interface EducationalMaterialsPreviewProps {
  open: boolean;
  onClose: () => void;
}

export const EducationalMaterialsPreview: FC<
  EducationalMaterialsPreviewProps
> = ({ open, onClose }: EducationalMaterialsPreviewProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { materialId, openModal, closeModal } = useEducationalModalContext();

  const { handleCopy } = useEducationalMaterialsCopy(materialId ?? "");

  const { section } = useFetchSectionQuery(Number(materialId), {
    enabled: !!materialId,
  });

  const sectionCategory = isPolishChosen
    ? section?.data.programSectionCategory.description
    : section?.data.programSectionCategory.descriptionEn;

  const handleEditMaterial = () => {
    closeModal(ModalType.PREVIEW);
    if (materialId) {
      openModal(ModalType.CREATE_DOCUMENT, materialId);
    }
  };

  const handleDeleteMaterial = () => {
    closeModal(ModalType.PREVIEW);
    if (materialId) {
      openModal(ModalType.DELETE_MATERIAL, materialId);
    }
  };

  const handleCopyMaterial = () => {
    if (materialId) {
      handleCopy();
      closeModal(ModalType.PREVIEW);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{ unmountOnExit: true }}
      fullScreen={true}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: 0,
        },
      }}
    >
      <EducationalMaterialsDialogHeader>
        <div>
          <p className="text[12px] text-gray-450 font-medium">
            {t("educational_materials.title")}
          </p>
          <p className="text-[20px] font-semibold">{sectionCategory ?? "-"}</p>
        </div>
        <div className="flex gap-x-2">
          <IconButton
            color="secondary-purple"
            className="flex gap-x-4 hover:bg-transparent !border-primary-opacity !rounded-lg w-10 h-10"
            disabled={!section?.data.actions.canEdit}
            onClick={handleEditMaterial}
          >
            <Pencil className="text-lg" />
          </IconButton>
          <IconButton
            color="secondary-purple"
            className="flex hover:bg-transparent gap-x-2.5 !border-primary-opacity !rounded-lg w-10 h-10 !p-0"
            onClick={handleCopyMaterial}
          >
            <CopyFrame className="!w-8 !h-8" />
          </IconButton>
          <IconButton
            color="secondary-purple"
            className="flex gap-x-4 hover:bg-transparent !border-primary-opacity !rounded-lg w-10 h-10"
            disabled={!section?.data.actions.canDelete}
            onClick={handleDeleteMaterial}
          >
            <TrashNew className="text-lg" />
          </IconButton>
          <IconButton color="gray" onClick={onClose}>
            <Close size="w-[12px] h-[12px]" />
          </IconButton>
        </div>
      </EducationalMaterialsDialogHeader>
      <DialogContentStyled className="mt-6">
        {materialId && <SectionDetails sectionId={Number(materialId)} />}
      </DialogContentStyled>
    </Dialog>
  );
};
