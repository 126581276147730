import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { searchSectionsQueryKey } from "./useSearchSectionsQuery";
import { deleteSection } from "@client";
import { toast } from "react-toastify";
import { ApiEndpoints } from "@typeDefinitions";

export const useDeleteSectionMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((id: number) => deleteSection(id), {
    ...options,
    onSuccess: () => {
      toast.success(t("sections.success_removed"));

      //TODO: Improve this part
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: [ApiEndpoints.SearchSectionsNew],
        });
      }, 500);

      queryClient.invalidateQueries({
        queryKey: [searchSectionsQueryKey],
      });

      options?.onSuccess && options.onSuccess();
    },
    onError: () => {
      toast.error(t("educational_materials.errorRemovingMaterial"));
    },
  });
};
