import { Stack } from "@mui/material";
import SharedAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/SharedAction";
import EditAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/EditAction";
import ExportPdfAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/ExportPdfAction";
import MenuAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/MenuAction";
import CloseDrawerAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/CloseDrawerAction";
import ActiveAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/ActiveAction";
import { PatientProgramPreviewSearchResource } from "@client/resources/PatientProgramPreviewSearchResource";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";
import { useFetchClientQuery } from "@hooks/queries";

type ProgramActionButtonsProps = {
  program: PatientProgramPreviewSearchResource;
  patientId: number;
};

const ProgramActionButtons = ({
  program,
  patientId,
}: ProgramActionButtonsProps) => {
  const feature = useNewMobileAppFeatureEnabled();
  const { client } = useFetchClientQuery(patientId);
  const isClientActive = !!client?.active;

  return (
    <Stack direction="row" gap="4px">
      {!feature && (
        <ActiveAction
          program={program}
          patientId={patientId}
          disabled={!isClientActive}
        />
      )}
      {feature && (
        <SharedAction
          program={program}
          patientId={patientId}
          disabled={!isClientActive}
        />
      )}
      <EditAction
        patientId={patientId}
        programId={program.id}
        disabled={!isClientActive}
      />
      <ExportPdfAction
        patientId={patientId}
        programId={program.id}
        disabled={!isClientActive}
      />
      <MenuAction
        program={program}
        patientId={patientId}
        isClientActive={isClientActive}
      />
      <CloseDrawerAction />
    </Stack>
  );
};

export default ProgramActionButtons;
