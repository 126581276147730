import { OuterLabel } from "@components/OuterLabel";
import { Card, CardHeader, Text } from "./components";
import { ChangePasswordModal } from "@views/dietician/SettingsAccount/components/ChangePasswordModal";
import { useAppTranslation, useModalState } from "@hooks";

export const Password = () => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();

  return (
    <Card>
      <CardHeader
        title={t("settings.password.password")}
        editText={t("settings.password.change_password")}
        onEdit={onOpen}
      />

      <OuterLabel label={t("settings.password.your_password")}>
        <Text>**********</Text>
      </OuterLabel>

      <ChangePasswordModal open={open} onClose={onClose} />
    </Card>
  );
};
