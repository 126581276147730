import PatientProgramsGrid from "@views/dietician/PatientPrograms/PatientProgramsGrid";
import { useState } from "react";
import useFetchClientPrograms from "@hooks/queries/client/program/useFetchClientPrograms";
import PatientProgramsEmptyState from "@views/dietician/PatientPrograms/PatientProgramsEmptyState";

type PatientProgramsProps = {
  patientId: number;
};
const PatientPrograms = ({ patientId }: PatientProgramsProps) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const { data: programs, isFetching } = useFetchClientPrograms({
    patientId,
    page: page + 1,
    perPage,
  });

  if (!isFetching && programs?.data.length === 0) {
    return <PatientProgramsEmptyState patientId={patientId} />;
  }

  return (
    <>
      <PatientProgramsGrid
        patientId={patientId}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        programs={programs}
        isFetching={isFetching}
      />
    </>
  );
};

export default PatientPrograms;
