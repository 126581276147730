import { SetStateAction, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { Button, Drawer, InputAdornment } from "@mui/material";
import { omit } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

import { List, Loupe, Plus } from "@assets/icons";
import { FourSquares } from "@assets/icons/DesignSystem";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { MealPatternsDataGrid } from "@components/MealPatternsDataGrid/MealPatternsDataGrid";
import { mapMealRows } from "@components/MealPatternsDataGrid/mealPatternsDataGridUtils";
import { MealPatternsGridLayout } from "@components/MealPatternsGridLayout";
import { SingleCardPageLayout } from "@components/PageLayout";
import { ITEMS_ON_PAGE } from "@components/PaginationNew";
import { CollectionPreview } from "@components/PreviewDrawer/Collection";
import { RecipePreview } from "@components/PreviewDrawer/Recipe";
import { RecipesDataGrid } from "@components/RecipesDataGrid/RecipesDataGrid";
import { mapRecipeRows } from "@components/RecipesDataGrid/recipesDataGridUtils";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
  RecipesFiltersNew,
  useRecipesFiltersForm,
} from "@components/RecipesFiltersNew";
import { FormTextFieldClearableStyled } from "@components/RecipesFiltersNew/RecipesFilters.styled";
import { RecipesGridLayout } from "@components/RecipesGridLayout";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { ListTabs, useAppTranslation, useListTabs, useUserRoles } from "@hooks";
import {
  useSearchMealPatternsQueryNew,
  useSearchRecipesQueryNew,
} from "@hooks/queries";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { MEAL_NEW, NEW_RECIPE } from "@routes";

import { defaultFilters } from "@components/RecipesFiltersNew/recipesFiltersUtils";
import { ThemeProviderWrapperNew } from "themeNew";
import { RecipesViewWrapper } from "./RecipesAndCollections.styles";
import { AddButton } from "./AddButton";

interface RecipesAndCollectionsProps {
  collections?: boolean;
}

export const RecipesAndCollections = ({
  collections,
}: RecipesAndCollectionsProps) => {
  const { t } = useAppTranslation();
  const form = useRecipesFiltersForm();
  const tab = form.watch("tabs");

  const base = collections ? BaseSwitch.COLLECTIONS : BaseSwitch.RECIPES;

  const [view, setView] = useState<ViewTabs>(ViewTabs.GRID);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const { account } = useFetchDietitianAccountQuery();
  const { isAdminClinicMember } = useUserRoles();
  const { adminTabs, regularTabs } = useListTabs();
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();

  const {
    recipes,
    submit: submitRecipes,
    isFetching: isFetchingRecipes,
    meta: metaRecipes,
  } = useSearchRecipesQueryNew({
    keepPreviousData: true,
    enabled: base === BaseSwitch.RECIPES,
  });
  const {
    meals,
    meta: metaMeals,
    submit: submitMeals,
    isFetching: isFetchingMeals,
  } = useSearchMealPatternsQueryNew({
    keepPreviousData: true,
    enabled: base === BaseSwitch.COLLECTIONS,
  });

  const meta = base === BaseSwitch.RECIPES ? metaRecipes : metaMeals;
  const isFetching = isFetchingRecipes || isFetchingMeals;

  const tabs: { label: string; id: ListTabs }[] = useMemo(
    () => (isAdminClinicMember ? adminTabs : regularTabs),
    [t, isAdminClinicMember],
  );

  const tabsWithCount = useMemo(() => {
    return tabs.map(option => ({
      ...option,
      label:
        option.id === tab && !!meta?.total
          ? `${option.label} (${meta?.total})`
          : option.label,
    }));
  }, [meta, t, tabs]);

  const mappedRows = useMemo(
    () => mapRecipeRows(recipes, account?.id ?? 0),
    [recipes, mapRecipeRows, account?.id, tab, base],
  );

  const mappedMeals = useMemo(
    () => mapMealRows(meals, account?.id ?? 0),
    [meals, mapMealRows, account?.id, tab, base],
  );

  const submit = (value: SetStateAction<RecipesFiltersFormInput>) => {
    submitRecipes(value);
    submitMeals(value);
  };

  const handleChangePage = (id: number) => {
    submit(state => ({ ...state, page: id + 1 }));
  };
  const handleChangePerPage = (perPage: number) => {
    submit(state => ({ ...state, perPage }));
  };

  const handleChangeTab = (tab: string) => {
    submit(state => ({
      ...state,
      tabs: tab as ListTabs,
      page: undefined,
    }));
    form.setValue("page", undefined);
  };

  const handleChangeBase = (base: string) => {
    navigate(`/${base}`);
    setSelectedId(null);
    submit(state => ({
      ...state,
      page: undefined,
    }));
  };

  const handleSubmitForm = (data: RecipesFiltersFormInput) => {
    const nutrientsWithNonDefaultValues = Object.entries(
      data.nutrients || {},
    ).reduce((acc, [key, value]) => {
      const defaultValue = DEFAULT_MACROS_RANGE[key];
      if (
        !defaultValue ||
        defaultValue[0] !== value[0] ||
        defaultValue[1] !== value[1]
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [id: string]: [number, number] });

    submit({
      ...omit(data, ["nutrients"]),
      page: undefined,
      nutrients: nutrientsWithNonDefaultValues,
    });
  };

  const handleResetFilters = () => {
    form.reset({ tabs: ListTabs.ALL, ...defaultFilters }, { keepDirty: true });
    form.setValue("page", undefined, { shouldDirty: true });

    setSearchParams(prev => {
      const params = new URLSearchParams();

      const perPage = prev.get("perPage");

      if (perPage) params.set("perPage", perPage);

      return params;
    });
  };

  return (
    <ThemeProviderWrapperNew>
      <RecipesViewWrapper>
        <SingleCardPageLayout
          rebranding
          title={t("common.recipes")}
          childrenClassName="flex flex-col gap-[24px]"
          extra={<AddButton />}
        >
          <div className="grid gap-[16px]">
            <FormProvider {...form}>
              <div className="flex gap-[4px]">
                <TabSwitch
                  onChange={handleChangeBase}
                  tabs={[
                    {
                      id: BaseSwitch.RECIPES,
                      label: t("recipes.recipes_list"),
                    },
                    {
                      id: BaseSwitch.COLLECTIONS,
                      label: t("recipes.recipe_collections"),
                    },
                  ]}
                  value={base}
                />
                <FormTextFieldClearableStyled
                  name="query"
                  size="small"
                  placeholder={
                    base === BaseSwitch.RECIPES
                      ? t("filters.search_recipe")
                      : t("collections.search")
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Loupe />
                      </InputAdornment>
                    ),
                  }}
                />
                <RecipesFiltersNew total={meta?.total} />
              </div>

              <div className="flex justify-between">
                <TabsChipsFiltersForm
                  name="tabs"
                  options={tabsWithCount}
                  onChangeExternal={handleChangeTab}
                />

                <TabSwitch
                  onChange={val => setView(val)}
                  tabs={[
                    {
                      id: ViewTabs.GRID,
                      label: <FourSquares size="w-[16px] h-[16px]" />,
                    },
                    {
                      id: ViewTabs.LIST,
                      label: <List size="w-[16px] h-[16px]" />,
                    },
                  ]}
                  value={view}
                />
              </div>
              <AutosaveWatchComponent
                isLoading={isFetching}
                onSubmit={handleSubmitForm}
              />
            </FormProvider>

            {base === BaseSwitch.COLLECTIONS && view === ViewTabs.LIST && (
              <MealPatternsDataGrid
                rows={mappedMeals}
                isOnePage={false}
                perPage={metaMeals?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
                onPageChange={handleChangePage}
                page={metaMeals?.currentPage ? metaMeals.currentPage - 1 : 0}
                setPerPage={handleChangePerPage}
                rowCount={metaMeals?.total}
                loading={isFetching}
                onSelectRow={(id: number | null) => setSelectedId(id)}
                resetFilters={handleResetFilters}
              />
            )}

            {base === BaseSwitch.COLLECTIONS && view === ViewTabs.GRID && (
              <MealPatternsGridLayout
                meals={mappedMeals}
                isOnePage={false}
                perPage={metaMeals?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
                onPageChange={handleChangePage}
                page={metaMeals?.currentPage ? metaMeals.currentPage - 1 : 0}
                setPerPage={handleChangePerPage}
                rowCount={metaMeals?.total}
                loading={isFetching}
                onMealSelect={id => setSelectedId(id)}
                resetFilters={handleResetFilters}
                isLoading={isFetchingRecipes || isFetchingMeals}
              />
            )}

            {base === BaseSwitch.RECIPES && view === ViewTabs.LIST && (
              <RecipesDataGrid
                rows={mappedRows}
                isOnePage={false}
                perPage={metaRecipes?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
                onPageChange={handleChangePage}
                page={
                  metaRecipes?.currentPage ? metaRecipes.currentPage - 1 : 0
                }
                setPerPage={handleChangePerPage}
                rowCount={metaRecipes?.total}
                loading={isFetching}
                onSelectRow={(id: number | null) => setSelectedId(id)}
                resetFilters={handleResetFilters}
              />
            )}

            {base === BaseSwitch.RECIPES && view === ViewTabs.GRID && (
              <RecipesGridLayout
                recipes={mappedRows}
                isOnePage={false}
                perPage={metaRecipes?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
                onPageChange={handleChangePage}
                page={
                  metaRecipes?.currentPage ? metaRecipes.currentPage - 1 : 0
                }
                setPerPage={handleChangePerPage}
                rowCount={metaRecipes?.total}
                loading={isFetching}
                onRecipeSelect={id => setSelectedId(id)}
                resetFilters={handleResetFilters}
                isLoading={isFetchingRecipes || isFetchingMeals}
              />
            )}

            <Drawer open={!!selectedId} variant="persistent" anchor="right">
              {base === BaseSwitch.RECIPES && (
                <RecipePreview
                  key={selectedId}
                  recipeId={selectedId}
                  onClose={() => setSelectedId(null)}
                />
              )}
              {base === BaseSwitch.COLLECTIONS && (
                <CollectionPreview
                  key={selectedId}
                  mealId={selectedId}
                  onClose={() => setSelectedId(null)}
                />
              )}
            </Drawer>
          </div>
        </SingleCardPageLayout>
      </RecipesViewWrapper>
    </ThemeProviderWrapperNew>
  );
};

enum ViewTabs {
  LIST = "1",
  GRID = "2",
}

enum BaseSwitch {
  RECIPES = "recipes",
  COLLECTIONS = "meals",
}
