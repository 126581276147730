import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchUnreadNotificationsQueryKey } from "./useFetchAccountUnreadNotifications";
import { postAllNotificationsAsRead } from "@client";
import {
  accountNotificationQueryKey,
  accountUnreadNotificationQueryKey,
} from "@components/NotificationMenu/components/hooks";

export const usePostAllNotificationAsRead = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation(() => postAllNotificationsAsRead(), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([fetchUnreadNotificationsQueryKey]);
      queryClient.invalidateQueries([accountNotificationQueryKey]);
      queryClient.invalidateQueries([accountUnreadNotificationQueryKey]);
      options?.onSuccess && options?.onSuccess();
    },
  });
};
