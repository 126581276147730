import { NutrientsDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useForm } from "react-hook-form";

export const useEditProfileForm = (defaultValues?: EditProfileFormProps) => {
  const form = useForm<EditProfileFormProps>({ defaultValues });

  return form;
};

export type EditProfileFormProps = {
  kcal: boolean;
  macro: boolean;
  micro: boolean;
  important: boolean;
  targetMeals: boolean;
  importantNutrientsId: {
    [key: string]: boolean;
  };
};

export const mapEditProfileForm = (
  data: NutrientsDto | undefined,
): EditProfileFormProps | undefined => {
  if (!data) return;

  const {
    importantIds,
    show: { important, kcal, macro, micro, targetMacroMeals },
  } = data;
  return {
    important,
    kcal,
    macro,
    micro,
    importantNutrientsId: Object.fromEntries(
      importantIds.map(id => [id, true]),
    ),
    targetMeals: targetMacroMeals,
  };
};
export const mapEditProfileRequest = ({
  importantNutrientsId,
  targetMeals,
  ...rest
}: EditProfileFormProps): NutrientsDto => {
  return {
    importantIds: Object.entries(importantNutrientsId)
      .filter(([id, value]) => value)
      .map(([id]) => Number(id)),
    show: {
      ...rest,
      targetMacroMeals: targetMeals,
    },
  };
};
