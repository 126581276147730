import { Button, Stack, styled } from "@mui/material";
import { EditPdfFormProps } from "./useEditPdfForm";
import { Control, Controller, useController, useWatch } from "react-hook-form";
import { ColorPicker } from "@components/ColorPicker";
import usePdfColorsQuery from "@Pdf/hooks/query/usePdfColorsQuery";
import { useMemo } from "react";
import PdfTemplatePicker from "@Pdf/components/PdfTemplatePicker/PdfTemplatePicker";
import { useAppTranslation, useModalState } from "@hooks";
import usePdfImagesQuery from "@Pdf/hooks/query/usePdfImagesQuery";
import DeletePdfColorButton from "@Pdf/components/Buttons/DeletePdfColorButton";
import ColorPickerModal from "@Pdf/components/ColorPickerModal/ColorPickerModal";
import { PlusIcon } from "@assets/icons_new";
import { Template } from "@client/resources/GlobalClinicPatientSettingsResource";

interface AppearanceSectionProps {
  control: Control<EditPdfFormProps>;
  enterprise?: boolean;
}
export const AppearanceSection = ({
  control,
  enterprise,
}: AppearanceSectionProps) => {
  const { t } = useAppTranslation();
  const colorsQuery = usePdfColorsQuery();
  const imagesQuery = usePdfImagesQuery();
  const watchedValues = useWatch({ control });
  const {
    field: { onChange: onChangeColor },
  } = useController({ control, name: "color" });
  const [openedModal, onOpenModal, onCloseModal] = useModalState();

  const colors = useMemo(
    () => (colorsQuery.data ?? []).map(item => item.color),
    [colorsQuery.data],
  );
  const coverPages = useMemo(
    () =>
      (imagesQuery.data ?? []).map((item, i) => ({
        _id: item.id,
        id: item.id.toString(),
        name: `${t("pdf.cover")} ${i + 1}`,
        url: item.url,
        delete: item.action.delete,
      })),
    [imagesQuery],
  );
  const colorId = useMemo(
    () =>
      (colorsQuery.data ?? []).find(
        ({ color, action }) => action.delete && color === watchedValues.color,
      )?.id,
    [watchedValues.color, colors],
  );
  return (
    <Stack gap="24px" paddingTop="16px">
      <Stack gap="8px">
        <Subtitle>
          {t("cooperation_settings.modal.pdf.choose_primary_color")}
        </Subtitle>
        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorPicker
              {...field}
              colors={colors}
              outlined
              onChange={value => {
                field.onChange(value);
              }}
            />
          )}
        />
      </Stack>

      {enterprise && (
        <div className={"flex gap-[8px]"}>
          <DeletePdfColorButton colorId={colorId} disabled={!colorId} />
          <Button
            style={{ width: "fit-content" }}
            variant="text"
            onClick={onOpenModal}
            startIcon={<PlusIcon />}
          >
            {t("cooperation_settings.modal.pdf.add_custom_color")}
          </Button>
          <ColorPickerModal
            open={openedModal}
            onClose={onCloseModal}
            onSubmitted={onChangeColor}
          />
        </div>
      )}

      <Stack gap="8px">
        <Subtitle>
          {t("cooperation_settings.modal.pdf.choose_template")}
        </Subtitle>
        <Controller
          name="template"
          control={control}
          render={({ field: { value, onChange } }) => (
            <PdfTemplatePicker
              value={value.toString()}
              templates={[
                {
                  id: Template.PHOTO.toString(),
                  url: "/static/pdf/photo-cover.jpg",
                  name: t("cooperation_settings.modal.pdf.photo"),
                  delete: false,
                },
                {
                  id: Template.HAND_DRAWN.toString(),
                  url: "/static/pdf/hand-drawn-cover.jpg",
                  name: t("cooperation_settings.modal.pdf.hand_drawn"),
                  delete: false,
                },
                {
                  id: Template.PRINT.toString(),
                  url: "/static/pdf/print-cover.jpg",
                  name: t("cooperation_settings.modal.pdf.print"),
                  delete: false,
                },
              ]}
              onChange={value => {
                onChange(Number(value));
              }}
            />
          )}
        />
      </Stack>

      <Stack>
        <Subtitle className={"font-semibold text-neutral-dark-700"}>
          {t("cooperation_settings.modal.pdf.choose_photo")}
        </Subtitle>
        <Controller
          name="mainPhotoId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <PdfTemplatePicker
              value={value?.toString() ?? ""}
              addable={enterprise}
              templates={coverPages}
              onChange={value => {
                onChange(value);
              }}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};

const Subtitle = styled("span")`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
