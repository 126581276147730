import { ProductAccordion } from "./Accordions/ProductAccordion";
import { Title } from "./RecipesAndProducts.styled";
import { RecipeAccordion } from "./Accordions/RecipeAccordion";
import { useAppTranslation } from "@hooks";
import { scaleNutrients } from "@utils";
import { FoodWrapperDto, RecipeWrapperDto } from "@client/diets";
import { getTranslation } from "@utils/translations";

interface RecipesAndProductsTab {
  products: FoodWrapperDto[];
  recipes: RecipeWrapperDto[];
  collectionId: number;
}
export const RecipesAndProductsTab = ({
  products,
  recipes,
  collectionId,
}: RecipesAndProductsTab) => {
  const { t, currentLanguage } = useAppTranslation();

  return (
    <div className="grid gap-[16px]">
      {!!recipes.length && <Title>{t("common.recipes")}</Title>}

      {recipes.map(({ id, recipe, servings }) => {
        return (
          <RecipeAccordion
            key={id}
            id={id}
            name={getTranslation(recipe.translations, currentLanguage)}
            nutrients={recipe.nutrients}
            tags={recipe.tags.map(t => t.id)}
            servings={servings}
            totalServings={recipe.servings}
            img={recipe.image?.url ?? null}
            collectionId={collectionId}
          />
        );
      })}

      {!!products.length && <Title>{t("common.products")}</Title>}

      {products.map(({ id, grams, measure, product }) => {
        return (
          <ProductAccordion
            id={id}
            key={id}
            name={getTranslation(product.translations, currentLanguage)}
            nutrients={scaleNutrients(product.nutrients, grams)}
            grams={grams}
            measureId={measure.id}
            img={product.image?.url ?? null}
            collectionId={collectionId}
          />
        );
      })}
    </div>
  );
};
