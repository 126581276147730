import { useEffect, useMemo } from "react";

import { MeasureDto } from "@client";
import {
  ProductRow,
  ProductsSummary,
  TableHeaders,
} from "@components/PreviewDrawer/Recipe/NutrientsTab/ProductsTable";
import { useAppTranslation } from "@hooks";
import { usePostPatientProductsContextMutation } from "@hooks/queries";
import { Nutrient } from "@typeDefinitions";

interface ProductsTableProps {
  products: ProductIf[];
  nutrients: Nutrient[];
  selectedServings: number;
}

export interface ProductIf {
  id: number;
  foodId: number;
  nutrients: Nutrient[];
  name: string;
  grams: number;
  measureId: number;
  measures: MeasureDto[];
}

export const ProductsTable = ({
  products,
  nutrients,
  selectedServings,
}: ProductsTableProps) => {
  const { t } = useAppTranslation();
  const foodIds = useMemo(() => products.map(({ id }) => id), [products]);
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();

  useEffect(() => {
    if (foodIds.length) fetchProducts({ payload: foodIds });
  }, [foodIds.length]);

  return (
    <div className="grid gap-[8px]">
      <TableHeaders />
      <ProductsSummary
        nutrients={nutrients}
        selectedServingsExternal={selectedServings}
      />
      {products.map((p, idx) => (
        <ProductRow {...p} key={idx} />
      ))}
    </div>
  );
};
