import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CopyFrame = (props: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" {...props}>
    <path
      d="M7.125 18.3188H12.8125C13.0156 18.3188 13.2188 18.1411 13.2188 17.9126V16.2876H14.4375V17.9126C14.4375 18.8267 13.7012 19.5376 12.8125 19.5376H7.125C6.21094 19.5376 5.5 18.8267 5.5 17.9126V12.2251C5.5 11.3364 6.21094 10.6001 7.125 10.6001H8.75V11.8188H7.125C6.89648 11.8188 6.71875 12.022 6.71875 12.2251V17.9126C6.71875 18.1411 6.89648 18.3188 7.125 18.3188ZM11.1875 14.2563H16.875C17.0781 14.2563 17.2812 14.0786 17.2812 13.8501V8.1626C17.2812 7.95947 17.0781 7.75635 16.875 7.75635H11.1875C10.959 7.75635 10.7812 7.95947 10.7812 8.1626V13.8501C10.7812 14.0786 10.959 14.2563 11.1875 14.2563ZM9.5625 13.8501V8.1626C9.5625 7.27393 10.2734 6.5376 11.1875 6.5376H16.875C17.7637 6.5376 18.5 7.27393 18.5 8.1626V13.8501C18.5 14.7642 17.7637 15.4751 16.875 15.4751H11.1875C10.2734 15.4751 9.5625 14.7642 9.5625 13.8501Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
