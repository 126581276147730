import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringMealsClient } from "@hooks/queries/client/monitoring";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { PatientMealsMonitoringResource } from "@client/resources/PatientMealsMonitoringResource";
import { Dayjs } from "dayjs";
import { useMemo } from "react";
import uniqBy from "lodash/uniqBy";
import SymptomsSummaryCard from "@views/dietician/PatientMonitoring2/components/Symptoms/SummaryCard/SymptomsSummaryCard";

const SymptomsSummaryCardOfDay = () => {
  const { patientId, selectedDays, type } = useMonitoring();

  const { data } = useFetchMonitoringMealsClient(
    {
      patientId,
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
    },
    { enabled: type.type === "day" },
  );

  const symptoms = useMemo(
    () => getSymptoms(data?.data ?? [], selectedDays.selectDays.from),
    [data, selectedDays],
  );

  return <SymptomsSummaryCard tags={symptoms} />;
};

const getSymptoms = (data: PatientMealsMonitoringResource[], day: Dayjs) => {
  return uniqBy(
    data
      .filter(item => item.date === day.format("YYYY-MM-DD"))
      .map(item => item.meals.map(meal => meal.tags).flat())
      .flat(),
    tag => tag.id,
  );
};

export default SymptomsSummaryCardOfDay;
