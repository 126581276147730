import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, useMediaQuery, useTheme } from "@mui/material";

import { ArrowDown } from "@assets/icons/Arrows";
import { DropDownMenu, MenuItemIf } from "@components/DropDownMenu";
import { useAppTranslation, useUserRoles } from "@hooks";
import { useLogoutMutation } from "@hooks/queries";
import CreditCardIcon from "@icons/creditCard.svg?react";
import ExitArrowIcon from "@icons/exitArrow.svg?react";
import HandshakeIcon from "@icons/handshake.svg?react";
import PersonIcon from "@icons/person.svg?react";
import SquareWithPointsIcon from "@icons/squareWithPoints.svg?react";
import {
  COOPERATION_SETTINGS,
  DIETICIAN_SETTINGS,
  INTEGRATIONS,
  PATIENTS,
  SETTINGS,
} from "@routes";

import { NameWrapper } from "../Header.styled";

interface UserSettingsDropDownProps {
  fullName: string;
}

export const UserSettingsDropDown = ({
  fullName,
}: UserSettingsDropDownProps) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => setAnchorEl(null);
  const { colors, breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const { isClinicAdmin } = useUserRoles();
  const { logout } = useLogoutMutation();
  const navigate = useNavigate();

  const userDependentOption = useMemo(
    () =>
      isClinicAdmin
        ? [
            {
              text: t("dropdown.payments"),
              onClick: () => navigate(DIETICIAN_SETTINGS),
              Icon: () => <CreditCardIcon />,
              description: t("dropdown.manage_office"),
            },
            {
              text: t("dropdown.cooperation"),
              onClick: () => navigate(COOPERATION_SETTINGS),
              Icon: () => <HandshakeIcon />,
              description: t("dropdown.manage_settings"),
            },
          ]
        : [
            {
              text: t("dropdown.integrations"),
              onClick: () => navigate(INTEGRATIONS),
              Icon: () => <SquareWithPointsIcon />,
              description: t("dropdown.integrate_calendar"),
            },
          ],
    [isClinicAdmin, t],
  );

  const options = useMemo<MenuItemIf[][]>(
    () => [
      [
        {
          text: t("dropdown.account"),
          onClick: () => navigate(SETTINGS),
          Icon: () => <PersonIcon />,
          description: t("dropdown.manage_profile"),
        },
        ...userDependentOption,
      ],
      [
        {
          text: t("dropdown.logout"),
          onClick: () => logout(),
          Icon: () => <ExitArrowIcon />,
        },
      ],
    ],
    [userDependentOption],
  );

  return (
    <>
      <NameWrapper>{fullName}</NameWrapper>
      <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
        <ArrowDown size="w-5 h-5" fill={colors.neutral.dark[900]} />
      </IconButton>
      <DropDownMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        options={options}
      />
    </>
  );
};
