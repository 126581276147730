import { Chip, Stack } from "@mui/material";
import { ReactNode } from "react";
export type BooleanRecord = Record<string, any>;
export const mapChipsFromObject = <T extends BooleanRecord>(
  object: T,
  t: (
    name: string,
    options?: {
      count?: number;
      lng?: string;
      variable?: string | ReactNode;
    },
  ) => string,
) => {
  const list = Object.entries(object);

  return (
    <Stack direction="row" gap="8px" flexWrap="wrap">
      {list.some(([_, value]) => value)
        ? list
            .filter(([_, value]) => value)
            .map(([name]) => (
              <Chip label={t(traslations[name])} key={name} color="primary" />
            ))
        : "-"}
    </Stack>
  );
};

const traslations: Record<string, string> = {
  kcal: "cooperation_settings.modal.profile.energy",
  macro: "cooperation_settings.modal.profile.macronutrients",
  micro: "cooperation_settings.modal.profile.all_nutritional_values",
  important: "cooperation_settings.modal.profile.key_values",
  targetMacroMeals:
    "cooperation_settings.modal.profile.macronutrients_per_meal",
  alloweat: "cooperation_settings.modal.databases.alloweat_recipes",
  clinic: "cooperation_settings.modal.databases.dietitian_recipes",
  glutenFree: "cooperation_settings.modal.databases.gluten_free",
  lactoseFree: "cooperation_settings.modal.databases.lactose_free",
  vegan: "cooperation_settings.modal.databases.vegan",
  vegetarian: "cooperation_settings.modal.databases.vegetarian",
  chat: "cooperation_settings.modal.mobile.chat",
  mealRating: "cooperation_settings.modal.mobile.meal_review",
  measurement: "cooperation_settings.modal.mobile.measurements",
  discover: "cooperation_settings.modal.mobile.discover",
  hydration: "cooperation_settings.modal.mobile.hydration",
  inspired: "cooperation_settings.modal.discover.get_inspired",
  meals: "cooperation_settings.modal.discover.what_are_you_craving",
  quiz: "cooperation_settings.modal.discover.quiz",
  name: "cooperation_settings.modal.pdf.name",
  address: "cooperation_settings.modal.pdf.address",
  email: "cooperation_settings.modal.pdf.email_address",
  phone: "cooperation_settings.modal.pdf.phone_number",
  logo: "cooperation_settings.modal.pdf.logo",
  weekDay: "cooperation_settings.modal.pdf.weekday",
  date: "cooperation_settings.modal.pdf.date",
  typeDay: "cooperation_settings.modal.pdf.day_type",
  mealHour: "cooperation_settings.modal.pdf.meal_hour",
  alloweatLogo: "cooperation_settings.modal.pdf.alloweat_logo",
};
