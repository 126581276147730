import React, { useState, useEffect, ReactNode } from "react";
import { Path } from "react-hook-form";
import classNames from "classnames";
import { RadioTileProps } from "@components/RadioTile/RadioTile";

export const RadioTileGroup = <T,>(props: RadioTileGroupProps<T>) => {
  const {
    children,
    name,
    onChange,
    disabled,
    defaultValue,
    fullWidth,
    error,
    className,
    variant = "standard",
  } = props;

  const [value, setValue] = useState(defaultValue || undefined);

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement<RadioTileProps>(child)) {
      const props: RadioTileProps = {
        selectedValue: value,
        name,
        variant,
        disabled,
        onChange: v => setValue(v),
        className: classNames(
          {
            "mr-4": variant !== "outlined",
          },
          child.props.className,
        ),
      };
      return React.cloneElement(child, props);
    }
    return child;
  });

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div
        className={classNames(
          {
            "flex flex-wrap items-center rounded-md p-1": true,
            "justify-center bg-gray-500": variant === "outlined",
            "cursor-not-allowed": disabled,
            "w-full": fullWidth,
            "w-max": !fullWidth,
          },
          className,
        )}
      >
        {childrenWithProps}
      </div>
      {!!error && (
        <p className="text-xs text-light text-red pt-1 pl-1">{error}</p>
      )}
    </>
  );
};

export interface RadioTileGroupProps<T> {
  name: Path<T>;
  onChange?: (val?: number | string) => void;
  error?: string;
  disabled?: boolean;
  defaultValue?: number | string;
  children?: ReactNode;
  fullWidth?: boolean;
  className?: string;
  variant?: "standard" | "outlined" | "primary";
}
