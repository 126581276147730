import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import CalendarIcon from "@assets/icons_new/calendar.svg?react";
import CancelIcon from "@assets/icons_new/cancel.svg?react";
import ChatBubbleIcon from "@assets/icons_new/chatBubble.svg?react";
import PenIcon from "@assets/icons_new/edit.svg?react";
import EllipsisVerticalIcon from "@assets/icons_new/ellipsisVerticalClean.svg?react";
import PlusIcon from "@assets/icons_new/plusClean.svg?react";
import ScaleIcon from "@assets/icons_new/scale.svg?react";
import SurveyIcon from "@assets/icons_new/survey.svg?react";
import TrashIcon from "@assets/icons_new/trash.svg?react";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { DropDownMenu, MenuItemIf } from "@components/DropDownMenu";
import { EditClientInfoModal } from "@components/EditClientInfoModal";
import {
  useAppTranslation,
  useModalState,
  useSubscription,
  useUpdateClientPayments,
} from "@hooks";
import { useDeleteClientMutation } from "@hooks/queries";
import { Alert, Button, IconButton, styled, Tooltip } from "@mui/material";
import { PATIENTS } from "@routes";
import { AddVisitModal } from "@views/dietician/Diet/components/AddVisitModal";
import { SendQuestionnaireModal } from "@views/dietician/Questionnaires/SendQuestionnaireModal";
import CreateBodyMeasurementsModal from "@components/Client/FormBodyMeasurementsModal/CreateBodyMeasurementsModal";
import { ConfirmPopover } from "@components/ConfirmPopper";
import { DollarSign } from "@assets/icons";
import PersonIcon from "@assets/icons_new/personWithCheck.svg?react";
import { toast } from "react-toastify";

interface ExtraButtonsProps {
  chat?: boolean;
  id: number;
  hasEmail?: boolean;
  isActive?: boolean;
  onChatOpen: () => void;
}

export const ExtraButtons = ({
  chat,
  id,
  hasEmail,
  isActive,
  onChatOpen,
}: ExtraButtonsProps) => {
  const { t } = useAppTranslation();
  const { mutate: updateClient } = useUpdateClientPayments();
  const { mutate: deleteClient } = useDeleteClientMutation();
  const { isTrial } = useSubscription();
  const notActive = !isActive;
  const noEmail = !hasEmail;

  const navigate = useNavigate();
  const [addAnchorEl, setAddAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const [openVisit, onOpenVisit, onCloseVisit] = useModalState();
  const [openQuestionnaire, onOpenQuestionnaire, onCloseQuestionnaire] =
    useModalState();
  const [openEditClient, onOpenEditClient, onCloseEditClient] = useModalState();
  const [openMeasurements, onOpenMeasurements, onCloseMeasurements] =
    useModalState();
  const [openDelete, onOpenDelete, onCloseDelete] = useModalState();

  const activateClientButtonRef = useRef<HTMLButtonElement | null>(null);
  const [openConfirmPopover, setOpenConfirmPopover] = useState(false);

  const updateClientActivation = (
    isActive: boolean,
    onSuccess?: () => void,
  ) => {
    updateClient(
      {
        id: id.toString(),
        payload: { activeUntil: null, isActive },
      },
      {
        onSuccess,
      },
    );
  };

  const activateClient = () => {
    updateClientActivation(true, () => setOpenConfirmPopover(false));
  };

  const deactivateClient = () => {
    updateClientActivation(false);
  };

  const toggleClientActivation = () => {
    if (!isActive) {
      setOpenConfirmPopover(true);
      return;
    }

    deactivateClient();
  };

  const handleDelete = () =>
    deleteClient(id, {
      onSuccess: () => navigate(PATIENTS),
    });

  const addMenuOptions: MenuItemIf[] = [
    {
      onClick: onOpenVisit,
      text: t("patient.add.appointment"),
      IconNew: CalendarIcon,
    },
    {
      onClick: onOpenMeasurements,
      text: t("patient.add.measurement"),
      IconNew: ScaleIcon,
      disabled: notActive,
      tooltip: notActive
        ? t("client_profile.navigation_buttons.tooltip_measurement")
        : undefined,
    },
    {
      onClick: onOpenQuestionnaire,
      text: t("patient.add.questionnaire"),
      IconNew: SurveyIcon,
      disabled: notActive || noEmail,
      tooltip:
        notActive || noEmail
          ? t("client_profile.navigation_buttons.tooltip_questionnaire")
          : undefined,
    },
  ];

  const deactivateMenuOptions: MenuItemIf = {
    onClick: toggleClientActivation,
    text: t("patient.deactivate"),
    IconNew: CancelIcon,
  };

  const moreMenuOptions: MenuItemIf[] = [
    {
      onClick: onOpenEditClient,
      text: t("common.edit"),
      IconNew: PenIcon,
    },
    ...(isActive ? [deactivateMenuOptions] : []),
    {
      onClick: onOpenDelete,
      text: t("common.delete"),
      IconNew: TrashIcon,
    },
  ];

  const toastOnSuccessVisit = () => {
    toast.success(t("client_profile.history.appointment_added"));
  };

  const toastOnSuccessMeasurements = () => {
    toast.success(t("client_profile.history.measurement_added"));
  };

  const tooltipText = useMemo(() => {
    if (!hasEmail) return t("client_profile.chat_tooltip.no_email");
    if (!chat) return t("client_profile.chat_tooltip.inactive_chat");
    return "";
  }, [chat, hasEmail, t]);

  return (
    <>
      <Container>
        {isActive && (
          <Tooltip title={tooltipText} disableHoverListener={chat && hasEmail}>
            <div>
              <IconButtonStyled
                onClick={onChatOpen}
                size="small"
                disabled={!chat || !hasEmail}
              >
                <ChatBubbleIcon fontSize={24} />
              </IconButtonStyled>
            </div>
          </Tooltip>
        )}

        <IconButtonStyled onClick={e => setMoreAnchorEl(e.currentTarget)}>
          <EllipsisVerticalIcon />
        </IconButtonStyled>

        {!isActive && (
          <ButtonStyled
            variant="outlined"
            onClick={toggleClientActivation}
            ref={activateClientButtonRef}
          >
            <PersonIcon fontSize={24} />
            {t("patient.activate")}
          </ButtonStyled>
        )}

        <ButtonStyled
          variant="contained"
          onClick={e => setAddAnchorEl(e.currentTarget)}
        >
          <PlusIcon fontSize={14} />
          {t("patient.add.add")}
        </ButtonStyled>

        <DropDownMenu
          anchorEl={moreAnchorEl}
          onClose={() => setMoreAnchorEl(null)}
          options={moreMenuOptions}
        />
        <DropDownMenu
          anchorEl={addAnchorEl}
          onClose={() => setAddAnchorEl(null)}
          options={addMenuOptions}
        />
      </Container>

      {openVisit && (
        <AddVisitModal
          onOpen
          onClose={onCloseVisit}
          defaultClient={id}
          onSuccess={toastOnSuccessVisit}
        />
      )}
      <SendQuestionnaireModal
        onClose={onCloseQuestionnaire}
        open={openQuestionnaire}
        onSuccess={() => {
          toast.success(t("client_profile.info.questionnaire_added"));
        }}
      />

      <EditClientInfoModal
        open={openEditClient}
        onClose={onCloseEditClient}
        id={id}
      />
      <CreateBodyMeasurementsModal
        open={openMeasurements}
        onClose={onCloseMeasurements}
        onSuccess={toastOnSuccessMeasurements}
      />
      <ConfirmationModal
        open={openDelete}
        onCancel={onCloseDelete}
        onConfirm={handleDelete}
        title={t("patient.delete_client.title")}
        description={t("patient.delete_client.confirmation")}
        confirmText={t("common.delete")}
      />
      <ConfirmPopover
        setAnchor={value => setOpenConfirmPopover(!!value)}
        anchor={
          openConfirmPopover ? activateClientButtonRef.current : undefined
        }
        onConfirm={activateClient}
        confirmationText={t("client_profile.confirm_activate_tooltip")}
        submitText={t("client_profile.activate_client")}
        extraContent={
          !isTrial && (
            <StyledAlert variant="filled" icon={<DollarSign size="w-4 h-4" />}>
              {t("client_profile.confirm_activate_context")}
            </StyledAlert>
          )
        }
      />
    </>
  );
};

const Container = styled("div")`
  display: flex;
  gap: 8px;
`;

const IconButtonStyled = styled(IconButton)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  color: ${({ theme }) => theme.palette.primary.main};
  padding: unset;
  width: 40px;
  height: 40px;

  &.Mui-disabled {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    border-color: rgba(0, 0, 0, 0.12);
  }
` as typeof IconButton;

const ButtonStyled = styled(Button)`
  display: flex;
  gap: 4px;
  padding: 7px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  height: unset;
`;

const StyledAlert = styled(Alert)`
  font-size: 10px;
  padding: 4px;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.light};
  color: ${({ theme }) => theme.palette.info.main};
  line-height: 18px;

  &.MuiPaper-root {
    border-radius: 4;
  }

  & .MuiAlert-icon {
    padding: 0;
  }

  & .MuiAlert-message {
    padding: 0;
  }
`;
