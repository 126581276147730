import { SyntheticEvent, useMemo, useRef, useState } from "react";

import { round } from "lodash";
import { TabContext } from "@mui/lab";

import { ProductTabs } from "@components/PreviewDrawer/Product";
import { ProductTabPanels } from "@components/PreviewDrawer/Product/ProductTabPanels";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { useAppTranslation, useMeasures } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { ArrowRight } from "@assets/icons/Arrows";

import { AccordionTitle } from "../AccordionTitle/AccordionTitle";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  Label,
  TabsWrapper,
} from "./common.styled";
import { ProductTabPanelsProxy } from "./ProductTabPanelsProxy";

interface Measure {
  id: number;
  grams: number;
}
interface ProductAccordionProps {
  id: number;
  name: string;
  nutrients: Nutrient[];
  grams: number;
  measureId: number;
  img: string | null;
  collectionId: number;
}

export const ProductAccordion = ({
  id,
  name,
  nutrients,
  grams,
  measureId,
  img,
  collectionId,
}: ProductAccordionProps) => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState(ProductTabs.NUTRITIONAL_VALUES);
  const { getMeasure } = useMeasures();
  const measure = getMeasure(measureId);
  const [measureValue, setMeasureValue] = useState(0);
  const anchorEl = useRef<null | HTMLDivElement>(null);

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        id: ProductTabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.tags"),
        id: ProductTabs.TAGS,
      },
    ],
    [t],
  );

  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    isExpanded: boolean,
  ) => {
    const current = anchorEl.current;
    if (isExpanded && current) {
      setTimeout(
        () => current.scrollIntoView({ behavior: "smooth", block: "center" }),
        350,
      );
    }
  };

  return (
    <AccordionStyled
      key={id}
      TransitionProps={{ unmountOnExit: true }}
      ref={anchorEl}
      onChange={handleChange}
    >
      <AccordionSummaryStyled
        expandIcon={<ArrowRight size="w-[16px] h-[16px]" />}
      >
        <AccordionTitle
          img={img}
          name={name}
          tags={
            <>
              <Label>{grams} g</Label>
              {/* {!!measureValue && (
                <Label>
                  {measureValue} {measure?.name}
                </Label>
              )} */}
            </>
          }
          nutrients={nutrients}
        />
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <TabsWrapper>
          <TabSwitch value={tab} onChange={v => setTab(v)} tabs={tabs} />
        </TabsWrapper>
        <TabContext value={tab}>
          <ProductTabPanelsProxy
            collectionId={collectionId}
            id={id}
            grams={grams}
            setMeasureValue={setMeasureValue}
            measureId={measureId}
          />
        </TabContext>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
