import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateSectionFavorite = async ({
  id,
  payload,
}: UpdateSectionFavoriteParams) => {
  await fetchData(
    `/dietitian/program-section-templates/${id}/favorite`,
    APIMethods.POST,
    payload,
  );
};

export interface UpdateSectionFavoritePayload {
  favorite: boolean;
}

export interface UpdateSectionFavoriteParams {
  id: string;
  payload: UpdateSectionFavoritePayload;
}
