import ExportPdfModal from "@Pdf/components/ExportPdfModal/ExportPdfModal";
import { IconButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { DownloadPdf, Hide } from "@assets/icons";
import { useState } from "react";
import { useAppTranslation } from "@hooks";

type ExportPdfActionProps = {
  programId: number;
  patientId: number;
  disabled?: boolean;
};
const ExportPdfAction = ({
  programId,
  patientId,
  disabled,
}: ExportPdfActionProps) => {
  const { t } = useAppTranslation();
  const [openExportPdfModal, setOpenExportPdfModal] = useState(false);

  return (
    <>
      <Tooltip
        title={
          disabled
            ? t("common.access_module_tooltip")
            : t("patient.program_preview.menu_actions.save_pdf")
        }
        arrow
      >
        <div>
          <IconButton
            onClick={() => setOpenExportPdfModal(true)}
            disabled={disabled}
          >
            <DownloadPdf size="" style={{ width: "32px", height: "32px" }} />
          </IconButton>
        </div>
      </Tooltip>
      {openExportPdfModal && (
        <ExportPdfModal
          open={openExportPdfModal}
          patientId={patientId}
          programId={programId}
          onClose={() => setOpenExportPdfModal(false)}
        />
      )}
    </>
  );
};

export default ExportPdfAction;
