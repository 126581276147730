import { useEducationalMaterialsFilterOptions } from "./useEducationalMaterialsOptions";
import { useAppTranslation } from "@hooks";
import { FilterType, TagAutocompleteFilterWrapper } from "@components/Filters";

export const useEducationalMaterialsFilterConfig = () => {
  const { t } = useAppTranslation();

  const { dietitianOptions } = useEducationalMaterialsFilterOptions();

  const filters: TagAutocompleteFilterWrapper[] = [
    {
      type: FilterType.TagAutocomplete,
      props: {
        placeholder: t("educational_materials.selectAuthor"),
        name: "author",
        label: t("educational_materials.author"),
        options: dietitianOptions,
      },
    },
  ];

  return filters;
};
