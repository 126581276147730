import { useMemo, type FC } from "react";
import { Typography } from "@mui/material";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { useAppTranslation } from "@hooks";
import { useUpdateTaskStatus } from "@hooks/queries/tasks/useUpdateTaskStatus";

import { GridPaginationWrapper } from "@components/PaginationNew";

import { Metadata } from "@typeDefinitions";
import type { Client } from "@client/tasks/fetchTasks";
import { ActionsDto } from "@client";
import { EducationalMaterialsDropdown } from "./EducationalMaterialsDropdown";
import { EducationalMaterialsGrid } from "../EducationalMaterialsView.styled";
import { EducationalMaterialsTableCategoryBadge } from "../EducationalMaterialsView.styled";
import {
  ModalType,
  useEducationalModalContext,
} from "../_hooks/useEducationalMaterialsContext";
import type { SectionsNew } from "@client/searchSectionsNew";
import { useUpdateSectionFavorite } from "@hooks/queries/useUpdateSectionFavorite";
import { useGetSectionCategoryName } from "../_hooks/useGetSectionCategoryName";
import { EducationalMaterialAuthor } from "./EducationalMaterialAuthor";

interface EducationalMaterialsTableProps {
  materials: SectionsNew;
  meta: Metadata | undefined;
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  setPage: (value: number) => void;
  itemsOnPage: number[];
}

export const EducationalMaterialsTable: FC<EducationalMaterialsTableProps> = ({
  materials,
  meta,
  page,
  setPage,
  perPage,
  setPerPage,
  itemsOnPage,
}) => {
  const { t } = useAppTranslation();
  const { handleUpdateTaskStatus } = useUpdateTaskStatus();
  const { openModal } = useEducationalModalContext();

  const { getSectionCategoryName } = useGetSectionCategoryName();

  const { mutate: updateSectionFavorite, isLoading: isLoadingFavorite } =
    useUpdateSectionFavorite();

  const isOnePage = !!meta?.lastPage && meta?.lastPage <= 1;

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("educational_materials.name"),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Typography
            fontWeight={600}
            variant="body2"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            className="block line-clamp-1 pl-4"
          >
            {params.row.name}
          </Typography>
        ),
      },
      {
        field: "category",
        headerName: t("sections.category"),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<string>) => (
          <EducationalMaterialsTableCategoryBadge>
            {getSectionCategoryName(params.row.category.id)}
          </EducationalMaterialsTableCategoryBadge>
        ),
      },
      {
        field: "author",
        headerName: t("educational_materials.author"),
        minWidth: 230,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<Client>) => {
          const authorId = params.row.author?.id;
          return <EducationalMaterialAuthor authorId={authorId} />;
        },
      },
      {
        field: "actions",
        headerName: t("educational_materials.actions"),
        minWidth: 140,
        sortable: false,
        headerAlign: "right",
        align: "right",
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ActionsDto>) => {
          const {
            row: { actions, id },
          } = params;
          return (
            <div className="flex items-center gap-x-2">
              <EducationalMaterialsDropdown id={String(id)} actions={actions} />
            </div>
          );
        },
      },
    ],
    [t, handleUpdateTaskStatus],
  );

  return (
    <div className="relative">
      <EducationalMaterialsGrid
        columns={columns}
        rows={materials}
        onPageChange={setPage}
        page={page}
        rowCount={meta?.total ?? 0}
        loading={false}
        pageSize={perPage}
        autoHeight
        rowsPerPageOptions={itemsOnPage}
        rowHeight={45}
        disableSelectionOnClick
        onRowClick={params => openModal(ModalType.PREVIEW, String(params.id))}
        sortingMode="server"
        getRowSpacing={() => ({ top: 8 })}
        paginationMode="server"
        className="custom-scrollbar custom-scrollbar-transparent"
        components={{
          Pagination: GridPaginationWrapper,
        }}
        componentsProps={{
          pagination: { setPerPage, perPage, isOnePage },
        }}
      />
    </div>
  );
};
