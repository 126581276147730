import { ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { ProgramRowType } from "@views/dietician/PatientPrograms/Grid/RowType";
import { useChangeSharedProgramMutation } from "@hooks/queries/client/program/useChangeSharedProgramMutation";
import { CustomMenuItem } from "@views/dietician/PatientPrograms/PatientProgramsGrid.styled";
import { Hide, Mobile } from "@assets/icons";
import { useAppTranslation } from "@hooks";

type SharedActionType = {
  program: ProgramRowType["program"];
  onSuccess: () => void;
  patientId: number;
  disabled?: boolean;
};

const SharedAction = ({
  program,
  onSuccess,
  patientId,
  disabled,
}: SharedActionType) => {
  const { t } = useAppTranslation();
  const { mutate, isLoading } = useChangeSharedProgramMutation({
    onSuccess: () => {
      onSuccess();
    },
  });

  const handleOnClick = () => {
    mutate({
      programId: program.id,
      patientId: patientId,
      payload: {
        shared: !program.shared,
      },
    });
  };

  return (
    <Tooltip
      arrow
      title={t("common.access_module_tooltip")}
      disableHoverListener={!disabled}
    >
      <div>
        {program.shared && (
          <CustomMenuItem
            onClick={handleOnClick}
            disabled={disabled || isLoading}
          >
            <ListItemIcon>
              <Hide fill="#727272" style={{ width: "24px" }} />
            </ListItemIcon>
            <ListItemText>
              {t("patient.programs_grid.row.actions.hide_in_app")}
            </ListItemText>
          </CustomMenuItem>
        )}
        {!program.shared && (
          <CustomMenuItem
            onClick={handleOnClick}
            disabled={disabled || isLoading}
          >
            <ListItemIcon>
              <Mobile size="" fill="#727272" style={{ width: "24px" }} />
            </ListItemIcon>
            <ListItemText>
              {t("patient.programs_grid.row.actions.show_in_app")}
            </ListItemText>
          </CustomMenuItem>
        )}
      </div>
    </Tooltip>
  );
};

export default SharedAction;
