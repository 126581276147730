import { useEffect } from "react";

import dayjs from "dayjs";
import LogRocket from "logrocket";

import { FetchDietitianAccountResponse } from "@client/settings";

import { useUserRoles } from "./useUserRoles";

const LAST_DEPLOY = "2025-03-12";

const BILLING_PERIOD = "2025-02-20";

export const useLogRocket = (
  account: FetchDietitianAccountResponse | undefined,
) => {
  const { isAdminClinicMember } = useUserRoles();
  const enableLogRocket = import.meta.env.VITE_APP_ENABLE_LOGROCKET === "true";

  const getIsTrial = (date: string | undefined) =>
    date ? dayjs().diff(date, "days") <= TWO_WEEKS : false;

  const isNextWorkday = () => {
    const lastDeploy = dayjs(LAST_DEPLOY);
    const isFriday = lastDeploy.day() === 5;
    const today = dayjs();
    const difference = today.diff(lastDeploy, "days");

    return isFriday ? difference <= 3 : difference <= 1;
  };

  useEffect(() => {
    if (!enableLogRocket || !account) return;

    const { id, firstName, lastName, email, clinic } = account;
    const fullName = [firstName, lastName].join(" ");
    const userTraits = {
      firstName: firstName,
      lastName: lastName,
      name: fullName,
      email: email,
      clinicId: clinic?.id ?? 0,
    };

    const isAdminClinicMemberOrEmail =
      email.endsWith("@alloweat.com") ||
      email.endsWith("@becompleat.pl") ||
      isAdminClinicMember;
    const isTrialPeriod = getIsTrial(clinic?.subscriptionNew?.startedAt);
    const isNextWorkDay = isNextWorkday();
    const isBeforeNextBillingPeriod = dayjs().isBefore(BILLING_PERIOD);

    if (isAdminClinicMemberOrEmail) {
      LogRocket.identify(id.toString(), userTraits);
      LogRocket.init("fy7ls2/demo");
      console.log("Tester's log rocket initialization success");

      return;
    }

    if (isBeforeNextBillingPeriod || isTrialPeriod || isNextWorkDay) {
      LogRocket.identify(id.toString(), userTraits);
      LogRocket.init("ru7dkj/alloweatbe");
      console.log("Log rocket initialization success");

      return;
    }
  }, [account]);
};

const TWO_WEEKS = 14;
