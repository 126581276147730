import {
  ChipItemsWrapper,
  SectionItemChips,
  SectionItemLabel,
  SectionItemLayout,
  TabSectionLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import {
  useAppTranslation,
  useClientAdditionalFeatures,
  useClientParams,
} from "@hooks";
import { useFetchClientQuery } from "@hooks/queries";
import { useMemo } from "react";
import { Chip } from "@mui/material";

const AdditionalInformationItemSection = () => {
  const { t } = useAppTranslation();
  const id = useClientParams();
  const { client } = useFetchClientQuery(id);
  const { getAdditionalFeatureName } = useClientAdditionalFeatures();

  const additionalFeatures = useMemo(() => {
    if (!client) {
      return [];
    }

    return client.profile.additionalFeatures.map(({ id }) =>
      getAdditionalFeatureName(id),
    );
  }, [client, getAdditionalFeatureName]);

  return (
    <SectionItemLayout>
      <SectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.section_dietary_profile.additional_information",
        )}
      </SectionItemLabel>
      <SectionItemChips>
        {!additionalFeatures.length && "-"}
        {additionalFeatures.map((name, index) => (
          <Chip key={index} color="primary" label={name} />
        ))}
      </SectionItemChips>
    </SectionItemLayout>
  );
};

export default AdditionalInformationItemSection;
