import { Chip, Stack } from "@mui/material";

import { OuterLabel } from "@components/OuterLabel";
import { useFetchGlobalPatientSettingsQuery } from "@hooks/queries/clinic";

import { Card, CardHeader } from "../components";
import { mapChipsFromObject } from "../utils";
import { useAppTranslation, useModalState, useNutrients } from "@hooks";
import { EditProfileModal } from "../modals/EditProfileModal";
import { ContentProps } from "./Databases";

export const Profile = () => {
  const { data } = useFetchGlobalPatientSettingsQuery();

  if (!data) return null;
  return <ProfileContent data={data} />;
};

export const ProfileContent = ({ data }: ContentProps) => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { nutrientDict } = useNutrients();
  const {
    nutrients: { importantIds, show },
  } = data.data;

  return (
    <Card>
      <CardHeader
        onEdit={onOpen}
        title={t("cooperation_settings.profile.dietary_profile")}
      />

      <OuterLabel label={t("cooperation_settings.profile.visible_to_client")}>
        {mapChipsFromObject(show, t)}
      </OuterLabel>
      <OuterLabel
        label={t("cooperation_settings.profile.key_nutritional_values")}
      >
        <Stack direction="row" gap="8px" flexWrap="wrap">
          {importantIds.length && show.important
            ? importantIds.map(id => {
                const name = nutrientDict.get(id)?.name;
                return <Chip color="primary" key={id} label={name} />;
              })
            : "-"}
        </Stack>
      </OuterLabel>

      <EditProfileModal
        open={open}
        onClose={onClose}
        nutrients={{ importantIds, show }}
      />
    </Card>
  );
};
