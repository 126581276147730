import { PdfDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useForm } from "react-hook-form";

export const useEditPdfForm = (defaultValues?: EditPdfFormProps) => {
  const form = useForm<EditPdfFormProps>({ defaultValues });

  return form;
};

export interface EditPdfFormProps {
  weekDay: boolean;
  date: boolean;
  typeDay: boolean;
  mealHour: boolean;
  menuLogo: boolean;
  name: boolean;
  address: boolean;
  email: boolean;
  phone: boolean;
  clinicLogo: boolean;
  mainPhotoId: number | null;
  color: string;
  template: 1 | 2 | 3;
  shoppingListPeriod: number;
}

export const mapEditPdfForm = (
  data: PdfDto | undefined,
  enterprise: boolean,
): EditPdfFormProps | undefined => {
  if (!data) return;

  const {
    clinicData: { logo: clinicLogo, ...clinicRest },
    menuData: { logo: menuLogo, ...menuRest },
    ...rest
  } = data;

  return {
    ...rest,
    ...clinicRest,
    ...menuRest,
    clinicLogo: enterprise ? clinicLogo : true,
    menuLogo: enterprise ? menuLogo : true,
  };
};

export const mapEditPdfRequest = (values: EditPdfFormProps): PdfDto => {
  const {
    weekDay,
    date,
    typeDay,
    mealHour,
    menuLogo,
    name,
    address,
    email,
    phone,
    clinicLogo,
    mainPhotoId,
    color,
    template,
    shoppingListPeriod,
  } = values;

  return {
    clinicData: {
      address,
      email,
      logo: clinicLogo,
      name,
      phone,
    },
    color,
    mainPhotoId,
    menuData: {
      date,
      logo: menuLogo,
      mealHour,
      typeDay,
      weekDay,
    },
    shoppingListPeriod,
    template,
  };
};
