import { Checkbox, FormControlLabel, styled } from "@mui/material";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface LabeledCheckboxProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
}
export const LabeledCheckbox = <T extends FieldValues>({
  control,
  name,
  label,
}: LabeledCheckboxProps<T>) => {
  const {
    field: { value, onChange },
  } = useController<T>({ control, name });
  return (
    <FormControlLabelStyled
      control={<Checkbox />}
      label={label}
      checked={value}
      onChange={(e, v) => onChange(v)}
    />
  );
};

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: unset;
`;
