import { DiscoverFeaturesDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useForm } from "react-hook-form";

export const useEditDiscoverForm = (defaultValues?: EditDiscoverFormInput) => {
  const form = useForm<EditDiscoverFormInput>({ defaultValues });

  return form;
};

interface EditDiscoverFormInput {
  inspire: boolean;
  preference: boolean;
  quiz: boolean;
}

export const mapEditDiscoverFormMap = (
  data: DiscoverFeaturesDto | undefined,
): EditDiscoverFormInput | undefined => {
  if (!data) return;

  const { inspired, meals, quiz } = data;
  return {
    inspire: inspired,
    preference: meals,
    quiz,
  };
};

export const mapEditDiscoverRequestMap = ({
  inspire,
  preference,
  quiz,
}: EditDiscoverFormInput): DiscoverFeaturesDto => {
  return {
    inspired: inspire,
    meals: preference,
    quiz,
  };
};
