import { IconButton, Tooltip } from "@mui/material";
import { Pencil } from "@assets/icons";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "@hooks";

type EditActionProps = {
  patientId: number;
  programId: number;
  disabled?: boolean;
};

const EditAction = ({ patientId, programId, disabled }: EditActionProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(`/patients/${patientId}/nutritional-programs/${programId}/edit`);
  };

  return (
    <Tooltip
      title={
        disabled
          ? t("common.access_module_tooltip")
          : t("patient.program_preview.menu_actions.edit")
      }
      arrow
    >
      <div>
        <IconButton onClick={handleOnClick} disabled={disabled}>
          <Pencil size="" style={{ width: "32px", height: "32px" }} />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default EditAction;
