import { HydrationDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useForm } from "react-hook-form";

export const useEditHydrationForm = (
  defaultValues?: EditHydrationFormProps,
) => {
  const form = useForm({ defaultValues });

  return form;
};

interface EditHydrationFormProps {
  hydrationProductId: string;
  selectedGoal: string;
  goal: string;
}

export const mapEditHydrationForm = (
  data: HydrationDto | undefined,
): EditHydrationFormProps | undefined => {
  if (!data) return;

  const { goal, mainProductId } = data;

  return {
    goal: String(goal),
    hydrationProductId: String(mainProductId),
    selectedGoal: String(goal),
  };
};

export const mapEditHydrationRequest = ({
  goal,
  hydrationProductId,
  selectedGoal,
}: EditHydrationFormProps): HydrationDto => {
  return {
    goal: Number(selectedGoal) || Number(goal),
    mainProductId: Number(hydrationProductId),
  };
};
