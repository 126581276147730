import { FavoriteToggleButton } from "@components/FavoriteToggleButton";
import { LaptopWrapper } from "@components/ProductsDataGrid/ActionsColumnCell/ActionsColumnCell.styled";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { useState } from "react";
import type { FC } from "react";
import { EducationalMaterialsCardAction } from "../EducationalMaterialsView.styled";
import { EducationalMaterialsDropdown } from "./EducationalMaterialsDropdown";
import { useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useUpdateSectionFavorite } from "@hooks/queries/useUpdateSectionFavorite";

interface EducationalMaterialsCardActionsProps {
  id: number;
  favorite: boolean;
}

export const EducationalMaterialsCardActions: FC<
  EducationalMaterialsCardActionsProps
> = ({ id, favorite }) => {
  const { t } = useAppTranslation();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const { mutate: updateSectionFavorite, isLoading: isLoadingFavorite } =
    useUpdateSectionFavorite();

  const theme = useTheme();

  const handleToggle = () => {
    updateSectionFavorite({
      id: id.toString(),
      payload: { favorite: !favorite },
    });
  };

  return (
    <LaptopWrapper
      className={`absolute top-4 left-1 w-full justify-end px-2 ${
        isDropdownOpened ? "opacity-100" : "opacity-0 group-hover:opacity-100"
      } transition-opacity duration-200`}
    >
      <div className="flex items-center gap-x-2">
        {/* <EducationalMaterialsCardAction>
          <ShareArrow />
        </EducationalMaterialsCardAction> */}

        <EducationalMaterialsDropdown
          id={id.toString()}
          actions={{ canDelete: true, canEdit: true, canVisible: true }}
          externalActions={handleOpenDropdown => (
            <EducationalMaterialsCardAction
              onClick={e => {
                setIsDropdownOpened(true);
                handleOpenDropdown(e);
              }}
            >
              <MoreVertIcon />
            </EducationalMaterialsCardAction>
          )}
          setIsDropdownOpened={setIsDropdownOpened}
        />
      </div>
    </LaptopWrapper>
  );
};
