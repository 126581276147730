import { LangDto } from "@client";
import { CollectionSearchPreviewDto } from "@client/collections";
import { useAppTranslation, useMealClientContext } from "@hooks";
import { useFetchCollectionSearchPreviewQuery } from "@hooks/queries/collections";
import { CircularProgress } from "@mui/material";
import { getTranslation } from "@utils/translations";
import { useEffect, useState } from "react";
import { ProductItem, RecipeItem, useItemsContext } from "../ItemsContext";
import {
  CollectionContentStyled,
  TitleUppercase,
} from "./AccordionWrapper.styled";
import { ProductAccordion } from "./ProductAccordion";
import { RecipeAccordion } from "./RecipeAccordion";
import {
  usePostPatientProductsContextMutation,
  usePostPatientRecipesContextMutation,
} from "@hooks/queries";
import { scaleNutrients } from "@utils";

interface CollectionContentProps {
  id: number;
  selectAll: boolean;
  onSuccess: () => void;
  mealId: number;
}
export const CollectionContent = ({
  id: collectionId,
  selectAll,
  onSuccess,
  mealId,
}: CollectionContentProps) => {
  const { t } = useAppTranslation();
  const { toggleItems, updateCollectionItems } = useItemsContext();
  const [products, setProducts] = useState<number[]>([]);
  const [recipes, setRecipes] = useState<number[]>([]);
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();
  const { mutate: fetchRecipes } = usePostPatientRecipesContextMutation();

  const { productsClientContextMap, recipesClientContextMap } =
    useMealClientContext();

  const handleSelectAll = (meal: CollectionSearchPreviewDto | undefined) => {
    if (selectAll && meal) {
      const mappedItems = mapItems(meal);
      toggleItems(mappedItems);
      updateCollectionItems(
        collectionId,
        mappedItems.map(i => i.id),
      );
      onSuccess();
    }
  };

  const { data: collection, isLoading } = useFetchCollectionSearchPreviewQuery(
    collectionId.toString(),
    {
      onSuccess: data => {
        handleSelectAll(data?.data);
        setProducts(data?.data.products.map(({ product }) => product.id) ?? []);
        setRecipes(data?.data.recipes.map(({ recipe }) => recipe.id) ?? []);
      },
    },
  );

  const mapItems = (
    data: CollectionSearchPreviewDto | undefined,
  ): (RecipeItem | ProductItem)[] => {
    if (!data) return [];
    const mappedRecipes: RecipeItem[] = data.recipes.map(recipeWrapper => ({
      type: "recipe",
      id: recipeWrapper.recipe.id,
      servings: recipeWrapper.servings,
      mealId,
    }));

    const mappedProducts: ProductItem[] = data.products.map(
      ({ product, grams, measure }) => ({
        type: "product",
        id: product.id,
        grams: grams,
        measure: measure.id,
        mealId,
      }),
    );

    return [...mappedRecipes, ...mappedProducts];
  };

  useEffect(() => {
    handleSelectAll(collection);
  }, [selectAll]);
  useEffect(() => {
    if (products.length) {
      fetchProducts({ payload: products });
    }
  }, [products.length]);
  useEffect(() => {
    if (recipes.length) {
      fetchRecipes({ payload: recipes });
    }
  }, [recipes.length]);

  if (isLoading)
    return (
      <div className="grid place-items-center">
        <CircularProgress />
      </div>
    );

  if (!collection) return <></>;

  return (
    <CollectionContentStyled>
      {!!collection.recipes.length && (
        <TitleUppercase>
          {t("common.recipes").toLocaleLowerCase()} ({collection.recipes.length}
          )
        </TitleUppercase>
      )}

      {collection.recipes.map(({ recipe, servings, id: itemId }) => {
        const {
          id: recipeId,
          nutrients,
          image,
          tags,
          name,
          translations,
        } = recipe;

        return (
          <RecipeAccordion
            key={recipeId}
            id={recipeId}
            macros={nutrients}
            imgUrl={image?.url}
            totalServings={recipe.servings}
            commitedSelectedServings={servings}
            tags={tags.map(t => t.id)}
            title={name}
            titleEn={getTranslation(translations, "en")}
            mealId={mealId}
            clientContext={recipesClientContextMap.get(recipeId)}
            collectionId={collectionId}
            itemId={itemId}
          />
        );
      })}

      {!!collection.products.length && (
        <TitleUppercase>
          {t("common.products").toLocaleLowerCase()} (
          {collection.products.length})
        </TitleUppercase>
      )}

      {collection.products.map(({ product, grams, measure, id }) => {
        const { name, translations, id: productId, nutrients, image } = product;
        return (
          <ProductAccordion
            key={productId}
            id={productId}
            namePl={name}
            nameEn={getTranslation(translations, LangDto.EN)}
            macros={scaleNutrients(nutrients, grams)}
            tags={[]}
            imgUrl={image?.url}
            mealId={mealId}
            clientContext={productsClientContextMap.get(productId)}
            collectionId={collectionId}
            itemId={id}
            defalutMeasure={measure.id.toString()}
            defaultGrams={grams}
          />
        );
      })}
    </CollectionContentStyled>
  );
};
