import { Control, FieldValues, Path, useController } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel as MuiFormControlLabel,
  styled,
} from "@mui/material";
interface CheckboxLabeledProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label: string;
}

export const CheckboxLabeled = <T extends FieldValues>({
  name,
  control,
  label,
}: CheckboxLabeledProps<T>) => {
  const {
    field: { value, onChange },
  } = useController<T>({ name, control });
  return (
    <FormControlLabel
      control={<Checkbox />}
      label={label}
      checked={value}
      onChange={(e, checked) => onChange(checked)}
    />
  );
};

const FormControlLabel = styled(MuiFormControlLabel)`
  margin: unset;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  border-radius: 8px;
  width: 100%;
`;
