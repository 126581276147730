import { Trans } from "react-i18next";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Link,
  Stack,
  styled,
} from "@mui/material";
import dayjs from "dayjs";

import ArrowIcon from "@assets/icons_new/arrow.svg?react";
import OpenInWindowIcon from "@assets/icons_new/squareWithArrow.svg?react";
import { useAppTranslation } from "@hooks";

import { TypeIcon } from "./TypeIcon";

interface EventItemProps {
  id: number;
  type: "mobile" | "web" | "announcement";
  date: string;
  title: string;
  content: string;
  url: null | string;
  unread: boolean;
}

export const EventItem = ({
  id,
  type,
  date,
  title,
  content,
  url,
  unread,
}: EventItemProps) => {
  const { t } = useAppTranslation();

  const dateString = dayjs(date).format("DD/MM/YYYY");
  const transComponents = {
    paragraph: <SectionSubTitle />,
    bold: <strong />,
    element: <li className="list-item last:pb-0" />,
    list: <StyledList />,
  };

  return (
    <AccordionStyled defaultExpanded={unread}>
      <AccordionSummaryStyled expandIcon={<ArrowIcon fontSize={16} />}>
        <Stack gap="8px" direction="row">
          <TypeIcon type={type} unread={unread} />

          <Stack>
            <DateText>{dateString}</DateText>
            <Title>{title}</Title>
          </Stack>
        </Stack>
      </AccordionSummaryStyled>

      <AccordionDetailsStyled>
        <Stack gap="12px" alignItems="flex-start">
          <div>
            <Trans i18nKey={content} components={transComponents} />
          </div>

          {url && (
            <ButtonStyled LinkComponent={Link} target="_blank" href={url}>
              {t("whatsNew.learn_more")}
              <OpenInWindowIcon fontSize={24} />
            </ButtonStyled>
          )}
        </Stack>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

const DateText = styled("span")`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

const Title = styled("span")`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

const ButtonStyled = styled(Button)`
  display: flex;
  gap: 4px;
  height: 24px;
` as typeof Button;

const StyledList = styled("ul")`
  list-style-type: disc;
  margin-left: 22px;
  font-size: 14px;
  line-height: 20px;
`;

const SectionSubTitle = styled("p")`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

const AccordionStyled = styled(Accordion)`
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  border-radius: 16px;
  padding: 12px 0;
  box-shadow: unset;

  &.MuiPaper-root.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  :before {
    display: none;
  }
`;

const AccordionSummaryStyled = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    min-height: 42px;
    padding: 0 12px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    margin-left: 0;
  }

  & .Mui-expanded {
    margin: 0;
  }

  & .MuiAccordionSummary-content.Mui-expanded {
    min-height: unset;
    margin: 0;
  }
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 8px 12px 0;
  }
`;
