import { styled } from "@mui/material";

const MealItemListItem = styled("li")`
  //padding: 6px 0px;
  //:last-of-type {
  //  padding-bottom: 0px;
  //}
  //:first-of-type {
  //  padding-top: 0px;
  //}
`;

export default MealItemListItem;
