import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "@hooks/queries";
import {
  fetchMonitoringIrrigation,
  FetchMonitoringIrrigationParams,
  FetchMonitoringIrrigationResponse,
} from "@client/patient/monitoring/fetchMonitoringIrrigation";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringIrrigation = (
  params: FetchMonitoringIrrigationParams,
  options?: QueryOptionsTyped<FetchMonitoringIrrigationResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.irrigation(params),
    queryFn: () => fetchMonitoringIrrigation(params),
    ...options,
  });
};
