import { useMemo } from "react";
import SumNutrientsValues from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/SumNutrientsValues";
import { useFetchDictionaryNutrients } from "@hooks/queries/dictionaries";
import { Box } from "@mui/material";
import {
  ProgramDayChip,
  ProgramDayName,
} from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientsValuesSection.styled";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";

type NutrientsValuesSectionProps = {
  data: NutrientsMonitoringResource[];
};

const NutrientsValuesSectionByDay = ({ data }: NutrientsValuesSectionProps) => {
  const { selectedDays } = useMonitoring();
  const { data: nutrients } = useFetchDictionaryNutrients();

  const nutrientsValueByType = useMemo(() => {
    if (data === undefined) {
      return [];
    }

    return data.filter(
      i => i.date === selectedDays.selectDays.from.format("YYYY-MM-DD"),
    );
  }, [selectedDays, data]);

  const programDay = useMemo(() => {
    if (data === undefined) {
      return "";
    }

    return (
      data.filter(
        i => i.date === selectedDays.selectDays.from.format("YYYY-MM-DD"),
      )[0]?.programDay?.name ?? ""
    );
  }, [data]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="end"
        alignItems="center"
        flexDirection="row"
      >
        {programDay != "" && (
          <ProgramDayChip
            variant="outlined"
            label={<ProgramDay color="#5252E5" name={programDay} />}
          />
        )}
      </Box>
      <SumNutrientsValues
        data={nutrientsValueByType}
        nutrients={nutrients ?? []}
      />
    </>
  );
};

type ProgramDayProps = {
  name: string;
  color: string;
};
const ProgramDay = ({ name, color }: ProgramDayProps) => {
  return (
    <Box display="flex" flexDirection="row" gap="4px" alignItems="center">
      <Box
        width="8px"
        height="8px"
        borderRadius="16px"
        sx={{ background: color }}
      />
      <ProgramDayName>{name}</ProgramDayName>
    </Box>
  );
};

export default NutrientsValuesSectionByDay;
