import { forwardRef } from "react";
import { InputAttributes, NumericFormat } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const decimalInputFactory = (
  decimal: number,
  max?: number,
  fixedDecimalScale = true,
) =>
  forwardRef<InputAttributes, CustomProps>(function NumberFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        allowedDecimalSeparators={[",", "."]}
        decimalScale={decimal}
        fixedDecimalScale={fixedDecimalScale}
        isAllowed={
          max
            ? ({ floatValue }) => (floatValue ? floatValue <= max : true)
            : undefined
        }
      />
    );
  });

export const decimalInput0 = decimalInputFactory(0) as any;
export const decimalInput1 = decimalInputFactory(1) as any;
export const decimalInput2 = decimalInputFactory(2) as any;
export const decimalInput1NotFixed = decimalInputFactory(
  1,
  undefined,
  false,
) as any;
export const decimalInput1FourDigitsFixed = decimalInputFactory(
  1,
  9999,
  false,
) as any;
export const decimalInput2ThreeDigitsNotFixed = decimalInputFactory(
  2,
  999,
  false,
) as any;
export const decimalInput3 = decimalInputFactory(3) as any;
export const decimalTwoDigitsInput = decimalInputFactory(0, 99) as any;
export const decimalThreeDigitsInput = decimalInputFactory(0, 999) as any;
export const decimalInput1ThreeDigits = decimalInputFactory(1, 999.9) as any;
export const decimalFourDigitsInput = decimalInputFactory(0, 9999) as any;
export const decimalPercentageInput = decimalInputFactory(0, 100) as any;
export const decimalPercentageInput1 = decimalInputFactory(1, 100) as any;
export const decimalInputDict = {
  0: decimalInput0,
  1: decimalInput1,
  2: decimalInput2,
  3: decimalInput3,
};
export const barcodeInput = decimalInputFactory(0, 9999999999999) as any;

export const phoneInputE164 = forwardRef<InputAttributes, CustomProps>(
  function PhoneFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        allowLeadingZeros={false}
        isAllowed={({ value }) => value.length <= 14}
      />
    );
  },
);
