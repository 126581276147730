import { ModalWrapper } from "@components/ModalWrapperNew";
import { LanguageTextField } from "@views/dietician/DietCreator/components/DietNameModal";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useAppTranslation } from "@hooks";
import { Box, Typography } from "@mui/material";
import { useEducationalMaterialsAddCategoryForm } from "../_hooks/useEducationalMaterialsAddCategoryForm";
import { EducationalMaterialsFormSchema } from "../_hooks/useEducationalMaterialsForm";
import { EducationalMaterialsLanguageSwitch } from "./EducationalMaterialsLanguageSwitch";
import { useEducationalMaterialsFilterOptions } from "../_hooks/useEducationalMaterialsOptions";
import { Labeled } from "@components/Labeled";
import { StyledFormAutocomplete } from "@components/SectionForm";
import { CheckedIcon, LinkAnchor } from "@assets/icons";
import { FormInput } from "@components/FormInput";
import { FormTextField } from "@components/FormTextField";
import { EducationalMaterialsFormTextField } from "../EducationalMaterialsView.styled";
import FileUpload from "@components/FileUpload/FileUpload";
import { FormTextarea } from "@components/FormTextarea";

interface EducationalMaterialsCreateVideoModalProps {
  onClose: () => void;
  open: boolean;
}

export const EducationalMaterialsCreateVideoModal: FC<
  EducationalMaterialsCreateVideoModalProps
> = ({ onClose, open }) => {
  const { t } = useAppTranslation();

  const { control, handleSubmit } = useEducationalMaterialsAddCategoryForm();
  const { categoryOptions } = useEducationalMaterialsFilterOptions();

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      title={t("educational_materials.uploadVideo")}
    >
      <div className="flex flex-col gap-y-4">
        <EducationalMaterialsLanguageSwitch control={control} />
        <Labeled label={t("sections.category")}>
          <StyledFormAutocomplete
            control={control}
            name="sectionCategoryId"
            id="sectionCategoryId"
            size="small"
            popupIcon={<CheckedIcon />}
            options={categoryOptions ?? []}
          />
        </Labeled>
        <Labeled label={t("educational_materials.sectionName")}>
          <FormInput fullWidth name="pl" control={control} variant="outlined" />
        </Labeled>
        <Labeled label={t("educational_materials.videoLink")}>
          <EducationalMaterialsFormTextField
            fullWidth
            name="pl"
            control={control}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <LinkAnchor className="text-[24px] text-gray-450 mr-2" />
              ),
            }}
          />
        </Labeled>
        <FileUpload
          onFileDrop={() => ({})}
          label={t("educational_materials.materialCoverVideo")}
        />
        <Labeled label={t("educational_materials.shortenDescription")}>
          <FormTextarea
            fullWidth
            name="pl"
            control={control}
            placeholder={t("educational_materials.content")}
            maxLength={160}
            minRows={6}
            maxRows={20}
          />
        </Labeled>
      </div>
    </ModalWrapper>
  );
};
