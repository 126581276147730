import { CloseIcon } from "@assets/icons_new";
import { ClientMobileAppView } from "@components/EditClientPaymentsModal/components/ClientMobileAppView";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import {
  mapEditMobileForm,
  mapEditMobileRequest,
  useEditMobileForm,
} from "./useEditMobileForm";
import { MobileAppDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useMemo } from "react";
import { useUpdateGlobalPatientSettingsMutation } from "@hooks/queries/clinic";
import { useAppTranslation } from "@hooks";
import { LoadingButton } from "@mui/lab";

interface EditMobileModalProps {
  open: boolean;
  onClose: () => void;
  mobileApp: MobileAppDto;
}
export const EditMobileModal = ({
  open,
  onClose,
  mobileApp,
}: EditMobileModalProps) => {
  const { t } = useAppTranslation();
  const { mutate, isLoading } = useUpdateGlobalPatientSettingsMutation();
  const defaultValues = useMemo(
    () => mapEditMobileForm(mobileApp.features),
    [mobileApp.features],
  );
  const { control, handleSubmit } = useEditMobileForm(defaultValues);
  const handleCloseAndReset = () => {
    onClose();
  };
  const onSubmit = () =>
    handleSubmit(
      values =>
        mutate(
          {
            mobileApp: { ...mobileApp, features: mapEditMobileRequest(values) },
          },
          { onSuccess: onClose },
        ),
      e => console.log(e),
    )();
  return (
    <Dialog open={open}>
      <DialogTitleStyled>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {t("client_profile.edit.payments.mobile_app")}
          <IconButtonStyled onClick={handleCloseAndReset} size="small">
            <CloseIcon fontSize="24px" />
          </IconButtonStyled>
        </Stack>
      </DialogTitleStyled>

      <DialogContent sx={{ width: "510px" }}>
        <ClientMobileAppView control={control} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseAndReset} variant="outlined">
          {t("common.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onSubmit}
          loading={isLoading}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: unset;
  height: unset;
`;
const DialogTitleStyled = styled(DialogTitle)`
  font-size: 16px;
  padding: 16px;
`;
