import { Slider, styled } from "@mui/material";

export const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 12px;
  border-radius: 8px;
`;

export const Label = styled("span")`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;

export const Value = styled("span")`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const SliderStyled = styled(Slider)`
  flex: 1;
  width: 340px;
`;

export const LabelWrapper = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
`;
