import { Button, Dialog, IconButton, Link, styled } from "@mui/material";

import CloseIcon from "@assets/icons_new/closeClean.svg?react";

interface StudentModalProps {
  open: boolean;
  onClose: () => void;
}
export const StudentModal = ({ open, onClose }: StudentModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="p-[8px] w-full flex justify-end">
        <IconButton size="small" onClick={onClose}>
          <CloseIcon fontSize={10} />
        </IconButton>
      </div>

      <Container>
        <img
          src="/static/studentModal/wavingHand.png"
          width={164}
          height={164}
        />

        <TitleWrapper>
          <Title>Oferta studencka -50%</Title>
          <Description>
            Cieszymy się, że jesteś z nami! Twoje konto studenckie daje Ci pełny
            dostęp do naszej platformy.
          </Description>
        </TitleWrapper>

        <PurpleWrapper>
          <div className="flex flex-col gap-[4px] items-center">
            <OfferTextBold>Rozwiń swoją karierę!</OfferTextBold>
            <OfferText>
              Tę ofertę przygotowaliśmy specjalnie dla Ciebie:
            </OfferText>
          </div>

          <img
            src="/static/studentModal/offerBadge.svg"
            width={203}
            height={26}
          />
        </PurpleWrapper>

        <div className="flex gap-[16px]">
          <ButtonStyled
            variant="outlined"
            LinkComponent={Link}
            href="https://alloweat.com/historie-sukcesu/"
            target="_blank"
          >
            Zobacz historie sukcesów
          </ButtonStyled>
          <ButtonStyled
            variant="contained"
            href="mailto: hello@alloweat.com?subject=Zakup pakietu student"
          >
            Skorzystaj z oferty
          </ButtonStyled>
        </div>
      </Container>
    </Dialog>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 600px;
  padding: 0 24px 24px;
`;

const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
  max-width: 380px;
`;

const Title = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

const Description = styled("span")`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

const PurpleWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background: ${({ theme }) => theme.palette.primary.light};
  padding: 8px 12px;
  border-radius: 8px;
  width: 552px;
`;

const OfferText = styled("span")`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

const OfferTextBold = styled(OfferText)`
  font-weight: 600;
`;

const ButtonStyled = styled(Button)`
  height: 40px;
` as typeof Button;
