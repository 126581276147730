import { CircularProgress } from "@mui/material";

import { RecipeTabPanels } from "@components/PreviewDrawer/Recipe";
import { useAppTranslation } from "@hooks";
import { useFetchRecipeQuery } from "@hooks/queries";

import { RecipeNutrientsTab } from "../RecipeNutrientsTab/RecipeNutrientsTab";

interface RecipeContentProps {
  id: number;
}
export const RecipeContent = ({ id }: RecipeContentProps) => {
  const { recipe, isLoading } = useFetchRecipeQuery(id);
  const { isPolishChosen } = useAppTranslation();

  if (isLoading) return <CircularProgress />;
  if (!recipe) return <></>;

  return (
    <RecipeTabPanels
      nutrients={recipe.nutrients}
      description={recipe.description ?? ""}
      descriptionEn={recipe.descriptionEn ?? ""}
      products={recipe.foodRecipe.map(f => ({
        grams: f.grams,
        id: f.id,
        foodId: f.food.id,
        measureId: f.foodMeasureId,
        measures: f.food.measures,
        name: f.food[isPolishChosen ? "descriptionPl" : "description"] ?? "",
        nutrients: f.food.nutrients,
      }))}
      tags={recipe.tags.map(t => t.id)}
      NutrientsTab={props => <RecipeNutrientsTab {...props} />}
      padding="8px"
      comment={recipe.comment}
      commentEn={recipe.commentEn}
    />
  );
};
