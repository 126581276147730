import RocketIcon from "@assets/icons_new/gradientRocket.svg?react";
import { styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";
import { StudentModal } from "@views/dietician/Home/components/StudentModal/StudentModal";
import { useState } from "react";

interface StudentMenuItemProps {
  open: boolean;
}

export const StudentMenuItem = ({ open }: StudentMenuItemProps) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => setOpenModal(true);
  return (
    <>
      <ItemWrapper open={open} onClick={handleClick}>
        <ContentWrapper open={open}>
          <TopSection>
            <RocketIcon fontSize={21} />
            {open && <Badge src="/static/studentModal/50percentBadge.svg" />}
          </TopSection>

          {open && (
            <TextWrapper>
              <Text>Rozwiń swoją karierę!</Text>
              <TextUnderlined>Dowiedz się więcej</TextUnderlined>
            </TextWrapper>
          )}
        </ContentWrapper>
      </ItemWrapper>
      <StudentModal open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

interface ListItemStyledProps {
  open: boolean;
}

const ItemWrapper = styled("a")<ListItemStyledProps>`
  cursor: pointer;
  width: ${({ open }) => (open ? "100%" : "35px")};
  height: ${({ open }) => (open ? "101px" : "35px")};
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1),
    height 300ms cubic-bezier(0.4, 0, 0.2, 1),
    background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: ${({ theme }) => theme.palette.primary.light2};
  border: 1px solid
    ${({ theme }) => getHexColorOpacity(theme.palette.primary.main, 30)};
  border-radius: 8px;
  backdrop-filter: blur(4px);
  padding: 6px;
  overflow: hidden;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.medium};
  }
  &:active {
    background: ${({ theme }) =>
      getHexColorOpacity(theme.palette.primary.main, 15)};
  }
`;

const ContentWrapper = styled("div")<ListItemStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ open }) => (open ? "87px" : "26px")};
  overflow: hidden;
`;

const TopSection = styled("div")`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 178px;
`;

const Badge = styled("img")`
  width: 77px;
  height: 26px;
`;

const TextWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: ${({ theme }) => theme.palette.primary.main};
  white-space: nowrap;
`;

const TextUnderlined = styled(Text)`
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
`;
