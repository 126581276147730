import { Stack, styled } from "@mui/material";
import { Languages, Password, Profile } from "./cards";
import { FavoriteNutrients } from "./cards/FavoriteNutrients";
import { useAppTranslation } from "@hooks";

export const Settings = () => {
  const { t } = useAppTranslation();

  return (
    <Stack gap="24px">
      <Title>{t("settings.account_settings")}</Title>

      <Stack direction="row" gap="16px">
        <Stack gap="16px" width="1">
          <Profile />
          <Password />
        </Stack>

        <Stack gap="16px" width="1">
          <Languages />
          <FavoriteNutrients />
        </Stack>
      </Stack>
    </Stack>
  );
};

const Title = styled("h1")`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
