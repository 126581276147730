import { IconButton, styled, Tooltip } from "@mui/material";
import { ReactNode } from "react";

interface IconButtonTooltipProps {
  onClick: () => void;
  tooltip?: string;
  children: ReactNode;
}

export const IconButtonTooltip = ({
  onClick,
  tooltip,
  children,
}: IconButtonTooltipProps) => {
  if (tooltip)
    return (
      <Tooltip title={tooltip} arrow>
        <div>
          <IconButtonStyled onClick={onClick} size="small">
            {children}
          </IconButtonStyled>
        </div>
      </Tooltip>
    );

  return (
    <IconButtonStyled onClick={onClick} size="small">
      {children}
    </IconButtonStyled>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: unset;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
