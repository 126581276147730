import { useAppTranslation, useModalState } from "@hooks";
import { Chip, Divider, Tooltip } from "@mui/material";
import { Pencil } from "@assets/icons";
import {
  ClientProfileCard,
  EditButton,
  SectionTitle,
  TitleSection,
} from "../../ClientProfile.styled";
import { MobileAppSectionProps, noData } from "../../ClientProfile";
import { ActiveServicesContent, CheckIcon } from "./MobileAppSection.styled";
import { ClientProfileCardItem } from "../ClientProfileCardItem";
import { EditClientPaymentsModal } from "@components/EditClientPaymentsModal";
import { RecipeAndProductsModal } from "../modals/RecipeAndProducts";
import IrrigationSection from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/IrrigationSection";
import { ExploreModal } from "../modals/ExploreModal";
import SettingsSection from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/SettingsSection";
import { InfoWithTooltip } from "@components/InfoWithTooltip";

export const MobileAppSection = ({ account }: MobileAppSectionProps) => {
  const {
    active,
    profile: {
      applicationModules: {
        chat,
        accessRecipeBase: { alloweat, clinic },
      },
    },
  } = account;
  const { t } = useAppTranslation();
  const [openRecipesModal, onOpenRecipesModal, onCloseRecipesModal] =
    useModalState();
  const [openExploreModal, onOpenExploreModal, onCloseExploreModal] =
    useModalState();
  const [modalOpened, onModalOpen, onModalClose] = useModalState();
  const hasAppModule = chat || alloweat || clinic;

  return (
    <>
      <ClientProfileCard>
        <TitleSection>
          <p>{t("client_profile.payments.mobile_app")}</p>
        </TitleSection>

        <div className="flex justify-between">
          <SectionTitle>
            {t("client_profile.payments.status_and_functions")}
          </SectionTitle>
          <Tooltip
            title={t("client_profile.payments.edit_payments_tooltip")}
            disableHoverListener={!!active}
            arrow
            placement="top"
          >
            <div>
              <EditButton
                disabled={!active}
                onClick={onModalOpen}
                startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
                disableRipple
              >
                {t("common.edit")}
              </EditButton>
            </div>
          </Tooltip>
        </div>

        <ClientProfileCardItem
          title={t("client_profile.payments.app_status")}
          subtitle={
            active ? (
              <Chip
                className="w-fit"
                icon={
                  active.mobileApp ? (
                    <CheckIcon size="w-3.5 h-3.5" />
                  ) : undefined
                }
                color={active.mobileApp ? "success" : "warning"}
                size="small"
                label={
                  active.mobileApp
                    ? t("client_profile.payments.status_active")
                    : t("client_profile.payments.status_inactive")
                }
              />
            ) : (
              noData
            )
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.payments.active_functions")}
          subtitle={
            hasAppModule ? (
              <ActiveServicesContent>
                {chat && (
                  <Chip
                    className="w-fit"
                    color="primary"
                    size="small"
                    label={t("common.chat")}
                  />
                )}
                {alloweat && (
                  <Chip
                    className="w-fit"
                    color="primary"
                    size="small"
                    label={t("client_profile.payments.alloweat_recipes")}
                  />
                )}
                {clinic && (
                  <Chip
                    className="w-fit"
                    color="primary"
                    size="small"
                    label={t("client_profile.payments.dietitians_recipes")}
                  />
                )}
              </ActiveServicesContent>
            ) : (
              noData
            )
          }
        />

        <Divider />

        <IrrigationSection />

        <Divider />

        <div className="flex justify-between">
          <div className="flex items-center gap-[4px]">
            <SectionTitle>{t("client_profile.payments.discover")}</SectionTitle>
            <InfoWithTooltip
              label="Moduł z przepisami udostępnionymi klientowi, które może dowolnie 
eksplorować w aplikacji mobilnej."
            />
          </div>
          <EditButton
            disabled={!active}
            onClick={onOpenExploreModal}
            startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
            disableRipple
          >
            {t("common.edit")}
          </EditButton>
        </div>

        <Divider />

        <SettingsSection />

        <Divider />

        <div className="flex justify-between">
          <SectionTitle>
            {t("client_profile.payments.recipes_products_db")}
          </SectionTitle>
          <EditButton
            disabled={!active}
            onClick={onOpenRecipesModal}
            startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
            disableRipple
          >
            {t("common.edit")}
          </EditButton>
        </div>

        <ClientProfileCardItem
          title={t("client_profile.payments.available_dbs")}
          subtitle="-"
        />
        <ClientProfileCardItem
          title={t("client_profile.payments.filter_dbs")}
          subtitle="-"
          infoTooltip="Filtry ograniczające bazy przepisów dostępne w aplikacji klienta, bazujące na
alergenach i rodzaju diety klienta."
        />
        <ClientProfileCardItem
          title={t("client_profile.payments.substitutes")}
          subtitle="-"
          infoTooltip="Propozycje produktów, którymi można zastąpić dany składnik przepisu."
        />
      </ClientProfileCard>

      <EditClientPaymentsModal open={modalOpened} onClose={onModalClose} />
      <RecipeAndProductsModal
        open={openRecipesModal}
        onClose={onCloseRecipesModal}
      />
      <ExploreModal open={openExploreModal} onClose={onCloseExploreModal} />
    </>
  );
};
