import {
  LoadingButton,
  TabContext,
  TabList as TabListMaterial,
  TabPanel,
} from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  IconButton,
  Stack,
  styled,
  Tab,
} from "@mui/material";

import CloseIcon from "@assets/icons_new/close.svg?react";
import { useAppTranslation } from "@hooks";
import { useMemo, useState } from "react";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import { uniq } from "lodash";

interface FavoriteNutrientsModalProps {
  open: boolean;
  onClose: () => void;
}

export const FavoriteNutrientsModal = ({
  open,
  onClose,
}: FavoriteNutrientsModalProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { data } = useCategoriesNutrientsFetchQuery();
  const { nutrients: visibleNutrients, setNutrients } = useVisibleNutrients();
  const [favoriteNutrients, setFavoriteNutrients] = useState(visibleNutrients);
  const [tab, setTab] = useState("3");

  const categories = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter(category => ![1, 2].includes(category.id));
  }, [data]);

  const toggleCheckNutrient =
    (id: string) => (e: React.SyntheticEvent, checked: boolean) => {
      if (checked && favoriteNutrients.length < 10) {
        setFavoriteNutrients(uniq([...favoriteNutrients, id.toString()]));
        return;
      }
      if (!checked) {
        setFavoriteNutrients(
          favoriteNutrients.filter(nutrient => nutrient !== id),
        );
        return;
      }
    };

  const handleSubmit = () => {
    setNutrients(favoriteNutrients);
    onClose();
  };
  const handleClose = () => {
    setFavoriteNutrients(visibleNutrients);
    onClose();
  };
  return (
    <Dialog open={open}>
      <Stack
        direction="row"
        justifyContent="space-between"
        padding="16px"
        alignItems="center"
        width="510px"
      >
        <Title>{t("settings.favorite.modal.favorite_nutrition")}</Title>

        <CloseButton size="small" onClick={onClose}>
          <CloseIcon fontSize="24px" />
        </CloseButton>
      </Stack>
      <Stack padding="0 16px 24px" gap="20px">
        <Subtitle>{t("settings.favorite.modal.select_up_to_10")}</Subtitle>
        <div>
          <TabContext value={tab}>
            <TabList onChange={(e, value) => setTab(value)}>
              {categories.map(category => {
                return (
                  <Tab
                    key={category.id.toString()}
                    label={isPolishChosen ? category.namePl : category.nameEn}
                    value={category.id.toString()}
                  />
                );
              })}
            </TabList>
            {categories.map(category => {
              return (
                <TabPanel
                  key={category.id}
                  value={category.id.toString()}
                  sx={{ padding: "12px" }}
                >
                  <Stack gap="8px">
                    {category.nutrients.map(
                      ({ id, descriptionPl, descriptionEn }) => (
                        <FormControlLabel
                          key={id}
                          sx={{ margin: "unset" }}
                          control={<Checkbox />}
                          label={isPolishChosen ? descriptionPl : descriptionEn}
                          checked={favoriteNutrients.includes(id.toString())}
                          onChange={toggleCheckNutrient(id.toString())}
                        />
                      ),
                    )}
                  </Stack>
                </TabPanel>
              );
            })}
          </TabContext>
        </div>
      </Stack>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <LoadingButton variant="contained" onClick={handleSubmit}>
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const Title = styled("h2")`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

const CloseButton = styled(IconButton)`
  padding: unset;
  height: unset;
`;

const TabList = styled(TabListMaterial)`
  border: 1px solid #f5f1fe;
  border-radius: 8px;
  padding: 4px;
  width: fit-content;
  max-width: 100%;
  .Mui-selected {
    border-radius: 8px;
    border: none;
    background: #f5f1fe;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root.MuiButtonBase-root {
    font-family: Figtree, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 6px 8px;
  }

  .MuiTabs-flexContainer {
    gap: 4px;
    width: 478px;
  }

  .MuiTabs-scrollButtons {
    width: 20px;
  }
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
` as typeof TabListMaterial;

const Subtitle = styled("div")`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #4d516e;
`;
