import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FetchGlobalPatientSettingsResponse } from "@client/clinic/fetchGlobalPatientSettings";
import { updateGlobalPatientSettings } from "@client/clinic/updateGlobalPatientSettings";
import { GlobalClinicPatientSettingsResource } from "@client/resources/GlobalClinicPatientSettingsResource";

import { clinicKeys } from "./clinicKeys";

export const useUpdateGlobalPatientSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Partial<GlobalClinicPatientSettingsResource>) =>
      updateGlobalPatientSettings(payload),
    onMutate: variables => {
      queryClient.setQueryData<FetchGlobalPatientSettingsResponse>(
        clinicKeys.globalPatientSettings(),
        oldData => {
          if (oldData === undefined) return;

          return {
            ...oldData,
            data: {
              ...oldData.data,
              ...variables,
            },
          };
        },
      );
    },
    onError: () => {
      queryClient.invalidateQueries(clinicKeys.globalPatientSettings());
    },
  });
};
