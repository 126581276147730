import { MeasurementDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useForm } from "react-hook-form";

export const useEditMeasurementsForm = (
  defaultValues?: EditMeasurementsFormProps,
) => {
  const form = useForm<EditMeasurementsFormProps>({ defaultValues });

  return form;
};

interface EditMeasurementsFormProps {
  measurementsBodyId: number[];
  requiredPhotos: boolean;
}

export const mapEditMeasurementsForm = (
  data: MeasurementDto | undefined,
): EditMeasurementsFormProps | undefined => {
  if (!data) return;

  const { requiredImages, types } = data;

  return {
    requiredPhotos: requiredImages,
    measurementsBodyId: types,
  };
};
export const mapEditMeasurementsRequest = ({
  measurementsBodyId,
  requiredPhotos,
}: EditMeasurementsFormProps): MeasurementDto => {
  return {
    requiredImages: requiredPhotos,
    types: measurementsBodyId,
  };
};
