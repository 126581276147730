import { useContext, useMemo } from "react";

import dayjs from "dayjs";

import { useAppParams } from "@hooks";
import {
  useFetchDietitianClientProgramScheduleQuery,
  useFetchDietitianProgramScheduleQuery,
} from "@hooks/queries/schedule";
import { ScheduleViews } from "@typeDefinitions";
import { ProgramScheduleNavigationContext } from "@views/dietician/ProgramSchedule/context";

export const useRecipeEncounter = (query: string) => {
  const { patientId, programId } = useAppParams();
  const { currentRangeStart, currentRangeEnd } = useContext(
    ProgramScheduleNavigationContext,
  );
  const periodDays = currentRangeEnd.diff(currentRangeStart, "day") + 1;

  const getProperPeriodDays = (periodDays: number): ScheduleViews => {
    if (periodDays >= ScheduleViews.FOUR_WEEKS) return ScheduleViews.FOUR_WEEKS;
    if (periodDays >= ScheduleViews.TWO_WEEKS) return ScheduleViews.TWO_WEEKS;
    return ScheduleViews.WEEK;
  };
  const schedulePeriodDays = useMemo(
    () => getProperPeriodDays(periodDays),
    [periodDays],
  );

  const { scheduleData } = useFetchDietitianProgramScheduleQuery(
    Number(programId),
    schedulePeriodDays,
    currentRangeStart,
    { enabled: !patientId },
  );
  const { scheduleData: patientScheduleData } =
    useFetchDietitianClientProgramScheduleQuery(
      patientId,
      programId,
      schedulePeriodDays,
      currentRangeStart,
      { enabled: !!patientId },
    );

  const encounters = useMemo(() => {
    const data = patientId ? patientScheduleData : scheduleData;

    return (
      data?.flatMap(({ date, meals }) =>
        meals
          .filter(({ recipe }) =>
            [
              recipe?.title.toLocaleLowerCase(),
              recipe?.titleEn?.toLocaleLowerCase(),
            ].includes(query.toLocaleLowerCase()),
          )
          .flatMap(({ dietMeal: { hour, title }, servings }) => ({
            date: dayjs(date).toDate(),
            hour: hour ?? "",
            name: title,
            selectedServings: servings ?? 1,
          })),
      ) ?? []
    );
  }, [scheduleData, patientScheduleData]);

  return { encounters };
};
