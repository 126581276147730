import { createContext, useContext, useReducer } from "react";
import type { PropsWithChildren } from "react";

export enum ModalType {
  "DELETE_MATERIAL" = "DELETE_MATERIAL",
  "CREATE_CATEGORY" = "CREATE_CATEGORY",
  "CREATE_DOCUMENT" = "CREATE_DOCUMENT",
  "CREATE_VIDEO" = "CREATE_VIDEO",
  "CREATE_FILE" = "CREATE_FILE",
  "PREVIEW" = "PREVIEW",
}

enum ActionType {
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
  UPDATE_MATERIAL_ID = "UPDATE_MATERIAL_ID",
}

interface ModalState {
  materialId: string | null;
  sectionId: number | null;
  [ModalType.DELETE_MATERIAL]: boolean;
  [ModalType.CREATE_CATEGORY]: boolean;
  [ModalType.CREATE_DOCUMENT]: boolean;
  [ModalType.CREATE_VIDEO]: boolean;
  [ModalType.CREATE_FILE]: boolean;
  [ModalType.PREVIEW]: boolean;
}

interface Action {
  type: ActionType;
  modal?: ModalType;
  materialId: string | null;
  sectionId?: number | null;
}

type VoidFunction = (
  modal: ModalType,
  materialId?: string,
  sectionId?: number,
) => void;

const reducer = (
  state: ModalState,
  { type, materialId, modal, sectionId }: Action,
): ModalState => {
  switch (type) {
    case ActionType.OPEN_MODAL:
      return {
        ...state,
        [modal!]: true,
        materialId,
        sectionId: sectionId ?? null,
      };
    case ActionType.CLOSE_MODAL:
      return { ...state, [modal!]: false, materialId: null, sectionId: null };
    case ActionType.UPDATE_MATERIAL_ID:
      return { ...state, materialId };
    default:
      return state;
  }
};

const initialState: ModalState = {
  [ModalType.DELETE_MATERIAL]: false,
  [ModalType.CREATE_CATEGORY]: false,
  [ModalType.CREATE_DOCUMENT]: false,
  [ModalType.CREATE_VIDEO]: false,
  [ModalType.CREATE_FILE]: false,
  [ModalType.PREVIEW]: false,
  materialId: null,
  sectionId: null,
};

const EducationalModalContext = createContext<{
  openModal: VoidFunction;
  closeModal: VoidFunction;
  getModalState: (modal: ModalType) => boolean;
  materialId: string | null;
  sectionId: number | null;
  updateMaterialId: (materialId: string | null) => void;
}>({
  openModal: () => null,
  closeModal: () => null,
  getModalState: () => false,
  materialId: null,
  sectionId: null,
  updateMaterialId: () => null,
});

export const EducationalModalProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = (
    modal: ModalType,
    materialId?: string,
    sectionId?: number,
  ) =>
    dispatch({
      type: ActionType.OPEN_MODAL,
      modal,
      materialId: materialId ?? null,
      sectionId: sectionId ?? null,
    });

  const closeModal = (modal: ModalType) =>
    dispatch({
      type: ActionType.CLOSE_MODAL,
      modal,
      materialId: null,
      sectionId: null,
    });

  const updateMaterialId = (materialId: string | null) =>
    dispatch({
      type: ActionType.UPDATE_MATERIAL_ID,
      materialId,
    });

  const getModalState = (modal: ModalType) => state[modal];

  const materialId = state.materialId;

  const sectionId = state.sectionId;

  return (
    <EducationalModalContext.Provider
      value={{
        openModal,
        closeModal,
        getModalState,
        materialId,
        sectionId,
        updateMaterialId,
      }}
    >
      {children}
    </EducationalModalContext.Provider>
  );
};

export function useEducationalModalContext() {
  const context = useContext(EducationalModalContext);
  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
}
