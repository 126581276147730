import { InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";
import { BodyMeasurementResource } from "@client/dictionaries";
import TextField from "@components/Alloweat/Inputs/TextField/TextField";
import { useMemo } from "react";
import { useAppTranslation, useClientParams } from "@hooks";
import { getTranslation } from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";
import FormLabel from "@components/Alloweat/Inputs/FormLabel";
import FormControl from "@components/Alloweat/Inputs/FormControl";
import useFetchPatientLastBodyMeasurement from "@hooks/queries/client/bodyMeasurement/useFetchPatientLastBodyMeasurement";

type BodyMeasurementFieldProps = {
  bodyMeasurement: BodyMeasurementResource;
};

const BodyMeasurementField = ({
  bodyMeasurement,
}: BodyMeasurementFieldProps) => {
  const { currentLanguage } = useAppTranslation();
  const id = useClientParams();

  const { data: lastBodyMeasurement } = useFetchPatientLastBodyMeasurement({
    patientId: id,
  });

  const label = useMemo(() => {
    const translation = getTranslation(bodyMeasurement, currentLanguage);

    return translation ? translation.name : bodyMeasurement.name;
  }, [bodyMeasurement, currentLanguage]);

  const placeholder = useMemo(() => {
    if (!lastBodyMeasurement || !lastBodyMeasurement.data) {
      return "";
    }

    const value = lastBodyMeasurement.data.bodyMeasurements.find(
      bm => bm.body.id === bodyMeasurement.id,
    );

    return value?.value?.toString() ?? "";
  }, [bodyMeasurement, lastBodyMeasurement]);

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Controller
        render={({ field, fieldState }) => {
          return (
            <TextField
              error={fieldState.invalid}
              {...field}
              onChange={e => field.onChange(e.target.value.replace(",", "."))}
              InputProps={{
                placeholder: placeholder,
                endAdornment: (
                  <InputAdornment position="end">
                    {bodyMeasurement.unit}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
        name={`measurementBody.${bodyMeasurement.id}`}
      />
    </FormControl>
  );
};

export default BodyMeasurementField;
