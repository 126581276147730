import { useAppTranslation } from "@hooks";
import { Autocomplete, TextField } from "@mui/material";
import { range } from "lodash";
import { Control, FieldValues, useController } from "react-hook-form";
import { EditPdfFormProps } from "./useEditPdfForm";
import { useMemo } from "react";

interface ShoppingListAutocompleteProps {
  control: Control<EditPdfFormProps>;
}
export const ShoppingListAutocomplete = ({
  control,
}: ShoppingListAutocompleteProps) => {
  const { t } = useAppTranslation();
  const daysOptions = range(1, 31).map(id => ({
    id: id.toString(),
    label: `${id} ${t("common.days", { count: id })}`,
  }));
  const {
    field: { value, onChange },
  } = useController({ control, name: "shoppingListPeriod" });

  const selectedValue = useMemo(
    () => daysOptions.find(({ id }) => value.toString() === id),
    [value],
  );
  return (
    <Autocomplete
      size="small"
      fullWidth
      options={daysOptions}
      renderInput={p => <TextField {...p} />}
      value={selectedValue}
      onChange={(e, value) => onChange(Number(value?.id))}
    />
  );
};
