import { useForm, useWatch } from "react-hook-form";
import { object, string, number, boolean, InferType } from "yup";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { DetailedSection } from "@typeDefinitions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEducationalModalContext } from "@views/dietician/EducationalMaterials/_hooks/useEducationalMaterialsContext";
import {
  Language,
  SectionCategory,
} from "@views/dietician/EducationalMaterials/EducationalMaterialsView.types";
import {
  useCreateSectionMutation,
  useFetchProgramSectionQuery,
  useFetchSectionQuery,
  useUpdateProgramSectionMutation,
  useUpdateSectionMutation,
} from "./queries";
import { FetchSectionResponse } from "@client";
import { useDebounce } from "react-use";
import isEqual from "lodash/isEqual";

import { Dispatch, SetStateAction } from "react";

interface ProgramSectionDto {
  id: number;
  title: string;
  titleEn: string | null;
  content: string | null;
  contentEn: string | null;
  isSystemic: boolean;
}

const mapSectionToFormSchema = (
  section: FetchSectionResponse,
): SectionFormSchema => {
  return {
    language: Language.PL,
    title: section.title,
    titleEn: section.titleEn ?? null,
    content: section.content,
    contentEn: section.contentEn ?? null,
    description_pl: section.descriptionPl ?? "",
    description_en: section.descriptionEn ?? null,
    imageId: section.image?.id ?? null,
    imageUrl: section.image?.url ?? "",
    programSectionCategoryId: section.programSectionCategory.id,
    reviewed: section.reviewed,
  };
};

const mapProgramSectionToFormSchema = (
  programSection: ProgramSectionDto,
  defaultValues: SectionFormSchema,
): SectionFormSchema => {
  return {
    ...defaultValues,
    language: Language.PL,
    title: programSection.title ?? "",
    titleEn: programSection.titleEn ?? null,
    content: programSection.content ?? "",
    contentEn: programSection.contentEn ?? null,
  };
};

const formSchema = object().shape({
  language: string().defined(),
  title: string().nullable().required(),
  titleEn: string().nullable().default(""),
  content: string().nullable().required(),
  description_pl: string().default(""),
  description_en: string().nullable().default(""),
  imageId: number().nullable().default(null),
  imageUrl: string().default(""),
  contentEn: string().nullable().default(""),
  reviewed: boolean().default(false),
  programSectionCategoryId: number().default(SectionCategory.OTHERS),
});

export type SectionFormSchema = InferType<typeof formSchema>;

interface UseSectionFormOptions {
  defaultSection?: DetailedSection;
  onSave?: Dispatch<SetStateAction<boolean>>;
  programId?: number;
}

export function useSectionForm(options?: UseSectionFormOptions) {
  const { onSave, defaultSection, programId } = options || {};
  const { t } = useAppTranslation();

  const { mutateAsync: createSectionMutation } = useCreateSectionMutation();
  const { mutateAsync: updateSectionMutation } = useUpdateSectionMutation();
  const { mutateAsync: updateProgramSectionMutation } =
    useUpdateProgramSectionMutation();

  const { sectionId, materialId, updateMaterialId } =
    useEducationalModalContext();

  const { section } = useFetchSectionQuery(Number(materialId), {
    enabled: !!materialId,
  });

  const { data: programSection } = useFetchProgramSectionQuery(
    programId?.toString() ?? "",
    materialId?.toString() ?? "",
    !!programId && !!materialId,
  );

  const defaultValues = {
    language: Language.PL,
    title: "",
    titleEn: "",
    content: "",
    description_pl: "",
    description_en: "",
    contentEn: "",
    imageId: null,
    imageUrl: "",
    reviewed: false,
    programSectionCategoryId: SectionCategory.OTHERS,
  };

  const composedData =
    programId && programSection
      ? mapProgramSectionToFormSchema(programSection, defaultValues)
      : materialId && section
      ? mapSectionToFormSchema(section.data)
      : {
          ...defaultValues,
          programSectionCategoryId:
            sectionId ?? defaultValues.programSectionCategoryId,
        };

  const form = useForm<SectionFormSchema>({
    defaultValues,
    mode: "onChange",
    values: composedData,
    resolver: yupResolver(formSchema),
  });

  const formData = useWatch({ control: form.control });

  const { isDirty, isValid } = form.formState;

  const handleCreateSection = async () => {
    if (isDirty && !materialId && isValid) {
      try {
        const newMaterial = await createSectionMutation({
          title: formData.title ?? "",
          titleEn: formData.titleEn ?? null,
          content: formData.content ?? null,
          contentEn: formData.contentEn ?? null,
          description_pl: formData.description_pl ?? "",
          description_en: formData.description_en ?? null,
          imageId: formData.imageId ?? null,
          programSectionCategoryId:
            formData.programSectionCategoryId ?? SectionCategory.OTHERS,
          reviewed: formData.reviewed,
        });

        if (newMaterial) {
          updateMaterialId(newMaterial.data.id);
          onSave?.(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUpdateSection = async () => {
    if (
      materialId &&
      isValid &&
      !isEqual(formData, composedData) &&
      programId
    ) {
      await updateProgramSectionMutation({
        sectionId: Number(materialId),
        programId: programId,
        payload: {
          title: formData.title ?? "",
          titleEn: formData.titleEn ?? "",
          content: formData.content ?? "",
          contentEn: formData.contentEn ?? "",
        },
      });
      onSave?.(true);
    }

    if (
      materialId &&
      isValid &&
      !isEqual(formData, composedData) &&
      !programId
    ) {
      try {
        await updateSectionMutation({
          id: Number(materialId),
          input: {
            title: formData.title ?? "",
            titleEn: formData.titleEn ?? null,
            content: formData.content ?? null,
            contentEn: formData.contentEn ?? null,
            description_pl: formData.description_pl ?? "",
            description_en: formData.description_en ?? null,
            imageId: formData.imageId ?? null,
            programSectionCategoryId:
              formData.programSectionCategoryId ?? SectionCategory.OTHERS,
            reviewed: formData.reviewed,
          },
        });
        onSave?.(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useDebounce(
    () => {
      if (!materialId) {
        handleCreateSection();
      } else {
        handleUpdateSection();
      }
    },
    2000,
    [formData, isDirty, isValid, materialId],
  );

  return form;
}
