import { Box, DialogContent, Stack, TextField } from "@mui/material";
import { ButtonOwnProps } from "@mui/material/Button/Button";
import dayjs from "dayjs";
import {
  BoxWrapper,
  TodayButton,
  Title,
  CustomButton,
  ButtonList,
} from "@views/dietician/PatientMonitoring2/components/CalendarType/CalendarType.styled";
import {
  Type,
  useMonitoring,
} from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useAppTranslation } from "@hooks";
import { CalendarMonitoring } from "@assets/icons/Monitoring";
import CalendarTypeCustomButtom from "@views/dietician/PatientMonitoring2/components/CalendarType/CalendarTypeCustomButtom";
import { useMemo } from "react";

const CalendarType = () => {
  const { type, selectedDays } = useMonitoring();
  const { t } = useAppTranslation();

  const getVariantButton = (buttonType: Type): ButtonOwnProps["variant"] =>
    buttonType === type.type ? "contained" : undefined;

  const handleOnClick = (variant: Type) => {
    type.setType(variant);
    if (variant === "custom") {
      selectedDays.setSelectDays({
        from: dayjs().startOf("day").subtract(5, "day"),
        to: dayjs().endOf("day").add(5, "day"),
      });
    } else {
      selectedDays.setSelectDays({
        from: dayjs().startOf(variant),
        to: dayjs().endOf(variant),
      });
    }
  };

  const title = useMemo(() => {
    if (type.type === "day") {
      return selectedDays.selectDays.from.format("DD.MM.YYYY");
    }
    if (type.type === "month") {
      const month = t(
        "common.full_month_name." + (selectedDays.selectDays.from.month() + 1),
      );
      return `${month} ${selectedDays.selectDays.from.format("YYYY")}`;
    }
    return `${selectedDays.selectDays.from.format(
      "DD.MM",
    )} - ${selectedDays.selectDays.to.format("DD.MM.YYYY")}`;
  }, [type.type, selectedDays.selectDays, t]);

  return (
    <BoxWrapper>
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Title>{title}</Title>
          <Box display="flex" alignItems="center">
            <TodayButton
              onClick={() => handleOnClick("day")}
              variant="outlined"
              color="primary"
              size="small"
            >
              {t("monitoring2.calendar.today_button")}
            </TodayButton>
          </Box>
        </Stack>
        <ButtonList direction="row" spacing="4px">
          <CustomButton
            variant={getVariantButton("day")}
            onClick={() => handleOnClick("day")}
          >
            D
          </CustomButton>
          <CustomButton
            variant={getVariantButton("week")}
            onClick={() => handleOnClick("week")}
          >
            T
          </CustomButton>
          <CustomButton
            variant={getVariantButton("month")}
            onClick={() => handleOnClick("month")}
          >
            M
          </CustomButton>
          <CalendarTypeCustomButtom />
        </ButtonList>
      </Stack>
    </BoxWrapper>
  );
};

export default CalendarType;
