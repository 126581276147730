import {
  Divider,
  IconButton,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { BookWithQuestionMark } from "@assets/icons";
import CheckListIcon from "@assets/icons_new/checkList.svg?react";
import NoteIcon from "@assets/icons_new/note.svg?react";
import { Avatar } from "@components/AvatarNew";
import { useNotesSidePanelContext } from "@components/NotesSidePanel/NotesSidePanel.context";
import { NotificationMenu } from "@components/NotificationMenu";
import { useTourTriggerContext } from "@context";
import {
  useAppEnvironment,
  useAppTranslation,
  useLogRocket,
  useUserRoles,
} from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { DASHBOARD, RESET } from "@routes";
import { useFeatureFlags } from "@utils/featureFlags";
import { ChatList } from "@views/dietician/Chat";
import { useTasksContext } from "@views/dietician/Tasks/Tasks.context";

import { ClientsSearch } from "./ClientsSearch";
import { TourButton } from "./Header.styled";
import { IconButtonTooltip } from "./IconButtonTooltip";
import { OfflineBackdrop } from "./OfflineBackdrop";
import { UserSettingsDropDown } from "./UserSettingsDropDown";
import { StudentBanner } from "./StudentBanner";

export const Header = memo(() => {
  const { t, i18n, secondLanguage } = useAppTranslation();
  const { pathname } = useLocation();

  const { account } = useFetchDietitianAccountQuery();
  const { handleOpenCreateModal } = useTasksContext();
  const { handleOpenNotesPanel } = useNotesSidePanelContext();
  const { isProd } = useAppEnvironment();
  const { isSchool } = useUserRoles();
  const { notify, enabled } = useTourTriggerContext();
  const { chat } = useFeatureFlags();
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);

  const userFullName = useMemo(() => {
    if (!account) return "";
    return account.firstName + " " + account.lastName;
  }, [account]);
  const imgUrl = account?.avatar;

  useLogRocket(account);
  if (import.meta.env.VITE_APP_ENABLE_USERCOM === "true") {
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (account && window?.UE?.pageHit && !pathname.startsWith(RESET)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.UE.pageHit({
          apiKey: "kjcaol",
          name: `${account.firstName} ${account.lastName}`,
          user_id: account.id,
          email: account.email,
        });
        console.log("User initialization success");
      }
    }, [account, pathname]);
  }

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    notify();
  };

  return (
    <HeaderStyled>
      <Stack flex={1}>
        <Link to={DASHBOARD}>
          {downMedium ? (
            <ImgSmall
              src="/static/logoIcon.svg"
              srcSet="/static/logoIcon.svg"
              alt="alloweat logo"
            />
          ) : (
            <Img
              src="/static/Logo@2x.png"
              srcSet="/static/Logo@2x.png"
              alt="alloweat logo"
            />
          )}
        </Link>
      </Stack>

      {!isProd && (
        <LangButton
          size="small"
          onClick={() => i18n.changeLanguage(secondLanguage)}
        >
          <p>{secondLanguage.toUpperCase()}</p>
        </LangButton>
      )}

      <Stack>
        <ClientsSearch />
      </Stack>

      <Stack
        flex={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap="24px"
      >
        <Stack direction="row" gap="8px" alignItems="center">
          {enabled && (
            <TourButton id="show-tour" size="small" onClick={handleClickStart}>
              <BookWithQuestionMark />
            </TourButton>
          )}
          <IconButtonTooltip
            tooltip={t("tasks.addTask")}
            onClick={handleOpenCreateModal}
          >
            <CheckListIcon fontSize={24} />
          </IconButtonTooltip>
          <IconButtonTooltip
            tooltip={t("tasks.addNote")}
            onClick={handleOpenNotesPanel}
          >
            <NoteIcon fontSize={24} />
          </IconButtonTooltip>
          {chat && <ChatList />}
          <NotificationMenu />
        </Stack>

        <DividerStyled orientation="vertical" />

        <Stack direction="row" alignItems="center">
          <Avatar
            size="small"
            image={imgUrl}
            fullName={userFullName}
            status={undefined}
          />
          {account && <UserSettingsDropDown fullName={userFullName} />}
        </Stack>
      </Stack>

      <OfflineBackdrop />
    </HeaderStyled>
  );
});

const HeaderStyled = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  position: fixed;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: 600;
  box-shadow: 0px 0px 2px 0px #00000014;
  top: 0;
  height: 56px;
  padding: 10px 24px;
`;

const Img = styled("img")`
  height: 26.76px;
  width: 129px;
`;

const ImgSmall = styled("img")`
  min-height: 32px;
  min-width: 32px;
`;

const DividerStyled = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutral.light[200]};
  height: 32px;
`;

const LangButton = styled(IconButton)`
  position: absolute;
  padding: unset;
  height: 24px;
  width: 24px;
  top: 16px;
  left: 155px;
  color: ${({ theme }) => theme.palette.primary.main};

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    left: 60px;
  }
`;
