import { Box } from "@mui/material";
import { LikedIcon } from "@icons/index";

type LikeCircleProps = {
  liked: boolean;
  color?: string;
  circle?: {
    color?: string;
    size?: number;
  };
};
const LikeCircle = (props: LikeCircleProps) => {
  return (
    <>
      <Box
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={props.circle?.size ?? 24}
        height={props.circle?.size ?? 24}
        sx={{
          backgroundColor: props.circle?.color ?? "#F5F1FE",
          transform: props.liked ? "rotate(180deg)" : "",
        }}
      >
        <LikedIcon color={props.color ?? "#7448D0"} />
      </Box>
    </>
  );
};

export default LikeCircle;
