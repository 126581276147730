import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { useTheme } from "@mui/material";

import { Plus } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { CreateClientResponse } from "@client";
import {
  useCloneClientProgramToClient,
  useFetchClientQuery,
  useFetchPatientProgramQuery,
} from "@hooks/queries";
import { TransitionDuration } from "@views/dietician/Diet/components/AddVisitModal";
import { AddClientModal } from "@components/AddClientModal";
import { ModalWrapper } from "@components/ModalWrapper";
import { SearchClientsAutocomplete } from "@components/SearchClientsAutocomplete";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";

import {
  BoldSpan,
  Divider,
  FormCheckboxMuiStyled,
  RegularSpan,
  StyledAddBtn,
  StyledP,
} from "./ProgramCopyModal.styled";
import {
  mapProgramCopyForm,
  mapProgramCopyRequest,
  useProgramCopyForm,
} from "./useProgramCopyForm";

interface ProgramCopyModalProps {
  open: boolean;
  onClose: () => void;
  selectedProgramId: number;
  patientId: number;
}

export const ProgramCopyModal = ({
  open,
  onClose,
  selectedProgramId,
  patientId,
}: ProgramCopyModalProps) => {
  const [transition, setTransition] = useState<TransitionDuration>();
  const [addClientModal, setAddClientModal] = useState(false);
  const [clientId, setClientId] = useState<number | undefined>(undefined);

  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { transitions } = useTheme();

  const { program } = useFetchPatientProgramQuery(
    patientId.toString(),
    selectedProgramId.toString(),
    { enabled: open },
  );
  const { client } = useFetchClientQuery(clientId ?? 0, {
    enabled: !!clientId,
  });
  const { client: currClient } = useFetchClientQuery(patientId);
  const active = currClient?.active === null ? 1 : undefined;

  const { mutate, isLoading } = useCloneClientProgramToClient(
    patientId,
    selectedProgramId,
    {
      onSuccess: data =>
        navigate(
          `/patients/${watch("clientId")}/nutritional-programs/${
            data?.data.id
          }`,
        ),
    },
  );

  const mappedProgram = useMemo(
    () => mapProgramCopyForm(patientId, program),
    [mapProgramCopyForm, program],
  );
  const newClientLabel = useMemo(
    () =>
      client
        ? {
            id: client.id.toString() ?? "",
            label: client.firstName + " " + client.lastName,
          }
        : {
            id: "",
            label: "",
          },
    [client],
  );

  const { control, watch, handleSubmit, setValue, reset } =
    useProgramCopyForm(mappedProgram);

  const programLengthInDays =
    program?.startDate && program.finishDate
      ? dayjs(program?.finishDate).diff(program?.startDate, "days") + 1
      : undefined;
  const selectedStartDate = dayjs(watch("startDate"));

  const openAddClientModal = useCallback(() => setAddClientModal(true), []);
  const closeAddClientModal = useCallback(() => setAddClientModal(false), []);
  const handleCreateClientSuccess = useCallback(
    (data?: CreateClientResponse) => setClientId(data?.id),
    [],
  );

  const removeTransition = () =>
    setTransition({
      enter: 0,
      exit: transitions.duration.leavingScreen,
    });
  const resetTransition = () =>
    setTransition({
      enter: transitions.duration.enteringScreen,
      exit: transitions.duration.leavingScreen,
    });

  const handleSubmitProgram = useCallback(
    handleSubmit(data => {
      mutate(mapProgramCopyRequest(data));
      resetTransition();
    }),
    [mutate, mapProgramCopyRequest, handleSubmit, resetTransition],
  );

  const onCloseWrapper = () => {
    onClose();
    resetTransition();
    reset(mappedProgram);
  };

  useEffect(() => {
    if (clientId) setValue("clientId", clientId.toString());
  }, [clientId]);

  useEffect(() => {
    if (addClientModal) removeTransition();
  }, [addClientModal]);

  if (addClientModal)
    return (
      <AddClientModal
        onClose={closeAddClientModal}
        open={addClientModal}
        onSuccess={handleCreateClientSuccess}
        transitionDuration={0}
      />
    );

  return (
    <ModalWrapper
      open={open}
      onClose={onCloseWrapper}
      onSubmit={handleSubmitProgram}
      title={t("program_clone.clone_title")}
      transitionDuration={transition}
      isLoading={isLoading}
      width="400px"
    >
      <StyledP>{t("program_clone.choose_client")}</StyledP>
      <div className="flex gap-4">
        <SearchClientsAutocomplete
          control={control}
          name="clientId"
          label={t("program_clone.client")}
          newClient={newClientLabel}
          active={active}
        />
        <StyledAddBtn
          size="small"
          variant="contained"
          onClick={openAddClientModal}
        >
          <Plus size="w-3 h-3" className="stroke-current" />
        </StyledAddBtn>
      </div>
      <Divider />
      <div className="w-1/2">
        <MuiTimeDatePicker
          dateVersion
          minDate={dayjs()}
          name="startDate"
          control={control}
          label={t("program_clone.start_date")}
        />
      </div>
      <FormCheckboxMuiStyled
        control={control}
        name="isIndefinite"
        label={t("program_clone.indefinite")}
        disabled
      />
      <div className="flex flex-col gap-2">
        <div className="flex gap-[8px]">
          <div className="flex flex-1 whitespace-nowrap">
            <RegularSpan>{t("program_clone.start_date")}:&nbsp;</RegularSpan>
            <BoldSpan>
              {selectedStartDate.isValid()
                ? selectedStartDate.format("L")
                : "-"}
            </BoldSpan>
          </div>
          <div className="flex flex-1 whitespace-nowrap">
            <RegularSpan>{t("program_clone.finish_date")}:&nbsp;</RegularSpan>
            <BoldSpan>
              {programLengthInDays
                ? selectedStartDate.add(programLengthInDays, "days").format("L")
                : "-"}
            </BoldSpan>
          </div>
        </div>
        <div className="flex">
          <RegularSpan>{t("program_clone.days")}:&nbsp;</RegularSpan>
          <BoldSpan>{programLengthInDays ?? "-"}</BoldSpan>
        </div>
      </div>
    </ModalWrapper>
  );
};
