import { ModalWrapper } from "@components/ModalWrapperNew";
import { LanguageTextField } from "@views/dietician/DietCreator/components/DietNameModal";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useAppTranslation } from "@hooks";
import { Box, Typography } from "@mui/material";
import { useEducationalMaterialsAddCategoryForm } from "../_hooks/useEducationalMaterialsAddCategoryForm";
import { EducationalMaterialsFormSchema } from "../_hooks/useEducationalMaterialsForm";

interface EducationalMaterialsCreateCategoryModalProps {
  onClose: () => void;
  open: boolean;
}

export const EducationalMaterialsCreateCategoryModal: FC<
  EducationalMaterialsCreateCategoryModalProps
> = ({ onClose, open }) => {
  const { t } = useAppTranslation();

  const { control, handleSubmit } = useEducationalMaterialsAddCategoryForm();

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      title={t("educational_materials.addCustomCategory")}
    >
      <div>
        <Typography
          fontSize="12px"
          fontWeight="400"
          color="neutral.medium[800]"
        >
          {t("educational_materials.categoryName")}
        </Typography>
        <Box paddingTop="4px" display="flex" flexDirection="column" gap="8px">
          <LanguageTextField name="pl" langPrefix="PL" control={control} />
          <LanguageTextField name="en" langPrefix="EN" control={control} />
        </Box>
      </div>
    </ModalWrapper>
  );
};
