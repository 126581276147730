import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";

import { ApiEndpoints } from "@typeDefinitions";
import {
  updateSectionFavorite,
  UpdateSectionFavoriteParams,
} from "@client/updateSectionFavorite";

export function useUpdateSectionFavorite(options?: MutationOptions) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ApiEndpoints.UpdateSectionFavorite],
    mutationFn: (params: UpdateSectionFavoriteParams) =>
      updateSectionFavorite(params),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [ApiEndpoints.SearchSectionsNew],
      });

      options?.onSuccess?.(data, variables);
    },
    onError: error => {
      options?.onError?.(error);
    },
  });
}
