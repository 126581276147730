import { SyntheticEvent, useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  IconButton,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import { uniq } from "lodash";

import CloseIcon from "@assets/icons_new/close.svg?react";
import { OptionIf } from "@components/Filters";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";
import {
  useFetchDietitianAccountQuery,
  useUpdateDietitianAccountMutation,
} from "@hooks/queries/settings";
import { LangDto } from "@client";

interface LanguageModalProps {
  open: boolean;
  onClose: () => void;
}

export const LanguageModal = ({ open, onClose }: LanguageModalProps) => {
  const { t } = useAppTranslation();
  const { account } = useFetchDietitianAccountQuery();
  const { mutate, isLoading } = useUpdateDietitianAccountMutation();

  const langs = [
    { id: "pl", label: t("common.pl") },
    { id: "en", label: t("common.en") },
  ];

  const [selectedLang, setSelectedLang] = useState(
    langs.find(({ id }) => id === account?.language),
  );
  const [contentLang, setContentLang] = useState([selectedLang?.id]);

  const handleCheck =
    (id: string) => (e: SyntheticEvent<Element, Event>, checked: boolean) => {
      const newList = checked
        ? uniq([...contentLang, id])
        : contentLang.filter(currId => currId !== id);
      setContentLang(newList);
    };

  const handleChangeSelected = (
    e: SyntheticEvent<Element, Event>,
    value: OptionIf,
  ) => setSelectedLang(value);
  const handleClose = () => {
    onClose();
    setSelectedLang(langs.find(({ id }) => id === account?.language));
    setContentLang([account?.language]);
  };

  const handleSubmit = () => {
    if (!account) return;
    const { firstName, lastName, phone, language: prevLang } = account;
    const language = (selectedLang?.id ?? prevLang) as LangDto;
    mutate(
      {
        firstName,
        lastName,
        language,
        phone,
      },
      {
        onSuccess: onClose,
      },
    );
  };

  return (
    <Dialog open={open}>
      <Stack
        direction="row"
        justifyContent="space-between"
        padding="16px"
        alignItems="center"
      >
        <Title>{t("settings.language.modal.language")}</Title>

        <CloseButton size="small" onClick={handleClose}>
          <CloseIcon fontSize="24px" />
        </CloseButton>
      </Stack>

      <Stack gap="16px" width="510px" padding="0 16px 24px">
        <OuterLabel label={t("settings.language.modal.system_language")}>
          <Autocomplete
            size="small"
            disableClearable
            fullWidth
            renderInput={p => <TextField {...p} />}
            options={langs}
            value={selectedLang}
            onChange={handleChangeSelected}
          />
        </OuterLabel>

        {/* <div className="opacity-50">
          <OuterLabel label={t("settings.language.modal.shared_content_language")}>
            <Stack gap="8px">
              {langs.map(({ id, label }) => (
                <FormControlLabelStyled
                  key={id}
                  control={<Checkbox />}
                  label={label}
                  checked={contentLang.includes(id)}
                  onChange={handleCheck(id)}
                />
              ))}
            </Stack>
          </OuterLabel>
        </div> */}
      </Stack>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!selectedLang || !contentLang.length}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const Title = styled("h2")`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

const CloseButton = styled(IconButton)`
  padding: unset;
  height: unset;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  margin: unset;
`;
