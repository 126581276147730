import { Title } from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCard.styled";
import { Stack } from "@mui/material";

type SummaryCardTitleProps = {
  title: string;
  icon: JSX.Element;
};
const SummaryCardTitle = ({ title, icon }: SummaryCardTitleProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing="6px">
      {icon}
      <Title>{title}</Title>
    </Stack>
  );
};

export default SummaryCardTitle;
