import {
  Dispatch,
  FC,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import {
  Calendar,
  Carrot,
  ClosedBook,
  Doc,
  ExclamationMarkTriangle,
  Home,
  OpenBook,
  People,
  Survey,
} from "@assets/icons/DrawerMenu";
import { useAppTranslation, useUserRoles } from "@hooks";
import {
  IconButton,
  Stack,
  styled,
  SvgIconProps,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  CALENDAR,
  DASHBOARD,
  DIETS,
  MEALS,
  PATIENTS,
  PRODUCTS,
  PROGRAMS,
  QUESTIONNAIRES,
  RECIPES,
  SECTIONS,
} from "@routes";
import { CollapseStyled, DrawerStyled } from "./DrawerMenu.styled";
import { MenuGroup } from "./MenuGroup";
import TwoStarsIcon from "@assets/icons_new/twoStars.svg?react";
import GraduationCapIcon from "@assets/icons_new/graduationCapSimple.svg?react";
import DoubleChevronIcon from "@assets/icons_new/doubleChevron.svg?react";
import { StudentMenuItem } from "./StudentMenuItem";
import { WhatsNewList } from "@components/WhatsNewList";
import { useWhatsNew } from "@components/WhatsNewList/hooks";
import { SubscriptionStatusItem } from "./SubscriptionStatusItem";

const triggerJira = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.showCollectorDialog && window.showCollectorDialog();
};

export interface MenuGroupIf {
  label: string;
  links: MenuOptionIf[];
}

export interface MenuOptionIf {
  link: string | null;
  label: string;
  icon: (props: SvgIconProps) => JSX.Element;
  onClick?: MouseEventHandler<HTMLDivElement> | (() => void);
  disabled?: boolean;
  wrapper?: FC<any>;
  activePaths?: string[];
  badgeContent?: ReactNode;
}

export const NEWS_UPDATE_KEY = "newsUpdateKey";

interface DrawerMenuProps {
  open: boolean | undefined;
  setOpen: Dispatch<SetStateAction<boolean | undefined>>;
}

export const DrawerMenu = ({ open, setOpen }: DrawerMenuProps) => {
  const { t } = useAppTranslation();
  const [hover, setHover] = useState(false);
  const {
    openWhatsNew,
    onOpenWhatsNew,
    onCloseWhatsNew,
    handleReadAll,
    readNews,
    unreadNews,
  } = useWhatsNew();
  const { isSchool } = useUserRoles();
  const {
    colors: { neutral },
  } = useTheme();

  const handleOpenWhatsNew: MouseEventHandler<HTMLDivElement> = e => {
    !openWhatsNew && e.stopPropagation();
    onOpenWhatsNew();
  };
  const handleCloseWhatsNew = () => {
    if (!openWhatsNew) return;
    handleReadAll();
    onCloseWhatsNew();
  };

  const groupedMenuButtons: MenuGroupIf[] = [
    {
      label: "sidemenu.dashboard",
      links: [
        {
          link: DASHBOARD,
          label: "sidemenu.dashboard",
          icon: Home,
        },
      ],
    },
    {
      label: "sidemenu.office",
      links: [
        {
          link: PATIENTS,
          label: "sidemenu.clients",
          icon: People,
        },
        {
          link: CALENDAR,
          label: "sidemenu.agenda",
          icon: Calendar,
        },
        {
          link: QUESTIONNAIRES,
          label: "sidemenu.surveys",
          icon: Survey,
        },
      ],
    },
    {
      label: "sidemenu.bases",
      links: [
        {
          link: PRODUCTS,
          label: "sidemenu.products",
          icon: Carrot,
        },
        {
          link: RECIPES,
          label: "sidemenu.recipes",
          icon: Doc,
          activePaths: [RECIPES, MEALS],
        },
        {
          link: DIETS,
          label: "sidemenu.diets",
          icon: ClosedBook,
        },
        {
          link: PROGRAMS,
          label: "sidemenu.programs",
          icon: OpenBook,
        },
        {
          link: SECTIONS,
          label: "sidemenu.sections",
          icon: () => <GraduationCapIcon fontSize={24} />,
        },
      ],
    },
    {
      label: "sidemenu.others",
      links: [
        {
          link: null,
          label: "sidemenu.whats_new",
          icon: () => (
            <TwoStarsIcon fontSize={24} color={neutral.medium[800]} />
          ),
          onClick: handleOpenWhatsNew,
          badgeContent: unreadNews.length || undefined,
        },
        {
          link: null,
          label: "sidemenu.report",
          icon: ExclamationMarkTriangle,
          onClick: triggerJira,
        },
      ],
    },
  ];

  return (
    <>
      <DrawerStyled
        variant="permanent"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CollapseStyled orientation="horizontal" in={open} collapsedSize={40}>
          {groupedMenuButtons.map((group, id) => {
            const isDashboard = id === 0;

            return (
              <MenuGroup
                key={id}
                isDashboard={isDashboard}
                open={!!open}
                group={group}
              />
            );
          })}

          {isSchool && <StudentMenuItem open={!!open} />}

          <Stack flex={1} justifyContent="flex-end">
            <SubscriptionStatusItem open={!!open} />
          </Stack>
        </CollapseStyled>
      </DrawerStyled>

      <WhatsNewList
        open={openWhatsNew}
        onClose={handleCloseWhatsNew}
        readNews={readNews}
      />

      <Tooltip
        title={open ? t("common.collapse_menu") : t("common.expand_menu")}
        arrow
      >
        <ExpandArrow
          size="small"
          color="primary"
          onClick={() => setOpen(!open)}
          open={!!open}
          hover={hover}
        >
          <DoubleChevronIcon
            fontSize={28}
            transform={open ? "" : "rotate(180)"}
          />
        </ExpandArrow>
      </Tooltip>
    </>
  );
};

interface ExpandArrowProps {
  open: boolean;
  hover: boolean;
}

const ExpandArrow = styled(IconButton)<ExpandArrowProps>`
  height: unset;
  width: unset;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: 100%;
  background: #fff;
  padding: 0;
  position: absolute;
  top: 66px;
  left: ${({ open }) => (open ? "192px" : "40px")};
  z-index: 600;
  opacity: ${({ hover }) => (hover ? "1" : "0")};
  transition: all 50ms cubic-bezier(0.4, 0, 0.2, 1),
    left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.MuiButtonBase-root.MuiIconButton-root:hover {
    background: ${({ theme }) => theme.palette.primary.medium};
    opacity: 1;
  }
`;
