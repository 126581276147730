import dayjs from "dayjs";
import { SubscriptionTypes } from "@utils/user";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";

export const getSubscriptionInfo = () => {
  const { account } = useFetchDietitianAccountQuery();
  const today = dayjs();
  const hasSubscription = account?.clinic?.subscriptionNew
    ? Math.ceil(
        dayjs
          .tz(account?.clinic?.subscriptionNew.finishedAt, "UTC")
          .local()
          .diff(dayjs.tz(today, "UTC").local(), "days", true),
      )
    : null;
  const isUnlimitedGrowth =
    account?.clinic?.subscriptionNew?.subscription.id ===
    SubscriptionTypes.UNLIMITED_GROWTH;
  const isTrial =
    account?.clinic?.subscriptionNew?.subscription.id ===
    SubscriptionTypes.TRIAL;

  return {
    hasSubscription,
    isTrial,
    isUnlimitedGrowth,
    name: account?.clinic?.subscriptionNew?.subscription.name,
    finishedAt: account?.clinic?.subscriptionNew?.finishedAt,
  };
};
