import { Dialog, styled } from "@mui/material";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useExploreForm } from "./useExploreForm";
import { LabeledCheckbox } from "../LabeledCheckbox";

interface ExploreModalProps {
  open: boolean;
  onClose: () => void;
}
export const ExploreModal = ({ open, onClose }: ExploreModalProps) => {
  const { control } = useExploreForm();

  return (
    <Dialog open={open} onClose={onClose}>
      <Header title="Odkrywaj" onClose={onClose} />

      <Container>
        <Text>
          Wybierz sekcje widoczne w module “Odkrywaj” w aplikacji mobilnej
          klienta.
        </Text>

        <ItemWrapper>
          <LabeledCheckbox
            control={control}
            name="inspire"
            label="Zainspiruj się"
          />
          <Text>
            W tej sekcji dostępne są przepisy pogrupowane w starannie
            przygotowane przez nas kategorie tj. Wegańskie, Na słodko,
            LowFodmap.
          </Text>
        </ItemWrapper>
        <ItemWrapper>
          <LabeledCheckbox
            control={control}
            name="preference"
            label="Na co masz ochotę?"
          />
          <Text>
            W tej sekcji dostępne są przepisy podzielone na kategorie na
            podstawie typu posiłków, tj. śniadanie, II śniadanie, lunch itd.
          </Text>
        </ItemWrapper>
        <ItemWrapper>
          <LabeledCheckbox control={control} name="quiz" label="Quiz" />
          <Text>
            W tej sekcji klient wypełnia krótki quiz, a nasz algorytm proponuje
            3 najlepiej dopasowane przepisy.
          </Text>
        </ItemWrapper>
      </Container>

      <Footer onCancel={onClose} onSubmit={() => console.log("submit")} />
    </Dialog>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px 16px;
  width: 510px;
`;

const Text = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #4d516e;
`;

const ItemWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
