import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteAccountNotification } from "@client";
import { useAppTranslation } from "@hooks";
import { toast } from "react-toastify";
import { MutationOptions } from "./types";
import { fetchUnreadNotificationsQueryKey } from "./useFetchAccountUnreadNotifications";
import {
  accountNotificationQueryKey,
  accountUnreadNotificationQueryKey,
} from "@components/NotificationMenu/components/hooks";

export const useDeleteAccountNotification = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (notificationId: string) => deleteAccountNotification(notificationId),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("notifications.notification_removed"));
        queryClient.invalidateQueries([fetchUnreadNotificationsQueryKey]);
        queryClient.invalidateQueries([accountNotificationQueryKey]);
        queryClient.invalidateQueries([accountUnreadNotificationQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
