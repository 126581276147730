import { ReactNode, useCallback } from "react";

import { Dialog } from "@mui/material";

import { useAppTranslation } from "@hooks";

import {
  ActionButtonsWrapper,
  HeaderStyled,
  ModalWrapperStyled,
  StyledCancelBtn,
  StyledSubmitBtn,
} from "./ModalWrapper.styled";

interface TransitionDuration {
  appear?: number | undefined;
  enter?: number | undefined;
  exit?: number | undefined;
}

export interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  reset?: () => void;
  transitionDuration?: number | TransitionDuration;
  children: ReactNode;
  title?: string;
  onTransitionEnd?: () => void;
  isLoading?: boolean;
  width?: string;
}
/**
 * @deprecated use ModalShell.tsx or ModalWrapperNew/ModalWrapper.tsx instead
 */
export const ModalWrapper = ({
  onClose,
  open,
  transitionDuration,
  children,
  reset,
  onSubmit,
  title,
  isLoading,
  width,
}: ModalWrapperProps) => {
  const { t } = useAppTranslation();
  const closeWithReset = useCallback(() => {
    onClose();
    reset && reset();
  }, [onClose, reset]);

  return (
    <Dialog
      open={open}
      onClose={closeWithReset}
      transitionDuration={transitionDuration}
    >
      <ModalWrapperStyled width={width}>
        {title && <HeaderStyled>{title}</HeaderStyled>}
        {children}
        <ActionButtonsWrapper>
          <StyledCancelBtn
            variant="contained"
            onClick={closeWithReset}
            disabled={isLoading}
          >
            {t("common.cancel")}
          </StyledCancelBtn>
          <StyledSubmitBtn
            variant="contained"
            onClick={onSubmit}
            loading={isLoading}
          >
            {t("common.save")}
          </StyledSubmitBtn>
        </ActionButtonsWrapper>
      </ModalWrapperStyled>
    </Dialog>
  );
};
