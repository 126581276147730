import { memo, useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { useAppTranslation, useProgramSectionForm } from "@hooks";
import { DetailedSection } from "@typeDefinitions";
import { DietitianProgramSectionFormInput } from "@typeDefinitions/types";
import {
  useFetchProgramSectionQuery,
  useUpdateProgramSectionMutation,
} from "@hooks/queries";
import { Modal } from "@components/Modal";
import { SectionForm } from "@components/SectionForm";

export const EditSectionModal = memo(
  ({ hide, sectionId, programId }: SectionModalProps) => {
    const { t } = useAppTranslation();
    const { data } = useFetchProgramSectionQuery(
      programId.toString(),
      sectionId.toString(),
    );

    const parsedSection = useMemo((): DetailedSection | undefined => {
      if (!data) return undefined;
      const { title, titleEn, content, contentEn } = data;

      return {
        id: sectionId,
        categories: [],
        nameEn: titleEn ?? undefined,
        namePl: title,
        contentEn: contentEn ?? undefined,
        contentPl: content ?? "",
        categoryName: "",
      };
    }, [data, sectionId, programId]);

    const form = useProgramSectionForm(parsedSection);

    const { mutate: updateSectionMutation, isLoading } =
      useUpdateProgramSectionMutation();

    const submit = (data: DietitianProgramSectionFormInput) => {
      updateSectionMutation(
        {
          sectionId: sectionId,
          programId: programId,
          payload: {
            title: data.namePl ?? "",
            titleEn: data.nameEn ?? "",
            content: data.contentPl ?? "",
            contentEn: data.contentEn ?? "",
          },
        },
        { onSuccess: hide },
      );
    };

    return (
      <FormProvider {...form}>
        <Modal
          onClose={hide}
          submitButtonDisabled={isLoading}
          onSubmit={() => form.handleSubmit(submit)()}
          title={t("addNutritionalProgram.customSectionModal.title")}
        >
          <div className={"p-7"}>
            <SectionForm />
          </div>
        </Modal>
      </FormProvider>
    );
  },
);

interface SectionModalProps {
  hide: () => any;
  sectionId: number;
  programId: number;
}

interface sectionDetails {
  title: string;
  titleEn: string;
  content: string;
  contentEn: string;
}
