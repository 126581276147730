import { AccordionDetails, Chip, Stack } from "@mui/material";
import { PatientMealsMonitoringResource } from "@client/resources/PatientMealsMonitoringResource";
import {
  CustomAccordion,
  CustomAccordionSummary,
  CustomChip,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Drawer/RecipeMealItem.styled";
import {
  ArrowMonitoring,
  CheckedMonitoring,
  CommentMonitoring,
  CookMonitoring,
  LikeMonitoring,
  SymptomsMonitoring,
  UncheckedMonitoring,
} from "@assets/icons/Monitoring";
import {
  MealItem,
  MealLabel,
  MealName,
} from "@views/dietician/PatientMonitoring2/components/Symptoms/Meals/SymptomsText.styled";
import { Comment } from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import { useAppTranslation } from "@hooks";
import { useTagsUtils } from "@hooks/useTagsUtils";
import { useMemo } from "react";
import { FetchTagCategoriesTag } from "@client";
import { LikedIcon } from "@icons/index";
import LikeCircle from "@views/dietician/PatientMonitoring2/components/LikeCircle";

type SymptomsMealType = {
  meal: PatientMealsMonitoringResource["meals"][0];
};

const SymptomsMeal = ({ meal }: SymptomsMealType) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { getTagById } = useTagsUtils();

  const tagsDictionary = useMemo(
    () =>
      meal.tags
        .map(tag => getTagById(tag.id))
        .filter(t => !!t) as FetchTagCategoriesTag[],
    [getTagById, meal],
  );

  return (
    <CustomAccordion
      defaultExpanded
      disableGutters
      sx={{
        "&:before": {
          content: "unset",
        },
      }}
    >
      <CustomAccordionSummary
        sx={{ padding: 0 }}
        expandIcon={
          <ArrowMonitoring
            style={{
              color: "#727272",
            }}
          />
        }
      >
        <Stack
          useFlexGap
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flex="1"
          marginRight="8px"
        >
          <Stack useFlexGap direction="row" alignItems="center" gap="6px">
            {meal.isEaten ? (
              <CheckedMonitoring fill="#3AB795" size="" />
            ) : (
              <UncheckedMonitoring fill="#F7F7F7" size="" />
            )}
            <MealName>
              {meal.name}, {meal.time}
            </MealName>
          </Stack>
          {meal.comment && (
            <CustomChip
              color="primary"
              label={<CommentMonitoring fill="#5252E5" size="" />}
            />
          )}
        </Stack>
      </CustomAccordionSummary>
      <AccordionDetails>
        <Stack useFlexGap direction="column" gap="16px" marginTop="8px">
          <Stack useFlexGap direction="column" gap="4px">
            {meal.items.map(item => {
              let name = "";
              switch (item.type) {
                case "food_diary_meal_item_recipe":
                  name = item.item.recipe.name;
                  break;
                case "food_diary_meal_item_product":
                  name = item.item.product.name;
                  break;
              }

              return (
                <Stack useFlexGap direction="row" gap="8px" alignItems="center">
                  <MealItem>{name}</MealItem>
                  {item.rating !== null && (
                    <LikeCircle liked={!!item.rating} circle={{ size: 28 }} />
                  )}
                </Stack>
              );
            })}
          </Stack>
          {meal.tags.length > 0 && (
            <Stack useFlexGap direction="column" gap="4px">
              <MealLabel>Objawy</MealLabel>
              <Stack
                display="flex"
                flexDirection="row"
                gap="8px"
                flexWrap="wrap"
              >
                {tagsDictionary.map(tag => (
                  <Chip
                    key={tag.id}
                    color="primary"
                    label={isPolishChosen ? tag?.namePl : tag?.nameEn}
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {meal.comment && (
            <Stack useFlexGap direction="column" gap="4px">
              <MealLabel>Komentarz</MealLabel>
              <Comment>{meal.comment}</Comment>
            </Stack>
          )}
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default SymptomsMeal;
