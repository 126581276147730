import { SvgIconProps } from "@components/SvgIcon";
import { Divider, Tooltip, useTheme } from "@mui/material";
import { isArray } from "lodash";
import {
  DescriptionStyled,
  ListItemIconStyled,
  ListItemTextStyled,
  MenuItemStyled,
  MenuStyled,
} from "./DropDownMenu.styled";
import {
  FunctionComponent,
  MouseEvent,
  MouseEventHandler,
  RefObject,
  SVGProps,
} from "react";

export interface MenuItemIf {
  text: string;
  description?: string;
  onClick: () => void;
  Icon?: (props: SvgIconProps) => JSX.Element;
  IconNew?: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  disabled?: boolean;
  tooltip?: string;
  ref?: RefObject<HTMLLIElement> | null;
}
interface DropDownMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  options: MenuItemIf[] | MenuItemIf[][];
}

export const DropDownMenu = ({
  anchorEl,
  onClose,
  options,
}: DropDownMenuProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const closeMenu = (fn: () => void) => () => {
    onClose();
    fn();
  };

  const renderOptions = () => {
    const elements: JSX.Element[] = [];

    options.forEach((option, optionIdx) => {
      if (isArray(option)) {
        if (optionIdx > 0) {
          elements.push(<Divider key={`divider-${optionIdx}`} />);
        }
        option.forEach((o, oIdx) => {
          elements.push(
            <Tooltip
              title={o.tooltip}
              key={`option-${optionIdx}-${oIdx}`}
              disableHoverListener={!o.tooltip}
            >
              <div>
                <MenuItemStyled
                  onClick={closeMenu(o.onClick)}
                  disabled={o.disabled}
                  ref={o.ref}
                >
                  {o.Icon && (
                    <ListItemIconStyled>
                      <o.Icon fill={neutral.dark[700]} />
                    </ListItemIconStyled>
                  )}
                  {o.IconNew && (
                    <ListItemIconStyled>
                      <o.IconNew color={neutral.dark[700]} fontSize={24} />
                    </ListItemIconStyled>
                  )}
                  <div className="flex flex-col">
                    <ListItemTextStyled>{o.text}</ListItemTextStyled>
                    {o.description && (
                      <DescriptionStyled>{o.description}</DescriptionStyled>
                    )}
                  </div>
                </MenuItemStyled>
              </div>
            </Tooltip>,
          );
        });
      } else {
        elements.push(
          <Tooltip
            title={option.tooltip}
            key={`option-${optionIdx}`}
            disableHoverListener={!option.tooltip}
          >
            <div>
              <MenuItemStyled
                onClick={closeMenu(option.onClick)}
                disabled={option.disabled}
                ref={option.ref}
              >
                {option.Icon && (
                  <ListItemIconStyled>
                    <option.Icon fill={neutral.dark[700]} />
                  </ListItemIconStyled>
                )}
                {option.IconNew && (
                  <ListItemIconStyled>
                    <option.IconNew color={neutral.dark[700]} fontSize={24} />
                  </ListItemIconStyled>
                )}
                <div className="flex flex-col">
                  <ListItemTextStyled>{option.text}</ListItemTextStyled>
                  {option.description && (
                    <DescriptionStyled>{option.description}</DescriptionStyled>
                  )}
                </div>
              </MenuItemStyled>
            </div>
          </Tooltip>,
        );
      }
    });

    return elements;
  };

  return (
    <MenuStyled open={Boolean(anchorEl)} onClose={onClose} anchorEl={anchorEl}>
      {renderOptions()}
    </MenuStyled>
  );
};
