import { memo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { ACTIVATION, LOGIN, VISIT_CANCEL, VISIT_CONFIRM } from "@routes";
import { useAppTranslation } from "@hooks";
import { Link } from "@components/Link";
import { IconButton } from "@components/IconButton";

/** Dashboard layout header */
interface HeaderProps {
  authPage?: boolean;
}

export const Header = memo(({ authPage = false }: HeaderProps) => {
  const { i18n, secondLanguage } = useAppTranslation();
  const { pathname } = useLocation();
  const matchActivation = matchPath(pathname, ACTIVATION);
  const matchVisitConfirm = matchPath(pathname, VISIT_CONFIRM);
  const matchVisitCancel = matchPath(pathname, VISIT_CANCEL);
  const match = matchActivation || matchVisitCancel || matchVisitConfirm;

  return (
    <div
      className="flex sm:h-16 lg:h-20 h-12 lg:py-5 lg:px-4 py-2 px-2 shadow fixed w-screen justify-between items-center bg-white shadow"
      style={{ zIndex: 80 }}
    >
      {authPage ? (
        <a href={match ? undefined : ALLOWEAT_COM}>
          <img
            src="/static/Logo@2x.png"
            srcSet="/static/Logo@2x.png"
            className="h-8 w-40 mr-4"
            alt="Alloweat logo"
          />
        </a>
      ) : (
        <Link to={match ? undefined : LOGIN}>
          <img
            src="/static/Logo@2x.png"
            srcSet="/static/Logo@2x.png"
            className="h-8 w-40 mr-4"
            alt="Alloweat logo"
          />
        </Link>
      )}
      <div className="flex items-center">{/* here go landing links */}</div>
      <div className="flex items-center">
        <IconButton
          size="large"
          className="mr-2"
          onClick={() => i18n.changeLanguage(secondLanguage)}
        >
          <p className="font-roman">{secondLanguage.toUpperCase()}</p>
        </IconButton>
        {/*<Button color="secondary" to={REGISTER}>*/}
        {/*  {t("auth.login.no_account")}*/}
        {/*</Button>*/}
      </div>
    </div>
  );
});

const ALLOWEAT_COM = "https://alloweat.com/";
