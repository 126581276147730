import { useMutation } from "@tanstack/react-query";
import { confirmVisit } from "@client";
import { MutationOptions } from "./types";

export function useConfirmVisitMutation(options?: MutationOptions) {
  return useMutation((url: string) => confirmVisit(url), {
    ...options,
    onSuccess: () => {
      options?.onSuccess && options.onSuccess();
    },
  });
}
