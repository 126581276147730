import { MouseEvent, SyntheticEvent, useEffect, useRef, useState } from "react";

import { ClickAwayListener, IconButton, styled, useTheme } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { ThreeGreyPoints } from "@assets/icons";
import {
  DrawerHeader,
  NotificationsListActionButton,
  NotificationsMenuCard,
  StyledBadge,
  StyledBox,
  StyledPopper,
  StyledTab,
  StyledTabList,
  StyledTabPanel,
} from "./NotificationMenu.styled";
import { TabContext } from "@mui/lab";
import { NotificationHistory } from "./components/NotificationHistory";
import { NotificationsTitleActions } from "./components/NotificationsTitleActions";
import { UnreadNotifications } from "./components/UnreadNotifications";
import { useFetchAccountUnreadNotifications } from "@hooks/queries";
import BellIcon from "@assets/icons_new/bell.svg?react";

export const NotificationMenu = () => {
  const { t } = useAppTranslation();
  const { colors } = useTheme();
  const { unreadNotifications, refetch } = useFetchAccountUnreadNotifications();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const ref = useRef(null);
  const open = Boolean(anchorEl);
  enum NotificationTabs {
    All = "1",
    UNREAD = "2",
  }
  const [tab, setTab] = useState(NotificationTabs.All);

  useEffect(() => {
    const interval = notificationsOpen
      ? undefined
      : setInterval(() => refetch(), 10 * 60 * 1000);

    return () => clearInterval(interval);
  }, [notificationsOpen]);

  const handleClickActions = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event: SyntheticEvent, newValue: NotificationTabs) => {
    setTab(newValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
    refetch();
  };

  useEffect(() => {
    setTab(NotificationTabs.All);
  }, [notificationsOpen]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setNotificationsOpen(false)}>
        <div>
          <IconButtonStyled
            ref={ref}
            size="small"
            onClick={handleOpenNotifications}
          >
            <StyledBadge badgeContent={unreadNotifications} color="error">
              <BellIcon fontSize={24} />
            </StyledBadge>
          </IconButtonStyled>

          <StyledPopper
            open={notificationsOpen}
            anchorEl={ref.current}
            placement="bottom"
            keepMounted
          >
            {notificationsOpen && (
              <NotificationsMenuCard>
                <DrawerHeader>
                  {t("notifications.title")}
                  <NotificationsListActionButton
                    size="small"
                    onClick={handleClickActions}
                  >
                    <ThreeGreyPoints />
                  </NotificationsListActionButton>
                </DrawerHeader>
                <TabContext value={tab}>
                  <StyledBox>
                    <StyledTabList onChange={handleChange}>
                      <StyledTab
                        label={t("notifications.all_notifications")}
                        value={NotificationTabs.All}
                      />
                      <StyledTab
                        label={`${t(
                          "notifications.unread",
                        )} (${unreadNotifications})`}
                        value={NotificationTabs.UNREAD}
                      />
                    </StyledTabList>
                  </StyledBox>
                  <StyledTabPanel value={NotificationTabs.All}>
                    <NotificationHistory />
                  </StyledTabPanel>
                  <StyledTabPanel value={NotificationTabs.UNREAD}>
                    <UnreadNotifications />
                  </StyledTabPanel>
                </TabContext>
              </NotificationsMenuCard>
            )}
          </StyledPopper>
        </div>
      </ClickAwayListener>
      <NotificationsTitleActions
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

const IconButtonStyled = styled(IconButton)`
  padding: unset;
  height: unset;
  width: unset;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
