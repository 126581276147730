import { useMemo, useState } from "react";

import { TabContext } from "@mui/lab";
import { CircularProgress, ClickAwayListener } from "@mui/material";

import { MacrosText } from "@components/MacrosText";
import { SpecialTags } from "@components/TagWithIcon";
import { useAppTranslation } from "@hooks";
import {
  useFetchRecipeQuery,
  usePostToggleFavoriteRecipeMutation,
} from "@hooks/queries";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useTagsNew } from "@hooks/useTagsNew";
import { scaleNutrients } from "@utils";
import { useSpecialTags } from "@views/dietician/DietCreator/components/RecipesTab/MealRow/hooks";

import { DetailsSection } from "../common/DetailsSection";
import { PreviewActionButtons } from "../common/PreviewActionButtons";
import { TabsWrapper } from "../common/TabsWrapper";
import { ProductIf } from "./NutrientsTab/ProductsTable";
import {
  DetailsWrapper,
  ImgStyled,
  LoadingWrapper,
  Wrapper,
} from "./RecipeReview.styled";
import { RecipeTabPanels } from "./RecipeTabPanels";
import { ServingContextProvider } from "./ServingsContext";

interface RecipePreviewProps {
  recipeId: number | null;
  onClose: () => void;
  ActionButtons?: (props: { hasImg: boolean }) => JSX.Element;
  actions?: {
    like: boolean;
    edit: boolean;
    delete: boolean;
    favorite: boolean;
  };
  selectedServings?: number;
}
export const RecipePreview = ({
  recipeId,
  onClose,
  ActionButtons,
  actions,
  selectedServings = 1,
}: RecipePreviewProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const { account } = useFetchDietitianAccountQuery();
  const { recipe, isLoading } = useFetchRecipeQuery(recipeId as number, {
    enabled: !!recipeId,
  });
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteRecipeMutation();
  const [tab, setTab] = useState(RecipeTabs.NUTRITIONAL_VALUES);

  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();

  const getRecipeSpecialTags = useSpecialTags(
    tagCategoryDictionary,
    tagNameDictionary,
    t,
  );
  const tagsWithIcons = getRecipeSpecialTags(
    recipe?.tags.map(t => t.id) ?? [],
    recipe?.servings ?? 1,
    selectedServings ?? 1,
  );

  const scaledNutrients = useMemo(() => {
    const servings = selectedServings ?? 1;
    const totalServings = recipe?.servings ?? 1;

    return scaleNutrients(recipe?.nutrients ?? [], servings, totalServings);
  }, [selectedServings, recipe?.servings, recipe?.nutrients]);

  const img = recipe?.media?.s3Url;

  const productsMapped = useMemo<ProductIf[]>(
    () =>
      recipe?.foodRecipe.map(f => ({
        id: f.food.id,
        grams: f.grams,
        measureId: f.foodMeasureId,
        name:
          (isPolishChosen ? f.food.descriptionPl : f.food.description) ?? "",
        nutrients: f.food.nutrients,
        measures: f.food.measures,
        foodId: f.food.id,
      })) ?? [],
    [recipe, isPolishChosen],
  );

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        value: RecipeTabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.preparation"),
        value: RecipeTabs.PREPARATION,
      },
      {
        label: t("common.tags"),
        value: RecipeTabs.TAGS,
      },
    ],
    [t],
  );

  const ActionButtonsComponent = ActionButtons ? (
    <ActionButtons hasImg={!!img} />
  ) : (
    <PreviewActionButtons
      onToggle={() => mutate(recipeId?.toString() ?? "")}
      isLoading={isLoadingToggle}
      isFavorite={!!recipe?.favorite.includes(account?.id ?? 0)}
      actions={
        actions
          ? {
              canEdit: actions.edit && !!recipe?.actions.canEdit,
              canDelete: actions.delete && !!recipe?.actions.canDelete,
              canVisible: recipe?.actions.canVisible,
            }
          : recipe?.actions
      }
      editLink={`/recipes/${recipeId}/edit`}
      copyLink={`/recipes/${recipeId}/copy`}
      onClose={onClose}
      withImg={!!img}
      showFavorite={actions ? actions.favorite : true}
    />
  );

  if (isLoading)
    return (
      <Wrapper>
        <LoadingWrapper>
          <CircularProgress />;
        </LoadingWrapper>
      </Wrapper>
    );

  return (
    <ServingContextProvider
      totalServings={recipe?.servings ?? 1}
      servings={selectedServings}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Wrapper>
          <TabContext value={tab}>
            {img && ActionButtonsComponent}

            {img && <ImgStyled src={img} />}

            <DetailsWrapper>
              <DetailsSection
                titlePl={recipe?.name ?? ""}
                titleEn={recipe?.nameEn ?? ""}
                actions={<>{!img && ActionButtonsComponent}</>}
                tags={
                  <>
                    <MacrosText nutrients={scaledNutrients} />
                    <SpecialTags tags={tagsWithIcons} />
                  </>
                }
              />

              <TabsWrapper onChange={v => setTab(v)} tabs={tabs} value={tab} />
            </DetailsWrapper>

            <RecipeTabPanels
              nutrients={recipe?.nutrients ?? []}
              products={productsMapped}
              description={recipe?.description ?? ""}
              descriptionEn={recipe?.descriptionEn ?? ""}
              tags={recipe?.tags.map(t => t.id) ?? []}
              comment={recipe?.comment}
              commentEn={recipe?.commentEn}
            />
          </TabContext>
        </Wrapper>
      </ClickAwayListener>
    </ServingContextProvider>
  );
};

export enum RecipeTabs {
  NUTRITIONAL_VALUES = "0",
  PREPARATION = "1",
  TAGS = "2",
}
