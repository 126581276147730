import { Tooltip } from "@mui/material";
import InfoIcon from "@assets/icons_new/iInCircle.svg?react";

interface InfoWithTooltipProps {
  label: string;
}

export const InfoWithTooltip = ({ label }: InfoWithTooltipProps) => {
  return (
    <Tooltip title={label} arrow>
      <div className="w-[16px]">
        <InfoIcon fontSize={16} color="#727272" />
      </div>
    </Tooltip>
  );
};
