import { ReactNode } from "react";

import { useTheme } from "@mui/material";

import { FlameFilled, FlameOutlined } from "@assets/icons";
import { useAppTranslation } from "@hooks";

import {
  KcalText,
  TagIconWrapper,
  Title,
  TitleWrapper,
} from "./DetailsSection.styled";
import { TagWithIcon } from "@components/TagWithIcon";

interface DetailsSectionProps {
  titlePl: string;
  titleEn: string;
  actions?: ReactNode;
  tags?: ReactNode;
  kcal?: number;
}

export const DetailsSection = ({
  titlePl,
  titleEn,
  actions,
  tags,
  kcal,
}: DetailsSectionProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const { isPolishChosen } = useAppTranslation();

  return (
    <div className="grid gap-[8px]">
      <TitleWrapper>
        <Title>{isPolishChosen ? titlePl : titleEn}</Title>
        {actions}
      </TitleWrapper>
      <div className="flex gap-[16px]">
        {kcal && (
          <TagWithIcon
            Icon={FlameFilled}
            label={<KcalText>{kcal} kcal</KcalText>}
          />
        )}
        {tags}
      </div>
    </div>
  );
};
