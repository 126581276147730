import { Button, Stack, styled, Tooltip, useTheme } from "@mui/material";
import dayjs from "dayjs";

import BoxIcon from "@assets/icons_new/box.svg?react";
import DiamondIcon from "@assets/icons_new/diamond.svg?react";
import { useAppTranslation } from "@hooks";
import { getSubscriptionInfo } from "@utils/subscription";
import { useNavigate } from "react-router-dom";
import { PRICING } from "@routes";
interface SubscriptionStatusItemProps {
  open: boolean;
}

export const SubscriptionStatusItem = ({
  open,
}: SubscriptionStatusItemProps) => {
  const { t } = useAppTranslation();
  const { isTrial, isUnlimitedGrowth, name, hasSubscription, finishedAt } =
    getSubscriptionInfo();
  const { palette } = useTheme();
  const navigate = useNavigate();

  const tooltip = (
    <Stack>
      <TooltipTitle>{name}</TooltipTitle>
      <TooltipText>
        {isTrial
          ? `Pozostało: ${hasSubscription} ${t("common.days", {
              count: hasSubscription || 0,
            })}`
          : `Aktywny do: ${dayjs(finishedAt).format("DD.MM.YYYY")}`}
      </TooltipText>
    </Stack>
  );

  if (!isTrial && !isUnlimitedGrowth) return null;

  return (
    <Tooltip disableHoverListener={open} title={tooltip} arrow>
      <Container open={open}>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap="8px" alignItems="center">
              {isTrial && (
                <BoxIcon fontSize={24} color={palette.primary.main} />
              )}
              {isUnlimitedGrowth && (
                <DiamondIcon fontSize={24} color={palette.primary.main} />
              )}

              {open && <Name>{name}</Name>}
            </Stack>

            {isTrial && (
              <UpgradeButton
                onClick={() => navigate(PRICING)}
                sx={{ opacity: open ? "1" : "0" }}
              >
                Upgrade
              </UpgradeButton>
            )}
          </Stack>

          {open && (
            <Stack direction="row">
              <Info>{isTrial ? "Pozostało:" : "Aktywny do:"}&nbsp;</Info>
              <Bold>
                {isTrial
                  ? `${hasSubscription} ${t("common.days", {
                      count: hasSubscription || 0,
                    })}`
                  : dayjs(finishedAt).format("DD.MM.YYYY")}
              </Bold>
            </Stack>
          )}
        </Stack>
      </Container>
    </Tooltip>
  );
};

interface ContainerProps {
  open: boolean;
}

const Container = styled("div")<ContainerProps>`
  width: ${({ open }) => (open ? "100%" : "35px")};
  min-height: 35px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1),
    height 300ms cubic-bezier(0.4, 0, 0.2, 1),
    padding 300ms cubic-bezier(0.4, 0, 0.2, 1),
    background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;
  padding: ${({ open }) => (open ? "6px 12px" : "4.5px")};

  &:hover {
    background: ${({ theme }) => theme.palette.primary.medium};
  }
  overflow: hidden;
`;

const Name = styled("span")`
  font-family: Figtree;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.palette.primary.main};
  white-space: nowrap;
`;

const UpgradeButton = styled(Button)`
  padding: 2px;
  min-height: unset;
  height: unset;
  min-width: unset;
  font-family: Figtree;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: underline;
  text-decoration-style: solid;
`;

const Info = styled("span")`
  font-family: Figtree;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
  white-space: nowrap;
`;
const Bold = styled(Info)`
  font-weight: 600;
`;

const TooltipTitle = styled("span")`
  font-family: Figtree;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.light[100]};
`;
const TooltipText = styled(TooltipTitle)`
  font-weight: 400;
`;
