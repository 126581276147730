import { Button, Stack, Tooltip } from "@mui/material";
import AddPatientProgramDialog from "@views/dietician/PatientPrograms/AddPatientProgramDialog/AddPatientProgramDialog";
import { useAppTranslation } from "@hooks";
import { useState } from "react";
import { PlusIcon } from "@icons/index";
import { useFetchClientQuery } from "@hooks/queries";

type AddPatientProgramButtonProps = {
  patientId: number;
};

const AddPatientProgramButton = ({
  patientId,
}: AddPatientProgramButtonProps) => {
  const { t } = useAppTranslation();
  const [openAddPatientProgramDialog, setOpenAddPatientProgramDialog] =
    useState(false);
  const { client } = useFetchClientQuery(patientId);
  const isActive = !!client?.active;

  return (
    <Stack direction="row" justifyContent="start">
      <Tooltip
        arrow
        title={t("common.access_module_tooltip")}
        disableHoverListener={isActive}
      >
        <div>
          <Button
            sx={{
              "&.MuiButtonBase-root": {
                height: "unset",
                padding: "7px 12px",
                lineHeight: "24px",
              },
              ".MuiButton-startIcon": { marginRight: "4px", fontSize: "24px" },
              ".MuiButton-startIcon>*:nth-of-type(1)": { fontSize: "24px" },
            }}
            startIcon={<PlusIcon />}
            variant="outlined"
            onClick={() => setOpenAddPatientProgramDialog(true)}
            color="primary"
            disabled={!isActive}
          >
            {t("patient.programs.create_modal.button")}
          </Button>
          <AddPatientProgramDialog
            patientId={patientId}
            open={openAddPatientProgramDialog}
            setOpen={setOpenAddPatientProgramDialog}
          />
        </div>
      </Tooltip>
    </Stack>
  );
};

export default AddPatientProgramButton;
