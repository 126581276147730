import { Control, FieldValues, Path, useController } from "react-hook-form";

import { Slider } from "@mui/material";
import { isArray } from "lodash";

import { MediumThumbComponent } from "@components/SliderThumbs";
import { useAppTranslation } from "@hooks";
import PieChartIcon from "@assets/icons_new/pieChart.svg?react";

import {
  Label,
  LabelWrapper,
  Value,
  Wrapper,
} from "./FormServingsSlider.styled";
import { useState } from "react";

interface FormServingsSliderProps<T extends FieldValues> {
  name: Path<T>;
  control?: Control<T>;
  selectedServings?: number;
}
export const FormServingsSlider = <T extends FieldValues>({
  name,
  control,
  selectedServings = 1,
}: FormServingsSliderProps<T>) => {
  const { t } = useAppTranslation();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const handleSliderChange = (value: number) => {
    onChange(value);
  };

  const [hideSlider] = useState(selectedServings > value);
  const MAX_SERVINGS = 20;

  return (
    <Wrapper>
      {hideSlider ? (
        <LabelWrapper>
          <Label>{t("recipe.ingredients_table.multiplied_servings")}:</Label>
          <Value>{selectedServings}</Value>
        </LabelWrapper>
      ) : (
        <LabelWrapper>
          <PieChartIcon fontSize={14} color="#727272" />
          <Label>{t("recipe.ingredients_table.total_servings")}:</Label>
          <Value>{value}</Value>
        </LabelWrapper>
      )}

      {!hideSlider && (
        <Slider
          slots={{ thumb: MediumThumbComponent }}
          value={value}
          max={MAX_SERVINGS}
          min={1}
          onChange={(e, v) => !isArray(v) && handleSliderChange(v)}
        />
      )}
    </Wrapper>
  );
};
