import { InfoWithTooltip } from "@components/InfoWithTooltip";
import { Typography, useTheme } from "@mui/material";
import { Subtitle } from "@views/dietician/ClientProfile/ClientProfile.styled";
import { ReactNode } from "react";

interface ClientProfileCardItemProps {
  title?: string;
  subtitle?: ReactNode;
  extraEl?: ReactNode;
  infoTooltip?: string;
}

export const ClientProfileCardItem = ({
  title,
  subtitle,
  extraEl,
  infoTooltip,
}: ClientProfileCardItemProps) => {
  const { colors } = useTheme();
  return (
    <div className="grid gap-1">
      <div className="flex items-center gap-[4px]">
        <Typography variant="body2" color={colors.neutral.medium[800]}>
          {title}
        </Typography>

        {infoTooltip && <InfoWithTooltip label={infoTooltip} />}
      </div>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {extraEl && <>{extraEl}</>}
    </div>
  );
};
