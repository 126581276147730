import { array, boolean, mixed, number, object, string } from "yup";

interface RecipeBasesAvailableDto {
  alloweat: boolean;
  clinic: boolean;
}

interface AutoFiltersDto {
  allergens: boolean;
  dietType: boolean;
}

interface ExchangersProductsDto {
  glutenFree: boolean;
  lactoseFree: boolean;
  vegan: boolean;
  vegetarian: boolean;
}

export interface BasesDto {
  recipeBasesAvailable: RecipeBasesAvailableDto;
  autoFilters: AutoFiltersDto;
  exchangersProducts: ExchangersProductsDto;
}

export interface FeaturesDto {
  chat: boolean;
  mealRating: boolean;
  measurement: boolean;
  discover: boolean;
  hydration: boolean;
}

export interface DiscoverFeaturesDto {
  inspired: boolean;
  meals: boolean;
  quiz: boolean;
}

export interface MobileAppDto {
  features: FeaturesDto;
  discoverFeatures: DiscoverFeaturesDto;
}

export interface HydrationDto {
  goal: number;
  mainProductId: number;
}

export interface MeasurementDto {
  types: number[];
  requiredImages: boolean;
}

interface ClinicDataDto {
  name: boolean;
  address: boolean;
  email: boolean;
  phone: boolean;
  logo: boolean;
}

interface MenuDataDto {
  weekDay: boolean;
  date: boolean;
  typeDay: boolean;
  mealHour: boolean;
  logo: boolean;
}

export enum Template {
  PHOTO = 1,
  HAND_DRAWN = 2,
  PRINT = 3,
}
export interface PdfDto {
  color: string;
  template: Template;
  mainPhotoId: number | null;
  clinicData: ClinicDataDto;
  menuData: MenuDataDto;
  shoppingListPeriod: number;
}

interface NutrientShowDto {
  kcal: boolean;
  macro: boolean;
  micro: boolean;
  important: boolean;
  targetMacroMeals: boolean;
}

export interface NutrientsDto {
  show: NutrientShowDto;
  importantIds: number[];
}

export interface GlobalClinicPatientSettingsResource {
  nutrients: NutrientsDto;
  bases: BasesDto;
  mobileApp: MobileAppDto;
  hydration: HydrationDto;
  measurement: MeasurementDto;
  pdf: PdfDto;
}

const recipeBasesAvailableSchema = object({
  alloweat: boolean().required(),
  clinic: boolean().required(),
});

const autoFiltersSchema = object({
  allergens: boolean().required(),
  dietType: boolean().required(),
});

const exchangersProductsSchema = object({
  glutenFree: boolean().required(),
  lactoseFree: boolean().required(),
  vegan: boolean().required(),
  vegetarian: boolean().required(),
});

const basesSchema = object({
  recipeBasesAvailable: recipeBasesAvailableSchema.required(),
  autoFilters: autoFiltersSchema.required(),
  exchangersProducts: exchangersProductsSchema.required(),
});

const featuresSchema = object({
  chat: boolean().required(),
  mealRating: boolean().required(),
  measurement: boolean().required(),
  discover: boolean().required(),
  hydration: boolean().required(),
});

const discoverFeaturesSchema = object({
  inspired: boolean().required(),
  meals: boolean().required(),
  quiz: boolean().required(),
});

const mobileAppSchema = object({
  features: featuresSchema.required(),
  discoverFeatures: discoverFeaturesSchema.required(),
});

const hydrationSchema = object({
  goal: number().required(),
  mainProductId: number().required(),
});

const measurementSchema = object({
  types: array().of(number().required()).required(),
  requiredImages: boolean().required(),
});

const clinicDataSchema = object({
  name: boolean().required(),
  address: boolean().required(),
  email: boolean().required(),
  phone: boolean().required(),
  logo: boolean().required(),
});

const menuDataSchema = object({
  weekDay: boolean().required(),
  date: boolean().required(),
  typeDay: boolean().required(),
  mealHour: boolean().required(),
  logo: boolean().required(),
});

const pdfSchema = object({
  color: string().required(),
  template: mixed<1 | 2 | 3>()
    .oneOf([Template.PHOTO, Template.HAND_DRAWN, Template.PRINT])
    .required(),
  mainPhotoId: number().nullable().defined(),
  clinicData: clinicDataSchema.required(),
  menuData: menuDataSchema.required(),
  shoppingListPeriod: number().required(),
});

const nutrientShowSchema = object({
  kcal: boolean().required(),
  macro: boolean().required(),
  micro: boolean().required(),
  important: boolean().required(),
  targetMacroMeals: boolean().required(),
});

const nutrientsSchema = object({
  show: nutrientShowSchema.required(),
  importantIds: array().of(number().required()).required(),
});

export const globalClinicPatientSettingsSchema = object({
  nutrients: nutrientsSchema.required(),
  bases: basesSchema.required(),
  mobileApp: mobileAppSchema.required(),
  hydration: hydrationSchema.required(),
  measurement: measurementSchema.required(),
  pdf: pdfSchema.required(),
});
