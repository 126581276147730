import axios from "axios";

import { GlobalClinicPatientSettingsResource } from "@client/resources/GlobalClinicPatientSettingsResource";
import { APIMethods } from "@typeDefinitions";
import { getCSRFToken } from "@utils/csrf";

export const updateGlobalPatientSettings = async (
  payload: Partial<GlobalClinicPatientSettingsResource>,
) => {
  try {
    return await axios({
      method: APIMethods.PUT,
      url: `${
        import.meta.env.VITE_APP_API_URL
      }/dietitian/clinic/global-patient-settings`,
      data: payload,
    });
  } catch (e: any) {
    if (e?.response?.status === 401) window.location.replace("/auth/login");
    else if (e?.response?.status === 419) {
      await getCSRFToken();
      return await axios({
        method: APIMethods.PUT,
        url: `${
          import.meta.env.VITE_APP_API_URL
        }/dietitian/clinic/global-patient-settings`,
        data: payload,
      });
    }
    throw e;
  }
};
