import { BasesDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useForm } from "react-hook-form";

export const useEditBasesForm = (defaultValues?: EditBasesInput) => {
  const form = useForm<EditBasesInput>({ defaultValues });

  return form;
};

interface EditBasesInput {
  alloweat: boolean;
  dietitian: boolean;
  allergen: boolean;
  diet: boolean;
  glutenFree: boolean;
  lactoseFree: boolean;
  vegetarian: boolean;
  vegan: boolean;
}

export const mapEditBasesForm = (
  data: BasesDto | undefined,
): EditBasesInput | undefined => {
  if (!data) return;
  const {
    autoFilters: { allergens, dietType },
    exchangersProducts: { glutenFree, lactoseFree, vegan, vegetarian },
    recipeBasesAvailable: { alloweat, clinic },
  } = data;
  return {
    allergen: allergens,
    alloweat,
    diet: dietType,
    dietitian: clinic,
    glutenFree,
    lactoseFree,
    vegan,
    vegetarian,
  };
};

export const mapEditBasesRequest = ({
  allergen,
  alloweat,
  diet,
  dietitian,
  glutenFree,
  lactoseFree,
  vegan,
  vegetarian,
}: EditBasesInput): BasesDto => {
  return {
    autoFilters: {
      allergens: allergen,
      dietType: diet,
    },
    exchangersProducts: {
      glutenFree,
      lactoseFree,
      vegan,
      vegetarian,
    },
    recipeBasesAvailable: {
      alloweat,
      clinic: dietitian,
    },
  };
};
