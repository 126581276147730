import { Drawer, Stack } from "@mui/material";
import { useFetchMonitoringMealsDietitian } from "@hooks/queries/client/monitoring";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { RecipePreview } from "@components/PreviewDrawer/Recipe";
import MealItem from "@views/dietician/PatientMonitoring2/components/MealsBox/Dietitian/MealItem";
import { useEffect, useState } from "react";
import MealSkeleton from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsSkeleton";
import MealListItem from "@views/dietician/PatientMonitoring2/components/MealsBox/MealListItem";
import { flatten } from "lodash";
import NoResult from "@views/dietician/PatientMonitoring2/components/MealsBox/NoResult";
import { useAppTranslation } from "@hooks";

type MealsBoxDietitianProps = {
  toggleMeal: {
    setMealsId: (mealsId: number[]) => void;
    toggleExpandedMeal: (mealId: number) => void;
    isExpandedMeal: (mealId: number) => boolean;
  };
};

const MealsBoxDietitian = ({ toggleMeal }: MealsBoxDietitianProps) => {
  const { t } = useAppTranslation();
  const [drawerSelected, setDrawerSelected] = useState<number | null>(null);

  const { type, patientId, selectedDays } = useMonitoring();

  const { data } = useFetchMonitoringMealsDietitian(
    {
      patientId,
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
    },
    { enabled: type.type === "day" },
  );
  useEffect(() => {
    toggleMeal.setMealsId(
      flatten(
        data?.data.map(i =>
          i.meals.filter(m => m.items.length > 0).map(m => m.id),
        ) ?? [],
      ),
    );
  }, [data?.data]);

  if (data === undefined) {
    return <MealSkeleton />;
  }

  return (
    <Stack spacing="12px">
      {(data?.data ?? []).length > 0 ? (
        <ul>
          {data?.data[0].meals.map(meal => (
            <MealListItem key={meal.id}>
              <MealItem
                meal={meal}
                onClickRecipe={setDrawerSelected}
                expanded={toggleMeal.isExpandedMeal(meal.id)}
                toggleExpanded={() => toggleMeal.toggleExpandedMeal(meal.id)}
              />
            </MealListItem>
          ))}
        </ul>
      ) : (
        <NoResult title={t("monitoring2.meals_box.no_results.title")} text="" />
      )}

      <Drawer open={!!drawerSelected} variant="persistent" anchor="right">
        <RecipePreview
          recipeId={drawerSelected}
          onClose={() => setDrawerSelected(null)}
          actions={{
            like: false,
            delete: false,
            edit: false,
            favorite: false,
          }}
        />
      </Drawer>
    </Stack>
  );
};

export default MealsBoxDietitian;
