import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation, useModalState } from "@hooks";
import { Chip, Stack } from "@mui/material";
import { Card, CardHeader } from "./components";
import { LanguageModal } from "../modals";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";

export const Languages = () => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { account } = useFetchDietitianAccountQuery();
  const lang = account?.language;
  return (
    <Card>
      <CardHeader
        title={t("settings.language.languages")}
        editText={t("settings.language.change_language")}
        onEdit={onOpen}
      />

      <OuterLabel label={t("settings.language.system_language")}>
        {lang && <Chip color="primary" label={t(`common.${lang}`)} />}
      </OuterLabel>
      {/* <OuterLabel label={t("settings.language.language_of_shared_content")}>
        <Stack direction="row" flexWrap="wrap" gap="8px">
          <Chip color="primary" label="polski" />
          <Chip color="primary" label="angielski" />
        </Stack>
      </OuterLabel> */}

      <LanguageModal open={open} onClose={onClose} />
    </Card>
  );
};
