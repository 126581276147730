import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { RemoveCircleOutline } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { omit, range } from "lodash";

import { ArrowDown, CheckedIcon, Pacman } from "@assets/icons";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import { MacrosListMui } from "@components/MacrosList";
import { LabeledDetail } from "@components/PreviewDetails";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { SelectOption } from "@components/FormAutocomplete";

import {
  HeaderWrapper,
  PreviewWrapper,
  RecipeDetailsWrapper,
  TitleWrapper,
  CountsWrapper,
  StyledAccordionDetails,
  StyledTypography,
  StyledAccordion,
  StyledServingsAutocomplete,
  StyledDivider,
  AccordionSummaryPlaceholder,
  StyledExpandButton,
} from "./SelectedRecipeEdit.styled";
import { RecipeDto } from "@client/meals";
import { useDeleteCreatorMealRecipeMutation } from "@hooks/queries/meals/useDeleteCreatorMealRecipeMutation";
import { StyledDragHandle } from "@components/RecipeEditForm/components/RecipeTableForm.styled";
import { SelectedRecipeEditForm } from "@views/dietician/MealEdit/components/SelectedRecipeEditForm";
import { usePatchCreatorRecipeServingsMutation } from "@hooks/queries/meals/usePatchCreatorRecipeServingsMutation";
import { MealRecipeImage } from "./MealRecipeImage";
import { LangContext } from "../context/LangContext";
import { MenuMuiWrapper } from "@components/MenuWrapper/MenuMuiWrapper";
import { useCloneRecipeToBaseMutation } from "@hooks/queries";
import { toast } from "react-toastify";
import { calcProportionalNutrients } from "@components/RecipeCompositionSection/nutrientsUtils";

export interface RecipeWrapperIf {
  id: number;
  recipe: RecipeDto;
  servings: number;
}

interface SelectedRecipeEditProps {
  openedRecipes: number[];
  setOpenedRecipes: Dispatch<SetStateAction<number[]>>;
  recipeWrapper: RecipeWrapperIf;
  version?: number;
}

export const SelectedRecipeEdit = memo(
  ({
    openedRecipes,
    setOpenedRecipes,
    recipeWrapper,
  }: SelectedRecipeEditProps) => {
    const { t } = useAppTranslation();
    const { mealId } = useAppParams();
    const { palette, breakpoints } = useTheme();
    const { isPolish } = useContext(LangContext);
    const [modalOpened, onModalOpen, onModalClose] = useModalState();
    const isMobile = useMediaQuery(`${breakpoints.down("small")}`);
    const {
      id,
      recipe: {
        media,
        name,
        nameEn,
        nutrients,
        isComplex,
        foodRecipe,
        servings: totalServings,
      },
      servings,
    } = recipeWrapper;
    const { mutate: deleteRecipe, isLoading } =
      useDeleteCreatorMealRecipeMutation(mealId, id.toString());
    const { mutate: updateRecipe } = usePatchCreatorRecipeServingsMutation(
      mealId,
      id.toString(),
    );
    const { mutate: saveToBase, isLoading: isRecipeLoading } =
      useCloneRecipeToBaseMutation({
        onSuccess: () => toast.success(t("common.saved")),
      });
    const isOpened = openedRecipes.includes(id);
    const handleChange = () => {
      setOpenedRecipes(state =>
        state.includes(id) ? state.filter(el => el !== id) : [...state, id],
      );
    };
    const defaultServings = {
      id: `${servings}`,
      label: `${servings}/${totalServings}`,
    };
    const [selectedServings, setSelectedServing] =
      useState<SelectOption>(defaultServings);

    const optionsArray: SelectOption[] = useMemo(
      () =>
        range(1, totalServings + 1).map(num => ({
          id: `${num}`,
          label: `${num}/${totalServings}`,
        })),
      [totalServings],
    );

    const propotionalNutrients = useMemo(
      () =>
        calcProportionalNutrients(
          nutrients,
          100,
          Number(selectedServings.id) / totalServings,
        ),
      [selectedServings, totalServings, nutrients],
    );
    const onChangeServings = (value: SelectOption) => {
      setSelectedServing(value ?? defaultServings);
      updateRecipe({ servings: Number(value.id) });
    };

    const handleSaveToBase = () => {
      saveToBase(recipeWrapper.recipe.id.toString());
      onModalClose();
    };

    const [_, memoWorkAround] = useState(false);
    const handleDelete = () => {
      memoWorkAround(state => !state);
      deleteRecipe();
    };

    useEffect(() => {
      if (Number(selectedServings.id) <= optionsArray.length)
        setSelectedServing(optionsArray[Number(selectedServings.id) - 1]);
      else setSelectedServing(optionsArray[optionsArray.length - 1]);
    }, [totalServings]);

    return (
      <div>
        <RecipeDetailsWrapper>
          <HeaderWrapper>
            <div className="self-center">
              <StyledDragHandle className="dragHandle" />
            </div>
            <MealRecipeImage
              media={media}
              preview={!isOpened}
              recipeWrapper={recipeWrapper}
            />
            <PreviewWrapper>
              <TitleWrapper>
                <div className="flex justify-between">
                  <Typography variant="h5">
                    {isPolish ? name : nameEn ?? ""}
                  </Typography>
                  <div className="justify-end">
                    <MenuMuiWrapper
                      isLoading={isRecipeLoading}
                      onClose={onModalClose}
                      onOpen={onModalOpen}
                      open={modalOpened}
                    >
                      <MenuItem onClick={handleSaveToBase}>
                        {t("meal.save_to_base")}
                      </MenuItem>
                    </MenuMuiWrapper>
                  </div>
                </div>
                <div className="flex gap-2">
                  <MacrosListMui nutrients={propotionalNutrients} />
                  {!isMobile && (
                    <MicronutrientPreview
                      means={propotionalNutrients}
                      noPadding
                    />
                  )}
                </div>
              </TitleWrapper>
              <div className="flex justify-between">
                <CountsWrapper>
                  {isComplex && <Pacman size="w-5 h-5" />}
                  <StyledServingsAutocomplete
                    disabled={totalServings === 1}
                    disableClearable
                    size="small"
                    disablePortal
                    options={optionsArray}
                    value={selectedServings}
                    onChange={(_, value) =>
                      onChangeServings(value ?? defaultServings)
                    }
                    popupIcon={<CheckedIcon fill={palette.primary.main} />}
                    renderInput={params => (
                      <TextField
                        {...omit(params, "InputLabelProps")}
                        label={t("meal.selected_servings")}
                      />
                    )}
                  />
                  <LabeledDetail
                    label={`${t("recipe.products_count")}:`}
                    value={`${foodRecipe.length}`}
                  />
                </CountsWrapper>
                <div className="grid justify-items-end">
                  <IconButton disabled={isLoading} onClick={handleDelete}>
                    {isLoading ? (
                      <CircularProgress size="1.5rem" />
                    ) : (
                      <RemoveCircleOutline color="primary" />
                    )}
                  </IconButton>
                  {!isMobile && (
                    <StyledExpandButton
                      disableRipple
                      isOpen={isOpened}
                      onClick={handleChange}
                      endIcon={<ArrowDown className="arrow-down" />}
                    >
                      <StyledTypography variant="caption">
                        {!isOpened ? t("common.expand") : t("common.roll_up")}
                      </StyledTypography>
                    </StyledExpandButton>
                  )}
                </div>
              </div>
              {isMobile && (
                <div className="flex justify-between">
                  <div className="-ml-1">
                    <MicronutrientPreview
                      means={propotionalNutrients}
                      noPadding
                    />
                  </div>
                  <StyledExpandButton
                    disableRipple
                    isOpen={isOpened}
                    onClick={handleChange}
                    endIcon={<ArrowDown className="arrow-down" />}
                  >
                    <StyledTypography variant="caption">
                      {!isOpened ? t("common.expand") : t("common.roll_up")}
                    </StyledTypography>
                  </StyledExpandButton>
                </div>
              )}
            </PreviewWrapper>
          </HeaderWrapper>
        </RecipeDetailsWrapper>

        <StyledAccordion
          isMargin={isOpened}
          expanded={isOpened}
          onChange={handleChange}
        >
          <AccordionSummaryPlaceholder expandIcon={<></>} />
          <StyledAccordionDetails>
            {isOpened && (
              <SelectedRecipeEditForm
                recipe={recipeWrapper.recipe}
                wrapperId={recipeWrapper.id}
                selectedServings={Number(selectedServings.id)}
                media={recipeWrapper.recipe.media}
                selectedServingOption={selectedServings}
              />
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
        <StyledDivider />
      </div>
    );
  },
);
