import { useMutation } from "@tanstack/react-query";

import { MutationOptions } from "./types";

import { uploadSectionMedia } from "@client";
import { uploadEducationalMaterial } from "@client/uploadEducationalMaterial";

export function useUploadEducationalMaterialMutation(
  options?: MutationOptions,
) {
  return useMutation(async (input: FormData) =>
    uploadEducationalMaterial(input),
  );
}
