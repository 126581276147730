import { useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { useDebounce } from "react-use";

import { LoadingButton } from "@mui/lab";
import { IconButton, Tooltip } from "@mui/material";

import { TrashNew } from "@assets/icons";
import { PlusRounded } from "@assets/icons/PlusRounded";
import { FullSurveyDto } from "@client/surveys";
import { FormTextField } from "@components/FormTextField";
import { OuterLabel } from "@components/OuterLabel";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import {
  fetchSurveyFullQueryKey,
  useDeleteSurveyGroupMutation,
  useDeleteSurveySubgroupMutation,
  usePostSurveySubgroupMutation,
  useUpdateSurveyGroupMutation,
} from "@hooks/queries/surveys";
import { ApiResponse } from "@typeDefinitions";

import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { ConfirmationModal } from "@components/ConfirmationModal";

import { StyledSubgroupField, SubgroupTitle } from "./GroupCard.styled";
import { QuestionnaireCardWrapper } from "./QuestionnaireCardWrapper";
import { useCardForm } from "./useCardForm";

interface GroupCardProps {
  id: number;
}

export const GroupCard = ({ id }: GroupCardProps) => {
  const { t } = useAppTranslation();
  const { questionnaireId } = useAppParams();
  const [modalOpened, onModalOpen, onModalClose] = useModalState();
  const [subgroupModalOpened, onSubgroupModalOpen, onSubgroupModalClose] =
    useModalState();
  const [subgroupToRemove, setSubgroupToRemove] = useState<
    number | undefined
  >();

  const { setGroupId, setSubgroupId, setSelectedId, getNearGroupId } =
    useContext(TableOfContentsContext);
  const { mutate: deleteGroup } = useDeleteSurveyGroupMutation({
    onSuccess: () => {
      const nextGroupId = getNearGroupId(id);
      setGroupId(nextGroupId);
      setSelectedId(`${nextGroupId}`);
    },
  });
  const { mutate: updateGroup } = useUpdateSurveyGroupMutation();
  const { mutate: createSubgroup, isLoading: isCreatingSubgroup } =
    usePostSurveySubgroupMutation({
      onSuccess: ({ data: { id: subgroupId } }) => {
        setSubgroupId(subgroupId);
        setSelectedId(`${id}-${subgroupId}`);
      },
    });
  const { mutate: deleteSubgroup } = useDeleteSurveySubgroupMutation({
    onSuccess: () => setSubgroupId(null),
  });
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<ApiResponse<FullSurveyDto>>([
    fetchSurveyFullQueryKey,
    questionnaireId,
  ]);

  const groupData = useMemo(
    () => data?.data.groups.find(g => g.id === id),
    [data?.data.groups, id],
  );
  const {
    control,
    watch,
    formState: { isDirty },
    handleSubmit,
  } = useCardForm(groupData);
  const isLastGroup = data?.data.groups.length === 1;
  const isLastSubgroup = groupData?.sections.length === 1;

  const handleDeleteGroup = () => {
    if (!isLastGroup) deleteGroup(id.toString());
  };

  const handleDeleteSubgroup = (subgroupId: number) => {
    if (!isLastSubgroup && !!id) deleteSubgroup(subgroupId.toString());
  };

  const handleOpenSubgroupModal = (id: number) => {
    setSubgroupToRemove(id);
    onSubgroupModalOpen();
  };
  const handleCreateSubgroup = () => {
    createSubgroup({
      id: id.toString(),
      payload: {
        title: t("questionnaires.new_subgroup"),
        description: null,
      },
    });
  };

  useDebounce(
    () => {
      if (isDirty)
        handleSubmit(
          data => updateGroup({ id: id.toString(), payload: data }),
          e => console.log(e),
        )();
    },
    1000,
    [JSON.stringify(watch())],
  );

  return (
    <QuestionnaireCardWrapper
      title={t("questionnaires.group")}
      actions={
        <Tooltip
          title={t("questionnaires.cant_delete_group")}
          disableHoverListener={!isLastGroup}
          arrow
        >
          <div>
            <IconButton
              color="primary"
              onClick={onModalOpen}
              disabled={isLastGroup}
            >
              <TrashNew />
            </IconButton>
          </div>
        </Tooltip>
      }
    >
      <OuterLabel label={t("questionnaires.groups.title")}>
        <FormTextField control={control} name="title" size="small" fullWidth />
      </OuterLabel>
      <OuterLabel label={t("questionnaires.groups.desc")}>
        <FormTextField
          multiline
          control={control}
          name="description"
          size="small"
          placeholder={t("questionnaires.groups.desc_placeholder")}
          fullWidth
        />
      </OuterLabel>
      <OuterLabel label={t("questionnaires.subgroups.subgroups")}>
        {groupData?.sections.map(({ id: subgroupId, title }) => (
          <StyledSubgroupField key={subgroupId}>
            <SubgroupTitle
              onClick={() => {
                setSubgroupId(subgroupId);
                setSelectedId(`${id}-${subgroupId}`);
              }}
            >
              {title}
            </SubgroupTitle>
            <Tooltip
              title={t("questionnaires.cant_delete_subgroup")}
              disableHoverListener={!isLastSubgroup}
              arrow
            >
              <div>
                <IconButton
                  disabled={isLastSubgroup}
                  color="primary"
                  size="medium"
                  onClick={() => handleOpenSubgroupModal(subgroupId)}
                >
                  <TrashNew />
                </IconButton>
                <ConfirmationModal
                  open={subgroupModalOpened && subgroupToRemove === subgroupId}
                  onCancel={onSubgroupModalClose}
                  onConfirm={() => handleDeleteSubgroup(subgroupId)}
                  confirmText={t("questionnaires.delete_modal_subgroup.button")}
                  description={t("questionnaires.delete_modal_subgroup.desc", {
                    variable: title,
                  })}
                  title={t("questionnaires.delete_modal_subgroup.title")}
                />
              </div>
            </Tooltip>
          </StyledSubgroupField>
        ))}
        <LoadingButton
          startIcon={<PlusRounded size="w-3 h-3" />}
          onClick={handleCreateSubgroup}
          loading={isCreatingSubgroup}
        >
          {t("questionnaires.add_subgroup")}
        </LoadingButton>
      </OuterLabel>
      <ConfirmationModal
        open={modalOpened}
        onCancel={onModalClose}
        onConfirm={handleDeleteGroup}
        confirmText={t("questionnaires.delete_modal_group.button")}
        description={t("questionnaires.delete_modal_group.desc", {
          variable: watch("title"),
        })}
        title={t("questionnaires.delete_modal_group.title")}
      />
    </QuestionnaireCardWrapper>
  );
};
