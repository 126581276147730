import { PaperPlaneIcon } from "@assets/icons_new";
import { useAppParams, useAppTranslation } from "@hooks";
import { usePostClientEmailVerification } from "@hooks/queries";
import { LoadingButton } from "@mui/lab";
import { styled, Tooltip } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

interface SendEmailButtonProps {
  isAppActive?: boolean;
}

export const SendEmailButton = ({ isAppActive }: SendEmailButtonProps) => {
  const [allowSend, setAllowSend] = useState(true);
  const { patientId } = useAppParams();
  const { mutate, isLoading } = usePostClientEmailVerification(patientId);
  const handleSend = () =>
    mutate(undefined, {
      onSuccess: () => {
        setAllowSend(false);
        toast.success(t("client_profile.info.link_sent"));
      },
    });
  const { t } = useAppTranslation();

  return (
    <Tooltip
      title={t("client_profile.info.inactive_app")}
      disableHoverListener={isAppActive}
    >
      <div>
        <ButtonStyled
          onClick={handleSend}
          loading={isLoading}
          disabled={!allowSend || !isAppActive}
        >
          <PaperPlaneIcon fontSize={14} />
          {allowSend
            ? t("client_profile.info.resend_link")
            : t("client_profile.info.link_sent")}
        </ButtonStyled>
      </div>
    </Tooltip>
  );
};

const ButtonStyled = styled(LoadingButton)`
  display: flex;
  align-self: flex-start;
  gap: 4px;
`;
