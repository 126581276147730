import { ProgramRowType } from "@views/dietician/PatientPrograms/Grid/RowType";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
  Tooltip,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import ExportPdfModal from "@Pdf/components/ExportPdfModal/ExportPdfModal";
import { SaveToBaseModal } from "@views/dietician/Program/components/SaveToBaseModal";
import DeleteAction from "@views/dietician/PatientPrograms/Grid/Actions/DeleteAction";
import SharedAction from "@views/dietician/PatientPrograms/Grid/Actions/SharedAction";
import { ProgramCopyModal } from "@views/dietician/PatientProgramsLegacy/components/ProgramCopyModal";
import {
  CloudAdd,
  DownloadPdf,
  DuplicateFiles,
  Eye,
  Pencil,
  ThreeVerticalPoints,
} from "@assets/icons";
import { CustomMenuItem } from "@views/dietician/PatientPrograms/PatientProgramsGrid.styled";
import Divider from "@components/Alloweat/Divider";
import ActiveAction from "@views/dietician/PatientPrograms/Grid/Actions/ActiveAction";
import { useAppTranslation } from "@hooks";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";
import { useFetchClientQuery } from "@hooks/queries";

const ActionsColumn = ({
  program,
  patientId,
}: ProgramRowType & { patientId: number }) => {
  const { t } = useAppTranslation();
  const { client } = useFetchClientQuery(patientId);
  const isClientActive = !!client?.active;
  const newMobileAppFeatureEnabled = useNewMobileAppFeatureEnabled();
  const [openExportPdfModal, setOpenExportPdfModal] = useState(false);
  const [openSaveToBaseModal, setOpenSaveToBaseModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <CustomIconButton onClick={handleClick}>
        <ThreeVerticalPoints />
      </CustomIconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Tooltip
          arrow
          title={t("common.access_module_tooltip")}
          disableHoverListener={isClientActive}
        >
          <div>
            <CustomMenuItem
              component={Link}
              to={`/patients/${patientId}/nutritional-programs/${program.id}`}
              disabled={!isClientActive}
            >
              <ListItemIcon>
                <Eye fill="#727272" style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText>
                {t("patient.programs_grid.row.actions.open")}
              </ListItemText>
            </CustomMenuItem>
          </div>
        </Tooltip>

        <Tooltip
          arrow
          title={t("common.access_module_tooltip")}
          disableHoverListener={isClientActive}
        >
          <div>
            <CustomMenuItem
              onClick={() => {
                setOpenExportPdfModal(true);
                handleClose();
              }}
              disabled={!isClientActive}
            >
              <ListItemIcon>
                <DownloadPdf size="" fill="#727272" style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText>
                {t("patient.programs_grid.row.actions.save_pdf")}
              </ListItemText>
            </CustomMenuItem>
          </div>
        </Tooltip>

        {!newMobileAppFeatureEnabled && (
          <ActiveAction
            program={program}
            onSuccess={handleClose}
            patientId={patientId}
            disabled={!isClientActive}
          />
        )}
        {newMobileAppFeatureEnabled && (
          <SharedAction
            program={program}
            onSuccess={handleClose}
            patientId={patientId}
            disabled={!isClientActive}
          />
        )}
        <CustomMenuItem
          onClick={() => {
            setOpenSaveToBaseModal(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <CloudAdd sx={{ fontSize: "24px", color: "#fff" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.programs_grid.row.actions.add_to_db")}
          </ListItemText>
        </CustomMenuItem>
        <Divider />

        <Tooltip
          arrow
          title={t("common.access_module_tooltip")}
          disableHoverListener={isClientActive}
        >
          <div>
            <CustomMenuItem
              component={Link}
              to={`/patients/${patientId}/nutritional-programs/${program.id}/edit`}
              disabled={!isClientActive}
            >
              <ListItemIcon>
                <Pencil size="" fill="#727272" style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText>
                {t("patient.programs_grid.row.actions.edit")}
              </ListItemText>
            </CustomMenuItem>
          </div>
        </Tooltip>

        <CustomMenuItem
          onClick={() => {
            setOpenCopyModal(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <DuplicateFiles fill="#727272" style={{ width: "24px" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.programs_grid.row.actions.copy")}
          </ListItemText>
        </CustomMenuItem>
        <DeleteAction
          onSuccess={handleClose}
          patientId={patientId}
          programId={program.id}
          disabled={!isClientActive}
        />
      </Menu>
      {openExportPdfModal && (
        <ExportPdfModal
          open={openExportPdfModal}
          patientId={patientId}
          programId={program.id}
          onClose={() => setOpenExportPdfModal(false)}
        />
      )}
      {openSaveToBaseModal && (
        <SaveToBaseModal
          open={openSaveToBaseModal}
          onClose={() => setOpenSaveToBaseModal(false)}
          defaultName={program.name ?? ""}
          programId={program.id}
        />
      )}
      {openCopyModal && (
        <ProgramCopyModal
          open={openCopyModal}
          onClose={() => setOpenCopyModal(false)}
          selectedProgramId={program.id}
          patientId={patientId}
        />
      )}
    </div>
  );
};

const CustomIconButton = styled(IconButton)`
  &:hover {
    background: #fbfafc;
    border: none;
    outline: none;
  }
  &.MuiButtonBase-root.MuiIconButton-root {
    :hover {
      background: #fbfafc;
    }
  }
`;

export default ActionsColumn;
