import { ReactNode } from "react";
import ReactDatePicker from "react-datepicker";

import dayjs from "dayjs";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { IconButton, capitalize } from "@mui/material";

import { LangDto } from "@client";
import { useAppTranslation } from "@hooks";
import {
  CalendarMonthLabel,
  DateRangePickerStyled,
} from "./DateRangePicker.styled";
import { CalendarContainer } from "./CalenderContainer";
import { CalendarButtonProps } from "./CalendarCustomButtons";

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onRangeChange: (dates: [Date | null, Date | null]) => void;
  inline?: boolean;
  maxDate?: Date | null;
  minDate?: Date | null;
  customInput?: ReactNode;
  customButtons?: CalendarButtonProps[];
  portalId?: string;
  isFooterDisplayed?: boolean;
  showPreviousMonths?: boolean;
}

export const DateRangePicker = ({
  startDate,
  endDate,
  onRangeChange,
  inline = false,
  maxDate,
  minDate,
  customInput,
  customButtons = [],
  portalId,
  isFooterDisplayed = true,
  showPreviousMonths,
}: DateRangePickerProps) => {
  const { isPolishChosen } = useAppTranslation();

  return (
    <DateRangePickerStyled>
      <ReactDatePicker
        inline={inline}
        maxDate={maxDate}
        minDate={minDate}
        onChange={onRangeChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        portalId={portalId}
        monthsShown={2}
        showPreviousMonths={showPreviousMonths}
        popperClassName="w-[unset]"
        dateFormat={isPolishChosen ? "dd.MM.yyyy" : "MM/dd/yyyy"}
        calendarContainer={({ children }) => (
          <CalendarContainer
            children={children}
            buttons={customButtons}
            bottomBorder={inline}
            isFooterDisplayed={isFooterDisplayed}
          />
        )}
        renderCustomHeader={props => {
          const {
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            decreaseMonth,
            increaseMonth,
            monthDate,
            customHeaderCount,
          } = props;

          return (
            <div className="flex items-center">
              {!customHeaderCount && (
                <IconButton
                  onClick={decreaseMonth}
                  color="primary"
                  disabled={prevMonthButtonDisabled}
                >
                  <ArrowBackIosRoundedIcon />
                </IconButton>
              )}
              <CalendarMonthLabel variant="subtitle2">
                {capitalize(dayjs(monthDate).format("MMMM YYYY"))}
              </CalendarMonthLabel>
              {!!customHeaderCount && (
                <IconButton
                  onClick={increaseMonth}
                  color="primary"
                  disabled={nextMonthButtonDisabled}
                >
                  <ArrowForwardIosRoundedIcon />
                </IconButton>
              )}
            </div>
          );
        }}
        locale={isPolishChosen ? LangDto.PL : LangDto.EN}
        customInput={customInput}
      />
    </DateRangePickerStyled>
  );
};

export enum Weeks {
  ONE = 7,
  TWO = 14,
  THREE = 21,
  FOUR = 28,
}

export const WEEKS_LIST = [Weeks.ONE, Weeks.TWO, Weeks.THREE, Weeks.FOUR];
