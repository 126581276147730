import { useEffect, useMemo, useState } from "react";

import { TabContext } from "@mui/lab";

import { mapUserChips } from "@components/ExpandableChips";
import { ServingContextProvider } from "@components/PreviewDrawer/Recipe/ServingsContext";
import { TabSwitch } from "@components/TabSwitch";
import { SpecialTags } from "@components/TagWithIcon";
import { useAppTranslation } from "@hooks";
import { useDietUsedRecipes } from "@hooks/useDietUsedRecipes";
import { useTagsNew } from "@hooks/useTagsNew";
import { ClientContextIf, Nutrient } from "@typeDefinitions";
import { scaleNutrients } from "@utils";

import { RecipeItem, useItemsContext } from "../ItemsContext";
import { useSpecialTags } from "../MealRow/hooks";
import { ServingsSlider } from "../ServingsSlider/ServingsSlider";
import { AccordionWrapper } from "./AccordionWrapper";
import { RecipeCollectionContent } from "./RecipeCollectionContent";
import { RecipeContent } from "./RecipeContent";

export interface RecipeAccordionProps {
  id: number;
  macros: Nutrient[];
  tags: number[];
  imgUrl: string | undefined;
  title: string;
  titleEn: string | null;
  totalServings: number;
  commitedSelectedServings?: number;
  clientContext?: ClientContextIf;
  mealId: number;
  collectionId?: number;
  itemId?: number;
}

export const RecipeAccordion = ({
  id,
  macros,
  tags,
  imgUrl,
  title,
  titleEn,
  totalServings,
  commitedSelectedServings,
  clientContext,
  mealId,
  collectionId,
  itemId,
}: RecipeAccordionProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const [tab, setTab] = useState(Tabs.NUTRIENTS);
  const tabs = useMemo(
    () => [
      { id: Tabs.NUTRIENTS, label: t("common.nutrients_values") },
      { id: Tabs.PREPARATION, label: t("common.preparation") },
      { id: Tabs.TAGS, label: t("common.tags") },
    ],
    [t],
  );

  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const { toggleItems, updateItem, isChecked, singleSelect, itemsCount } =
    useItemsContext();
  const { isUsedRecipe } = useDietUsedRecipes();
  const [selectedServings, setSelectedServings] = useState(
    commitedSelectedServings ?? totalServings,
  );
  const [expanded, setExpanded] = useState<boolean | undefined>(false);
  const getRecipeSpecialTags = useSpecialTags(
    tagCategoryDictionary,
    tagNameDictionary,
    t,
  );

  const tagsWithIcons = getRecipeSpecialTags(
    tags,
    totalServings,
    selectedServings,
  );

  const item = useMemo<RecipeItem>(
    () => ({
      type: "recipe",
      id,
      servings: selectedServings,
      mealId,
    }),
    [selectedServings, id, mealId],
  );
  const shouldHideCheckbox = singleSelect && !!itemsCount && !isChecked(item);

  const userTags = useMemo(
    () =>
      mapUserChips({
        already_used: isUsedRecipe(isPolishChosen ? title : titleEn ?? title),
        disliked: clientContext?.hasUnliked,
        liked: clientContext?.hasLiked,
        allergen: clientContext?.hasAllergens,
      }),
    [title, titleEn, isPolishChosen, clientContext],
  );

  const toggleCheck = () => toggleItems([item]);

  const updateServings = (servings: number) => {
    updateItem({ ...item, servings });
    setSelectedServings(servings);
  };

  const name = useMemo(
    () => (isPolishChosen ? title : titleEn ?? title),
    [isPolishChosen, title, titleEn],
  );
  const checked = useMemo(() => isChecked(item), [item, isChecked]);
  const macrosScaled = useMemo(
    () => scaleNutrients(macros, selectedServings, totalServings),
    [macros, scaleNutrients, selectedServings, totalServings],
  );

  useEffect(() => {
    if (checked && selectedServings !== DEFAULT_SERVINGS && expanded)
      updateServings(selectedServings);
    else if (commitedSelectedServings === undefined && (expanded || checked))
      updateServings(DEFAULT_SERVINGS);
    else if (!checked && !expanded)
      setSelectedServings(commitedSelectedServings ?? totalServings);
  }, [checked, expanded, totalServings, commitedSelectedServings]);

  return (
    <AccordionWrapper
      withImage
      checked={isChecked(item)}
      extras={<SpecialTags tags={tagsWithIcons} />}
      nutrients={macrosScaled}
      onCheck={toggleCheck}
      tags={tags}
      title={name}
      image={imgUrl}
      userTags={userTags}
      hiddenCheckbox={shouldHideCheckbox}
      servingsComponent={
        (checked || expanded) && (
          <ServingsSlider
            onSliderCommit={updateServings}
            onSliderChange={value => setSelectedServings(value)}
            selectedServings={selectedServings}
            totalServings={totalServings}
          />
        )
      }
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <div className="pl-[8px]">
        <TabSwitch tabs={tabs} value={tab} onChange={setTab} />
      </div>
      <TabContext value={tab}>
        <ServingContextProvider
          totalServings={totalServings}
          servings={selectedServings}
        >
          {collectionId && itemId ? (
            <RecipeCollectionContent
              id={id}
              collectionId={collectionId}
              itemId={itemId}
            />
          ) : (
            <RecipeContent id={id} />
          )}
        </ServingContextProvider>
      </TabContext>
    </AccordionWrapper>
  );
};

enum Tabs {
  NUTRIENTS = "0",
  PREPARATION = "1",
  TAGS = "2",
}

const DEFAULT_SERVINGS = 1;
