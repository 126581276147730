import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchSectionQueryKey } from "./useFetchSectionQuery";
import { searchSectionsQueryKey } from "./useSearchSectionsQuery";
import { programCategoriesQuery } from "./useFetchProgramSectionsCategoryQuery";
import { createSection, SectionRequestDto } from "@client";
import { ApiEndpoints } from "@typeDefinitions";

export function useCreateSectionMutation(options?: MutationOptions) {
  const queryClient = useQueryClient();

  return useMutation(async (input: SectionRequestDto) => createSection(input), {
    onSuccess: () => {
      //TODO: Improve this logic to trigger on modal exit
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [ApiEndpoints.SearchSectionsNew],
          exact: false,
        });
      }, 500);

      queryClient.invalidateQueries({
        queryKey: [fetchSectionQueryKey],
        exact: false,
      });

      queryClient.invalidateQueries({
        queryKey: [searchSectionsQueryKey],
        exact: false,
      });

      queryClient.invalidateQueries({
        queryKey: [programCategoriesQuery],
        exact: false,
      });

      options?.onSuccess && options.onSuccess();
    },
  });
}
