import { useState } from "react";
import { Link } from "react-router-dom";

import { Button, Menu, MenuItem } from "@mui/material";
import type { FC } from "react";
import { Plus } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { MEAL_NEW, NEW_RECIPE } from "@routes";
import { MenuItemStyled, MenuStyled } from "./AddButton.styled";

interface AddButtonProps {
  className?: string;
}

export const AddButton: FC<AddButtonProps> = ({ className }) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <Button
        variant="contained"
        className={className ? className : ""}
        onClick={e => setAnchorEl(e.currentTarget)}
        startIcon={<Plus size="w-[12px] h-[12px]" className="stroke-current" />}
      >
        {t("common.add")}
      </Button>
      <MenuStyled
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItemStyled component={Link} to={NEW_RECIPE}>
          {t("recipes.add_new_recipe")}
        </MenuItemStyled>
        <MenuItemStyled component={Link} to={MEAL_NEW}>
          {t("collections.add_collection")}
        </MenuItemStyled>
      </MenuStyled>
    </>
  );
};
