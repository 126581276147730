import { OuterLabel } from "@components/OuterLabel";
import { Stack } from "@mui/material";
import { CheckboxLabeled } from "./CheckboxLabeled";
import { EditPdfFormProps } from "./useEditPdfForm";
import { Control } from "react-hook-form";
import { ShoppingListAutocomplete } from "./ShoppingListAutocomplete";
import { useAppTranslation } from "@hooks";

interface DetailsSectionProps {
  control: Control<EditPdfFormProps>;
  enterprise: boolean;
}
export const DetailsSection = ({
  control,
  enterprise,
}: DetailsSectionProps) => {
  const { t } = useAppTranslation();

  return (
    <Stack gap="32px" paddingTop="16px">
      <OuterLabel
        label={t("cooperation_settings.modal.pdf.visible_clinic_data")}
        className="w-full"
      >
        <Stack gap="8px" width="100%">
          {enterprise && (
            <CheckboxLabeled
              control={control}
              name="clinicLogo"
              label={t("cooperation_settings.modal.pdf.logo")}
            />
          )}
          <CheckboxLabeled
            control={control}
            name="name"
            label={t("cooperation_settings.modal.pdf.name")}
          />
          <CheckboxLabeled
            control={control}
            name="address"
            label={t("cooperation_settings.modal.pdf.address")}
          />
          <CheckboxLabeled
            control={control}
            name="email"
            label={t("cooperation_settings.modal.pdf.email_address")}
          />
          <CheckboxLabeled
            control={control}
            name="phone"
            label={t("cooperation_settings.modal.pdf.phone_number")}
          />
        </Stack>
      </OuterLabel>

      <OuterLabel
        label={t("cooperation_settings.modal.pdf.additional_schedule_info")}
        className="w-full"
      >
        <Stack gap="8px" width="100%">
          <CheckboxLabeled
            control={control}
            name="weekDay"
            label={t("cooperation_settings.modal.pdf.weekday")}
          />
          <CheckboxLabeled
            control={control}
            name="date"
            label={t("cooperation_settings.modal.pdf.date")}
          />
          <CheckboxLabeled
            control={control}
            name="typeDay"
            label={t("cooperation_settings.modal.pdf.day_type")}
          />
          <CheckboxLabeled
            control={control}
            name="mealHour"
            label={t("cooperation_settings.modal.pdf.meal_hour")}
          />
          {enterprise && (
            <CheckboxLabeled
              control={control}
              name="menuLogo"
              label={t("cooperation_settings.modal.pdf.alloweat_logo")}
            />
          )}
        </Stack>
      </OuterLabel>

      <OuterLabel
        label={t("cooperation_settings.modal.pdf.shopping_list_frequency")}
        className="w-full"
      >
        <ShoppingListAutocomplete control={control} />
      </OuterLabel>
    </Stack>
  );
};
