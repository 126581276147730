import { Dialog, Radio, RadioGroup, styled } from "@mui/material";
import { Header } from "./Header";
import { Footer } from "./Footer";
import {
  mapEditBasesForm,
  mapEditBasesRequest,
  useEditBasesForm,
} from "./useEditBasesForm";
import { OuterLabel } from "@components/OuterLabel";
import { FormControlLabelStyled, LabeledCheckbox } from "./LabeledCheckbox";
import { useMemo, useState } from "react";
import { useUpdateGlobalPatientSettingsMutation } from "@hooks/queries/clinic";
import { BasesDto } from "@client/resources/GlobalClinicPatientSettingsResource";
import { useAppTranslation } from "@hooks";

interface EditBasesModalProps {
  open: boolean;
  onClose: () => void;
  bases: BasesDto;
}
export const EditBasesModal = ({
  open,
  onClose,
  bases,
}: EditBasesModalProps) => {
  const { t } = useAppTranslation();
  const defaultValues = useMemo(() => mapEditBasesForm(bases), [bases]);
  const { control, watch, handleSubmit, reset, setValue } =
    useEditBasesForm(defaultValues);
  const exchangersWatch = [
    watch("glutenFree"),
    watch("lactoseFree"),
    watch("vegetarian"),
    watch("vegan"),
  ];
  const someExchangersSelected = !!exchangersWatch.filter(Boolean).length;
  const [radioValue, setRadioValue] = useState(
    someExchangersSelected ? Exchangers.SPECIAL : Exchangers.REGULAR,
  );
  const { mutate, isLoading } = useUpdateGlobalPatientSettingsMutation();
  const disableButton =
    radioValue === Exchangers.SPECIAL && exchangersWatch.every(value => !value);

  const handleSubmitForm = handleSubmit(
    values =>
      mutate({ bases: mapEditBasesRequest(values) }, { onSuccess: onClose }),
    e => console.log(e),
  );

  const handleSelectRegular = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setRadioValue(value as Exchangers);

    if (value === Exchangers.REGULAR) {
      setValue("glutenFree", false);
      setValue("lactoseFree", false);
      setValue("vegetarian", false);
      setValue("vegan", false);
    }
  };

  const handleCloseAndReset = () => {
    onClose();
    reset();
    setRadioValue(
      someExchangersSelected ? Exchangers.SPECIAL : Exchangers.REGULAR,
    );
  };

  return (
    <Dialog open={open}>
      <Header
        title={t(
          "cooperation_settings.modal.databases.recipe_and_product_database",
        )}
        onClose={handleCloseAndReset}
      />

      <Container>
        <OuterLabel
          label={t("cooperation_settings.modal.databases.available_databases")}
        >
          <LabeledCheckbox
            control={control}
            name="alloweat"
            label={t("cooperation_settings.modal.databases.alloweat_recipes")}
          />
          <LabeledCheckbox
            control={control}
            name="dietitian"
            label={t("cooperation_settings.modal.databases.dietitian_recipes")}
          />
        </OuterLabel>

        <OuterLabel
          label={t("cooperation_settings.modal.databases.product_exchangers")}
        >
          <RadioGroup value={radioValue} onChange={handleSelectRegular}>
            <FormControlLabelStyled
              control={<Radio />}
              label={t("cooperation_settings.modal.databases.traditional")}
              value={Exchangers.REGULAR}
            />
            <FormControlLabelStyled
              control={<Radio />}
              label={t("cooperation_settings.modal.databases.specialized")}
              value={Exchangers.SPECIAL}
            />
          </RadioGroup>

          {radioValue === Exchangers.SPECIAL && (
            <ExchangersWrapper>
              <LabeledCheckbox
                control={control}
                name="glutenFree"
                label={t("cooperation_settings.modal.databases.gluten_free")}
              />
              <LabeledCheckbox
                control={control}
                name="lactoseFree"
                label={t("cooperation_settings.modal.databases.lactose_free")}
              />
              <LabeledCheckbox
                control={control}
                name="vegetarian"
                label={t("cooperation_settings.modal.databases.vegetarian")}
              />
              <LabeledCheckbox
                control={control}
                name="vegan"
                label={t("cooperation_settings.modal.databases.vegan")}
              />
            </ExchangersWrapper>
          )}
        </OuterLabel>
      </Container>

      <Footer
        onCancel={handleCloseAndReset}
        onSubmit={handleSubmitForm}
        disabled={disableButton}
        loading={isLoading}
      />
    </Dialog>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

const ExchangersWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 24px;
`;
enum Exchangers {
  REGULAR = "1",
  SPECIAL = "2",
}
