import { useAppTranslation } from "@hooks";
import { Button, Stack, styled, Tooltip } from "@mui/material";
import PenIcon from "@assets/icons_new/edit.svg?react";
import InfoIcon from "@assets/icons_new/iInCircle.svg?react";

interface CardHeaderProps {
  title: string;
  editText?: string;
  tooltipText?: string;
  onEdit: () => void;
}
export const CardHeader = (props: CardHeaderProps) => {
  const { t } = useAppTranslation();
  const { title, editText = t("common.edit"), tooltipText, onEdit } = props;

  return (
    <Wrapper>
      <Stack direction="row" gap="8px" alignItems="center">
        <Title>{title}</Title>
        {tooltipText && (
          <Tooltip title={tooltipText} arrow>
            <div>
              <InfoIcon fontSize={16} color="#727272" />
            </div>
          </Tooltip>
        )}
      </Stack>

      <EditButton onClick={onEdit}>
        <PenIcon fontSize={24} />
        {editText}
      </EditButton>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const Title = styled("h2")`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

const EditButton = styled(Button)`
  display: flex;
  gap: 4px;
`;
