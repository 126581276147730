import { useAppTranslation, useClientParams } from "@hooks";
import {
  SectionHeadEditLayout,
  SectionHeadLayout,
  SectionItemChips,
  SectionItemLabel,
  SectionItemLayout,
  SectionLayout,
  SectionTitle,
  TabHeadActionEditLabel,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useMemo, useState } from "react";
import useFetchPatientBodyMeasurementsSettings from "@hooks/queries/client/bodyMeasurement/useFetchPatientBodyMeasurementsSettings";
import { Chip, styled } from "@mui/material";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { BodyMeasurementResource } from "@client/dictionaries";
import SettingsSectionDialog from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/SettingsSectionDialog";
import { EditIcon } from "@icons/index";

const SettingsSection = () => {
  const { t, currentLanguage } = useAppTranslation();
  const [open, setOpen] = useState(false);
  const id = useClientParams();
  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();
  const { data: bodyMeasurementsSettings } =
    useFetchPatientBodyMeasurementsSettings(
      {
        patientId: id,
      },
      { staleTime: Infinity },
    );

  const importantBodyMeasurements = useMemo(() => {
    if (!bodyMeasurements || !bodyMeasurementsSettings) {
      return [];
    }

    const importantBodyMeasurementsId =
      bodyMeasurementsSettings.data.important.map(important => important.id);

    return bodyMeasurements.data.filter(measurement =>
      importantBodyMeasurementsId.includes(measurement.id),
    );
  }, [bodyMeasurements, bodyMeasurementsSettings]);

  const getMeasurementBodyName = (bodyMeasurement: BodyMeasurementResource) => {
    const translation = bodyMeasurement.translations.find(
      translation => translation.lang === currentLanguage,
    );
    if (!translation) {
      return bodyMeasurement.name;
    }

    return translation.name;
  };

  return (
    <>
      <SectionLayout>
        <SectionHeadLayout>
          <SectionTitle>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_settings.section_title",
            )}
          </SectionTitle>
          <SectionHeadEditLayout onClick={() => setOpen(true)}>
            <EditIcon color="#7448D0" />
            <TabHeadActionEditLabel>{t("common.edit")}</TabHeadActionEditLabel>
          </SectionHeadEditLayout>
        </SectionHeadLayout>
        <SectionItemLayout>
          <SectionItemLabel>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_settings.monitored_measurements",
            )}
          </SectionItemLabel>
          <SectionItemChips>
            {importantBodyMeasurements.length === 0 && "-"}
            {importantBodyMeasurements.map(measurement => (
              <Chip
                key={measurement.id}
                color="primary"
                label={getMeasurementBodyName(measurement)}
              />
            ))}
          </SectionItemChips>
        </SectionItemLayout>
        <SectionItemLayout>
          <SectionItemLabel>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_settings.required_photos",
            )}
          </SectionItemLabel>
          <SectionItemChips>
            <Text>
              {bodyMeasurementsSettings?.data.requiredPhotos
                ? t("common.yes")
                : t("common.no")}
            </Text>
          </SectionItemChips>
        </SectionItemLayout>
      </SectionLayout>
      <SettingsSectionDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default SettingsSection;

const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
