import { MouseEventHandler, useState } from "react";
import { Link } from "react-router-dom";

import {
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  DuplicateFiles,
  Pen,
  ThreeVerticalPoints,
  TrashNew,
} from "@assets/icons";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { useAppTranslation, useModalState } from "@hooks";
import {
  useDeleteProductMutation,
  usePostToggleFavoriteFoodMutation,
} from "@hooks/queries";
import { Actions } from "@typeDefinitions";

import { FavoriteToggleButton } from "@components/FavoriteToggleButton";
import { LaptopWrapper } from "./ActionsColumnCell.styled";

interface ActionsColumnCellProps {
  id: number;
  isFavorite: boolean;
  actions: Actions;
  namePl: string;
  nameEn: string;
}

export const ActionsColumnCell = ({
  id,
  isFavorite,
  actions,
  namePl,
  nameEn,
}: ActionsColumnCellProps) => {
  const { canEdit, canDelete } = actions;
  const { isPolishChosen, t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [modalOpened, onModalOpen, onModalClose] = useModalState();
  const { mutate: deleteProduct, isLoading: isDeleting } =
    useDeleteProductMutation();
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteFoodMutation();

  const handleDelete = () => deleteProduct(id);
  const handleToggle = () => mutate(id.toString());
  const handleShowMore: MouseEventHandler<HTMLButtonElement> = e =>
    setAnchorEl(e.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  if (downMedium)
    return (
      <div className="flex items-start">
        <FavoriteToggleButton
          onChange={handleToggle}
          isLoading={isLoadingToggle}
          isFavorite={isFavorite}
        />

        <IconButton size="small" onClick={handleShowMore}>
          <ThreeVerticalPoints />
        </IconButton>
        <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseMenu}>
          <MenuItem
            component={Link}
            to={`/products/${id}/edit`}
            disabled={!canEdit}
          >
            {t("common.edit")}
          </MenuItem>
          <MenuItem component={Link} to={`/products/${id}/copy`}>
            {t("common.copy")}
          </MenuItem>
          <MenuItem onClick={onModalOpen} disabled={!canDelete || isDeleting}>
            {t("common.delete")}
          </MenuItem>
        </Menu>
        <ConfirmationModal
          confirmText={t("common.delete")}
          description={t("products.delete_modal.question", {
            variable: isPolishChosen ? namePl : nameEn,
          })}
          onCancel={onModalClose}
          open={modalOpened}
          onConfirm={handleDelete}
          title={t("products.delete_modal.delete")}
        />
      </div>
    );

  return (
    <LaptopWrapper>
      <FavoriteToggleButton
        onChange={handleToggle}
        isLoading={isLoadingToggle}
        isFavorite={isFavorite}
      />

      {canEdit && (
        <Link to={`/products/${id}/edit`}>
          <IconButton size="small">
            <Pen />
          </IconButton>
        </Link>
      )}
      {!canEdit && (
        <IconButton size="small" disabled>
          <Pen />
        </IconButton>
      )}

      <Link to={`/products/${id}/copy`}>
        <IconButton size="small">
          <DuplicateFiles />
        </IconButton>
      </Link>

      <IconButton
        size="small"
        onClick={onModalOpen}
        disabled={!canDelete || isDeleting}
      >
        <TrashNew />
      </IconButton>
      <ConfirmationModal
        confirmText={t("common.delete")}
        description={t("products.delete_modal.question", {
          variable: isPolishChosen ? namePl : nameEn,
        })}
        onCancel={onModalClose}
        open={modalOpened}
        onConfirm={handleDelete}
        title={t("products.delete_modal.delete")}
      />
    </LaptopWrapper>
  );
};
