import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { fetchSectionQueryKey } from "./useFetchSectionQuery";
import { searchSectionsQueryKey } from "./useSearchSectionsQuery";
import { SectionRequestDto, updateSection } from "@client";
import { ApiEndpoints } from "@typeDefinitions";

export function useUpdateSectionMutation(options?: MutationOptions) {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { id: number; input: SectionRequestDto }) =>
      updateSection(data.id, data.input),
    {
      ...options,
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries({
          queryKey: [ApiEndpoints.SearchSectionsNew],
        });

        queryClient.invalidateQueries({
          queryKey: [fetchSectionQueryKey],
        });

        queryClient.invalidateQueries({
          queryKey: [searchSectionsQueryKey],
        });

        options?.onSuccess && options.onSuccess(_, { id });
      },
    },
  );
}
