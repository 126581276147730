import React, { useState } from "react";
import {
  useForm,
  Controller,
  Control,
  FieldValues,
  useWatch,
} from "react-hook-form";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import FileUpload from "@components/FileUpload/FileUpload";
import { Labeled } from "@components/Labeled";
import HeartBeat from "@assets/img/HeartBeat.png";
import { useAppTranslation } from "@hooks";
import { Info } from "@assets/icons";

interface EducationalMaterialsCoverRadioGroupProps {
  control: Control<FieldValues>;
}

export const EducationalMaterialsCoverRadioGroup = ({
  control,
}: EducationalMaterialsCoverRadioGroupProps) => {
  const { t } = useAppTranslation();

  const selectedCoverType = useWatch({ control, name: "coverType" });

  const handleFileDrop = () => {
    // Set the file value
  };

  return (
    <FormControl component="fieldset">
      <Labeled label={t("educational_materials.cover")}>
        <Controller
          name="coverType"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <div className="flex gap-x-4 ">
                <div className="flex flex-col w-1/2 border border-gray-300 rounded-lg">
                  <div className=" flex justify-center items-center h-[180px] bg-[#E4F1F0] m-2 rounded-lg">
                    <div className="flex justify-center items-center w-32 h-32 rounded-full bg-[#00897B]/50">
                      <img
                        className="w-[90px]"
                        src={HeartBeat}
                        alt={t("educational_materials.template")}
                      />
                    </div>
                  </div>
                  <FormControlLabel
                    value="template"
                    control={<Radio />}
                    className="!m-0 !p-0"
                    label={t("educational_materials.template")}
                  />
                </div>
                <FileUpload
                  label=""
                  placeholder={t("educational_materials.addPhoto")}
                  infoText={t("educational_materials.photoUploadInfo")}
                  className="w-1/2"
                  wrapperClassName="h-64 !px-4"
                  onFileDrop={handleFileDrop}
                  additionalContent={
                    <p className="text-[10px] text-gray-450 flex gap-3 justify-center pt-2 items-center text-left">
                      <Info className="!w-4 !h-4" />
                      {t("educational_materials.maxPhotoQuote")}
                    </p>
                  }
                />

                {/* <div className="flex flex-col w-1/2 border border-gray-300 rounded-lg">
                  
                  <div className=" flex justify-center items-center h-[180px] bg-[#E4F1F0] m-2 rounded-lg">
                    <div className="flex justify-center items-center w-32 h-32 rounded-full bg-[#00897B]/50">
                      <img
                        className="w-[90px]"
                        src={HeartBeat}
                        alt={t("educational_materials.template")}
                      />
                    </div>
                  </div>
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    className="!m-0 !p-0"
                    label={t("educational_materials.customTemplate")}
                  />
                </div> */}
              </div>
            </RadioGroup>
          )}
        />
      </Labeled>
    </FormControl>
  );
};
