import { Avatar, IconButton, styled } from "@mui/material";
import { AvatarSize } from "./Avatar";

interface AvatarWrapperProps {
  size?: AvatarSize;
  status?: boolean;
}

const handleSizeType = (size: AvatarSize) => {
  switch (size) {
    case "extra-small":
      return `
        width: 1.75rem; 
        height: 1.75rem;
      `;
    case "small":
      return `
        width: 2rem; 
        height: 2rem;
      `;
    case "medium":
      return `
        width: 2.5rem; 
        height: 2.5rem;
      `;
    case "large":
      return `
        width: 4.75rem; 
        height: 4.75rem;
      `;
    case "huge":
      return `
        width: 6.75rem; 
        height: 6.75rem;
        font-size: 1.25rem;
        font-weight: 600;
      `;
    default:
      return `
        width: 2.5rem; 
        height: 2.5rem;
      `;
  }
};

export const AvatarMui = styled(Avatar, {
  shouldForwardProp: propName =>
    !["status", "size"].includes(propName as string),
})<AvatarWrapperProps>`
  font-size: 0.875rem;
  font-weight: 500;
  background: ${({ theme, status }) =>
    status ?? status === undefined
      ? theme.palette.primary.medium
      : theme.colors.neutral.light[200]};
  color: ${({ theme, status }) =>
    status === undefined
      ? theme.colors.neutral.dark[900]
      : status
      ? theme.palette.primary.main
      : theme.colors.neutral.dark[700]};
  ${({ size }) => size && handleSizeType(size)};
`;
