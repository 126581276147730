import { Dispatch, SetStateAction, useMemo } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { compact, uniq } from "lodash";

import { Label, Wrapper } from "./AutocompleteFilter.styled";
import { OptionIf } from "../FiltersBuilder";

export interface TagAutocompleteFilterProps {
  label: string;
  options: OptionIf[];
  selected?: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  placeholder: string;
  name?: string;
}

export const TagAutocompleteFilter = ({
  label,
  options,
  selected = [],
  setSelected,
  placeholder,
}: TagAutocompleteFilterProps) => {
  const selectedOptions = useMemo(
    () => compact(selected.map(s => options.find(o => o.id === s))),
    [options, selected],
  );
  const selectedOmitted = useMemo(
    () => selected.filter(s => !options.some(o => o.id === s)),
    [selected],
  );

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Autocomplete
        size="small"
        options={options}
        value={selectedOptions}
        onChange={(e, value) =>
          setSelected(uniq([...selectedOmitted, ...value.map(v => v.id ?? "")]))
        }
        multiple
        renderInput={params => (
          <TextField {...params} placeholder={placeholder} />
        )}
        sx={{
          "& .MuiAutocomplete-tag": {
            margin: "4px",
          },
        }}
        ChipProps={{
          color: "primary",
        }}
      />
    </Wrapper>
  );
};
