import { ExclamationMarkInTriangleIcon } from "@assets/icons_new";
import { useAppTranslation } from "@hooks";
import { Alert, Button, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProviderWrapperNew } from "themeNew";

interface DurationAlertProps {
  show: boolean;
  onSubmit?: (onSuccess: () => void) => void;
}

export const DurationAlert = ({ show, onSubmit }: DurationAlertProps) => {
  const { t } = useAppTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    const modifyPath = () => {
      const path = location.pathname.replace(/[^/]+$/, "schedule");
      if (path !== location.pathname) navigate(path);
    };
    onSubmit?.(modifyPath);
  };
  if (!show) return null;
  return (
    <div className="w-1/2">
      <ThemeProviderWrapperNew>
        <AlertStyled
          severity="warning"
          icon={<ExclamationMarkInTriangleIcon />}
        >
          <span>
            {t("addNutritionalProgram.step1.change_info")}&nbsp;
            <Bold>{t("addNutritionalProgram.step1.change_info_bold")}</Bold>
          </span>

          <ButtonStyled
            color="warning"
            variant="outlined"
            onClick={handleClick}
          >
            {t("addNutritionalProgram.step1.change_info_action")}
          </ButtonStyled>
        </AlertStyled>
      </ThemeProviderWrapperNew>
    </div>
  );
};

const AlertStyled = styled(Alert)`
  background: ${({ theme }) => theme.palette.warning.light};
  color: ${({ theme }) => theme.palette.warning.dark};
  padding: 6px 16px;
  display: flex;
  align-items: center;

  & .MuiAlert-icon {
    color: ${({ theme }) => theme.palette.warning.dark};
  }
  & .MuiAlert-message {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: unset;
  }
`;

const ButtonStyled = styled(Button)`
  background: ${({ theme }) => theme.palette.warning.light};
  color: ${({ theme }) => theme.palette.warning.dark};
  border-color: ${({ theme }) => theme.palette.warning.dark};
  height: 40px;
  white-space: nowrap;
  min-width: 166px;
`;

const Bold = styled("span")`
  font-weight: 600;
`;
