export const isProduction = () =>
  import.meta.env.VITE_APP_ENVIRONMENT === "prod" ||
  import.meta.env.VITE_APP_ENVIRONMENT === "production";

export const isLocal = () => import.meta.env.VITE_APP_ENVIRONMENT === "local";

export const apiUrl = () => {
  if (window === undefined) {
    return import.meta.env.VITE_APP_API_URL;
  }

  return window.location.origin === "https://panel.alloweat.com"
    ? "https://api.alloweat.com/api/v1"
    : import.meta.env.VITE_APP_API_URL;
};
