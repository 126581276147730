import { ReactNode, useEffect, useState } from "react";
import { ServingContext } from "./ServingContext";

interface ServingContextProviderProps {
  children: ReactNode;
  totalServings: number;
  servings?: number;
}
export const ServingContextProvider = ({
  children,
  totalServings,
  servings,
}: ServingContextProviderProps) => {
  const [commitedSelectedServings] = useState(servings);
  const [selectedServings, setSelectedServings] = useState(
    servings ?? totalServings,
  );

  useEffect(() => {
    if (servings !== undefined && servings !== selectedServings)
      setSelectedServings(servings);
  }, [servings]);

  return (
    <ServingContext.Provider
      value={{
        selectedServings,
        setSelectedServings,
        totalServings,
        commitedSelectedServings,
      }}
    >
      {children}
    </ServingContext.Provider>
  );
};
