import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { deletePatientProgram } from "@client";
import { patientProgramsSearchKeys } from "@hooks/queries/client/program";

export const useDeletePatientProgramMutation = (
  patientId: number,
  options?: MutationOptions,
) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((id: number) => deletePatientProgram(patientId, id), {
    ...options,
    onSuccess: data => {
      toast.success(t("programs.program_removed"));
      queryClient.invalidateQueries([[fetchPatientProgramQueryKey]]);
      queryClient.invalidateQueries([fetchProgramQueryKey, data?.id]);
      queryClient.invalidateQueries(patientProgramsSearchKeys.base(patientId));
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
