import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  MeasurementAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useMeasurementsAnswerForm = (
  values: MeasurementsAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    arm: yup.string().nullable(),
    biceps: yup.string().nullable(),
    waist: yup.string().nullable(),
    hip: yup.string().nullable(),
    thigh: yup.string().nullable(),
    calf: yup.string().nullable(),
    abdominal: yup.string().nullable(),
    chest: yup.string().nullable(),
    anyValue: yup.lazy((_, { parent }) =>
      [
        parent.arm,
        parent.biceps,
        parent.waist,
        parent.hip,
        parent.thigh,
        parent.calf,
        parent.abdominal,
        parent.chest,
      ].every(v => !v) && required
        ? yup.bool().required()
        : yup.bool().nullable(),
    ),
  });

  const form = useForm<MeasurementsAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values, { keepDirtyValues: true });
  }, [values]);

  return form;
};

export const mapMeasurementsAnswerForm = (
  data?: MeasurementAnswerQuestionDto,
): MeasurementsAnswerFormInputs => {
  if (!data)
    return {
      arm: null,
      biceps: null,
      waist: null,
      hip: null,
      thigh: null,
      calf: null,
      abdominal: null,
      chest: null,
    };
  return {
    arm: `${data.arm}`,
    biceps: `${data.tightBiceps}`,
    waist: `${data.waist}`,
    hip: `${data.hip}`,
    thigh: `${data.thigh}`,
    calf: `${data.calf}`,
    abdominal: `${data.abdominal}`,
    chest: `${data.chest}`,
  };
};

export const mapMeasurementsAnswerRequest = (
  data: MeasurementsAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  const { arm, biceps, waist, hip, thigh, calf, abdominal, chest } = data;
  return {
    questionId,
    answer: {
      arm: arm ? parseFloat(arm) : null,
      tightBiceps: biceps ? parseFloat(biceps) : null,
      waist: waist ? parseFloat(waist) : null,
      hip: hip ? parseFloat(hip) : null,
      thigh: thigh ? parseFloat(thigh) : null,
      calf: calf ? parseFloat(calf) : null,
      abdominal: abdominal ? parseFloat(abdominal) : null,
      chest: chest ? parseFloat(chest) : null,
    },
  };
};

export interface MeasurementsAnswerFormInputs {
  arm: string | null;
  biceps: string | null;
  waist: string | null;
  hip: string | null;
  thigh: string | null;
  calf: string | null;
  abdominal: string | null;
  chest: string | null;
}
