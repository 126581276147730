import SymptomsSummaryCard from "@views/dietician/PatientMonitoring2/components/Symptoms/SummaryCard/SymptomsSummaryCard";
import { useFetchMonitoringSymptoms } from "@hooks/queries/client/monitoring/useFetchMonitoringSymptoms";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { useMemo } from "react";

const SymptomsSummaryCardOfDays = () => {
  const { patientId, selectedDays } = useMonitoring();
  const { data } = useFetchMonitoringSymptoms({
    patientId,
    ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
  });

  const tags = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.data.map(item => ({ id: item.tag.id }));
  }, [data]);

  return <SymptomsSummaryCard tags={tags} />;
};

export default SymptomsSummaryCardOfDays;
