import { useAppTranslation } from "@hooks";
import { LoadingButton } from "@mui/lab";
import { Button, styled } from "@mui/material";

interface FooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  disabled?: boolean;
  loading?: boolean;
}
export const Footer = ({
  onCancel,
  onSubmit,
  disabled,
  loading,
}: FooterProps) => {
  const { t } = useAppTranslation();
  return (
    <Wrapper>
      <Button variant="outlined" onClick={onCancel}>
        {t("common.cancel")}
      </Button>
      <LoadingButton
        variant="contained"
        onClick={onSubmit}
        disabled={disabled}
        loading={loading}
      >
        {t("common.save")}
      </LoadingButton>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  width: 510px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background: ${({ theme }) => theme.palette.primary.light};
  gap: 8px;
`;
