import { useAppTranslation } from "@hooks/useAppTranslation";
import {
  CheckboxLabel,
  LogInText,
  RegisterClinicFormWrapper,
  RegistrationTitle,
  StyledA,
  StyledLink,
  TopRegistrationSection,
} from "./RegisterClinicForm.styled";
import { FormTextField } from "@components/FormTextField";
import { FormCheckboxMui } from "@components/FormCheckboxMui";
import { LoadingButton } from "@mui/lab";
import img from "@assets/img/dashboardEmptyState.png";
import { LOGIN } from "@routes";
import { FormItem } from "@components/FormItem";
import {
  mapRegisterClinicFormRequest,
  useRegisterClinicForm,
} from "./useRegisterClinicForm";
import { INVALID_NUMBER_ERROR, TAKEN_EMAIL_ERROR } from "@utils";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";
import { RegisterClinicRequest } from "@client";
import { ErrorText } from "@components/ErrorText";
import { useSearchParams } from "react-router-dom";
import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";
import { Stack, styled } from "@mui/material";
import ArrowIcon from "@assets/icons_new/chevron.svg?react";
import { decimalInput0, phoneInputE164 } from "@utils/inputs";

interface RegisterClinicFormProps {
  onClick: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  registerClinic: UseMutateFunction<
    unknown,
    AxiosError<ApiErrorIf, any>,
    RegisterClinicRequest,
    unknown
  >;
  setResendEmail: Dispatch<SetStateAction<string>>;
}

export const RegisterClinicForm = ({
  onClick,
  isLoading,
  registerClinic,
  setResendEmail,
}: RegisterClinicFormProps) => {
  const { t } = useAppTranslation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get(URL_CODE_PARAM);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useRegisterClinicForm();

  const handleRegisterClinic: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    const formClassName = e.currentTarget.parentElement?.className;
    handleSubmit(
      data => {
        registerClinic(mapRegisterClinicFormRequest({ ...data, code }), {
          onSuccess: () => {
            setResendEmail(data.email);
            onClick(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.dataLayer.push({
              "event": GTM_FORM_SUBMIT,
              "gtm.triggers": GTM_TRIGGERS,
              "gtm.elementClasses": formClassName,
            });
          },
          onError: err => {
            if (err.response?.data.errors.email?.includes(TAKEN_EMAIL_ERROR))
              setError("email", {
                message: t("auth.register_clinic.validation.email_taken"),
              });
            if (
              err.response?.data.errors.phone_number?.includes(
                INVALID_NUMBER_ERROR,
              )
            )
              setError("phoneNumber", {
                message: t(
                  "auth.register_clinic.validation.invalid_phone_number",
                ),
              });
          },
        });
      },
      e => console.log("e", e),
    )();
  };

  return (
    <RegisterClinicFormWrapper>
      <TopRegistrationSection>
        <img src={img} width={180} height={180} className="mx-auto" />
        <RegistrationTitle>
          {t("auth.register_clinic.create_account")}
        </RegistrationTitle>
      </TopRegistrationSection>

      <FormItem
        title={t("common.first_name")}
        extraEl={
          <>
            <FormTextField
              control={control}
              name="firstName"
              size="small"
              variant="outlined"
              placeholder={t("auth.register_clinic.placeholder_name")}
              FormHelperTextProps={{ hidden: true }}
            />
            <ErrorText content={errors.firstName?.message} />
          </>
        }
      />

      <FormItem
        title={t("common.last_name")}
        extraEl={
          <>
            <FormTextField
              control={control}
              name="lastName"
              size="small"
              variant="outlined"
              placeholder={t("auth.register_clinic.placeholder_last_name")}
              FormHelperTextProps={{ hidden: true }}
            />
            <ErrorText content={errors.lastName?.message} />
          </>
        }
      />

      <FormItem
        title={t("common.email_address")}
        extraEl={
          <>
            <FormTextField
              control={control}
              name="email"
              size="small"
              variant="outlined"
              placeholder={t("auth.register_clinic.placeholder_email")}
              FormHelperTextProps={{ hidden: true }}
            />
            <ErrorText content={errors.email?.message} />
          </>
        }
      />

      <FormItem
        title={t("auth.register_clinic.phone_number")}
        extraEl={
          <>
            <Stack direction="row" gap="16px">
              <FormCountryAutocompleteStyled
                control={control}
                id="phonePrefix"
                name="phonePrefix"
                size="small"
                variant="outlined"
                placeholder={t("patients.add.personal_info.phone_prefix")}
                disableClearable
                popupIcon={<ArrowIcon style={{ rotate: "270deg" }} />}
              />
              <FormTextField
                control={control}
                name="phoneNumber"
                size="small"
                variant="outlined"
                placeholder={t("auth.register_clinic.placeholder_phone_number")}
                FormHelperTextProps={{ hidden: true }}
                fullWidth
                InputProps={{
                  inputComponent: phoneInputE164 as any,
                }}
              />
            </Stack>
            <ErrorText content={errors.phoneNumber?.message} />
          </>
        }
      />

      <div className="grid items-center">
        <FormCheckboxMui
          name="policy"
          control={control}
          rebranding
          error={!!errors.policy?.message}
          label={
            <CheckboxLabel>
              {t("auth.register_clinic.accept_privacy")}{" "}
              <StyledLink
                to="https://alloweat.com/regulamin"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("auth.register_clinic.terms")}
              </StyledLink>{" "}
              {t("auth.register_clinic.and")}{" "}
              <StyledLink
                to="https://alloweat.com/polityka-prywatnosci"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("auth.register_clinic.privacy_policy")}
              </StyledLink>
              .
            </CheckboxLabel>
          }
        />
        <ErrorText content={errors.policy?.message} />
      </div>

      <LoadingButton
        loading={isLoading}
        fullWidth
        variant="contained"
        size="large"
        onClick={handleRegisterClinic}
        type="submit"
      >
        {t("auth.register_clinic.sign_up_specialist")}
      </LoadingButton>
      <p className="text-center	">
        {t("auth.register_clinic.have_an_account")}{" "}
        <LogInText>
          <StyledA color="primary" href={LOGIN}>
            {t("auth.register_clinic.log_in")}
          </StyledA>
        </LogInText>
      </p>
    </RegisterClinicFormWrapper>
  );
};

const GTM_FORM_SUBMIT = "gtm.formSubmit";
const GTM_TRIGGERS = "97646482_60";

export const URL_CODE_PARAM = "code";

const FormCountryAutocompleteStyled = styled(FormCountryAutocomplete)`
  width: 130px;
`;
