import { styled } from "@mui/material";

export const Label = styled("span")`
  font-family: Figtree, serif;
  font-weight: 500;
  color: #4d516e;
  line-height: 20px;
  font-size: 12px;
`;

export const Header = styled("span")`
  font: var(--font-figtree-semi-bold);
  color: #141414;
  line-height: 24px;
  font-size: 20px;
`;
