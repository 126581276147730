import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useFormContext, useWatch } from "react-hook-form";

import {
  mapRecipeRequest,
  RecipeEditInput,
} from "@components/RecipeForm/useRecipeEditForm";
import {
  UpdateScheduleRecipeMutationPayload,
  useUpdateScheduleRecipeMutation,
} from "@hooks/queries/schedule";

interface ScheduleRecipeEditWatch {
  recipe: BaseRecipeData;
  recipeLoading: boolean;
  programId: number;
  dayId: number;
  mealId: number;
}

interface BaseRecipeData {
  id: number;
  version?: number;
}

export const ScheduleRecipeEditWatch = ({
  recipeLoading,
  recipe,
  programId,
  dayId,
  mealId,
}: ScheduleRecipeEditWatch) => {
  const values = useWatch<RecipeEditInput>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<RecipeEditInput>();

  const { mutate, isLoading } = useUpdateScheduleRecipeMutation(programId);
  const [queued, setQueued] = useState(false);

  const createRequest = (
    data: RecipeEditInput,
    recipe: BaseRecipeData,
  ): UpdateScheduleRecipeMutationPayload => {
    return {
      recipe: mapRecipeRequest(data, recipe.id, recipe.version ?? 1),
      dayId,
      mealId,
    };
  };

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => mutate(createRequest(data, recipe)),
      err => console.log(err),
    )();
  };

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  useDebounce(
    () => {
      if (isLoading || recipeLoading) {
        setQueued(true);
      }

      if (
        (isDirty || isDirtyLatch) &&
        values &&
        recipe &&
        !(isLoading || recipeLoading)
      ) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !(isLoading || recipeLoading)) {
      update();
    }
  }, [queued, isLoading || recipeLoading]);

  return <></>;
};
