import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import type { FC } from "react";
import { AddDocument, Plus } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { EducationalMaterialsEmptyStatesDropdown } from "./EducationalMaterialsEmptyStatesDropdown";
import { EducationalMaterialsMenuButton } from "../EducationalMaterialsView.styled";
import { useTheme } from "@mui/material/styles";
import {
  ModalType,
  useEducationalModalContext,
} from "../_hooks/useEducationalMaterialsContext";
interface EducationalMaterialsAddButtonProps {
  className?: string;
}

export const EducationalMaterialsAddButton: FC<
  EducationalMaterialsAddButtonProps
> = ({ className }) => {
  const { t } = useAppTranslation();
  const { openModal } = useEducationalModalContext();

  const theme = useTheme();

  return (
    <EducationalMaterialsEmptyStatesDropdown
      horizontalOrigin="right"
      externalButton={setAnchorEl => (
        <Button
          variant="contained"
          className={className ? className : ""}
          onClick={e => setAnchorEl(e.currentTarget)}
          startIcon={
            <Plus size="w-[12px] h-[12px]" className="stroke-current" />
          }
        >
          {t("common.add")}
        </Button>
      )}
      // externalContent={setAnchorEl => (
      //   <MenuItem
      //     sx={{
      //       borderBottom: `1px solid ${theme.colors.neutral.light[200]}`,
      //     }}
      //     onClick={() => {
      //       setAnchorEl(null);
      //       openModal(ModalType.CREATE_CATEGORY);
      //     }}
      //   >
      //     <ListItemIcon>
      //       <EducationalMaterialsMenuButton>
      //         <AddDocument className="!text-[24px]" fill="none" />
      //         <span>{t("educational_materials.newCategory")}</span>
      //       </EducationalMaterialsMenuButton>
      //     </ListItemIcon>
      //   </MenuItem>
      // )}
    />
  );
};
