import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string, InferType } from "yup";

export const useEducationalMaterialsAddCategoryForm = () => {
  const resolver = object().shape({
    pl: string().nullable(),
    en: string().nullable(),
  });

  type QuickFiltersFormSchema = InferType<typeof resolver>;

  const defaultValues = {
    pl: "",
    en: "",
  };

  const form = useForm<QuickFiltersFormSchema>({
    defaultValues: defaultValues,
    resolver: yupResolver(resolver),
    mode: "onChange",
  });

  return form;
};
