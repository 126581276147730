import {
  GlobalClinicPatientSettingsResource,
  globalClinicPatientSettingsSchema,
} from "@client/resources/GlobalClinicPatientSettingsResource";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { logFetchValidateError } from "@utils/validate";
import { object } from "yup";

export type FetchGlobalPatientSettingsResponse =
  ApiResponse<GlobalClinicPatientSettingsResource>;

export const fetchGlobalPatientSettings =
  async (): Promise<FetchGlobalPatientSettingsResponse> => {
    const response = await fetchData(
      "/dietitian/clinic/global-patient-settings",
      APIMethods.GET,
    );

    return await schema
      .validate(response)
      .catch(
        logFetchValidateError("/dietitian/clinic/global-patient-settings"),
      );
  };

const schema = object({
  data: globalClinicPatientSettingsSchema.defined(),
});
