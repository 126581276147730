import { useState } from "react";

import { ProgramEmptyState } from "@views/emptyStates/ProgramEmptyState";
import AddPatientProgramDialog from "@views/dietician/PatientPrograms/AddPatientProgramDialog/AddPatientProgramDialog";
import { useFetchClientQuery } from "@hooks/queries";

type PatientProgramEmptyStateProps = {
  patientId: number;
};
const PatientProgramsEmptyState = ({
  patientId,
}: PatientProgramEmptyStateProps) => {
  const [openAddPatientProgramDialog, setOpenAddPatientProgramDialog] =
    useState(false);
  const { client } = useFetchClientQuery(patientId.toString());
  const active = !!client?.active;

  return (
    <>
      <ProgramEmptyState
        onClick={() => setOpenAddPatientProgramDialog(true)}
        disabled={!active}
      />
      <AddPatientProgramDialog
        patientId={patientId}
        setOpen={setOpenAddPatientProgramDialog}
        open={openAddPatientProgramDialog}
      />
    </>
  );
};

export default PatientProgramsEmptyState;
