import { SvgIconProps } from "@components/SvgIcon";
import { TagWithIcon } from "@components/TagWithIcon";
import { ReactNode } from "react";

export interface SpecialTagIf {
  id: string;
  label: string;
  Icon: (props: SvgIconProps) => JSX.Element;
}

export type DefinedLabelSpecialTagIf = SpecialTagIf & { label: string };

interface SpecialTagsProps {
  tags: SpecialTagIf[];
}

export const SpecialTags = ({ tags }: SpecialTagsProps) => {
  return (
    <>
      {tags.map(({ id, label, Icon }) => (
        <TagWithIcon key={id + label} label={label} Icon={Icon} />
      ))}
    </>
  );
};
