import { ReactNode, useState } from "react";
import type { FC, MouseEvent, Dispatch, SetStateAction } from "react";
import { Menu, MenuItem, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Pencil, TrashNew } from "@assets/icons";
import { IconButton } from "@components/IconButton";
import { useAppTranslation } from "@hooks";
import { CopyFrame } from "@assets/icons";
import { ActionsDto } from "@client";
import {
  ModalType,
  useEducationalModalContext,
} from "../_hooks/useEducationalMaterialsContext";
import { useEducationalMaterialsCopy } from "../_hooks/useEducationalMaterialsCopy";

type ExternalActionsType = (
  callback: (event: MouseEvent<HTMLElement>) => void,
) => ReactNode;
interface EducationalMaterialsDropdownProps {
  id: string;
  actions: ActionsDto;
  externalActions?: ExternalActionsType;
  setIsDropdownOpened?: Dispatch<SetStateAction<boolean>>;
}

export const EducationalMaterialsDropdown: FC<
  EducationalMaterialsDropdownProps
> = ({ id, actions, externalActions, setIsDropdownOpened }) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { openModal } = useEducationalModalContext();

  const { handleCopy } = useEducationalMaterialsCopy(id);

  const open = Boolean(anchorEl);

  const handleOpenDropdown = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
    setIsDropdownOpened?.(false);
  };

  const handleDeleteMaterial = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    openModal(ModalType.DELETE_MATERIAL, id);
    handleCloseDropDown();
  };

  const handleCopyMaterial = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleCopy();
    handleCloseDropDown();
  };

  const handleEditMaterial = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    openModal(ModalType.CREATE_DOCUMENT, id);
    handleCloseDropDown();
  };

  return (
    <div>
      {externalActions ? (
        externalActions(e => handleOpenDropdown(e))
      ) : (
        <IconButton color="gray" onClick={handleOpenDropdown}>
          <MoreVertIcon className="text-gray-450" />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseDropDown}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        slotProps={{
          paper: {
            style: {
              width: "200px",
            },
          },
        }}
      >
        <MenuItem disabled={!actions.canEdit} onClick={handleEditMaterial}>
          <ListItemIcon>
            <IconButton
              color="primary"
              className="flex gap-x-4 hover:bg-transparent"
              disabled={!actions.canEdit}
              //   onClick={() => {
              //     setCurrentTaskId(id);
              //     handleOpenCreateModal();
              //   }}
            >
              <Pencil className="text-gray-450 text-lg" />
              <span className="text-black">
                {t("educational_materials.edit")}
              </span>
            </IconButton>
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleCopyMaterial}>
          <ListItemIcon>
            <IconButton
              color="gray"
              className="flex hover:bg-transparent -ml-2 gap-x-2.5"
              //   onClick={() => {
              //     setCurrentTaskId(id);
              //     handleOpenDeleteModal();
              //   }}
            >
              <CopyFrame className="!w-8 !h-8" />
              <span className="text-black">
                {t("educational_materials.copy")}
              </span>
            </IconButton>
          </ListItemIcon>
        </MenuItem>
        <MenuItem disabled={!actions.canDelete} onClick={handleDeleteMaterial}>
          <ListItemIcon>
            <IconButton
              color="primary"
              className="flex gap-x-4 hover:bg-transparent"
              disabled={!actions.canDelete}
            >
              <TrashNew className="text-gray-450 text-lg" />
              <span className="text-black">
                {t("educational_materials.delete")}
              </span>
            </IconButton>
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  );
};
