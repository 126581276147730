import { useContext, useEffect, useMemo, useState } from "react";

import { TabContext } from "@mui/lab";

import { NutrientsTabProps } from "@components/PreviewDrawer/Recipe/NutrientsTab";
import { NutrientsByProduct } from "@components/PreviewDrawer/Recipe/NutrientsTab/NutrientsByProduct";
import { ServingContext } from "@components/PreviewDrawer/Recipe/ServingsContext";
import { NutrientsSection } from "@components/PreviewDrawer/common";
import { MacrosChart } from "@components/PreviewDrawer/common/MacrosChart";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { useAppTranslation } from "@hooks";
import { usePostPatientProductsContextMutation } from "@hooks/queries";
import { GLYCEMIC_INDEX_ID } from "@utils";

import { ProductsTable } from "./ProductsTable";
import {
  DividerStyled,
  TabPanelStyled,
  WrapperStyled,
} from "./RecipeNutrientsTab.styled";

export const RecipeNutrientsTab = ({
  nutrients,
  products,
}: NutrientsTabProps) => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState(Tabs.PRODUCTS);
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();
  const { selectedServings, totalServings } = useContext(ServingContext);
  const servingRatio = selectedServings / totalServings;

  const proportionalNutrients = useMemo(
    () =>
      nutrients.map(n => {
        if (n.id === GLYCEMIC_INDEX_ID) return n;
        return {
          ...n,
          value: n.value * servingRatio,
        };
      }),
    [nutrients, servingRatio],
  );

  const proportionalProducts = useMemo(
    () =>
      products.map(p => ({
        ...p,
        grams: p.grams * servingRatio,
        foodId: p.foodId,
      })),
    [products, servingRatio],
  );

  const productsIds = useMemo(
    () => proportionalProducts.map(({ foodId }) => foodId),
    [proportionalProducts],
  );

  useEffect(() => {
    if (productsIds.length) fetchProducts({ payload: productsIds });
  }, [products.length]);

  return (
    <TabContext value={tab}>
      <div className="grid gap-[24px] pt-[12px]">
        <div className="flex justify-between absolute right-[8px] top-0">
          <TabSwitch
            value={tab}
            onChange={setTab}
            tabs={[
              { id: Tabs.PRODUCTS, label: t("common.products") },
              { id: Tabs.GENERAL, label: t("recipe.general") },
            ]}
          />
        </div>

        <TabPanelStyled value={Tabs.PRODUCTS}>
          <WrapperStyled>
            <ProductsTable
              nutrients={proportionalNutrients}
              products={proportionalProducts}
            />

            <DividerStyled />

            <NutrientsByProduct products={proportionalProducts} />
          </WrapperStyled>
        </TabPanelStyled>

        <TabPanelStyled value={Tabs.GENERAL}>
          <WrapperStyled>
            <MacrosChart nutrients={proportionalNutrients} />

            <DividerStyled />

            <NutrientsSection nutrients={proportionalNutrients} />
          </WrapperStyled>
        </TabPanelStyled>
      </div>
    </TabContext>
  );
};

enum Tabs {
  PRODUCTS = "0",
  GENERAL = "1",
}
