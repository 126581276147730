import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { TabContext } from "@mui/lab";
import { CircularProgress } from "@mui/material";

import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchCreatorDietMealRecipeQuery } from "@hooks/queries/diets/creator";

import { mapRecipeEditForm, useRecipeEditForm } from "..";
import { BasicInformation } from "../BasicInformation";
import { ProductsAndNutritionalValues } from "../ProductsAndNutritionalValues";
import {
  ProductsContextProvider,
  mapDefaultProductsContext,
} from "../ProductsContext";
import { TagsPanel } from "../TagsPanel";
import { DrawerFooter } from "./DrawerFooter";
import { DrawerHeader } from "./DrawerHeader";
import { PanelWrapper, TabPanelStyled } from "./RecipeEditContent.styled";
import { RecipeId } from "../RecipeEditDrawer";

interface RecipeEditContentProps {
  mealId: string;
  recipeIds: RecipeId | null;
  onClose: () => void;
  dietId: string;
  onSuccess?: (id: number) => void;
}

export const RecipeEditContent = ({
  mealId,
  recipeIds,
  onClose,
  dietId,
  onSuccess,
}: RecipeEditContentProps) => {
  const { isPolishChosen } = useAppTranslation();
  const [tab, setTab] = useState(Tab.INFO);

  const recipeExist = recipeIds !== null;
  const { data, isLoading } = useFetchCreatorDietMealRecipeQuery(
    dietId,
    mealId,
    recipeIds?.dietRecipeId ?? "",
    { enabled: recipeExist },
  );

  const defaultValues = useMemo(() => mapRecipeEditForm(data), [data]);
  const defaultProducts = useMemo(
    () => mapDefaultProductsContext(data),
    [data],
  );
  const form = useRecipeEditForm(defaultValues);

  if (recipeExist && isLoading)
    return (
      <div className="grid h-full place-items-center">
        <CircularProgress />
      </div>
    );

  return (
    <FormProvider {...form}>
      <ProductsContextProvider defaultValues={defaultProducts}>
        <DrawerHeader
          onClose={onClose}
          tab={tab}
          setTab={setTab}
          title={isPolishChosen ? data?.recipe.name : data?.recipe.nameEn}
        />

        <TabContext value={tab}>
          <TabPanelStyled value={Tab.INFO}>
            <PanelWrapper>
              <BasicInformation />
            </PanelWrapper>
          </TabPanelStyled>
          <TabPanelStyled value={Tab.PRODUCTS}>
            <PanelWrapper>
              <ProductsAndNutritionalValues />
            </PanelWrapper>
          </TabPanelStyled>
          <TabPanelStyled value={Tab.TAGS}>
            <PanelWrapper>
              <TagsPanel />
            </PanelWrapper>
          </TabPanelStyled>
        </TabContext>

        <DrawerFooter
          dietId={dietId}
          onClose={onClose}
          mealId={mealId}
          recipeIds={recipeIds}
          defaultValues={defaultValues}
          onSuccess={onSuccess}
        />
      </ProductsContextProvider>
    </FormProvider>
  );
};

export enum Tab {
  INFO = "information",
  PRODUCTS = "products",
  TAGS = "tags",
}
