import { useCallback } from "react";
import type { ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { Upload } from "@assets/icons/DesignSystem";
import i18next from "i18next";
import { FileUploadWrapper } from "./FileUpload.styled";
import { Labeled } from "@components/Labeled";

export enum FileUploadError {
  FILE_TOO_LARGE = "file-too-large",
  FILE_INVALID_TYPE = "file-invalid-type",
  UNKNOWN_ERROR = "unknown-error",
}

interface FileUploadProps {
  onFileDrop: (files: File[]) => void;
  onFileRejected?: (reason: string) => void;
  acceptedFormats?: string[];
  maxSize?: number;
  label?: string;
  placeholder?: string;
  infoText?: string;
  icon?: ReactNode;
  wrapperClassName?: string;
  className?: string;
  additionalContent?: ReactNode;
  id?: string;
}

/**
 * A reusable drag-and-drop file upload component.
 *
 * @param {Object} props
 * @param {Array} props.acceptedFormats - Accepted file formats (example: ['image/png', 'image/jpeg']).
 * @param {number} props.maxSize - Maximum file size in bytes.
 * @param {string} props.label - Label to display above the file upload area.
 * @param {string} props.placeholder - Text to display in the drop zone area.
 * @param {string} props.infoText - Additional informational text (like "max size, dimensions").
 * @param {function} props.onFileDrop - Callback to handle the dropped or selected files.
 * @param {function} props.onFileRejected - Callback to handle file rejection.
 */
const FileUpload: React.FC<FileUploadProps> = ({
  acceptedFormats = ["image/png", "image/jpg", "image/jpeg"],
  maxSize = 1 * 1024 * 1024, // Default max size: 1MB
  label = i18next.t("educational_materials.materialCover"),
  placeholder = i18next.t("educational_materials.chooseFile"),
  infoText = i18next.t("educational_materials.infoText"),
  icon = <Upload className="text-xl text-purple-dark" />,
  wrapperClassName,
  className,
  onFileDrop,
  onFileRejected,
  additionalContent,
  id,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onFileDrop) {
        onFileDrop(acceptedFiles);
      }
    },
    [onFileDrop],
  );

  const onDropRejected = useCallback(
    (fileRejections: any[]) => {
      if (onFileRejected && fileRejections.length > 0) {
        const rejection = fileRejections[0];
        if (rejection.errors && rejection.errors.length > 0) {
          const error = rejection.errors[0];
          if (error.code === "file-too-large") {
            onFileRejected("file-too-large");
          } else if (error.code === "file-invalid-type") {
            onFileRejected("file-invalid-type");
          } else {
            onFileRejected("unknown-error");
          }
        }
      }
    },
    [onFileRejected],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: acceptedFormats.reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
    maxSize,
  });

  return (
    <div className={`flex flex-col ${className}`}>
      <Labeled label={`${label}`}>
        <FileUploadWrapper
          className={wrapperClassName}
          {...getRootProps()}
          id={id}
        >
          <input {...getInputProps()} />
          {icon}
          <p className="mt-6 text-[12px] text-neutral-dark-800 font-semibold">
            {placeholder}
          </p>
          <p className="text-[12px] text-gray-450">{infoText}</p>
          {additionalContent && additionalContent}
        </FileUploadWrapper>
      </Labeled>
    </div>
  );
};

export default FileUpload;
